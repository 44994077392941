import React from 'react';
import ConfirmationModal from '../../../../../../shared/design-system/components/atoms/confirmation-modal';
import { IProps } from './types';

const DeleteSchedule: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
}) => (
  <ConfirmationModal
    show={show}
    onClose={onClose}
    onSubmit={onSubmit}
    isRequestPending={isRequestPending}
    title="Do you want to delete this schedule?"
    content="If you delete the schedule, the sequences connected with this schedule will get affected."
    showSuccessButton={true}
    showCancelButton={true}
    cancelButtonText="Cancel"
  />
);

export default DeleteSchedule;
