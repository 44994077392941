import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import { UpdateAcademyTopicsProgressRequest } from '../types';

export const getAcademyTopics = () =>
  api.get<ResponseSuccess>('/academy/topics');

export const getAcademyTopicProgress = () =>
  api.get<ResponseSuccess>('/academy/user/topic/progress');

export const updateAcademyTopicsProgress = (
  payload: UpdateAcademyTopicsProgressRequest,
) => api.post<ResponseSuccess>('/academy/topics/complete', payload);
