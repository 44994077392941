import React from 'react';
import {
  Route,
  RouteProps,
  withRouter,
  RouteComponentProps,
} from 'react-router';
import { AuthHelper } from '../utils';

export type IProps = RouteComponentProps & Omit<RouteProps, 'render'>;
class ProtectedRoute extends React.Component<IProps, null> {
  constructor(props) {
    super(props);
    this.verifyAuth = this.verifyAuth.bind(this);
  }

  componentDidMount() {
    this.verifyAuth();
  }

  componentDidUpdate() {
    this.verifyAuth();
  }

  verifyAuth() {
    if (!AuthHelper.isAuthenticated) {
      const { location } = this.props;
      const { pathname, search } = location;
      const params = new URLSearchParams(search);
      const userEmail = params.get('userEmail');
      params.delete('authToken');
      AuthHelper.logout({
        fallbackTo: pathname,
        ...(userEmail && { query: params.toString() }),
      });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          AuthHelper.isAuthenticated ? <Component {...props} /> : null
        }
      />
    );
  }
}

export default withRouter(ProtectedRoute);
