import React from 'react';
import { IProps, IState } from './types';

class Timer extends React.Component<IProps, IState> {
  timer: any;

  constructor(props: IProps) {
    super(props);

    this.state = { seconds: this.props.seconds };

    this.timer = null;

    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({ seconds });

    // Check if we're at zero.
    if (seconds === 0) {
      const { onTimerFinish } = this.props;
      clearInterval(this.timer);
      onTimerFinish();
    }
  }

  render() {
    let seconds: string | number = this.state.seconds;

    // If seconds is in 1 digit then convert to 2 digit i.e. 2 => 02.
    if (Math.floor(seconds / 10) === 0) {
      seconds = `0${seconds}`;
    }

    return (
      <span className="verification-code-timer">
        Wait for {seconds} Seconds...
      </span>
    );
  }
}

export default Timer;
