import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { generateRandomString } from '../../shared/utils';

type Route = {
  id?: number;
  route: string;
  text?: string;
};

const routes: Route[] = [
  { route: '/login' },
  { route: '/signup' },
  { route: '/reset-password' },
  { route: '/reset-password/:code' },
  { route: '/settings' },
  { route: '/sequence' },
  { route: '/prospect' },
  { route: '/template' },
  { route: '/navigation' },
  { route: '/auth-indicator' },
  { route: '/design-system' },
  { route: '/' },
  { route: `/${generateRandomString(20)}` },
];

for (let i = 0; i < routes.length; i++) {
  routes[i].id = i + 1;
}

type TempNavigationProps = RouteComponentProps;

const TempNavigation: React.FC<TempNavigationProps> = () => {
  return (
    <div className="temp-navigation">
      <ul>
        {routes.map((route) => (
          <li key={route.id}>
            <Link to={route.route}>{route.text ?? route.route}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TempNavigation;
