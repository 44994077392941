import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../shared/design-system/components/atoms/icon/icon';
import { IProps } from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const DeleteApiTokenModal: React.FC<IProps> = ({
  hide,
  label,
  onSubmitDeleteApiToken,
  isRequestPending,
}) => {
  const { t } = useTranslation();
  const onSubmit = (e) => {
    e.preventDefault();
    onSubmitDeleteApiToken();
  };

  return (
    <Modal
      show
      className="delete-modal"
      titleContent={
        <div className="d-flex align-items-center">
          <Icon identifier="info" />
          <div className="pl-2"> {t('messages.delete_api_key')}</div>
        </div>
      }
      showCloseIcon={true}
      onClose={hide}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={t('labels.delete')}
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isRequestPending}
      onSubmit={onSubmit}
      cancelButtonVarient={Button.Variant.Outlined}
      onHide={hide}
    >
      <div className="bs-modal-inner-content">
        <p className="regular-2 popover-arrow-color-txt">
          {t('messages.delete_api_token_part_one')}
          {label && <strong>"{label}"</strong>}
          {t('messages.delete_api_token_part_two')}
        </p>
      </div>
    </Modal>
  );
};

export default DeleteApiTokenModal;
