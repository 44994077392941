import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Input from '../../../../../../shared/design-system/components/input';
import toaster from '../../../../../../shared/toaster';
import { copyToClipboard } from '../../../../../../shared/utils/copy-to-clipboard';
import validate from './validator';
import { IProps } from './types';

const CreateOrShowApiTokenModal: React.FC<IProps> = ({
  hide,
  onSubmit,
  showCreateApiTokenModal,
  showApiTokenModal,
  token,
  isRequestPending,
  error,
}) => {
  const { t } = useTranslation();
  const [label, setLabel] = useState<string>('');
  const [labelError, setLabelError] = useState<string>('');

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const inputError = validate('label', label);
    if (inputError) {
      setLabelError(inputError);
      return;
    }
    onSubmit(label);
  };

  const [showToken, setShowToken] = useState(false);
  const valueInput = useRef<HTMLInputElement>(null);

  const onCopyClickValueHandler = () => {
    copyToClipboard(token || '', valueInput.current);
    toaster.success(t('messages.api_key_copied_to_clipboard'));
  };

  return (
    <Modal
      show
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirm-modal-width"
    >
      {showCreateApiTokenModal && (
        <Modal.Body className="bs-modal-body">
          <form onSubmit={onSubmitHandler}>
            <div className="bs-modal-content">
              <div className="bs-modal-inner-content">
                <h3>{t('labels.create_api_key')}</h3>
                <p className="bs-mt-20 bs-mb-10">
                  {t('messages.create_api_token_label')}
                </p>
                <span className="required">{t('messages.label')}</span>
                <Input
                  name="label"
                  placeholder={t('messages.enter_your_label')}
                  variant={(labelError || error) && Input.Variant.Error}
                  autoFocus
                  caption={labelError ? t(`messages.${labelError}`) : error}
                  type="text"
                  className="bs-mb-20"
                  value={label}
                  onChange={(value) => setLabel(value.trim())}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                className="bs-mr-5"
                variant={Button.Variant.Outlined}
                onClick={hide}
              >
                {t('labels.cancel')}
              </Button>
              <Button
                type={Button.Type.Submit}
                variant={Button.Variant.Primary}
                isLoading={isRequestPending}
                disabled={isRequestPending}
              >
                {t('labels.create')}
              </Button>
            </div>
          </form>
        </Modal.Body>
      )}
      {showApiTokenModal && (
        <Modal.Body className="bs-modal-body">
          <div className="bs-modal-content">
            <div className="bs-modal-inner-content">
              <h3>{t('messages.your_new_api_key')}</h3>
              <p className="bs-mt-20 bs-mb-10">
                {t('messages.show_api_token')}
              </p>
              <Input
                readOnly={false}
                ref={valueInput}
                value={token}
                className="bs-mb-20"
                type={showToken ? 'text' : 'password'}
                icons={[
                  {
                    place: Input.IconPlace.Right,
                    identifier: showToken ? 'eye-alt' : 'eye',
                    className: 'pointer',
                    onClick: () => setShowToken(!showToken),
                  },
                ]}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="bs-mr-5"
              variant={Button.Variant.Outlined}
              onClick={hide}
            >
              {t('labels.close')}
            </Button>
            <Button
              variant={Button.Variant.Primary}
              onClick={onCopyClickValueHandler}
            >
              {t('labels.copy')}
            </Button>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default CreateOrShowApiTokenModal;
