import React from 'react';

type IProps = {
  className?: string;
};

const SMTPLabel: React.FC<IProps> = ({ className }) => (
  <div className={`smtp-label-container ${className}`}>
    <span className="semibold-1 font-medium gray-txt-15">SMTP</span>
  </div>
);

export default SMTPLabel;
