import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResetPasswordRequestPayload } from '../../types';
import { resetPassword } from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';

export const resetPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  ResetPasswordRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('resetPassword/resetPasswordRequest', async (args, thunkAPI) => {
  try {
    return await resetPassword(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
