import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  createScheduleRequest,
  updateScheduleRequest,
  getScheduleRequest,
  deleteScheduleRequest,
  setDefaultScheduleRequest,
} from './extra-actions';
import { Schedule } from '../../types/schedule';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  createScheduleRequest: RequestState;
  updateScheduleRequest: RequestState;
  getScheduleRequest: RequestState;
  deleteScheduleRequest: RequestState;
  setDefaultScheduleRequest: RequestState;
  schedules: Schedule[];
  isLoading: boolean;
  showError: boolean;
}

const initialState: State = {
  createScheduleRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateScheduleRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getScheduleRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteScheduleRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  setDefaultScheduleRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  schedules: [],
  isLoading: false,
  showError: false,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    hideCreateScheduleRequestError: (state) => {
      state.showError = false;
    },
  },
  extraReducers: (builder) => {
    //Create schedule
    builder.addCase(createScheduleRequest.pending, (state) => {
      state.createScheduleRequest.status = RequestStatus.Pending;
      state.createScheduleRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(createScheduleRequest.fulfilled, (state, action) => {
      state.createScheduleRequest.status = RequestStatus.Succeeded;
      state.createScheduleRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(createScheduleRequest.rejected, (state, action) => {
      state.createScheduleRequest.status = RequestStatus.Failed;
      state.createScheduleRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
      state.showError = !action.payload.isHandled;
    });

    //Get schedules
    builder.addCase(getScheduleRequest.pending, (state) => {
      state.getScheduleRequest.status = RequestStatus.Pending;
      state.getScheduleRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getScheduleRequest.fulfilled, (state, action) => {
      state.getScheduleRequest.status = RequestStatus.Succeeded;
      state.getScheduleRequest.message = action.payload.message;
      state.schedules = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getScheduleRequest.rejected, (state, action) => {
      state.getScheduleRequest.status = RequestStatus.Failed;
      state.getScheduleRequest.error = action.payload;
      state.isLoading = false;
    });

    //Update schedule
    builder.addCase(updateScheduleRequest.pending, (state) => {
      state.updateScheduleRequest.status = RequestStatus.Pending;
      state.updateScheduleRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(updateScheduleRequest.fulfilled, (state, action) => {
      state.updateScheduleRequest.status = RequestStatus.Succeeded;
      state.updateScheduleRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(updateScheduleRequest.rejected, (state, action) => {
      state.updateScheduleRequest.status = RequestStatus.Failed;
      state.updateScheduleRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    //Delete schedule
    builder.addCase(deleteScheduleRequest.pending, (state) => {
      state.deleteScheduleRequest.status = RequestStatus.Pending;
      state.deleteScheduleRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(deleteScheduleRequest.fulfilled, (state, action) => {
      state.deleteScheduleRequest.status = RequestStatus.Succeeded;
      state.deleteScheduleRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteScheduleRequest.rejected, (state, action) => {
      state.deleteScheduleRequest.status = RequestStatus.Failed;
      state.deleteScheduleRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    //// Make a default schedule
    builder.addCase(setDefaultScheduleRequest.pending, (state) => {
      state.setDefaultScheduleRequest.status = RequestStatus.Pending;
      state.setDefaultScheduleRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(setDefaultScheduleRequest.fulfilled, (state, action) => {
      state.setDefaultScheduleRequest.status = RequestStatus.Succeeded;
      state.setDefaultScheduleRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(setDefaultScheduleRequest.rejected, (state, action) => {
      state.setDefaultScheduleRequest.status = RequestStatus.Failed;
      state.setDefaultScheduleRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });
  },
});

export {
  createScheduleRequest,
  updateScheduleRequest,
  getScheduleRequest,
  deleteScheduleRequest,
  setDefaultScheduleRequest,
};
export const { hideCreateScheduleRequestError } = scheduleSlice.actions;
export default scheduleSlice.reducer;
