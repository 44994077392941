import moment from 'moment-timezone';
import { VariableTags } from '../enum/variable-tags';

const getYesterday = (currentTime: moment.Moment): string =>
  currentTime.subtract(1, 'days').format('dddd');

const getToday = (currentTime: moment.Moment): string =>
  currentTime.format('dddd');

const getTomorrow = (currentTime: moment.Moment): string =>
  currentTime.add(1, 'days').format('dddd');

const getCurrentTime = (currentTime: moment.Moment): string =>
  currentTime.format('h:mm A');

const getCurrentDate = (currentTime: moment.Moment, offset: number): string =>
  currentTime.add(offset, 'days').format('MMM D');

const getCurrentMonth = (currentTime: moment.Moment, offset: number): string =>
  currentTime.add(offset, 'months').format('MMMM');

const getCurrentYear = (currentTime: moment.Moment, offset: number): string =>
  currentTime.add(offset, 'years').format('YYYY');

const getCurrentQuarter = (
  currentTime: moment.Moment,
  offset: number,
): string => `Q${currentTime.add(offset, 'quarters').format('Q')}`;

const getCurrentWeekNumber = (currentTime: moment.Moment): string =>
  `Week ${currentTime.week()}`;

const getEndOfMonthDate = (currentTime: moment.Moment): string =>
  currentTime.endOf('month').format('MMM D');

const getEndOfQuarterDate = (currentTime: moment.Moment): string =>
  currentTime.endOf('quarter').format('MMM D');

const getCurrentTimePeriod = (currentTime: moment.Moment): string => {
  /* 
    "If the mail is sent between:
    6 am to 12 pm: morning 
    12 pm to 4 pm: afternoon 
    4 pm to 9 pm: evening
    9 pm to 6 am: night

    Note: small case only"
  */
  const currentHour = currentTime.hour();
  let timeText: string;

  switch (true) {
    case currentHour >= 6 && currentHour < 12:
      timeText = 'morning';
      break;
    case currentHour >= 12 && currentHour < 16:
      timeText = 'afternoon';
      break;
    case currentHour >= 16 && currentHour < 21:
      timeText = 'evening';
      break;
    default:
      timeText = 'night';
      break;
  }
  return timeText;
};

const firstLaterUpperCase = (word: string): string => {
  // should I create general util file and add it there?
  if (word.length === 0) return word;

  const firstLetter = word.charAt(0);

  const firstLetterCap = firstLetter.toUpperCase();

  const remainingLetters = word.slice(1) || '';

  return firstLetterCap + remainingLetters;
};

const getTimeBaseGreeting = (currentTime: moment.Moment): string => {
  const timeText = getCurrentTimePeriod(currentTime);
  return `Good ${firstLaterUpperCase(timeText)}`;
};

const getWorkingDays = () => [1, 2, 3, 4, 5]; // Static Working days (Monday to Friday)

const getWorkingDayWithOffset = (
  currentTime: moment.Moment,
  offset: number,
) => {
  const isNegative = offset < 0;
  const absOffset = Math.abs(offset);
  const actualWorkingDays = getWorkingDays();
  const actualWorkingDaysNo = actualWorkingDays.length;
  const extraDays = absOffset % actualWorkingDaysNo;
  const weeksToSkip = (absOffset - extraDays) / actualWorkingDaysNo;

  const nextWorkingDate = isNegative
    ? currentTime.subtract(weeksToSkip, 'weeks')
    : currentTime.add(weeksToSkip, 'weeks');
  if (extraDays > 0) {
    // eslint-disable-next-line
    for (let index = 0; index < extraDays; index++) {
      if (isNegative) nextWorkingDate.subtract(1, 'days');
      else nextWorkingDate.add(1, 'days');
      const tempSelectedDay = nextWorkingDate.day();
      if (!actualWorkingDays.includes(tempSelectedDay)) {
        // eslint-disable-next-line
        --index; // get back to previous index if not found.
      }
    }
  }
  return nextWorkingDate.day();
};

const getNextWorkingDay = (currentTime: moment.Moment): string => {
  const result: number = getWorkingDayWithOffset(currentTime, 1);
  return currentTime.day(result).format('dddd'); // Monday
};

const getNextWorkingDate = (
  currentTime: moment.Moment,
  offset: number,
): string => {
  const result = getWorkingDayWithOffset(currentTime, offset + 1);
  return currentTime.day(result).format('MMM DD'); // Jul 01
};

const getPreviousWorkingDay = (currentTime: moment.Moment): string => {
  const result = getWorkingDayWithOffset(currentTime, -1);
  return currentTime.day(result).format('dddd'); // Monday
};

const getPreviousWorkingDate = (currentTime: moment.Moment): string => {
  const result = getWorkingDayWithOffset(currentTime, -1);
  return currentTime.day(result).format('MMM DD'); // Jul 01
};

const getDaysLeftMonth = (currentTime: moment.Moment): string => {
  const lastDate = currentTime.clone().endOf('month');
  const daysLeft = lastDate.diff(currentTime, 'days'); // Add 1 to include today
  return `${daysLeft}`;
};

const getDaysLeftQuarter = (currentTime: moment.Moment): string => {
  const lastDate = currentTime.clone().endOf('quarter');
  const daysLeft = lastDate.diff(currentTime, 'days'); // Add 1 to include today
  return `${daysLeft}`;
};

const getDaysLeftIn = (
  currentTime: moment.Moment,
  inputDate: string,
  timezone: string,
) => {
  const targetDate = moment.tz(inputDate, 'DDMMYYYY', true, timezone);
  if (!targetDate.isValid()) return '0';

  const diff = targetDate.diff(currentTime.startOf('day'), 'days');
  return diff > 0 ? String(diff) : '0';
};

export const getVariableTagValue = (
  tagName: string,
  currentTime: moment.Moment,
  senderDetails: {
    firstName?: string;
    lastName?: string;
    email?: string;
    signature?: string;
  },
  offset = 0,
  specialN = '0',
  timezone = '',
): string | undefined => {
  switch (tagName) {
    case VariableTags.Yesterday:
      return getYesterday(currentTime);
    case VariableTags.Today:
      return getToday(currentTime);
    case VariableTags.Tomorrow:
      return getTomorrow(currentTime);
    case VariableTags.CurrentTime:
      return getCurrentTime(currentTime);
    case VariableTags.CurrentDate:
      return getCurrentDate(currentTime, offset);
    case VariableTags.CurrentMonth:
      return getCurrentMonth(currentTime, offset);
    case VariableTags.CurrentYear:
      return getCurrentYear(currentTime, offset);
    case VariableTags.CurrentQuarter:
      return getCurrentQuarter(currentTime, 0);
    case VariableTags.NextQuarter:
      return getCurrentQuarter(currentTime, 1);
    case VariableTags.PreviousQuarter:
      return getCurrentQuarter(currentTime, -1);
    case VariableTags.CurrentWeekNumber:
      return getCurrentWeekNumber(currentTime);
    case VariableTags.EndOfMonthDate:
      return getEndOfMonthDate(currentTime);
    case VariableTags.EndOfQuarterDate:
      return getEndOfQuarterDate(currentTime);
    case VariableTags.DaysLeftInMonth:
      return getDaysLeftMonth(currentTime);
    case VariableTags.DaysLeftInQuarter:
      return getDaysLeftQuarter(currentTime);
    case VariableTags.CurrentTimePeriod:
      return getCurrentTimePeriod(currentTime);
    case VariableTags.CurrentGreeting:
      return getTimeBaseGreeting(currentTime);
    case VariableTags.PreviousWorkingDay:
      return getPreviousWorkingDay(currentTime);
    case VariableTags.PreviousWorkingDate:
      return getPreviousWorkingDate(currentTime);
    case VariableTags.NextWorkingDay:
      return getNextWorkingDay(currentTime);
    case VariableTags.NextWorkingDate:
      return getNextWorkingDate(currentTime, offset);
    case VariableTags.SenderFirstName:
      return senderDetails?.firstName ?? '';
    case VariableTags.SenderLastName:
      return senderDetails?.lastName ?? '';
    case VariableTags.SenderEmail:
      return senderDetails?.email ?? '';
    case VariableTags.SenderSignature:
      return senderDetails?.signature ?? '';
    case VariableTags.DaysLeftIn:
      return getDaysLeftIn(currentTime, specialN, timezone);
    default:
      return undefined;
  }
};
