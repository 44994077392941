import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Images } from '../../../../../../../../shared/app-constants';
import Switch, {
  Size,
} from '../../../../../../../../shared/design-system/components/atoms/switch';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import DisableRampUpConfirmation from './disable-ramp-up-confirmation';
import YoutubeVideoModal from './youtube-video-modal';

const DailyRampUpToggle = ({
  isChecked,
  onChange,
  rampUpStatus,
  agencyConfig,
  isBulkUpdatingEmailAccounts = false,
}) => {
  const [showYoutubeVideo, setShowYoutubeVideo] = useState<boolean>(false);
  const [
    showDisableConfirmationModal,
    setShowDisableConfirmationModal,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  const onShowYoutubeVideoShow = () => {
    setShowYoutubeVideo(true);
  };

  const onShowYoutubeVideoHide = () => {
    setShowYoutubeVideo(false);
  };

  const onDisableConfirmationModalHide = () => {
    setShowDisableConfirmationModal(false);
  };

  const onDisableConfirmationModalSubmit = () => {
    onChange(false);
    onDisableConfirmationModalHide();
  };

  const onDailyRampUpChange = (value: boolean) => {
    if (!value && Boolean(rampUpStatus)) {
      setShowDisableConfirmationModal(true);
      return;
    }

    onChange(value);
  };

  const dailyRampUpToggleClasses = classNames([
    'daily-rampup-toggle',
    {
      'bulk-update-email-accounts-row--inner mb-2': isBulkUpdatingEmailAccounts,
    },
  ]);

  return (
    <>
      <div className={dailyRampUpToggleClasses}>
        <Switch
          checked={isChecked}
          size={Size.Small}
          className="daily-rampup-toggle-switch"
          onChange={onDailyRampUpChange}
        />

        <h3 className="setting-input-label">
          {t('messages.daily_rampup_title')}
        </h3>

        <OverlayTooltip
          className="daily-ramup-toggle-tooltip"
          text={
            <p className="tooltip-text">
              {t('messages.daily_rampup_tooltip_text_1')}{' '}
              {t('messages.daily_rampup_tooltip_text_2')}{' '}
            </p>
          }
          placement={Placement.Top}
        >
          <img className="info-icon" src={Images.InfoCircle} alt="Info" />
        </OverlayTooltip>

        {agencyConfig?.showYTVideoGuide && (
          <img
            className="youtube-icon"
            src={Images.YoutubeLogo}
            alt="Youtube"
            {...accessibleOnClick(onShowYoutubeVideoShow)}
          />
        )}
      </div>
      <YoutubeVideoModal
        show={showYoutubeVideo}
        youtubeVideoUrl={process.env.REACT_APP_DAILY_RAMPUP_YT_URL}
        onClose={onShowYoutubeVideoHide}
      />
      <DisableRampUpConfirmation
        show={showDisableConfirmationModal}
        onSubmit={onDisableConfirmationModalSubmit}
        onClose={onDisableConfirmationModalHide}
      />
    </>
  );
};

export default DailyRampUpToggle;
