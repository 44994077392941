import validator from 'validator';

type PasswordValidatorFunction = (password: string) => boolean;

type PasswordValidator = {
  uppercase: PasswordValidatorFunction;
  lowercase: PasswordValidatorFunction;
  numeric: PasswordValidatorFunction;
  minLength: PasswordValidatorFunction;
  maxLength: PasswordValidatorFunction;
  all: PasswordValidatorFunction;
};

const passwordValidator = {
  uppercase: (password) => validator.matches(password, /[A-Z]/),
  lowercase: (password) => validator.matches(password, /[a-z]/),
  numeric: (password) => validator.matches(password, /\d/),
  minLength: (password) => validator.isLength(password, { min: 8 }),
  maxLength: (password) => validator.isLength(password, { max: 64 }),
} as PasswordValidator;

passwordValidator.all = (password) =>
  passwordValidator.uppercase(password) &&
  passwordValidator.lowercase(password) &&
  passwordValidator.numeric(password) &&
  passwordValidator.minLength(password) &&
  passwordValidator.maxLength(password);

export default passwordValidator;
