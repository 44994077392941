import { VariableTags as VariableTagsEnum } from './enum/variable-tags';

export enum TagsDropdownTabs {
  MergeTags = 'merge-tags',
  VariableTags = 'variable-tags',
}

export type VariableTag = {
  key: string;
  categoryName: string;
  tags: {
    key: string;
    tag: string;
    value: VariableTagsEnum;
    offset?: number;
  }[];
};
