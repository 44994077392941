import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import { MyProfile } from '../../../../types/my-profile';
import {
  updateMyProfileRequest,
  updatePasswordRequest,
} from '../../extra-actions';
import {
  UpdateUserProfilePayload,
  UpdatePasswordPayload,
} from '../../../../types/request-payload';
import MyProfileContent from './my-profle-content';

const mapStateToProps = (state: RootState) => ({
  updatePasswordRequestStatus: state.myProfile.updatePasswordRequest.status,
  updatePasswordRequestMessage: state.myProfile.updatePasswordRequest.message,
  updatePasswordRequestError: state.myProfile.updatePasswordRequest.error,

  updateMyProfileRequestStatus: state.myProfile.updateMyProfileRequest.status,
  updateMyProfileRequestMessage: state.myProfile.updateMyProfileRequest.message,
  updateMyProfileRequestError: state.myProfile.updateMyProfileRequest.error,

  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
});

const mapDispatchToProps = {
  sendUpdateMyProfileRequest: (payload: UpdateUserProfilePayload) =>
    updateMyProfileRequest(payload),
  sendUpdatePasswordRequest: (payload: UpdatePasswordPayload) =>
    updatePasswordRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    getMyProfileRequest: () => void;
    myProfile: MyProfile;
  };

export default withRouter(connector(MyProfileContent));
