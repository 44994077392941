import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  cloneTemplate,
  createTemplate,
  deleteTemplate,
  getTemplates,
  shareTemplate,
  updateTemplate,
} from './helpers/templates.api';
import {
  CreateTemplateRequest,
  DeleteTemplateRequest,
  GetTemplatesRequest,
  UpdateTemplateRequest,
} from './types/request';
import { CloneTemplateRequest } from './types/request/clone-template';
import { ShareTemplateRequest } from './types/request/share-template';

export const getTemplatesRequest = createAsyncThunk<
  ResponseSuccess,
  GetTemplatesRequest,
  { rejectValue: ResponseErrorWithHandled }
>('templates/getTemplates', async (args, thunkAPI) => {
  try {
    return await getTemplates(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  CreateTemplateRequest,
  { rejectValue: ResponseErrorWithHandled }
>('templates/createTemplate', async (args, thunkAPI) => {
  try {
    return await createTemplate(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateTemplateRequest,
  { rejectValue: ResponseErrorWithHandled }
>('templates/updateTemplate', async (args, thunkAPI) => {
  try {
    return await updateTemplate(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteTemplateRequest,
  { rejectValue: ResponseErrorWithHandled }
>('templates/deleteTemplate', async (args, thunkAPI) => {
  try {
    return await deleteTemplate(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const cloneTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  CloneTemplateRequest,
  { rejectValue: ResponseErrorWithHandled }
>('templates/cloneTemplate', async (args, thunkAPI) => {
  try {
    return await cloneTemplate(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const shareTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  ShareTemplateRequest,
  { rejectValue: ResponseErrorWithHandled }
>('templates/shareTemplate', async (args, thunkAPI) => {
  try {
    return await shareTemplate(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
