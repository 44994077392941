import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './arrow';
import './horizontal-menu.scss';

type ChildType = React.ReactElement<{
  /**
      Required. id for every item, should be unique
  */
  itemId: string;
}>;

type IProps = {
  children: ChildType | ChildType[];
};

const HorizontalMenu: React.FC<IProps> = ({ children }) => (
  <div className="horizontal-menu-container">
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      options={{
        ratio: 0.9,
        rootMargin: '5px',
        threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
      }}
    >
      {children}
    </ScrollMenu>
  </div>
);

export default HorizontalMenu;
