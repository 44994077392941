import React, { useMemo } from 'react';
import { InfoCircle, Plus } from '@saleshandy/icons';

import Transfer from '../../design-system/components/transfer';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';

import type { IProps, SpintaxOption } from './types';
import { INSERT_NEW_SPINTAX_TAG, spintaxOptions } from './helpers';

import { Images } from '../../app-constants';
import { accessibleOnClick } from '../../utils/accessible-on-click';

const SpintaxDropdown: React.FC<IProps> = ({
  show,
  spintaxFor = 'subject',
  handleOnSpintaxToggle,
  handleAddSpintax,
  disabled = false,
}) => {
  const onSelect = (tag: string) => {
    handleAddSpintax(tag);
  };

  const spintaxIconMeta = useMemo(() => {
    if (spintaxFor === 'body') {
      return {
        src: Images.SpintaxBlack,
        height: 16,
        width: 16,
        className: '',
      };
    }

    return {
      src: Images.SpintaxGray,
      height: 18,
      width: 18,
      className: 'spintax-icon',
    };
  }, [spintaxFor]);

  return (
    <Transfer<SpintaxOption>
      options={spintaxOptions}
      header={
        <div className="w-100 d-flex align-items-center spintax-header">
          <div
            className="d-flex align-items-center insert-new-spintax"
            {...accessibleOnClick(() => {
              onSelect(INSERT_NEW_SPINTAX_TAG);
              handleOnSpintaxToggle(false);
            })}
          >
            <Plus height={16} width={16} />
            <span>Insert new spintax</span>
          </div>

          <OverlayTooltip
            className="tooltip-spintax"
            text="Spintax, short for 'Spin Syntax,' creates variations of sentences or words to personalize cold emails, which improves the engagement with leads."
          >
            <div className="d-flex align-items-center gray-txt-11 spintax-info-icon">
              <InfoCircle />
            </div>
          </OverlayTooltip>
        </div>
      }
      optionRenderer={(spintax) => (
        <div className="d-flex flex-column w-100">
          <div className="w-100 main-item">
            <div className="main-title">
              <span>{spintax?.name}</span>
            </div>
            {spintax?.options?.map((item) => (
              <div
                role="button"
                className="inner-item"
                {...accessibleOnClick(() => {
                  onSelect(item.tag);
                  handleOnSpintaxToggle(false);
                })}
              >
                {item?.name}
              </div>
            ))}
          </div>
        </div>
      )}
      show={show}
      onToggle={(value: boolean) => {
        handleOnSpintaxToggle(value);
      }}
      placement={Placement.BottomEnd}
      className="spintax-dropdown"
      showSearch={false}
    >
      <div>
        <OverlayTooltip
          text="Spintax"
          placement={Placement.Bottom}
          className="sidenav__item-tooltip"
        >
          <div
            className={`toolbar-btn ${disabled ? 'disabled' : ''} ${
              spintaxIconMeta?.className
            }`}
          >
            <img
              src={spintaxIconMeta?.src}
              width={spintaxIconMeta?.width}
              height={spintaxIconMeta?.height}
              alt="spintax"
            />
          </div>
        </OverlayTooltip>
      </div>
    </Transfer>
  );
};

export default SpintaxDropdown;
