import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '../../enums/request-status';
import { ResponseErrorWithHandled } from '../../../shared/types';
import { sendUpdatePreferredAppRequest } from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  sendUpdatePreferredAppRequest: RequestState;
  updatePreferredAppResponse: any;
};

const initialState: State = {
  sendUpdatePreferredAppRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updatePreferredAppResponse: null,
};

const connectEmailAccountSlice = createSlice({
  name: 'updatePreferredApp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get account subscription detail
    builder.addCase(sendUpdatePreferredAppRequest.pending, (state) => {
      state.sendUpdatePreferredAppRequest.status = RequestStatus.Pending;
      state.sendUpdatePreferredAppRequest.message = null;
      state.sendUpdatePreferredAppRequest.error = null;
    });
    builder.addCase(
      sendUpdatePreferredAppRequest.fulfilled,
      (state, action) => {
        state.sendUpdatePreferredAppRequest.status = RequestStatus.Succeeded;
        state.updatePreferredAppResponse = action.payload.payload;
      },
    );
    builder.addCase(sendUpdatePreferredAppRequest.rejected, (state, action) => {
      state.sendUpdatePreferredAppRequest.status = RequestStatus.Failed;
      state.sendUpdatePreferredAppRequest.message = action.payload.message;
      state.sendUpdatePreferredAppRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export default connectEmailAccountSlice.reducer;
