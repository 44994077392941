import React, { ReactNode, useCallback, useState } from 'react';
import { Button } from '@saleshandy/design-system';
import { ClassNameProp } from '../../../types';
import OptionList from './option-list';
import Search from './search';

type Key = {
  key: string | number;
};

// type TabType = Key & {
//   label: string;
// };

export type ExtendedOption = Key & {
  tab?: string | number;
  isDisabled?: boolean;
};

export type TransferProps<Option> = ClassNameProp & {
  multiSelect?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  options: Option[];
  // tabs?: TabType[];
  // showTabs?: boolean;
  optionRenderer: (option: Option) => ReactNode;
  filterOption?: (value: string, option: Option, options: Option[]) => boolean;
  customFilterOption?: (options: Option[], value: string) => Option[];
  optionFilterProp?: keyof Option;
  selectedOptionKey?: string | number | (string | number)[];
  onSearch?: (value: string) => void;
  onKeyDown?: (
    value: string,
    event?: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onSelect?: (option: Option) => void;
  onDeselect?: (option: Option) => void;
  onChange?: (selectedOption: Option[]) => void;
  showOptionsSeparator?: boolean;
  showSearch?: boolean;
  initialSearchValue?: string;
  showEmpty?: boolean;
  emptyText?: string;
  customEmptyElement?: ReactNode;
  searchPlaceholder?: string;
  isLoading?: boolean;
  clickListenerOn?: 'row' | 'checkbox';
  showListSeparator?: boolean;
  listSeparatorOptions?: Option[];
  transferContainerStyle?: any;
  autoFocusSearch?: boolean;
  downSearch?: boolean;
  loadMoreMeta?: {
    shouldShowLoadMoreBtn: boolean;
    onLoadMore: VoidFunction;
    isLoadingMore?: boolean;
    loadMoreText?: string;
  };
};

const Transfer = <T extends ExtendedOption>({
  multiSelect,
  header,
  footer,
  options,
  // tabs,
  // showTabs,
  optionRenderer,
  filterOption,
  customFilterOption,
  optionFilterProp,
  selectedOptionKey,
  onSearch,
  onKeyDown,
  onSelect,
  onDeselect,
  onChange,
  showOptionsSeparator,
  showSearch,
  initialSearchValue = '',
  showEmpty,
  emptyText,
  customEmptyElement,
  searchPlaceholder,
  className,
  isLoading,
  clickListenerOn = 'row',
  showListSeparator = false,
  listSeparatorOptions,
  transferContainerStyle,
  autoFocusSearch,
  downSearch,
  loadMoreMeta,
}: TransferProps<T>) => {
  const isSearchEnabled = !!(filterOption || optionFilterProp);

  const [searchText, setSearchText] = useState(initialSearchValue);

  const onSearchChangeHandler = useCallback(
    (value: string) => {
      isSearchEnabled && setSearchText(value);
      onSearch?.(value);
    },
    [isSearchEnabled, setSearchText, onSearch],
  );

  const onKeyDownHandlerHandler = useCallback(
    (value: string, event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(value, event);
    },
    [],
  );

  return (
    <div
      className={`transfer-container ${className}`}
      style={transferContainerStyle}
    >
      {header && <div className="transfer-header">{header}</div>}
      <div className="transfer-body disabled">
        {showSearch && !downSearch && (
          <Search
            value={searchText}
            onChange={onSearchChangeHandler}
            onKeyDown={onKeyDownHandlerHandler}
            searchPlaceholder={searchPlaceholder}
            autoFocusSearch={autoFocusSearch}
          />
        )}
        <div className="transfer-content">
          <OptionList<T>
            multiSelect={multiSelect}
            options={options}
            optionRenderer={optionRenderer}
            filterOption={filterOption}
            customFilterOption={customFilterOption}
            optionFilterProp={optionFilterProp}
            selectedOptionKey={selectedOptionKey}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onChange={onChange}
            showOptionsSeparator={showOptionsSeparator}
            searchText={searchText}
            showEmpty={showEmpty}
            emptyText={emptyText}
            customEmptyElement={customEmptyElement}
            isLoading={isLoading}
            clickListenerOn={clickListenerOn}
            showListSeparator={showListSeparator}
            listSeparatorOptions={listSeparatorOptions}
          />
          {loadMoreMeta?.shouldShowLoadMoreBtn && (
            <div className="load-more-wrapper">
              <Button
                className="gray-secondary-btn"
                variant="secondary"
                onClick={loadMoreMeta?.onLoadMore}
                isLoading={loadMoreMeta?.isLoadingMore || false}
                loadingText={loadMoreMeta?.loadMoreText || 'Load More'}
              >
                {loadMoreMeta?.loadMoreText || 'Load More'}
              </Button>
            </div>
          )}
        </div>
        {footer && <div className="transfer-footer">{footer}</div>}
        {showSearch && downSearch && (
          <Search
            value={searchText}
            onChange={onSearchChangeHandler}
            onKeyDown={onKeyDownHandlerHandler}
            searchPlaceholder={searchPlaceholder}
            autoFocusSearch={autoFocusSearch}
          />
        )}
      </div>
    </div>
  );
};

Transfer.defaultProps = {
  multiSelect: false,
  showTabs: false,
  showOptionsSeparator: false,
  showSearch: true,
  showEmpty: true,
  className: '',
};

// Removed the props value validation from here because of the types mismatch.
// Added in the transfer-overlay-trigger.tsx instead.
// Transfer.propTypes = {
//   selectedOptionKey: (props, propName) => {
//     if (
//       !props.multiSelect &&
//       Array.isArray(props[propName]) &&
//       props[propName].length > 1
//     ) {
//       throw new Error(
//         `\`${propName}\` can't contain multiple values if \`multiSelect\` is set to \`false\`. Either set \`multiSelect\` to \`true\` or pass only one value to \`${propName}\``,
//       );
//     }
//   },
// };

export default Transfer;
