import React from 'react';
import Modal from '../../../../../../../../shared/design-system/components/atoms/modal';

const YoutubeVideoModal = ({ show, youtubeVideoUrl, onClose }) => (
  <Modal
    show={show}
    titleContent="Video tutorial"
    className="youtube-video-modal"
    showCloseIcon
    hideFooter
    onClose={onClose}
    onHide={onClose}
  >
    <iframe
      width="560"
      height="315"
      src={youtubeVideoUrl}
      title="Video Tutorial"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Modal>
);

export default YoutubeVideoModal;
