import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getSafetySettingsRequest,
  updateSafetySettingsRequest,
} from './extra-actions';
import { SafetySetting } from '../../types/safety-settings';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  getSafetySettingsRequest: RequestState;
  updateSafetySettingsRequest: RequestState;
  safetySettings: SafetySetting[];
  isLoading: boolean;
}

const initialState: State = {
  getSafetySettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSafetySettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  safetySettings: [],
  isLoading: false,
};

const safetySettingsSlice = createSlice({
  name: 'safetySettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get safety settings
    builder.addCase(getSafetySettingsRequest.pending, (state) => {
      state.getSafetySettingsRequest.status = RequestStatus.Pending;
      state.getSafetySettingsRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getSafetySettingsRequest.fulfilled, (state, action) => {
      state.getSafetySettingsRequest.status = RequestStatus.Succeeded;
      state.getSafetySettingsRequest.message = action.payload.message;
      state.safetySettings = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getSafetySettingsRequest.rejected, (state, action) => {
      state.getSafetySettingsRequest.status = RequestStatus.Failed;
      state.getSafetySettingsRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update safety settings
    builder.addCase(updateSafetySettingsRequest.pending, (state) => {
      state.updateSafetySettingsRequest.status = RequestStatus.Pending;
      state.updateSafetySettingsRequest.error = null;
    });
    builder.addCase(updateSafetySettingsRequest.fulfilled, (state, action) => {
      state.updateSafetySettingsRequest.status = RequestStatus.Succeeded;
      state.updateSafetySettingsRequest.message = action.payload.message;
    });
    builder.addCase(updateSafetySettingsRequest.rejected, (state, action) => {
      state.updateSafetySettingsRequest.status = RequestStatus.Failed;
      state.updateSafetySettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export { getSafetySettingsRequest, updateSafetySettingsRequest };
export default safetySettingsSlice.reducer;
