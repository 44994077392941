import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import NoTagsFoundUI from './no-tags-found-ui';
import TagsDropdownSearchInput from './tags-dropdown-search-input';
import VariableTagCategory from './variable-tag-category';

import { VariableTag } from '../types';
import { getVariableTags } from '../helpers/get-variable-tags';
import { Schedule } from '../../../../components/settings/types/schedule';

type IProps = {
  onAddVariableTag: (tag: string) => void;
  onHide: VoidFunction;
  hideVariableTags: string[];
  disabledVariableTags: string[];
  sequenceSchedule: Schedule;
};

const VariableTagsContent: React.FC<IProps> = ({
  onAddVariableTag,
  onHide,
  hideVariableTags,
  disabledVariableTags,
  sequenceSchedule,
}) => {
  const [search, setSearch] = useState('');
  const [preExpanded, setPreExpanded] = useState(['1']);
  const variableTags = useMemo(() => getVariableTags(), []);

  const handleOnAddVariableTag = (tag: string) => {
    onAddVariableTag(tag);
    onHide();
  };

  const currentTime = useMemo(
    () =>
      sequenceSchedule?.timezone
        ? moment().tz(sequenceSchedule?.timezone)
        : moment(),
    [sequenceSchedule],
  );

  const options: VariableTag[] = useMemo(() => {
    if (!search) {
      return variableTags.map((category) => {
        // filter out tags that are in the hideVariableTags array
        const filteredTags = category.tags.filter(
          (tag) => !hideVariableTags.includes(tag.tag),
        );

        return {
          ...category,
          tags: filteredTags,
        };
      });
    }

    return (
      variableTags
        .map((category) => {
          // filter tags based on search and hideVariableTags array
          const filteredTags = category.tags.filter(
            (tag) =>
              tag.tag.toLowerCase().includes(search.toLowerCase()) &&
              !hideVariableTags.includes(tag.tag),
          );

          // return the category with the filtered tags if any match
          if (filteredTags.length > 0) {
            return {
              ...category,
              tags: filteredTags,
            };
          }

          // return null if no tags match the search
          return null;
        })
        // remove null categories
        .filter((category) => category !== null)
    );
  }, [variableTags, search, hideVariableTags]);

  // update preExpanded state with keys of filtered categories when search is not empty
  useEffect(() => {
    if (search) {
      const filteredKeys = options.map((option) => option.key);
      setPreExpanded(filteredKeys);
    } else {
      // reset to default when search is empty
      setPreExpanded(['1']);
    }
  }, [options]);

  return (
    <div>
      <TagsDropdownSearchInput search={search} onSearch={setSearch} />

      <div className="merge-variable-tags--list">
        {options?.length === 0 && search !== '' ? (
          <NoTagsFoundUI />
        ) : (
          options.map((option) => (
            <VariableTagCategory
              option={option}
              preExpanded={preExpanded}
              handleOnAddVariableTag={handleOnAddVariableTag}
              currentTime={currentTime}
              disabledVariableTags={disabledVariableTags}
              timezone={sequenceSchedule?.timezone}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default VariableTagsContent;
