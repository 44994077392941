import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginRequestPayload } from '../../types';
import { login } from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';

export const loginRequest = createAsyncThunk<
  ResponseSuccess,
  LoginRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('login/loginRequest', async (args, thunkAPI) => {
  try {
    return await login(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
