/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import { getCookie } from './cookies-handlers';
import {
  getUserEmail,
  getUserFullName,
  getUserFirstName,
  getUserLastName,
} from './user-details';
import store from '../../store';
// eslint-disable-next-line import/no-cycle
import { getPostLoadMeta } from '../../components/home/helpers/meta-api';

type InitializeThirdPartyIntegrations = {
  trackingId: string;
  showChatSupport?: boolean;
  firstName: string;
  lastName: string;
  email: string;
};

export const initializeThirdPartyIntegrations = ({
  trackingId,
  showChatSupport = false,
  firstName = '',
  lastName = '',
  email = '',
}: InitializeThirdPartyIntegrations): void => {
  const name = `${firstName} ${lastName}`;

  if (trackingId) {
    // Freshworks CRM Initialization
    window.fwcrm &&
      window.fwcrm.identify(trackingId, {
        'First Name': firstName,
        'Last Name': lastName,
        Email: email,
      });

    // Google Analytics Initialization
    window.analytics &&
      window.analytics.identify(trackingId, {
        'First Name': firstName,
        'Last Name': lastName,
        email,
        'Referral page': getCookie('shreferer'),
        'Landing page': getCookie('sh_ulp'),
      });
  }
};

// Sentry Initialization
export const initializeSentryIntegrations = (email: string): void =>
  email && Sentry.setUser({ email });

async function updateCelloAuthToken() {
  const reponse = await getPostLoadMeta();
  return reponse?.payload?.cellAuthToken;
}

export const initializeCello = () => {
  const state = store.getState().home;
  const celloAuthToken = state.getPostLoadMetaResponse?.celloAuthToken;
  window.cello = window.cello || { cmd: [] };
  // First step: Initialize Cello library
  const celloScript = document.createElement('script');
  celloScript.type = 'module';
  celloScript.src = process.env.REACT_APP_CELLO_SCRIPT;
  celloScript.async = true;
  if (celloAuthToken) {
    setTimeout(() => {
      document.body.appendChild(celloScript);
      window.cello.cmd.push((cello) => {
        cello.boot({
          productId: process.env.REACT_APP_CELLO_PRODUCT_ID,
          token: celloAuthToken,
          showOnBoot: true,
          customLauncherSelector: '.celloClass',
          hideDefaultLauncher: true,
          language: 'en',
          productUserDetails: {
            firstName: getUserFirstName(),
            lastName: getUserLastName(),
            fullName: getUserFullName(),
            email: getUserEmail(),
          },
          onTokenExpiring: async () => {
            const newToken = await updateCelloAuthToken();
            await window.Cello('updateToken', newToken);
          },
          onTokenExpired: async () => {
            const newToken = updateCelloAuthToken();
            await window.Cello('updateToken', newToken);
          },
        });
      });
    }, 1000);
  }
};
