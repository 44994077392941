/* eslint-disable import/no-cycle */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { EmailAccountMethod } from '../../../components/settings/enums/email-account';
import { Images } from '../../app-constants';
import { RequestStatus } from '../../enums/request-status';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { showGeneralErrorNotification } from '../../utils/errors';
import ImageIcon from '../images/image-icon';
import SMTPModal from './components/SMTP-modal/smtp-modal';
import { IProps } from './connect-email-account-options-container';
import { IState } from './types';

class ConnectEmailAccountOptions extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isSMTPModalOpen: false,
      accessToken: '',
      userEmailId: '',
    };

    this.showSMTPModal = this.showSMTPModal.bind(this);
    this.hideSMTPModal = this.hideSMTPModal.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    if (search) {
      const accessToken = new URLSearchParams(search).get('accessToken');
      const userEmailId = new URLSearchParams(search).get('email');

      this.setState((preState) => ({
        ...preState,
        accessToken,
        userEmailId,
      }));
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      sendUpdatePreferredAppRequestStatus,
      sendUpdatePreferredAppRequestError,
    } = this.props;
    const { accessToken } = this.state;

    if (
      sendUpdatePreferredAppRequestStatus !==
      prevProps.sendUpdatePreferredAppRequestStatus
    ) {
      if (sendUpdatePreferredAppRequestStatus === RequestStatus.Succeeded) {
        window.open(
          `${process.env.REACT_APP_V2_URL}/login?access_token=${accessToken}`,
          '_self',
        );
      } else if (
        sendUpdatePreferredAppRequestStatus === RequestStatus.Failed &&
        sendUpdatePreferredAppRequestError
      ) {
        showGeneralErrorNotification(sendUpdatePreferredAppRequestError);
      }
    }
  }

  handleConnectOnClick = (method: EmailAccountMethod) => {
    const { sendConnectEmailAccountRequest } = this.props;

    sendConnectEmailAccountRequest(method);
  };

  handleSendUpdatePreferredApp = () => {
    const { sendUpdatePreferredApp } = this.props;
    const { userEmailId } = this.state;

    sendUpdatePreferredApp({
      email: userEmailId,
      preferred_app: 'V2',
      from_v3: true,
    });
  };

  showSMTPModal() {
    this.setState({ isSMTPModalOpen: true });
  }

  hideSMTPModal() {
    this.setState({ isSMTPModalOpen: false });
  }

  render() {
    const { t, title, body, skipConnectEmailAccount } = this.props;
    const { isSMTPModalOpen } = this.state;

    return (
      <>
        {isSMTPModalOpen && (
          <SMTPModal
            onClose={this.hideSMTPModal}
            skipConnectEmailAccount={skipConnectEmailAccount}
          />
        )}
        <div className="connect-email-account-options-container">
          <h1 className="semibold-5">
            {title || t('labels.add_email_account')}
          </h1>
          <p className="regular-2 connect-email-account-description">
            {body || t('messages.connect_email_account_description')}
          </p>
          <div className="options-container">
            <h4>{t('labels.select_email_service')}</h4>
            <div className="options-main">
              <button
                type="button"
                className="btn-solid btn-solid-default options-item"
                onClick={() =>
                  this.handleConnectOnClick(EmailAccountMethod.Gmail)
                }
              >
                <ImageIcon src={Images.GmailIcon} alt="Google_Login" />
                <span className="regular-4">Gmail</span>
              </button>{' '}
              <button
                type="button"
                className="btn-solid btn-solid-default options-item"
                onClick={() =>
                  this.handleConnectOnClick(EmailAccountMethod.Microsoft)
                }
              >
                <ImageIcon src={Images.MicrosoftIcon} alt="Google_Login" />
                <span className="regular-4">Microsoft</span>
              </button>
              {/* We should not show SMTP option when user invites member from the V3 account */}
              <button
                type="button"
                className="btn-solid btn-solid-default options-item"
                onClick={this.showSMTPModal}
              >
                <div className="ribbon rib-danger">
                  <span className="rib-danger gray-txt-1">Beta</span>
                </div>
                <ImageIcon src={Images.Server} alt="Google_Login" />
                <span className="regular-4">SMTP/IMAP</span>
              </button>
            </div>
            <div className="local-footer">
              <span
                role="button"
                className="regular-2 btn-later"
                {...accessibleOnClick(skipConnectEmailAccount)}
              >
                {t('labels.do_later')}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ConnectEmailAccountOptions);
