const SpammyWords = [
  'As seen on',
  'Buy',
  'Buy direct',
  'Buying judgments',
  'Clearance',
  'Order',
  'Order status',
  'Orders shipped by shopper',
  'Dig up dirt on friends',
  'Meet singles',
  'Score with babes',
  'XXX',
  'Near you',
  'Additional income',
  'Be your own boss',
  'Compete for your business',
  'Double your',
  'Earn $',
  'Earn extra cash',
  'Earn per week',
  'Expect to earn',
  'Extra income',
  'Home based',
  'Home employment',
  'Homebased business',
  'Income from home',
  'Make $',
  'Make money',
  'Money making',
  'Online biz opportunity',
  'Online degree',
  'Opportunity',
  'Potential earnings',
  'University diplomas',
  'While you sleep',
  'Work at home',
  'Work from home',
  '$$$',
  'Affordable',
  'Bargain',
  'Beneficiary',
  'Best price',
  'Big bucks',
  'Cash',
  'Cash bonus',
  'Cashcashcash',
  'Cents on the dollar',
  'Cheap',
  'Check',
  'Claims',
  'Collect',
  'Compare rates',
  'Cost',
  'Credit',
  'Credit bureaus',
  'Discount',
  'Earn',
  'Easy terms',
  'F r e e',
  'Fast cash',
  'For just $XXX',
  'Hidden assets',
  'hidden charges',
  'Income',
  'Incredible deal',
  'Insurance',
  'Investment',
  'Loans',
  'Lowest price',
  'Million dollars',
  'Money',
  'Money back',
  'Mortgage',
  'Mortgage rates',
  'No cost',
  'No fees',
  'One hundred percent free',
  'Only $',
  'Pennies a day',
  'Price',
  'Profits',
  'Pure profit',
  'Quote',
  'Refinance',
  'Save $',
  'Save big money',
  'Save up to',
  'Serious cash',
  'Subject to credit',
  'They keep your money — no refund!',
  'Unsecured credit',
  'Unsecured debt',
  'US dollars',
  'Why pay more?',
  'Accept credit cards',
  'Cards accepted',
  'Check or money order',
  'Credit card offers',
  'Explode your business',
  'Full refund',
  'Investment decision',
  'No credit check',
  'No hidden Costs',
  'No investment',
  'Requires initial investment',
  'Sent in compliance',
  'Stock alert',
  'Stock disclaimer statement',
  'Stock pick',
  'Avoice bankruptcy',
  'Calling creditors',
  'Collect child support',
  'Consolidate debt and credit',
  'Consolidate your debt',
  'Eliminate bad credit',
  'Eliminate debt',
  'Financially independent',
  'Get out of debt',
  'Get paid',
  'Lower interest rate',
  'Lower monthly payment',
  'Lower your mortgage rate',
  'Lowest insurance rates',
  'Pre-approved',
  'Refinance home',
  'Social security number',
  'Your income',
  'Acceptance',
  'Accordingly',
  'Avoid',
  'Chance',
  'Dormant',
  'Freedom',
  'Here',
  'Hidden',
  'Home',
  'Leave',
  'Lifetime',
  'Lose',
  'Maintained',
  'Medium',
  'Miracle',
  'Never',
  'Passwords',
  'Problem',
  'Remove',
  'Reverses',
  'Sample',
  'Satisfaction',
  'Solution',
  'Stop',
  'Success',
  'Teen',
  'Wife',
  'Dear [email/friend/somebody]',
  'Friend',
  'Hello',
  'Ad',
  'Auto email removal',
  'Bulk email',
  'Click',
  'Click below',
  'Click here',
  'Click to remove',
  'Direct email',
  'Direct marketing',
  'Email harvest',
  'Email marketing',
  'Form',
  'Increase sales',
  'Increase traffic',
  'Increase your sales',
  'Internet market',
  'Internet marketing',
  'Marketing',
  'Marketing solutions',
  'Mass email',
  'Member',
  'Month trial offer',
  'More Internet Traffic',
  'Multi level marketing',
  'Notspam',
  'One time mailing',
  'Online marketing',
  'Open',
  'Opt in',
  'Performance',
  'Removal instructions',
  'Sale',
  'Sales',
  'Search engine listings',
  'Search engines',
  'Subscribe',
  'The following form',
  "This isn't junk",
  "This isn't spam",
  'Undisclosed recipient',
  'Unsubscribe',
  'Visit our website',
  'We hate spam',
  'Web traffic',
  'Will not believe your eyes',
  'Cures baldness',
  'Diagnostic',
  'Fast Viagra delivery',
  'Human growth hormone',
  'Life insurance',
  'Lose weight',
  'Lose weight spam',
  'Medicine',
  'No medical exams',
  'Online pharmacy',
  'Removes wrinkles',
  'Reverses aging',
  'Stop snoring',
  'Valium',
  'Viagra',
  'Vicodin',
  'Weight loss',
  'Xanax',
  '#1',
  '100% free',
  '100% satisfied',
  '4U',
  '50% off',
  'Billion',
  'Billion dollars',
  'Join millions',
  'Join millions of Americans',
  'Million',
  'One hundred percent guaranteed',
  'Thousands',
  'Being a member',
  'Billing address',
  'Call',
  'Cannot be combined with any other offer',
  'Confidentially on all orders',
  'Deal',
  'Financial freedom',
  'Gift certificate',
  'Giving away',
  'Guarantee',
  'Have you been turned down?',
  'If only it were that easy',
  'Important information regarding',
  'In accordance with laws',
  'Long distance phone offer',
  'Mail in order form',
  'Message contains',
  'Name brand',
  'Nigerian',
  'No age restrictions',
  'No catch',
  'No claim forms',
  'No disappointment',
  'No experience',
  'No gimmick',
  'No inventory',
  'No middleman',
  'No obligation',
  'No purchase necessary',
  'No questions asked',
  'No selling',
  'No strings attached',
  'No-obligation',
  'Not intended',
  'Obligation',
  'Off shore',
  'Offer',
  'Per day',
  'Per week',
  'Priority mail',
  'Prize',
  'Prizes',
  'Produced and sent out',
  'Reserves the right',
  'Shopping spree',
  'Stuff on sale',
  'Terms and conditions',
  'The best rates',
  'They’re just giving it away',
  'Trial',
  'Unlimited',
  'Unsolicited',
  'Vacation',
  'Vacation offers',
  'Warranty',
  'We honor all',
  'Weekend getaway',
  'What are you waiting for?',
  'Who really wins?',
  'Win',
  'Winner',
  'Winning',
  'Won',
  'You are a winner!',
  'You have been selected',
  'You’re a Winner!',
  'Cancel at any time',
  'Compare',
  'Copy accurately',
  'Get',
  'Give it away',
  'Print form signature',
  'Print out and fax',
  'See for yourself',
  'Sign up free today',
  'Free',
  'Free access',
  'Free cell phone',
  'Free consultation',
  'Free DVD',
  'Free gift',
  'Free grant money',
  'Free hosting',
  'Free installation',
  'Free Instant',
  'Free investment',
  'Free leads',
  'Free membership',
  'Free money',
  'Free offer',
  'Free preview',
  'Free priority mail',
  'Free quote',
  'Free sample',
  'Free trial',
  'Free website',
  'All natural',
  'All new',
  'Amazing',
  'Certified',
  'Congratulations',
  'Drastically reduced',
  'Fantastic deal',
  'For free',
  'Guaranteed',
  'It’s effective',
  'Outstanding values',
  'Promise you',
  'Real thing',
  'Risk free',
  'Satisfaction guaranteed',
  'Access',
  'Act now!',
  'Apply now',
  'Apply online',
  'Call free',
  'Call now',
  "Can't live without",
  'Do it today',
  "Don't delete",
  "Don't hesitate",
  'For instant access',
  'For Only',
  'For you',
  'Get it now',
  'Get started now',
  'Great offer',
  'Info you requested',
  'Information you requested',
  'Instant',
  'Limited time',
  'New customers only',
  'Now',
  'Now only',
  'Offer expires',
  'Once in lifetime',
  'One time',
  'Only',
  'Order now',
  'Order today',
  'Please read',
  'Special promotion',
  'Supplies are limited',
  'Take action now',
  'Time limited',
  'Urgent',
  'While supplies last',
  'Addresses on CD',
  'Beverage',
  'Bonus',
  'Brand new pager',
  'Cable converter',
  'Casino',
  'Celebrity',
  'Copy DVDs',
  'Laser printer',
  'Legal',
  'Luxury car',
  'New domain extensions',
  'Phone',
  'Rolex',
  'Stainless steel',
  '100% more',
  'Consolidate debt',
  'Double your cash',
  'Double your income',
  'Earn money',
  'Extra cash',
  'Free info',
  'Giveaway',
  'Lower rates',
  'Once in a lifetime',
  'Promise',
  'Risk-free',
  'Act now',
  'Become a member',
  'Don’t delete',
  'Exclusive deal',
  'Sign up free',
  'Take action',
  'This won’t last',
  'You are a winner',
  'Confidentiality',
  'Cures',
  'Dear friend',
  'Multi-level marketing',
  'No hidden fees',
  'No interest',
  'Not junk',
  'This isn’t a scam',
  'This isn’t junk',
  'This isn’t spam',
  'Undisclosed',
  'Billing',
  'Debt',
  'Fantastic',
  'Luxury',
  'Marketing solution',
  'Rates',
  'Removal',
  'Score',
  'Search engine',
  'Subject to…',
];

export default SpammyWords;
