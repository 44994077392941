import pageHeader from './page-header';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../store/root-reducer';
import { ReactNode } from 'react';

const mapStateToProps = (state: RootState) => ({
  pageHeaderBannerMessage: state.pageHeader.message,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> & {
  title: string;
  children?: ReactNode;
  heading?: ReactNode;
};

export default connector(pageHeader);
