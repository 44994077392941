import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

type ResizeHOCProps<T> = {
  onResize?: (width: number, height: number) => void;
  handleWidth?: boolean;
  handleHeight?: boolean;
} & T;

// Note: ReactResizeDetector adds a 'targetRef' prop to its child.

function withResizeDetectorRef<T, E = HTMLElement>(Component) {
  return React.forwardRef<E, ResizeHOCProps<T>>(
    ({ onResize, handleWidth, handleHeight, ...rest }, ref) => (
      <ReactResizeDetector
        handleWidth={handleWidth}
        handleHeight={handleHeight}
        onResize={onResize}
      >
        <Component {...rest} ref={ref} />
      </ReactResizeDetector>
    ),
  );
}

export default withResizeDetectorRef;
