import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  deleteMailboxEmail,
  getMailboxEmailActivity,
  getMailboxEmailsList,
  getMailboxesList,
  toggleMailboxEmailNotification,
} from './helpers/mailbox-emails.api';
import {
  MailboxEmailActivityPayload,
  MailboxEmailNotificationPayload,
  MailboxEmailsFiltersMeta,
} from './type';

export const getMailboxEmailsListRequest = createAsyncThunk<
  ResponseSuccess,
  MailboxEmailsFiltersMeta,
  { rejectValue: ResponseErrorWithHandled }
>('mailboxEmails/getMailboxEmailsList', async (args, thunkAPI) => {
  try {
    return await getMailboxEmailsList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getMailboxesListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('mailboxEmails/getMailboxesList', async (_args, thunkAPI) => {
  try {
    return await getMailboxesList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getMailboxEmailActivityRequest = createAsyncThunk<
  ResponseSuccess,
  MailboxEmailActivityPayload,
  { rejectValue: ResponseErrorWithHandled }
>('mailboxEmails/getMailboxEmailActivity', async (args, thunkAPI) => {
  try {
    return await getMailboxEmailActivity(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const toggleMailboxEmailNotificationRequest = createAsyncThunk<
  ResponseSuccess,
  MailboxEmailNotificationPayload,
  { rejectValue: ResponseErrorWithHandled }
>('mailboxEmails/toggleMailboxEmailNotification', async (args, thunkAPI) => {
  try {
    return await toggleMailboxEmailNotification(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteMailboxEmailRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('mailboxEmails/deleteMailboxEmail', async (args, thunkAPI) => {
  try {
    return await deleteMailboxEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
