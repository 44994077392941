import React from 'react';
import {
  Alert as ReactBootstrapAlert,
  Button as ReactBootstrapButton,
  AlertProps as ReactBootstrapAlertProps,
} from 'react-bootstrap';
import classNames from 'classnames';
import { isBoolean } from '../../utils';
import Icon from './atoms/icon/icon';
import { Modify } from '../../types';
import { ForwardRef } from '../../types/forward-ref';
import ImageIcon from '../../components/images/image-icon';

enum Variant {
  Primary = 'primary',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
}

enum Theme {
  New = 'new',
  Old = 'old',
}

type AlertProps = Modify<
  ReactBootstrapAlertProps,
  {
    variant?: Variant;
    theme?: Theme;
    banner?: boolean;
    dismissible?: boolean | string;
    iconIdentifier?: string;
    header?: React.ReactNode;
    onClose?: (
      event: React.MouseEvent<HTMLSpanElement | HTMLButtonElement>,
    ) => void;
    contentContainer?: JSX.Element;
    svgIcon?: string;
  }
>;

type AlertStatic = {
  Variant: typeof Variant;
  Theme: typeof Theme;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      variant,
      theme,
      banner,
      dismissible,
      iconIdentifier,
      header,
      children: content,
      className,
      onClose,
      contentContainer,
      svgIcon,
      ...rest
    },
    ref,
  ) => {
    const onDismissibleClick = (
      event: React.MouseEvent<HTMLSpanElement | HTMLButtonElement>,
    ) => onClose?.(event);

    const onCloseHandler = (a, event: React.MouseEvent<HTMLSpanElement>) =>
      onDismissibleClick(event);

    const alertClass = classNames([
      {
        'info-alert': variant === Variant.Primary,
        'warning-alert': variant === Variant.Warning,
        'danger-alert': variant === Variant.Danger,
        'success-alert': variant === Variant.Success,
      },
      {
        'alert-banner': banner,
      },
      {
        'new-theme': theme === Theme.New,
        'old-theme': theme === Theme.Old,
      },
      className,
    ]);

    let dismissibleProp = false;
    let dismissibleTextElement;

    if (dismissible) {
      if (isBoolean(dismissible)) {
        dismissibleProp = dismissible as boolean;
      } else {
        dismissibleTextElement = (
          <div className="d-flex justify-content-end alert-button">
            <ReactBootstrapButton variant="link" onClick={onDismissibleClick}>
              {dismissible}
            </ReactBootstrapButton>
          </div>
        );
      }
    }

    let iconElement;

    if (iconIdentifier) {
      iconElement = <Icon identifier={iconIdentifier} />;
    }

    let body;

    if (header) {
      body = (
        <>
          <ReactBootstrapAlert.Heading>{header}</ReactBootstrapAlert.Heading>
          <p>{content}</p>
        </>
      );

      if (iconElement) {
        iconElement = <div className="alert-icon">{iconElement}</div>;

        body = (
          <div className="d-flex">
            {iconElement}
            <div>{body}</div>
          </div>
        );
      }
    } else {
      body = (
        <p>
          {iconElement}
          {content}
        </p>
      );
    }

    if (contentContainer) {
      body = (
        <div className="d-flex alert-body">
          {svgIcon && (
            <div className="icon-div">
              <ImageIcon src={svgIcon} />
            </div>
          )}
          <div className="content-div">{contentContainer}</div>
        </div>
      );
    }

    body = (
      <>
        {body}
        {dismissibleTextElement}
      </>
    );

    return (
      <div className="alert-component">
        <ReactBootstrapAlert
          {...rest}
          variant={variant}
          dismissible={dismissibleProp}
          className={alertClass}
          onClose={onCloseHandler}
          ref={ref}
        >
          {body}
        </ReactBootstrapAlert>
      </div>
    );
  },
) as ForwardRef<HTMLDivElement, AlertProps> & AlertStatic;

Alert.displayName = 'Alert';

Alert.Variant = Variant;
Alert.Theme = Theme;

Alert.defaultProps = {
  variant: Variant.Primary,
  theme: Theme.Old,
  banner: false,
  dismissible: false,
};

export default Alert;
