import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import {
  getEspLimits,
  updateMaxSendingLimit,
} from '../../helpers/email-account-sending-limit';
import { UpdateMaxSendingLimitPayload } from './types';

export const getEspLimitsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccountSendingLimit/getEspLimits', async (args, thunkAPI) => {
  try {
    return await getEspLimits();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateMaxSendingLimitRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateMaxSendingLimitPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccountSendingLimit/updateMaxSendingLimit',
  async ({ emailAccountId, limit }, thunkAPI) => {
    try {
      return await updateMaxSendingLimit({ emailAccountId, limit });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
