import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateSafetySettingsRequestPayload } from '../../types/request-payload';
import {
  getSafetySettings,
  updateSafetySettings,
} from '../../helpers/safety-settings.api';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

export const getSafetySettingsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('safetySettings/getSafetySettingsRequest', async (args, thunkAPI) => {
  try {
    return await getSafetySettings();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateSafetySettingsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSafetySettingsRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('safetySettings/updateSafetySettingsRequest', async (payload, thunkAPI) => {
  try {
    return await updateSafetySettings(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
