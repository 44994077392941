import validator from 'validator';

const validate = (name, value) => {
  if (name === 'label') {
    if (validator.isEmpty(value)) {
      return 'invalid_label_input';
    }
    if (!validator.matches(value, /^[a-zA-Z0-9-\s]+$/)) {
      return 'only_letters_allowed';
    }
    if (!validator.isLength(value, { min: 1, max: 25 })) {
      return 'label_less_than';
    }
  }
  return null;
};

export default validate;
