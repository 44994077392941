import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { verifyResetPasswordCodeRequest } from './extra-actions';

type State = {
  status: RequestStatus;
  message: string;
  error: any;
};

const initialState: State = {
  status: RequestStatus.Ideal,
  message: '',
  error: '',
};

const resetPasswordCallbackSlice = createSlice({
  name: 'resetPasswordCallback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyResetPasswordCodeRequest.pending, (state) => {
      state.status = RequestStatus.Pending;
    });
    builder.addCase(
      verifyResetPasswordCodeRequest.fulfilled,
      (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.message = action.payload.message;
      },
    );
    builder.addCase(
      verifyResetPasswordCodeRequest.rejected,
      (state, action) => {
        state.status = RequestStatus.Failed;
        state.error = action.payload;
      },
    );
  },
});

export { verifyResetPasswordCodeRequest };
export default resetPasswordCallbackSlice.reducer;
