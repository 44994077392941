export type ApplyCouponCodeRequest = {
  couponCode: string;
  planId: number;
  amount: number;
};

export enum DiscountType {
  Amount = 'amount',
  Percentage = 'percentage',
}

export type ApplyCouponCodeResponse = {
  discountType: DiscountType;
  discountValue: number;
  message: string;
  isError: boolean;
};
