import { VariableTags } from '../enum/variable-tags';
import { VariableTag } from '../types';

export const getVariableTags = (): VariableTag[] => [
  {
    key: '1',
    categoryName: 'Sender Information',
    tags: [
      {
        key: '1',
        tag: '{{SenderFirstName}}',
        value: VariableTags.SenderFirstName,
      },
      {
        key: '2',
        tag: '{{SenderLastName}}',
        value: VariableTags.SenderLastName,
      },
      {
        key: '3',
        tag: '{{SenderEmail}}',
        value: VariableTags.SenderEmail,
      },
      {
        key: '4',
        tag: '{{SenderSignature}}',
        value: VariableTags.SenderSignature,
      },
    ],
  },
  {
    key: '2',
    categoryName: 'Time Based',
    tags: [
      {
        key: '1',
        tag: '{{CurrentTimePeriod}}',
        value: VariableTags.CurrentTimePeriod,
      },
      {
        key: '2',
        tag: '{{CurrentGreeting}}',
        value: VariableTags.CurrentGreeting,
      },
      {
        key: '3',
        tag: '{{Yesterday}}',
        value: VariableTags.Yesterday,
      },
      {
        key: '4',
        tag: '{{Today}}',
        value: VariableTags.Today,
      },
      {
        key: '5',
        tag: '{{Tomorrow}}',
        value: VariableTags.Tomorrow,
      },
      {
        key: '6',
        tag: '{{CurrentTime}}',
        value: VariableTags.CurrentTime,
      },
      {
        key: '7',
        tag: '{{CurrentDate}}',
        value: VariableTags.CurrentDate,
      },
      {
        key: '8',
        tag: '{{CurrentDate + N}}',
        value: VariableTags.CurrentDate,
        offset: 3,
      },
      {
        key: '9',
        tag: '{{CurrentDate - N}}',
        value: VariableTags.CurrentDate,
        offset: -3,
      },
    ],
  },
  {
    key: '3',
    categoryName: 'Calendar Based',
    tags: [
      {
        key: '1',
        tag: '{{PreviousWorkingDay}}',
        value: VariableTags.PreviousWorkingDay,
      },
      {
        key: '2',
        tag: '{{PreviousWorkingDate}}',
        value: VariableTags.PreviousWorkingDate,
      },
      {
        key: '3',
        tag: '{{NextWorkingDay}}',
        value: VariableTags.NextWorkingDay,
      },
      {
        key: '4',
        tag: '{{NextWorkingDate}}',
        value: VariableTags.NextWorkingDate,
      },
      {
        key: '5',
        tag: '{{NextWorkingDate + N}}',
        value: VariableTags.NextWorkingDate,
        offset: 3,
      },
      {
        key: '6',
        tag: '{{NextWorkingDate - N}}',
        value: VariableTags.NextWorkingDate,
        offset: -3,
      },
      {
        key: '7',
        tag: '{{CurrentMonth}}',
        value: VariableTags.CurrentMonth,
      },
      {
        key: '8',
        tag: '{{CurrentMonth + N}}',
        value: VariableTags.CurrentMonth,
        offset: 2,
      },
      {
        key: '9',
        tag: '{{CurrentMonth - N}}',
        value: VariableTags.CurrentMonth,
        offset: -2,
      },
      {
        key: '10',
        tag: '{{CurrentYear}}',
        value: VariableTags.CurrentYear,
      },
      {
        key: '11',
        tag: '{{CurrentYear + N}}',
        value: VariableTags.CurrentYear,
        offset: 2,
      },
      {
        key: '12',
        tag: '{{CurrentYear - N}}',
        value: VariableTags.CurrentYear,
        offset: -2,
      },
      {
        key: '13',
        tag: '{{CurrentQuarter}}',
        value: VariableTags.CurrentQuarter,
      },
      {
        key: '14',
        tag: '{{PreviousQuarter}}',
        value: VariableTags.PreviousQuarter,
      },
      {
        key: '15',
        tag: '{{NextQuarter}}',
        value: VariableTags.NextQuarter,
      },
      {
        key: '16',
        tag: '{{CurrentWeekNumber}}',
        value: VariableTags.CurrentWeekNumber,
      },
      {
        key: '17',
        tag: '{{DaysLeftInMonth}}',
        value: VariableTags.DaysLeftInMonth,
      },
      {
        key: '18',
        tag: '{{DaysLeftInQuarter}}',
        value: VariableTags.DaysLeftInQuarter,
      },
      {
        key: '19',
        tag: '{{EndOfMonthDate}}',
        value: VariableTags.EndOfMonthDate,
      },
      {
        key: '20',
        tag: '{{EndOfQuarterDate}}',
        value: VariableTags.EndOfQuarterDate,
      },
      {
        key: '20',
        tag: '{{DaysLeftIn / DDMMYYYY}}',
        value: VariableTags.DaysLeftIn,
      },
    ],
  },
];
