import React, { useEffect } from 'react';
import classnames from 'classnames';

import {
  BulkSelectedDNCListEmailsAndDomains,
  DoNotContactListEmailsAndDomains,
} from '../../../../../../types/do-not-contact-list';
import { constants } from '../../../../../../../../shared/enums/constants';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import {
  getFormattedEmailsDomainsCount,
  getSelectedEmailsDomainsCount,
} from './utils/bulk-action-helper-function';

type IProps = {
  selectedDNCListEmailsAndDomains: DoNotContactListEmailsAndDomains[];
  bulkSelectedDNCListEmailsAndDomains: BulkSelectedDNCListEmailsAndDomains;
  totalEmailsAndDomains: number;
  handleSelectAllRows: VoidFunction;
  handleClearSelection: VoidFunction;
};

const SelectedEmailsDomainsRowInfoBanner: React.FC<IProps> = ({
  selectedDNCListEmailsAndDomains,
  bulkSelectedDNCListEmailsAndDomains,
  totalEmailsAndDomains,
  handleSelectAllRows,
  handleClearSelection,
}) => {
  const selectedEmailsDomainsCount = getSelectedEmailsDomainsCount(
    selectedDNCListEmailsAndDomains,
    bulkSelectedDNCListEmailsAndDomains,
    totalEmailsAndDomains,
  );

  useEffect(() => {
    if (selectedEmailsDomainsCount === 0) {
      handleClearSelection();
    }
  }, [selectedEmailsDomainsCount]);

  const divClasses = classnames([
    'email-selected-row-info',
    {
      'email-selected-row-info-waring':
        selectedDNCListEmailsAndDomains.length > 0 ||
        bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
    },
    {
      'email-selected-row-info-danger':
        selectedDNCListEmailsAndDomains.length === 0 &&
        !bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
    },
  ]);

  const spanClasses = classnames([
    'semibold-1',
    {
      'popover-arrow-color-txt':
        selectedDNCListEmailsAndDomains.length > 0 ||
        bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
    },
    {
      'red-txt-12':
        selectedDNCListEmailsAndDomains.length === 0 &&
        !bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
    },
  ]);

  const renderSelectedEmailAccountDetails = () => (
    <div className="d-flex">
      {selectedEmailsDomainsCount ===
        constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT && (
        <span className={`${spanClasses} mr-1`}>First</span>
      )}

      <span className={`${spanClasses} mr-2`}>
        {getFormattedEmailsDomainsCount(selectedEmailsDomainsCount)}
      </span>

      {!bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected &&
        totalEmailsAndDomains !== selectedDNCListEmailsAndDomains?.length && (
          <>
            <span
              role="button"
              className="semibold-1 blue-txt-11"
              {...accessibleOnClick(handleSelectAllRows)}
            >
              Select all entries
            </span>
            <span className="divider-vertical" />
          </>
        )}

      <span
        role="button"
        className="semibold-1 blue-txt-11"
        {...accessibleOnClick(handleClearSelection)}
      >
        Clear selection
      </span>

      {totalEmailsAndDomains >
        constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS && (
        <span className="regular-1 red-txt-12 ml-2">
          (You can select max 25,000 entries.)
        </span>
      )}
    </div>
  );

  if (totalEmailsAndDomains > 0) {
    return (
      <div className={divClasses}>{renderSelectedEmailAccountDetails()}</div>
    );
  }

  return <></>;
};

export default SelectedEmailsDomainsRowInfoBanner;
