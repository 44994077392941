/* eslint-disable react/destructuring-assignment */
import Tabs, { TabPane } from 'rc-tabs';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Images } from '../../../../../../shared/app-constants';
import { AvatarWithName } from '../../../../../../shared/design-system/components/atoms/avatar-with-name/avatar-with-name';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import EmptyList from '../../../../../../shared/design-system/components/molecules/empty-list/empty-list';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import {
  Column,
  PaginationShowHide,
} from '../../../../../../shared/design-system/components/organisms/table/types';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import { capitalize } from '../../../../../../shared/utils';
import { DefaultField, CustomField } from '../../../../types/custom-field';
import {
  generateActionsColumn,
  getCustomFieldColumns,
  getDefaultTableWrapper,
} from './helpers/custom-field-column-data';
import { FieldContentTabs } from './types';
import HeaderBanner from '../../../header-banner';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';

type IProps = {
  customFields: CustomField[];
  defaultFields: DefaultField[];
  onCustomFieldPaginationOptionsChangeHandler: (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => void;
  onDefaultFieldPaginationOptionsChangeHandler: (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => void;
  customFieldPagination: {
    options: Required<PaginationQueryParams> & { totalElements: number };
    pagesCount: number;
    count: number;
  };
  defaultFieldPagination: {
    options: Required<PaginationQueryParams> & { totalElements: number };
    pagesCount: number;
    count: number;
  };
  onActonHandler: (key, row) => void;
  activeTabKey: string;
  onTabChange: (string) => void;
  onCreateHandler: (event: React.MouseEvent<HTMLElement>) => void;
  subscriptionPlan: SubscriptionPlans;
  isRequestPending: boolean;
  t: (x: string) => React.ReactNode;
};

const prepareCustomFields = (customFields) =>
  customFields.map((field) => ({
    ...field,
    type: capitalize(field.fieldType),
  }));

const prepareDefaultFields = (defaultFields) =>
  defaultFields.map((field) => ({
    ...field,
    type: capitalize(field.fieldType),
  }));

const CustomFieldTabPanes: React.FC<IProps> = ({
  customFields,
  defaultFields,
  onDefaultFieldPaginationOptionsChangeHandler,
  onCustomFieldPaginationOptionsChangeHandler,
  customFieldPagination,
  defaultFieldPagination,
  onActonHandler,
  activeTabKey,
  onTabChange,
  onCreateHandler,
  subscriptionPlan,
  isRequestPending,
  t,
}) => {
  const onCopyClickHandler = (value) => {
    navigator.clipboard.writeText(value);
  };

  const emptyListDescription = () => (
    <>
      {t('messages.custom_fields_empty_list_description')}{' '}
      {t('messages.custom_fields_empty_list_description2')}
    </>
  );

  const renderTable = (tab, columns) => {
    if (
      customFields.length === 0 &&
      tab === FieldContentTabs.CustomFields &&
      !isRequestPending
    ) {
      return (
        <div className="empty-custom-fields">
          <EmptyList
            description={emptyListDescription()}
            title={t('messages.custom_fields_empty_list_title')}
            imgSrc={Images.EmptyData1}
          >
            <span>
              {hasPermission(Permissions.FIELD_WRITE) && (
                <Button
                  variant={Button.Variant.Primary}
                  onClick={onCreateHandler}
                  disabled={subscriptionPlan === SubscriptionPlans.FREE}
                  className="header-btn"
                >
                  {t('labels.add_prospect_fields')}
                </Button>
              )}
            </span>
          </EmptyList>
        </div>
      );
    }
    return (
      <Table
        actions={generateActionsColumn(tab)}
        columns={columns}
        data={
          tab === FieldContentTabs.CustomFields
            ? prepareCustomFields(customFields)
            : prepareDefaultFields(defaultFields)
        }
        onAction={onActonHandler}
        paginationOptions={
          tab === FieldContentTabs.CustomFields
            ? customFieldPagination
            : defaultFieldPagination
        }
        onPaginationOptionsChange={
          tab === FieldContentTabs.CustomFields
            ? onCustomFieldPaginationOptionsChangeHandler
            : onDefaultFieldPaginationOptionsChangeHandler
        }
        headerVisibleForGenerateColumn
        pagination={PaginationShowHide.SHOW}
        hidePageOptions
        tableWrapperClasses={getDefaultTableWrapper(
          tab,
          customFieldPagination.count,
          defaultFieldPagination.count,
        )}
        isLoading={isRequestPending}
      />
    );
  };

  return (
    <Tabs
      activeKey={activeTabKey}
      prefixCls="bs-tabs"
      className="bs-tabs-small"
      onChange={(tabKey) => onTabChange(tabKey)}
    >
      {[FieldContentTabs.CustomFields, FieldContentTabs.SystemFields].map(
        (tab) => {
          let columns: Column[] = getCustomFieldColumns(
            onCopyClickHandler,
            null,
          );

          if (tab === FieldContentTabs.CustomFields) {
            columns = [
              ...columns,
              {
                dataField: 'user',
                text: 'Added By',
                headerAlign: 'left',
                align: 'left',
                component: (cell) => {
                  if (cell) {
                    const { firstName, lastName } = cell;
                    return (
                      <AvatarWithName
                        firstName={firstName}
                        lastName={lastName}
                      />
                    );
                  }
                  return '-';
                },
              },
            ];
          }

          return (
            <TabPane
              tab={
                tab === FieldContentTabs.CustomFields
                  ? t('labels.custom_fields')
                  : t('labels.system_fields')
              }
              key={tab}
            >
              <div className="custom-fields-content-container__tab-content table-wrapper">
                {subscriptionPlan === SubscriptionPlans.FREE && (
                  <HeaderBanner />
                )}
                {renderTable(tab, columns)}
              </div>
            </TabPane>
          );
        },
      )}
    </Tabs>
  );
};

export default withTranslation()(CustomFieldTabPanes);
