enum AlignmentActions {
  Left = 'justifyLeft',
  Right = 'justifyRight',
  Center = 'justifyCenter',
  Justify = 'justifyFull',
}

export const itemsData = [
  {
    icon: 'align-left',
    action: AlignmentActions.Left,
  },
  {
    icon: 'align-center',
    action: AlignmentActions.Center,
  },
  {
    icon: 'align-right',
    action: AlignmentActions.Right,
  },
  {
    icon: 'align-justify',
    action: AlignmentActions.Justify,
  },
];

export const addAlignmentSplitButton = (editor) => {
  let selectedAlignmentAction = AlignmentActions.Left;
  const alignmentSplitButton = {
    icon: 'alignment',
    onAction: () => {
      editor.execCommand(selectedAlignmentAction);
    },
    onItemAction: (api, value) => {
      editor.execCommand(value.action);
      selectedAlignmentAction = value.action;
    },
    fetch: (callback) => {
      const items = itemsData.map((data) => ({
        type: 'choiceitem',
        icon: data.icon,
        value: { action: data.action },
      }));
      callback(items);
    },
  };
  editor.ui.registry.addSplitButton('alignment', alignmentSplitButton);
};
