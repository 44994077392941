import React from 'react';
import { Link } from 'react-router-dom';
import { EmailAccount } from '../../../../../types/email-account';
import {
  OverlayPopover,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  emailAccount: EmailAccount;
  rowNumber: number;
  itemCount: number;
};

const ActiveSequencesField: React.FC<IProps> = ({
  emailAccount: { sequences },
  rowNumber,
  itemCount,
}) => {
  const getRowPosition = (): Placement => {
    // Check if rowNumber is within the last 2 rows
    if (rowNumber > itemCount - 2) {
      return Placement.TopStart;
    }
    return Placement.BottomStart;
  };

  const renderCell = (label: string, className = '') => (
    <p className={`active-sequences-label ${className}`}>{label}</p>
  );

  return (
    <div className="active-sequences-field">
      {sequences?.length === 0 ? (
        renderCell('-', 'blue-txt-15')
      ) : (
        <OverlayPopover
          className="active-sequences-popover"
          content={
            <div className="active-sequences-popover-content">
              <h2>Sequences</h2>
              <div className="active-sequences-wrapper">
                {sequences.map((sequence) => (
                  <Link
                    className="active-sequence text-decoration-none"
                    to={`/sequence/${sequence?.id}/steps`}
                  >
                    <p>{sequence?.title ?? ''}</p>
                  </Link>
                ))}
              </div>
            </div>
          }
          placement={getRowPosition()}
          rootClose
        >
          {renderCell(`${sequences.length}`, 'cursor-pointer')}
        </OverlayPopover>
      )}
    </div>
  );
};

export default ActiveSequencesField;
