import React, { useState } from 'react';
import Modal from '../../../../shared/design-system/components/atoms/modal';
import InputNumber from '../../../../shared/design-system/components/input-number';
import { LTDVerificationModalProps } from './types';
import { validate } from '../signup/components/verification-code-form/validator';
import ResendVerificationCode from '../signup/components/resend-verification-code';
import Button from '../../../../shared/design-system/components/atoms/button';
import { getIsRequestPending } from '../../../../shared/utils/get-request-status';
import Alert from '../../../../shared/design-system/components/alert';

const LTDVerificationModal: React.FC<LTDVerificationModalProps> = ({
  userEmailAddress,
  verificationModal,
  hideVerificationModal,
  sendVerifyUserVerificationCodeRequest,
  verifyUserVerificationCodeRequestStatus,
  verifyUserVerificationCodeRequestError,
}) => {
  const [otpValue, setOtpValue] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');

  const onInputChangeHelper = (value) => {
    const error = validate(value);

    if (!error || value === '') {
      setOtpValue(value);
    }
  };

  const onVerificationSubmit = () => {
    const error = validate(otpValue);

    if (error) {
      setOtpError(error);
      return;
    }

    sendVerifyUserVerificationCodeRequest(userEmailAddress, Number(otpValue));
  };

  const isRequestPending = getIsRequestPending(
    verifyUserVerificationCodeRequestStatus,
  );

  const isError =
    otpError ||
    (verifyUserVerificationCodeRequestError &&
      verifyUserVerificationCodeRequestError?.message?.toLowerCase() ===
        'invalid otp');

  return (
    <Modal
      show={verificationModal}
      onClose={hideVerificationModal}
      backdrop="static"
      hideHeader
      hideFooter
      className="ltd-verification-modal"
    >
      <h3 className="m-0 font-16 font-bold blue-txt-15">Verify your email</h3>
      <p className="m-0 font-14 gray-txt-12 text-center">
        You’re almost there! We sent an email to{' '}
        <span className="font-semibold blue-txt-15">{userEmailAddress}</span>
      </p>
      {isError && (
        <Alert
          contentContainer={
            <span>Failed to authenticate. OTP code is invalid.</span>
          }
          variant={Alert.Variant.Danger}
          theme={Alert.Theme.New}
          className="m-auto verification-error-alert"
        />
      )}
      <p className="m-0 font-14 gray-txt-12">
        Please enter the code you received below
      </p>
      <InputNumber
        className="ltd-verification-modal--otp-input"
        value={otpValue}
        onChange={onInputChangeHelper}
        variant={isError && InputNumber.Variant.Error}
      />
      <p className="m-0 font-12 gray-txt-11">
        Please check spam or junk folder if not received in inbox
      </p>
      <ResendVerificationCode email={userEmailAddress} />
      <Button
        type={Button.Type.Submit}
        variant={Button.Variant.Primary}
        className="verification-submit-btn header-btn"
        disabled={!otpValue || otpValue.length < 4 || isRequestPending}
        onClick={onVerificationSubmit}
        isLoading={isRequestPending}
      >
        Continue
      </Button>
    </Modal>
  );
};

export default LTDVerificationModal;
