import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateScheduleRequestPayload } from '../../types/request-payload';
import {
  getSchedules,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  setDefaultSchedule,
} from '../../helpers/schedule.api';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

export const createScheduleRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('schedule/createScheduleRequest', async (args, thunkAPI) => {
  try {
    return await createSchedule();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getScheduleRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('schedule/getScheduleRequest', async (args, thunkAPI) => {
  try {
    return await getSchedules();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

type UpdateSchedulePayloadCreatorArgs = UpdateScheduleRequestPayload & {
  scheduleId: number;
};

export const updateScheduleRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSchedulePayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'schedule/updateScheduleRequest',
  async ({ scheduleId, ...payload }, thunkAPI) => {
    try {
      return await updateSchedule(scheduleId, payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const deleteScheduleRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('schedule/deleteScheduleRequest', async (scheduleId, thunkAPI) => {
  try {
    return await deleteSchedule(scheduleId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const setDefaultScheduleRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('schedule/setDefaultScheduleRequest', async (scheduleId, thunkAPI) => {
  try {
    return await setDefaultSchedule(scheduleId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
