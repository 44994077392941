import React from 'react';
import classNames from 'classnames';
import { Modify } from '../../../types';
import { ForwardRef } from '../../../types/forward-ref';
import TagSubSpan from './tag-sub-span';
import { Color, Variant } from './enums';

type TagProps = Modify<
  React.HTMLProps<HTMLSpanElement>,
  {
    value: React.ReactNode;
    variant?: Variant;
    color?: Color;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  }
>;

type TagStatic = {
  Variant: typeof Variant;
  Color: typeof Color;
};

const Tag = React.forwardRef<HTMLSpanElement, TagProps>(
  ({ variant, value, color, onClick, className, ...rest }, ref) => {
    const tagSubClass = classNames([
      {
        'site-tag-plus': variant === Variant.Add,
      },
    ]);

    const tagClass = classNames([
      'bs-tag',
      tagSubClass,
      {
        [`bs-tag-${color}`]: color,
      },
      className,
    ]);

    const iconBefore = variant === Variant.Add;

    return (
      <span {...rest} className={tagClass} onClick={onClick} ref={ref}>
        {iconBefore && <TagSubSpan variant={variant} iconBefore={iconBefore} />}
        {value}
        {!iconBefore && (
          <TagSubSpan variant={variant} iconBefore={iconBefore} />
        )}
      </span>
    );
  },
) as ForwardRef<HTMLSpanElement, TagProps> & TagStatic;

Tag.displayName = 'Tag';

Tag.Variant = Variant;
Tag.Color = Color;

Tag.defaultProps = {
  variant: Variant.Default,
};

export default Tag;
