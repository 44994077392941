import React from 'react';
import './temp-auth-indicator.scss';
import Button from '../../shared/design-system/components/atoms/button';
import { AuthHelper } from '../../shared/utils';

const TempAuthIndicator = () => {
  return (
    <div className="temp-auth-indicator-wrapper">
      <div className="temp-auth-indicator">
        <div>you are logged in</div>
        <div className="temp-logout-wrapper">
          <Button
            variant={Button.Variant.Outlined}
            onClick={() => AuthHelper.logout()}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TempAuthIndicator;
