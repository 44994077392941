import React from 'react';
import Icon from '../icon';

const WarningMessage = ({ message, className }) => (
  <div className={`textWarning text-center mt-2 d-flex ${className}`}>
    <span className="mr-2">
      <Icon identifier="danger" className="gold-txt-8" />
    </span>
    <span>{message}</span>
  </div>
);

export default WarningMessage;
