export enum ProspectProgress {
  Active = 'active',
  Replied = 'replied',
  Bounced = 'bounced',
  Unsubscribed = 'unsubscribed',
  Finished = 'finished',
  Paused = 'paused',
}

export enum OnProspectSequenceActions {
  MarkAsReplied = 'mark as replied',
  RemoveFromSequence = 'remove from sequence',
  MoveToStep = 'move to step',
  MarkAsFinished = 'mark as finished',
}

export enum BulkActionProspect {
  AddManually = 'Add Prospect Manually',
  AddToStep = 'Add To Step',
  RemoveProspect = 'Remove Prospect',
  Unsubscribe = 'Unsubscribe Prospect',
  ProspectDetail = 'Prospect Detail Tab',
  TagsUpdated = 'Updated Tags',
}

export enum ProspectSortBy {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  JobTitle = 'jobTitle',
  Department = 'department',
  Owner = 'owner',
  City = 'city',
  State = 'state',
  Country = 'country',
  CreateAt = 'createAt',
  CompanyName = 'companyName',
  CompanyIndustry = 'companyIndustry',
  // LastContactedAt = 'lastContactedAt',
  // LastUpdatedAt = 'lastUpdatedAt',
  // TotalOpenCount = 'totalOpenCount',
  // TotalClickCount = 'totalClickCount',
  // LastClickedAt = 'lastClickedAt',
  // LastRepliedAt = 'lastRepliedAt',
}
