import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import LTDSignup from './ltd-signup';
import { verifyUserVerificationCodeRequest } from '../signup/extra-actions';

const mapStateToProps = (state: RootState) => ({
  verifyUserVerificationCodeRequestStatus:
    state.signup.verifyUserVerificationCodeRequest.status,
  verifyUserVerificationCodeRequestMessage:
    state.signup.verifyUserVerificationCodeRequest.message,
  verifyUserVerificationCodeRequestError:
    state.signup.verifyUserVerificationCodeRequest.error,

  email: state.signup.email,
  token: state.signup.token,
  trackingId: state.signup.trackingId,
  firstName: state.signup.firstName,
  lastName: state.signup.lastName,
  canResendUserVerificationCode: state.signup.canResendUserVerificationCode,
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {
  sendVerifyUserVerificationCodeRequest: (email: string, code: number) =>
    verifyUserVerificationCodeRequest({ email, code }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(LTDSignup);
