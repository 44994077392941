import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationProp } from '../types';

export const ValidationMsg = ({ validCondition, msg }: ValidationProp) => {
  const { t } = useTranslation();
  if (validCondition) {
    return <span className="validation-txt regular-1">{t(msg)}</span>;
  }
  return null;
};
