import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { changePasswordRequest } from './extra-actions';

type State = {
  status: RequestStatus;
  message: string;
  error: any;
  showError: boolean;
};

const initialState: State = {
  status: RequestStatus.Ideal,
  message: '',
  error: '',
  showError: false,
};

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    hideError: (state) => {
      state.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changePasswordRequest.pending, (state) => {
      state.status = RequestStatus.Pending;
      state.showError = false;
    });
    builder.addCase(changePasswordRequest.fulfilled, (state, action) => {
      state.status = RequestStatus.Succeeded;
      state.message = action.payload.message;
    });
    builder.addCase(changePasswordRequest.rejected, (state, action) => {
      state.status = RequestStatus.Failed;
      state.error = action.payload;
      state.showError = !action.payload.isHandled;
    });
  },
});

export const { hideError } = changePasswordSlice.actions;
export { changePasswordRequest };
export default changePasswordSlice.reducer;
