import React from 'react';

import SignupForm from '../signup-form';
import DeniedInvitation from '../denied-invitation';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';
import InvitationSignupFailed from './components/invitation-signup-failed';

import { IProps } from './types';
import { SHLogoDark } from '../../../../shared/svg';
import {
  AuthHelper,
  getIsRequestFailed,
  getIsRequestSucceeded,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
  executeOnRequestStatusWithPrevStatusCheck,
  getIsRequestPending,
} from '../../../../shared/utils';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import { isWhiteLabel } from '../../../../shared/utils/user-details';

type IState = {
  isDeniedScreenVisible: boolean;
};

class Signup extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isDeniedScreenVisible: false,
    };
  }

  componentDidMount() {
    const { sendVerifyInvitationTokenRequest } = this.props;

    sendVerifyInvitationTokenRequest();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      verifyInvitationTokenRequestStatus,
      verifyInvitationTokenRequestCode,
      acceptInvitationRequestStatus,
      accessToken,
      firstName,
      lastName,
      email,
      trackingId,
      agencyConfig,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: verifyInvitationTokenRequestStatus,
      prevStatus: prevProps.verifyInvitationTokenRequestStatus,
      onSuccess: () => {
        if (accessToken) {
          AuthHelper.login({ token: accessToken });

          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
        }
        initializeSentryIntegrations(email);
      },
      onFailed: () => {
        if (verifyInvitationTokenRequestCode === 403) {
          this.setState({ isDeniedScreenVisible: true });
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: acceptInvitationRequestStatus,
      prevStatus: prevProps.acceptInvitationRequestStatus,
      onSuccess: () => {
        if (accessToken !== prevProps.accessToken) {
          AuthHelper.login({ token: accessToken });
        }
      },
    });
  }

  render() {
    const {
      verifyInvitationTokenRequestStatus,
      email,
      match,
      accessToken,
      agencyConfigRequestStatus,
    } = this.props;
    const { isDeniedScreenVisible } = this.state;

    const isVerifyInvitationTokenRequestSucceeded = getIsRequestSucceeded(
      verifyInvitationTokenRequestStatus,
    );

    const isVerifyInvitationTokenRequestFailed = getIsRequestFailed(
      verifyInvitationTokenRequestStatus,
    );

    if (isDeniedScreenVisible) {
      return <DeniedInvitation />;
    }

    return (
      <>
        {(getIsRequestPending(agencyConfigRequestStatus) && !isInternalUrl()) ||
        isWhiteLabel() ? null : (
          <div className="testimonials-container">
            <SHLogoDark width={160} />
            <TestimonialsCarousel />
            <p className="copyright">
              &copy;{`${new Date().getFullYear()} Saleshandy`}
            </p>
          </div>
        )}

        {isVerifyInvitationTokenRequestSucceeded && !accessToken && (
          <SignupForm
            isInvitedUser={true}
            email={email}
            invitationToken={match?.params?.token || ''}
          />
        )}

        {isVerifyInvitationTokenRequestFailed && <InvitationSignupFailed />}
      </>
    );
  }
}

export default Signup;
