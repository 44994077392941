import React from 'react';
import { withTranslation } from 'react-i18next';
import { IProps } from './types';
import ConfirmationModal from '../../../../../../shared/design-system/components/atoms/confirmation-modal';

const DisconnectEmailAccountModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
  t,
}) => (
  <ConfirmationModal
    show={show}
    onClose={onClose}
    onSubmit={onSubmit}
    className="disconnect-email-account-modal"
    isRequestPending={isRequestPending}
    title="Do you want to disconnect this email account?"
    content={t('messages.disconnect_email_account_message')}
    showSuccessButton={true}
    showCancelButton={true}
    cancelButtonText="Cancel"
    submitButtonClassName="submit-btn"
  />
);

export default withTranslation()(DisconnectEmailAccountModal);
