// using typeof for comparison is slow.
// https://stackoverflow.com/questions/28814585/how-to-check-if-type-is-boolean#comment64005607_28814615
export const isBoolean = (obj: any): boolean => obj === true || obj === false;

export const isString = (obj: any): boolean =>
  typeof obj === 'string' || obj instanceof String;

export const isArray = (obj: any): boolean => Array.isArray(obj);

export const isFunction = (obj: any): boolean => typeof obj === 'function';
