import React from 'react';
import Switch from '../../../../shared/design-system/components/atoms/switch';
import { IProps } from './types';
import { SafetySettingCode } from '../../enums/safety-settings';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { UpdateSafetySettingsRequestPayload } from '../../types/request-payload';
import toaster from '../../../../shared/toaster';
import store from '../../../../store';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

type StateValues = {
  stopSequenceAllProspect: boolean;
};

type IState = {
  values: StateValues;
};

class SafetySettings extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        stopSequenceAllProspect: false,
      },
    };
    this.onSequenceAllProspectChangeHandler = this.onSequenceAllProspectChangeHandler.bind(
      this,
    );
  }

  onSequenceAllProspectChangeHandler(value) {
    const payload: UpdateSafetySettingsRequestPayload = {
      settings: [
        {
          code: SafetySettingCode.StopSequenceAllPropspect,
          value: String(Number(value)),
        },
      ],
    };
    this.props.sendUpdateSafetySettingsRequest(payload);
  }

  setSettings() {
    const { safetySettings } = this.props;
    const values = { ...this.state.values };
    safetySettings.forEach((setting) => {
      switch (setting.code) {
        case SafetySettingCode.StopSequenceAllPropspect:
          values.stopSequenceAllProspect = Boolean(JSON.parse(setting.value));
          break;
        default:
          break;
      }
    });
    this.setState({
      values,
    });
  }

  componentDidMount() {
    this.props.sendGetSafetySettingsRequest();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      getSafetySettingsRequestStatus,
      updateSafetySettingsRequestStatus,
      updateSafetySettingsRequestMessage,
      updateSafetySettingsRequestError,
      sendGetSafetySettingsRequest,
      isLoading,
    } = this.props;

    if (isLoading !== prevProps.isLoading) {
      isLoading ? store.dispatch(showLoading()) : store.dispatch(hideLoading());
    }

    if (
      getSafetySettingsRequestStatus !==
      prevProps.getSafetySettingsRequestStatus
    ) {
      if (getSafetySettingsRequestStatus === RequestStatus.Succeeded) {
        this.setSettings();
      }
    }

    if (
      updateSafetySettingsRequestStatus !==
      prevProps.updateSafetySettingsRequestStatus
    ) {
      if (updateSafetySettingsRequestStatus === RequestStatus.Succeeded) {
        toaster.success(updateSafetySettingsRequestMessage);
        sendGetSafetySettingsRequest();
      }

      if (
        updateSafetySettingsRequestStatus === RequestStatus.Failed &&
        updateSafetySettingsRequestError
      ) {
        toaster.error(updateSafetySettingsRequestError.message);
      }
    }
  }

  render() {
    const { values } = this.state;

    return (
      <div className="safety-setting-container">
        <div className="safety-setting-header">
          <span className="semibold-3">Safety Settings</span>
        </div>
        <div className="safety-setting-list">
          <div className="safety-setting-switch">
            <Switch
              name="stopSequenceAllProspect"
              checked={values.stopSequenceAllProspect}
              onChange={this.onSequenceAllProspectChangeHandler}
              label="Stop sequence to all prospects in an account when a reply is received"
              variant={Switch.Variant.Default}
              size={Switch.Size.Small}
              state={Switch.State.Default}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SafetySettings;
