import React from 'react';
import { IProps } from './type';
import Icon from '../icon/icon';
import Button from '../button/button-container';
import AlertTriangleLarge from '../../../../components/images/alert-triangle-large';
import Modal from '../modal';
import eventBus from '../../../../utils/event-bus';
import { constants } from '../../../../enums/constants';

const ConfirmationModal: React.FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  isRequestPending,
  title,
  content,
  contents,
  iconClass,
  iconType,
  showSuccessButton,
  showCancelButton,
  successButtonText,
  cancelButtonText,
  showCloseButton,
  hideIcon,
  showFooterBorder,
  className,
  submitButtonClassName,
}) => {
  const onCloseHandler = () => {
    onClose();
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
  };

  const onSubmitHandler = (e) => {
    onSubmit(e);
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
  };

  return (
    <Modal
      show={show}
      className={`confirm-modal-width ${className}`}
      onClose={onClose}
      showCloseIcon={showCloseButton}
      hideHeader={true}
      hideFooter={true}
      backdrop="static"
      extraModalProps={{
        centered: true,
        'aria-labelledby': 'contained-modal-title-vcenter',
      }}
    >
      <div className="bs-modal-content">
        {!hideIcon && (
          <div className={`bs-modal-icon ${iconClass || 'icon-warning'}`}>
            {iconType === 'warning' ? (
              <AlertTriangleLarge />
            ) : (
              <Icon identifier={iconType || 'info'} />
            )}
          </div>
        )}
        <div className="bs-modal-inner-content">
          <h3 className="bs-modal-inner-content__title">{title}</h3>

          {contents ? (
            <div className="contents">
              {contents.map((contentItem) => (
                <>
                  <p
                    key={contentItem}
                    className="bs-mb-20 bs-mt-15 bs-modal-inner-content__contents"
                  >
                    {contentItem}
                  </p>
                </>
              ))}
            </div>
          ) : (
            <p className="bs-mb-20 bs-mt-15">{content}</p>
          )}
        </div>
      </div>

      <div
        className={`d-flex justify-content-end ${
          showFooterBorder ? 'footer-border' : ''
        }`}
      >
        {showCancelButton && (
          <Button
            className="bs-mr-5"
            onClick={onCloseHandler}
            variant={Button.Variant.Outlined}
          >
            {cancelButtonText || 'No'}
          </Button>
        )}
        {showSuccessButton && (
          <Button
            variant={Button.Variant.Primary}
            onClick={onSubmitHandler}
            isLoading={isRequestPending}
            disabled={isRequestPending}
            className={submitButtonClassName}
          >
            {successButtonText || 'Yes'}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
