import React from 'react';
import ImageIcon from '../../../../../components/images/image-icon';

export function ProfileSettings({ propsProfileSettings }) {
  const {
    changeActivationState,
    DropdownKeys,
    accessibleOnClick,
    navigateToSettings,
    dropdownState,
    Images,
    t,
  } = propsProfileSettings;
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <li
      className="my-1 menu-item"
      onMouseOver={() =>
        changeActivationState(DropdownKeys.ProfileSettingsActive)
      }
      onMouseOut={() =>
        changeActivationState(DropdownKeys.ProfileSettingsActive)
      }
    >
      <div
        className="d-flex align-items-center px-3 py-2 profile-cursor-pointer"
        {...accessibleOnClick(navigateToSettings)}
      >
        <div className="text-left menu-icon">
          <ImageIcon
            src={
              dropdownState.profileSettingsActive
                ? Images.CogActive
                : Images.Cog
            }
          />
        </div>
        <div className="text-left ml-2">
          <p className="profile-list-font menu-text">
            {t('labels.profile_settings')}
          </p>
        </div>
      </div>
    </li>
  );
}
