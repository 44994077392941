import {
  CreateTemplateRequest,
  DeleteTemplateRequest,
  GetTemplatesRequest,
  UpdateTemplateRequest,
} from '../types/request';
import api from '../../../api';
import { CloneTemplateRequest } from '../types/request/clone-template';
import { ShareTemplateRequest } from '../types/request/share-template';

export const getTemplates = async ({
  limit = 50,
  ...rest
}: GetTemplatesRequest) =>
  api.get(`templates`, {
    params: { limit, ...rest },
  });

export const createTemplate = async (options: CreateTemplateRequest) =>
  api.post(`templates`, options);

export const updateTemplate = async ({ id, ...rest }: UpdateTemplateRequest) =>
  api.patch(`templates/${id}`, rest);

export const deleteTemplate = async ({ id }: DeleteTemplateRequest) =>
  api.delete(`templates/${id}`);

export const cloneTemplate = async ({ id }: CloneTemplateRequest) =>
  api.get(`templates/clone/${id}`);

export const shareTemplate = async ({ id }: ShareTemplateRequest) =>
  api.get(`templates/share/${id}`);
