import { connect, ConnectedProps } from 'react-redux';
import { extendTrialPeriodRequest } from '../../../components/home/extra-actions';
import { RootState } from '../../../store/root-reducer';
import { TrialExtendSource } from '../../enums/trial-extend-source';
import BlockPage from './block-page';

const mapStateToProps = (state: RootState) => ({
  firstName: state.home.adminDetails?.firstName,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,

  isEligibleToExtendTrial: state.home.isEligibleToExtendTrial,

  extendTrialPeriodRequestStatus: state.home.extendTrialPeriodRequest.status,
});

const mapDispatchToProps = {
  sendExtendTrialPeriodRequest: (source: TrialExtendSource) =>
    extendTrialPeriodRequest(source),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  redirectURL?: string;
};

export default connector(BlockPage);
