class PauseAccountHelper {
  private _showResumePlanModal;

  bindActions = (showResumePlanModal) => {
    // eslint-disable-next-line no-underscore-dangle
    this._showResumePlanModal = showResumePlanModal;
  };

  // eslint-disable-next-line no-underscore-dangle
  showModal = () => this._showResumePlanModal();
}

export default PauseAccountHelper;
