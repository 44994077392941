import { RootState } from '../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  getSafetySettingsRequest,
  updateSafetySettingsRequest,
} from './safety-settings-slice';
import { UpdateSafetySettingsRequestPayload } from '../../types/request-payload';
import SafetySettings from './safety-settings';

const mapStateToProps = (state: RootState) => ({
  getSafetySettingsRequestStatus:
    state.safetySettings.getSafetySettingsRequest.status,
  getSafetySettingsRequestMessage:
    state.safetySettings.getSafetySettingsRequest.message,
  getSafetySettingsRequestError:
    state.safetySettings.getSafetySettingsRequest.error,

  updateSafetySettingsRequestStatus:
    state.safetySettings.updateSafetySettingsRequest.status,
  updateSafetySettingsRequestMessage:
    state.safetySettings.updateSafetySettingsRequest.message,
  updateSafetySettingsRequestError:
    state.safetySettings.updateSafetySettingsRequest.error,
  safetySettings: state.safetySettings.safetySettings,
  isLoading: state.safetySettings.isLoading,
});

const mapDispatchToProps = {
  sendGetSafetySettingsRequest: () => getSafetySettingsRequest(),
  sendUpdateSafetySettingsRequest: (
    payload: UpdateSafetySettingsRequestPayload,
  ) => updateSafetySettingsRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(SafetySettings);
