import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseSuccess, ResponseErrorWithHandled } from '../../types';
import { sendUpdatePreferredApp } from './helper/update-preferred-app-api';
import { UpdatePreferredAppRequest } from './types';

export const sendUpdatePreferredAppRequest = createAsyncThunk<
  ResponseSuccess,
  UpdatePreferredAppRequest,
  { rejectValue: ResponseErrorWithHandled }
>('user/updatePreferredAppRequest', async (args, thunkAPI) => {
  try {
    return await sendUpdatePreferredApp(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
