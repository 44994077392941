import React from 'react';
import './validation-error.scss';

type ValidationErrorsProps = {
  messages: string | string[];
};

const ValidationError: React.FC<ValidationErrorsProps> = ({ messages }) => {
  if (typeof messages === 'string') {
    return (
      <ul className="notification-validation-errors">
        <li>{messages}</li>
      </ul>
    );
  }

  return (
    <ul className="notification-validation-errors">
      {messages.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  );
};

export default ValidationError;
