import { Permissions } from '../../utils/access-control/enums/permissions';
import hasPermission from '../../utils/access-control/has-permission';

export const getPlanExpiredDescriptionMessage = ({
  t,
  planTransitionDetails,
  isPlanDowngradedFromPaid,
}) => {
  const isPlanDowngradedFromPaidPlan =
    planTransitionDetails !== null &&
    isPlanDowngradedFromPaid(planTransitionDetails);

  if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
    if (isPlanDowngradedFromPaidPlan) {
      return t('messages.paid_plan_expired_message');
    }
    return t('messages.admin_block_page_message');
  }
  return t('messages.member_block_page_message');
};
