import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
  setToken,
} from '../../../../shared/utils';
import LTDSignupForm from '../ltd-signup-form';
import LTDVerificationModal from './ltd-verification-modal';
import type { IProps } from './ltd-signup-container';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import toaster, { Theme } from '../../../../shared/toaster';

const LTDSignup: React.FC<IProps> = ({
  email,
  token,
  trackingId,
  firstName,
  lastName,
  agencyConfig,
  sendVerifyUserVerificationCodeRequest,
  verifyUserVerificationCodeRequestStatus,
  verifyUserVerificationCodeRequestError,
  verifyUserVerificationCodeRequestMessage,
}) => {
  const history = useHistory();

  const [verificationModal, setVerificationModal] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>(email || '');

  const showVerificationModal = () => {
    setVerificationModal(true);
  };

  const hideVerificationModal = () => {
    setVerificationModal(false);
  };

  const showCheckoutComponent = () => {
    const { location } = history;

    const search = new URLSearchParams(location.search);

    history.push({
      pathname: '/LTD-checkout',
      search: search.toString(),
    });
  };

  useEffect(() => {
    if (token) {
      setToken(token);
      showCheckoutComponent();

      initializeThirdPartyIntegrations({
        trackingId,
        showChatSupport: agencyConfig?.showChatSupport,
        firstName,
        lastName,
        email: userEmail,
      });
    }
    initializeSentryIntegrations(userEmail);
  }, [token]);

  useEffect(() => {
    executeOnRequestStatus({
      status: verifyUserVerificationCodeRequestStatus,
      onSuccess: () => {
        toaster.success('Sign up has been completed successfully.', {
          theme: Theme.New,
        });
        showCheckoutComponent();
      },
    });
  }, [verifyUserVerificationCodeRequestStatus]);

  return (
    <>
      <LTDSignupForm
        welcomeHeader="Create an account"
        welcomeBody="Sign up to complete your purchase"
        showPasswordValidationMessage={false}
        showLoginLink={false}
        showVerificationCodeComponent={showVerificationModal}
        isLtdUser={true}
        setUserEmail={setUserEmail}
      />
      <LTDVerificationModal
        verificationModal={verificationModal}
        hideVerificationModal={hideVerificationModal}
        userEmailAddress={userEmail}
        sendVerifyUserVerificationCodeRequest={
          sendVerifyUserVerificationCodeRequest
        }
        verifyUserVerificationCodeRequestStatus={
          verifyUserVerificationCodeRequestStatus
        }
        verifyUserVerificationCodeRequestError={
          verifyUserVerificationCodeRequestError
        }
        verifyUserVerificationCodeRequestMessage={
          verifyUserVerificationCodeRequestMessage
        }
      />
    </>
  );
};

export default LTDSignup;
