import { Images } from '../../../../../../../shared/app-constants';
import { EmailAccountType } from '../../../../../enums/email-account';

export const getEmailAccountTypeIcon = (type: EmailAccountType) => {
  if (type === EmailAccountType.Microsoft) {
    return Images.MicrosoftIconSmall;
  }

  return Images.GoogleIcon;
};
