import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export type TableSkeletonProps = IContentLoaderProps & {
  colCount?: number;
  rowCount?: number;
  rowWidth?: number;
};
const vpX = 400;
// const vpY = 300;

const headerFactory = (colCount, rowWidth = 20) => {
  const headerBoxesPos: { x: number; y: number }[] = [];
  const box = {
    width: 20,
    height: 3,
  };
  const boxXY = {
    x: box.width,
    y: 0,
  };

  for (
    let i = 0, y = boxXY.y + rowWidth / 2 - box.height / 2;
    i < colCount;
    i++
  ) {
    headerBoxesPos.push({
      x: i * (vpX / colCount) + vpX / colCount / 2 - box.width / 2,
      y,
    });
  }

  return headerBoxesPos.map((el, index) => (
    <rect
      key={index}
      x={el.x}
      y={el.y}
      rx="0"
      ry="0"
      width={box.width}
      height={box.height}
    />
  ));
};

const rowFactory = (colCount, rowCount, rowWidth = 20) => {
  const rowLinePos: { x: number; y: number }[] = [];
  const rowElementPos: { x: number; y: number }[] = [];
  const box = {
    width: 20,
    height: 1,
  };
  const boxXY = {
    x: box.width,
    y: 0,
  };

  for (let i = 1, lineX = 0; i <= rowCount; i++) {
    rowLinePos.push({ x: lineX, y: rowWidth * i + boxXY.y });

    for (let j = 0; j < colCount; j++) {
      rowElementPos.push({
        x: j * (vpX / colCount) + vpX / colCount / 2 - box.width / 2,
        y: rowWidth * (i + 1) - rowWidth / 2 + box.height / 2,
      });
    }
  }
  const rows = {
    rowLines: rowLinePos.map((el, index) => (
      <rect
        key={index}
        x={el.x}
        y={el.y}
        rx="0"
        ry="0"
        width={vpX}
        height="0.5"
      />
    )),
    rowElements: rowElementPos.map((el, index) => (
      <rect
        key={index}
        x={el.x}
        y={el.y}
        rx="0"
        ry="0"
        width={box.width}
        height={box.height}
      />
    )),
  };
  return rows;
};

const TableSkeleton: React.FC<TableSkeletonProps> = ({
  width = '100%',
  height = '100%',
  colCount = 3,
  rowCount = 3,
  rowWidth = 20,
  ...rest
}) => {
  const headerBoxes = headerFactory(colCount, rowWidth);
  const { rowLines, rowElements } = rowFactory(colCount, rowCount, rowWidth);
  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox="0 0 400 300"
      {...rest}
    >
      {headerBoxes}
      {rowLines}
      {rowElements}
    </ContentLoader>
  );
};

export default TableSkeleton;
