import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../store/root-reducer';
import {
  addTagsToProspectsRequest,
  getProspectRequest,
  getTagsRequest,
  removeTagsFromProspectsRequest,
} from '../../../../../extra-actions';
// eslint-disable-next-line import/no-cycle
import TagModal from './tags-modal';
import {
  AddTagsToProspectsPayload,
  RemoveTagsFromProspectsPayload,
  ProspectParams,
} from '../../../../../types';
import withProspectsFiltersAPI from '../../../../../helpers/withProspectsFiltersAPI';
import {
  SequenceProspectBulkTagAssignPayload,
  SequenceProspectBulkTagUnAssignPayload,
} from '../../../../../../sequence/types';
import {
  sequenceProspectBulkTagAssignRequest,
  sequenceProspectBulkTagUnAssignRequest,
} from '../../../../../../sequence/extra-actions';
import { ProspectsFilters } from '../../../../../../../shared/types/prospects-filters';

const mapStateToProps = (state: RootState) => ({
  tags: state.prospect.tags,
  selectedAllProspects: state.prospect.selectAllContactDetails,

  selectedAllSequenceProspects: state.sequence.selectAllContactDetails,
});

const mapDispatchToProps = {
  sendGetTagsRequest: () => getTagsRequest(),

  addTagsToProspectsRequest: (payload: AddTagsToProspectsPayload) =>
    addTagsToProspectsRequest(payload),

  sendGetProspectRequest: (params: ProspectParams) =>
    getProspectRequest(params),

  removeTagsFromProspectsRequest: (payload: RemoveTagsFromProspectsPayload) =>
    removeTagsFromProspectsRequest(payload),

  sendSequenceProspectBulkTagAssignRequest: (
    payload: SequenceProspectBulkTagAssignPayload,
  ) => sequenceProspectBulkTagAssignRequest(payload),

  sendSequenceProspectBulkTagUnAssignRequest: (
    payload: SequenceProspectBulkTagUnAssignPayload,
  ) => sequenceProspectBulkTagUnAssignRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onHide: () => void;
  selectedProspects: any[];
  setResetSelected?: (state: boolean) => void;
  t: (x: string) => React.ReactNode;
  sequenceId?: string;
  search?: string;
  sequenceProspectsFilters?: ProspectsFilters;
  setTagsActions?: (payload: { assign: boolean; unassign: boolean }) => void;

  onAddTagsToProspects?: (ids: number[], newTags: string[]) => void;
  onRemoveTagsFromProspects?: (
    tagIds: number[],
    tagsIdsProspectIdsMapping,
  ) => void;
  showAllTags?: boolean;
};

export default connector(withProspectsFiltersAPI(TagModal));
