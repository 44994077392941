import passwordValidator from '../../../../../../shared/utils/password-validator';
import { StateValues } from './types';

export const validate = (name, value, values: StateValues) => {
  switch (name) {
    case 'password':
      if (!passwordValidator.all(value)) {
        return 'Password must contain at least one upper case, lowercase and a number';
      }
      break;

    case 'confirmPassword':
      if (value !== values.password) {
        return `Password doesn't match`;
      }
      break;
  }
  return '';
};
