import { createAsyncThunk } from '@reduxjs/toolkit';
import { verifyResetPasswordCode } from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';

export const verifyResetPasswordCodeRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>(
  'resetPasswordCallback/verifyResetPasswordCodeRequest',
  async (code, thunkAPI) => {
    try {
      return await verifyResetPasswordCode(code);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
