import validator from 'validator';

export const validate = (value) => {
  if (
    !validator.isLength(value, { min: 1, max: 4 }) ||
    !validator.isInt(value)
  ) {
    return 'Number must be between 0 to 9';
  }
  return '';
};
