import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../store/root-reducer';
import {
  getAcademyTopicsProgressRequest,
  getAcademyTopicsRequest,
} from './extra-actions';
import GrowthHub from './growth-hub';

const mapStateToProps = (state: RootState) => ({
  getAcademyTopicsRequestStatus: state.growthHub.getAcademyTopicsRequest.status,
  getAcademyTopicsProgressRequestStatus:
    state.growthHub.getAcademyTopicsProgressRequest.status,
  academyLessons: state.growthHub.getAcademyTopicsResponse,
});

const mapDispatchToProps = {
  getAcademyTopics: () => getAcademyTopicsRequest(),
  getAcademyTopicsProgress: () => getAcademyTopicsProgressRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(GrowthHub));
