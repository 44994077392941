import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types/response';
import { UpdateAdminSettingsRequestPayload } from '../types/request-payload';

export const getAdminSettings = async () =>
  api.get<ResponseSuccess>(`/accounts`);

export const updateAdminSettings = async (
  payload: UpdateAdminSettingsRequestPayload,
) => api.patch<ResponseSuccess>(`/accounts`, payload);
