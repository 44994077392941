/* eslint-disable no-plusplus */
import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import { DragAndDrop } from '@saleshandy/design-system';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import { EmailAccountsFileConfig } from '../select-email-accounts-file/types';
import UploadCloud from '../../../../../../../../shared/components/images/upload-cloud';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const allowedExtensions = ['csv'];

const PreFileSelect = ({ onSelectSuccess, getSkippedCount }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  //* State
  const [fileUploaderKey, setFileUploaderKey] = useState(0);
  const [dragging, setDragging] = useState(false);

  //* Functions
  const resetFileUploader = () => setFileUploaderKey(1 - fileUploaderKey);

  const showFailedMessage = (message: string) =>
    toaster.error(message, { theme: Theme.New });

  const failureHandler = (message: string) => {
    showFailedMessage(message);
    resetFileUploader();
  };

  const onFileSelect = (file) => {
    let headers;
    let firstRow;
    let count = 0;
    let failedMessage;
    let skCount = 0;
    const skippedRows = [];
    const validRows = [];
    const occurrences = new Map();

    const extension: string[] = file.name.split('.');

    if (
      !allowedExtensions.includes(
        extension[extension.length - 1].trim().toLowerCase(),
      )
    ) {
      failedMessage = `The file type you are trying to upload is not supported.`;
      return failureHandler(failedMessage);
    }

    if (file.size > EmailAccountsFileConfig.MaxFileSizeInMB * 1024 * 1024) {
      failedMessage = `File size must be less than ${EmailAccountsFileConfig.MaxFileSizeInMB} MB`;
      return failureHandler(failedMessage);
    }
    Papa.parse<string>(file, {
      step: ({ data }, parser) => {
        const emailAddress = data[0]; // email is in the first column
        const ownerHashId = data[data.length - 1]; // email is in the first column

        if (!emailAddress) {
          skippedRows.push(data);
          skCount++;
        } else {
          const emailId = emailAddress + ownerHashId;
          const c = occurrences.get(emailId) || 0;
          if (c >= 1) {
            skippedRows.push(data);
          } else {
            validRows.push(data);
          }

          occurrences.set(emailId, c + 1);
        }

        if (count > EmailAccountsFileConfig.MaxRows) {
          failedMessage = `Maximum ${EmailAccountsFileConfig.MaxRows} emails are allowed`;
          return parser.abort();
        }
        if (count === 1) {
          firstRow = data;
        }
        count += 1;

        return null;
      },
      complete: () => {
        if (!failedMessage && !firstRow) {
          failedMessage = 'At least 1 email account is required';
        }
        if (failedMessage) {
          return failureHandler(failedMessage);
        }

        occurrences.forEach((emailId) => {
          if (emailId > 1) {
            skCount++;
          }
        });

        const sCount = count - skCount - 1;

        //* Create CSV for Success Data
        const csvData = Papa.unparse({
          fields: headers,
          data: validRows,
        });
        const validFile = new File([csvData], file.name, {
          type: 'text/csv',
        });

        getSkippedCount(sCount, skCount, skippedRows);
        onSelectSuccess(validFile, headers, firstRow, count);

        return null;
      },
      skipEmptyLines: 'greedy',
    });

    return null;
  };

  const clickInput = () => inputRef.current.click();

  const inputChangeHandler = ({ target: { files } }) =>
    files.length > 0 && onFileSelect(files[0]);

  const dragAndDropClassName = classNames([
    {
      dragging,
    },
  ]);

  return (
    <DragAndDrop
      dragging={dragging}
      setDragging={setDragging}
      handleDroppedFiles={(files) => onFileSelect(files[0])}
      backgroundVariant="solid"
      title={t('messages.drop_your_csv_here')}
      className={`${dragAndDropClassName} d-flex justify-content-center align-items-center`}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <UploadCloud />

        <div className="d-flex mt-4">
          <span className="regular-2 font-medium popover-arrow-color-txt">
            Drag & Drop CSV file or{' '}
          </span>
          <div {...accessibleOnClick(clickInput)}>
            <div className="semibold-2 blue-txt-11 ml-1">Choose a file</div>
            <input
              type="file"
              accept=".csv, text/csv"
              ref={inputRef}
              onChange={inputChangeHandler}
              className="d-none"
            />
          </div>
        </div>
        <span className="regular-1 font-medium gray-txt-15 mt-2">
          Maximum allowed accounts per CSV: 100
        </span>
      </div>
    </DragAndDrop>
  );
};

export default PreFileSelect;
