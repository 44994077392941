import React from 'react';

const TimezoneOptionRenderer = ({ timezone }) => {
  const timeZoneArray = timezone.split('(');
  return (
    <div>
      <div>{timeZoneArray[0]}</div>
      <div>({timeZoneArray[1]}</div>
    </div>
  );
};
export default TimezoneOptionRenderer;
