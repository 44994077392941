import React from 'react';

import { IProps } from './types';
import { SMTPIMAP } from '../../../../enums/smtp-or-imap';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../shared/app-constants';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { isWhitelabelWithoutClientView } from '../../../../../../shared/utils/user-details';

const FormDetailsHeader: React.FC<IProps> = ({
  title,
  subTitle,
  requestFrom,
  agencyConfig,
}) => {
  const handleYoutubeClick = () => {
    const link =
      requestFrom === SMTPIMAP.SMTP
        ? process.env.REACT_APP_SMTP_YOUTUBE_VIDEO_URL
        : process.env.REACT_APP_IMAP_YOUTUBE_VIDEO_URL;

    window.open(link, '_blank');
  };

  return (
    <div className="form-header-wrapper d-flex justify-content-between">
      <div className="form-header-title d-flex justify-content-between">
        <div>
          <span className="semibold-2 popover-arrow-color-txt title-txt">
            {title}
          </span>
          <span className="regular-1 gray-txt-21">({subTitle})</span>
        </div>
        {agencyConfig?.showYTVideoGuide && isWhitelabelWithoutClientView() && (
          <div role="button" {...accessibleOnClick(handleYoutubeClick)}>
            <OverlayTooltip text={`Learn to connect ${requestFrom}`}>
              <ImageIcon src={Images.Youtube} />
            </OverlayTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormDetailsHeader;
