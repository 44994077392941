import React from 'react';
import { useTranslation } from 'react-i18next';

import { Images } from '../../../../../shared/app-constants';
import Modal from '../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../shared/design-system/components/atoms/button';
import Checkbox from '../../../../../shared/design-system/components/atoms/checkbox';
import { getESPSoftLimitWarningContentOne } from '../helpers';

const SoftLimitErrorNudge = ({
  show,
  softLimit,
  emailServiceProvider,
  softLimitAllowed,
  setSoftLimitAllowed,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.AlertCircleOrange} alt="Alert" />
          <h2>{t('messages.soft_limit_modal_title')}</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmit}
      onClose={onClose}
      onHide={onClose}
      submitButtonText={t('labels.save')}
      cancelButtonText={t('labels.cancel')}
      isSubmitDisabled={!softLimitAllowed}
      isSubmitLoading={isLoading}
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
    >
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">
          {getESPSoftLimitWarningContentOne(emailServiceProvider, softLimit, t)}
        </p>
        <p className="confirmation-modal-text">
          <Checkbox checked={softLimitAllowed} onChange={setSoftLimitAllowed} />
          <span className="checkbox-text">
            {t('messages.soft_limit_modal_content_6')} {softLimit}{' '}
            {t('messages.soft_limit_modal_content_7')}.
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default SoftLimitErrorNudge;
