import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import {
  resetUpdateSmtpImapAccountDetails,
  resetSmtpImapTestConnection,
  resetSmtpIMapConnectResponse,
} from '../../email-account-slice';
import {
  getUserSettingsRequest,
  updateProfileProgressRequest,
} from '../../../../../home/home-slice';
import {
  testSMTPConnectionRequest,
  testIMAPConnectionRequest,
  connectSmtpImapAccountRequest,
  getSmtpImapAccountDetailsRequest,
  updateSmtpImapAccountDetailsRequest,
} from '../../extra-actions';
import {
  ConnectSmtpImapAccountRequest,
  TestConnectionRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../../../../types/smtp-imap';
import { UpdateProfileProgressPayload } from '../../../../../../shared/types/user-setting';
import ConnectSMTPIMAPAccountModal from './connect-smtp-imap-account-modal';
import { PropsType } from './types';

const mapStateToProps = (state: RootState) => ({
  getTestSMTPConnectionRequestMessage:
    state.emailAccount.testSMTPConnectionRequest.message,
  getTestIMAPConnectionRequestMessage:
    state.emailAccount.testIMAPConnectionRequest.message,
  getTestSMTPConnectionRequestError:
    state.emailAccount.testSMTPConnectionRequest.error,
  getTestIMAPConnectionRequestError:
    state.emailAccount.testIMAPConnectionRequest.error,
  sendTestSMTPConnectionResponse: state.emailAccount.testSMTPConnectionResponse,
  sendTestIMAPConnectionResponse: state.emailAccount.testIMAPConnectionResponse,

  getConnectSmtpImapRequestStatus:
    state.emailAccount.connectSmtpImapAccountRequest.status,
  getConnectSmtpImapRequestMessage:
    state.emailAccount.connectSmtpImapAccountRequest.message,
  getConnectSmtpImapRequestError:
    state.emailAccount.connectSmtpImapAccountRequest.error,
  sendConnectSmtpImapResponse:
    state.emailAccount.connectSmtpImapAccountResponse,

  sendGetSmtpImapAccountDetailsRequestStatus:
    state.emailAccount.getSmtpImapAccountDetailsRequest.status,
  sendGetSmtpImapAccountDetailsResponse:
    state.emailAccount.sendGetSmtpImapAccountDetailsResponse,

  sendUpdateSmtpImapAccountRequestStatus:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.status,
  sendUpdateEmailAccountRequestMessage:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.message,
  sendUpdateSmtpImapAccountDetailsResponse:
    state.emailAccount.sendUpdateSmtpImapAccountDetailsResponse,
});

const mapDispatchToProps = {
  sendTestSMTPConnectionRequest: (payload: TestConnectionRequest) =>
    testSMTPConnectionRequest(payload),
  sendTestIMAPConnectionRequest: (payload: TestConnectionRequest) =>
    testIMAPConnectionRequest(payload),
  sendConnectSmtpImapAccountRequest: (payload: ConnectSmtpImapAccountRequest) =>
    connectSmtpImapAccountRequest(payload),
  sendGetSmtpImapAccountDetailsRequest: (emailAccountId: number) =>
    getSmtpImapAccountDetailsRequest(emailAccountId),
  sendUpdateSmtpImapAccountDetailsRequest: (
    emailAccountId: number,
    payload: UpdateSMTPIMAPAccountRequest,
  ) => updateSmtpImapAccountDetailsRequest({ emailAccountId, payload }),
  resetUpdateSmtpImapAccountDetails: () => resetUpdateSmtpImapAccountDetails(),
  resetSmtpImapTestConnection: () => resetSmtpImapTestConnection(),
  resetSmtpIMapConnectResponse: () => resetSmtpIMapConnectResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  updateProfileProgressRequest: (payload: UpdateProfileProgressPayload) =>
    updateProfileProgressRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & PropsType;

export default connector(ConnectSMTPIMAPAccountModal);
