import { RootState } from '../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getMyProfileRequest } from './my-profile-slice';
import MyProfile from './my-profile';

const mapStateToProps = (state: RootState) => ({
  getMyProfileRequestStatus: state.myProfile.getUserDetailRequest.status,
  getMyProfileRequestMessage: state.myProfile.getUserDetailRequest.message,
  getMyProfileRequestError: state.myProfile.getUserDetailRequest.error,

  myProfile: state.myProfile.myProfile,
  isEmpty: state.myProfile.myProfile.firstName === null,
});

const mapDispatchToProps = {
  sendGetMyProfileRequest: () => getMyProfileRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(MyProfile);
