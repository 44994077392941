import React from 'react';
import { withTranslation } from 'react-i18next';
import AlertInfoCircle from '../../../../../../shared/components/alert-info-circle';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import Modal from '../../../../../../shared/design-system/components/atoms/modal/modal';
import { IProps } from './types';

const DeleteEmailAccountModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
  isDefaultEmailAccount,
  t,
}) => (
  <Modal
    show={show}
    className="delete-modal"
    titleContent={
      <div className="d-flex">
        <AlertInfoCircle />
        <div className="pl-2"> {t('labels.delete_email_accounts')}</div>
      </div>
    }
    showCloseIcon={false}
    onClose={onClose}
    onHide={onClose}
    cancelButtonText={t('labels.cancel')}
    submitButtonText={t('labels.yes')}
    onSubmit={!isDefaultEmailAccount ? onSubmit : onClose}
    cancelButtonVarient={Variant.Outlined}
    isSubmitLoading={isRequestPending}
    isSubmitDisabled={isRequestPending}
  >
    <div>
      <span className="regular-2">
        {t('messages.delete_email_message_one')}{' '}
        {t('messages.delete_email_message_two')}
        {t('messages.delete_email_message_three')}
      </span>
    </div>
  </Modal>
);

export default withTranslation()(DeleteEmailAccountModal);
