import { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/root-reducer';

import { MergeTagOption } from '../overlay-merge-tags';
import { Placement } from '../../design-system/components/overlay';

import TagsDropdown from './tags-dropdown';

const mapStateToProps = (state: RootState) => ({
  schedule: state.sequence.sequenceConfig?.schedule,
  schedules: state.sequence.schedules,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  disabled?: boolean;
  mergeTags: MergeTagOption[];
  onAddMergeTag: (mergeTag: MergeTagOption) => void;
  onAddVariableTag: (tag: string) => void;
  children: ReactElement;
  placement?: Placement;
  hideVariableTags?: string[];
  disabledVariableTags?: string[];
  className?: string;
};

export default connector(TagsDropdown);
