export enum EmailAccountSettingCode {
  DailySendingLimit = 'daily-sending-limit',
  AvailableQuota = 'available-quota',
  Bcc = 'bcc',
  Signature = 'signature',
  RampUpInitialSendingLimit = 'ramp-up-initial-sending-limit',
  RampUpStatus = 'ramp-up-status',
  RampUpLimit = 'ramp-up-limit',
  RampUpPercent = 'ramp-up-percent',
  MaxSendingLimit = 'max-sending-limit',
  MinInterval = 'min-interval',
  MaxInterval = 'max-interval',
}
