export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum Variant {
  Default = 'default',
  Error = 'error',
}
