import React from 'react';
import type { IProps } from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import toaster, { Theme } from '../../../../../../shared/toaster/index';

const ErrorLogModal: React.FC<IProps> = ({
  show,
  onClose,
  accountType,
  errorLog,
}) => {
  const handelCopy = () => {
    navigator.clipboard.writeText(errorLog);
    toaster.success('Copied to Clipboard', {
      theme: Theme.New,
    });
  };

  const isJsonString = (str) => {
    try {
      const o = JSON.parse(str);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      onHide={onClose}
      onCancel={handelCopy}
      className="disconnect-email-account-modal"
      titleContent={`Error log (${accountType})`}
      showCloseIcon={true}
      hideSubmitButton={true}
      cancelButtonText="Copy"
      cancelButtonVarient={Variant.Outlined}
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
        backdrop: 'static',
      }}
    >
      <div className="gray-19 p-3 border-radius-4">
        {isJsonString(errorLog) ? (
          Object.entries(JSON.parse(errorLog)).map(([key, value]) => (
            <div className="d-flex font-monospace regular-2 popover-arrow-color-txt">
              <span>{`"${key}:"`}</span>
              <span className="ml-2">{`${value}`}</span>
            </div>
          ))
        ) : (
          <span className="font-monospace regular-2 popover-arrow-color-txt">
            {errorLog.replace(/" ?\\"|\\""|d{1,}"/g, '')}
          </span>
        )}
      </div>
    </Modal>
  );
};

export default ErrorLogModal;
