import { ProspectFiltersValuesPayload } from '../../types';

// Enum for filter source, such as prospects, fields, tags, or sequences
export enum FilterSource {
  PROSPECTS = 'prospects',
  FIELD = 'field',
  TAGS = 'tags',
  SEQUENCES = 'sequences',
}

// Enum for filter operations, like "is", "contains", "greater_than", etc.
export enum FilterOperation {
  IS = 'is',
  IS_NOT = 'is_not',
  IS_EMPTY = 'is_empty',
  IS_NOT_EMPTY = 'is_not_empty',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does_not_contain',
  EQUAL = 'equal',
  NOT_EQUAL = 'is_not_equal',
  GREATER_THAN = 'is_greater_than',
  GREATER_THAN_OR_EQUAL = 'is_greater_than_or_equal',
  LESS_THAN_OR_EQUAL = 'is_less_than_or_equal',
  LESS_THAN = 'is_less_than',
  BETWEEN = 'is_between',
  IS_WITHIN = 'is_within',
  IS_BEFORE = 'is_before',
  IS_AFTER = 'is_after',
  IS_ON_OR_BEFORE = 'is_on_or_before',
  IS_ON_OR_AFTER = 'is_on_or_after',
  START_WITH = 'starts_with',
  IN = 'in',
  NOT_IN = 'not_in',
}

// Enum for logic to apply between filter conditions (AND/OR)
export enum FilterLogic {
  AND = 'AND',
  OR = 'OR',
}

export enum DateFilter {
  YESTERDAY = 'Yesterday',
  TODAY = 'Today',
  LAST_WEEK = 'The last week',
  THIS_WEEK = 'This week',
  LAST_MONTH = 'The last month',
  THIS_MONTH = 'This month',
  LAST_YEAR = 'The last year',
  THIS_YEAR = 'This year',
}

// Type representing a single filter condition
export interface FilterCondition {
  field: ProspectFilterField;
  operation: FilterOperation | null;
  value?: string | number | (string | number)[];
}

// Type representing a group of filter conditions with logic
export interface FilterGroup {
  group: number;
  conditions: FilterCondition[];
  logic: FilterLogic;
}

// Type representing filter logic between groups
export interface FilterGroupLogic {
  filters: FilterGroup[];
  group_logic: FilterLogic;
}

export interface ProspectFilterField {
  id: string;
  key: string;
  source: FilterSource;
  value: string;
  label: string;
  operations: FilterOperation[];
}

export interface ProspectFiltersFieldsAttributesValue {
  [key: string]: {
    meta: ProspectFiltersValuesPayload & {
      hasMoreData: boolean;
    };
    payload: {
      name: string;
      value: string;
    }[];
  };
}
