import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IProps } from './types';
import { Images } from '../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const ReactivateSubscriptionModal: React.FC<IProps> = ({
  show,
  onClose,
  onReactivateSubscription,
  subscriptionEndDate,
  isRequestPending,
  reactivateSubscriptionModalMessage1,
  reactivateSubscriptionModalMessage2,
  reactivateSubscriptionModalHeaderMessage,
  submitButtonText,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      className="reactivate-subscription-modal"
      titleContent={
        <div className="d-flex">
          <ImageIcon src={Images.AlertTriangleLarge} />
          <div className="pl-3">
            {' '}
            {t(`messages.${reactivateSubscriptionModalHeaderMessage}`)}
          </div>
        </div>
      }
      showCloseIcon={!isRequestPending}
      onClose={onClose}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={t(`labels.${submitButtonText}`)}
      onSubmit={onReactivateSubscription}
      cancelButtonVarient={Variant.Outlined}
      isSubmitDisabled={isRequestPending}
      isSubmitLoading={isRequestPending}
      isCancelDisabled={isRequestPending}
      cancelButtonClassName="reactive-subscription-modal-cancel-btn"
      submitButtonClassName="reactive-subscription-modal-submit-btn"
      onHide={onClose}
      backdrop="static"
    >
      <div>
        <span className="regular-2">
          {t(`messages.${reactivateSubscriptionModalMessage1}`)}
        </span>{' '}
        <span className="semibold-2">
          {DateTime.fromJSDate(new Date(subscriptionEndDate)).toFormat(
            'd MMM, yyyy',
          )}
        </span>
      </div>
      <div className="mt-2">
        <span className="regular-2">
          {t(`messages.${reactivateSubscriptionModalMessage2}`)}
        </span>
      </div>
    </Modal>
  );
};

export default ReactivateSubscriptionModal;
