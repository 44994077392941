import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Icon from '../../design-system/components/atoms/icon/icon';
import PersonMonocularIcon from '../images/person-monocular-icon';

const Error404 = () => (
  <Container fluid className="error-404-container">
    <Row>
      <Col className="header">
        <div className="regular-4 gray-txt-10">Oops !</div>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs={8}>
        <div className="content">
          <PersonMonocularIcon />
          <h3 className="content-header semibold-8 gray-txt-10">
            Something is wrong
          </h3>
          <div className="regular-4 gray-txt-9">
            We can't seem to find the page you're looking for
          </div>
          <div className="external-icon-wrapper">
            <a href="/">
              <Icon identifier="external" className="external-icon" />
            </a>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Error404;
