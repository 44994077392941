type EventOptions = EventInit & {
  type?: string;
};

// https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js#46012210
// https://github.com/facebook/react/issues/11488#issuecomment-558874287
// https://github.com/facebook/react/issues/11488#issuecomment-347775628
// https://github.com/facebook/react/issues/11488
// The entire thread is helpful.
// Small improvements and use cases in a lot of different comments.
export const setNativeValue = (element: HTMLElement, value: any) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value',
  )?.set;
  if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else if (valueSetter) {
    valueSetter.call(element, value);
  }
};

export const emitEvent = (
  type: string,
  element: HTMLElement,
  options?: EventInit,
) => {
  const event = new Event(type, options);
  element.dispatchEvent(event);
};

export const changeNativeValue = (
  element: HTMLElement,
  value: any,
  { type = 'input', bubbles = true, ...rest }: EventOptions = {},
) => {
  setNativeValue(element, value);
  emitEvent(type, element, { bubbles, ...rest });
};
