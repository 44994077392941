import { FilterOperation, ProspectFilterField } from '../types';
import { staticDropdownKeysForProspectFilter } from './get-prospect-filters-field-attribute-values';

export const multiSelectOperations = [
  FilterOperation.IS,
  FilterOperation.IS_NOT,
];

export const textInputOperations = [
  FilterOperation.CONTAINS,
  FilterOperation.DOES_NOT_CONTAIN,
];

export const numberInputOperations = [
  FilterOperation.EQUAL,
  FilterOperation.NOT_EQUAL,
  FilterOperation.GREATER_THAN,
  FilterOperation.GREATER_THAN_OR_EQUAL,
  FilterOperation.LESS_THAN,
  FilterOperation.LESS_THAN_OR_EQUAL,
  FilterOperation.BETWEEN,
];

export const dateDropdownOperations = [
  FilterOperation.IS_WITHIN,
  FilterOperation.IS_BEFORE,
  FilterOperation.IS_AFTER,
  FilterOperation.IS_ON_OR_BEFORE,
  FilterOperation.IS_ON_OR_AFTER,
];

export const dateFieldsKeys = ['creationDate', 'lastUpdatedAt'];

const prospectFilterPriorityOrder = [
  'First Name',
  'Last Name',
  'Email',
  'Job Title',
  'Prospect Status',
  'Prospect Outcomes',
  'Prospect Tags',
  'Department',
  'Company',
  'Company Industry',
  'Company Website',
  'Prospect Owner',
  'Creation Date',
  'Last Updated',
  'Sequence',
  'Verification Status',
  'Creation Source',
  'Client associated with sequence',
  'LinkedIn',
  'Phone Number',
  'Experience',
  'Twitter',
  'Facebook',
  'City',
  'State',
  'Country',
  'Company LinkedIn',
  'Company Phone',
  'Company Domain',
  'Company Size',
  'Company Revenue',
  'Company Founded Year',
];

// Create a mapping of priorities
const priorityMap = new Map(
  prospectFilterPriorityOrder.map((item, index) => [item, index]),
);

// Sort the fieldsArray based on the priority of their 'value'
export const getSortedProspectFilterFields = (
  fields: ProspectFilterField[],
) => {
  const sortedFields = [...fields];

  sortedFields.sort((a, b) => {
    const priorityA = priorityMap.get(a.value) ?? Infinity;
    const priorityB = priorityMap.get(b.value) ?? Infinity;
    return priorityA - priorityB;
  });

  return sortedFields;
};

// keys which do not required search
const prospectFiltersFieldsKeysWithNoSearch = [
  ...staticDropdownKeysForProspectFilter,
  'creationDate',
  'lastUpdatedAt',
];

// keys which do not required api calls for search
export const prospectFiltersFieldsKeysFilterOptions = [
  'tags',
  'outcomes',
  'owners',
];

export const getProspectFiltersMultiSelectSearchOptions = (
  field: ProspectFilterField,
) => {
  if (prospectFiltersFieldsKeysWithNoSearch.includes(field.key)) {
    return {
      showSearch: false,
      useFilterOption: false,
      useAPICall: false,
    };
  }

  if (prospectFiltersFieldsKeysFilterOptions.includes(field.key)) {
    return {
      showSearch: true,
      useFilterOption: true,
      useAPICall: false,
    };
  }

  return {
    showSearch: true,
    useFilterOption: false,
    useAPICall: true,
  };
};

export const getSelectedUsers = (
  userIds: number[],
  users: {
    id: number;
    userName: string;
    email: string;
  }[],
) =>
  userIds
    ?.map((id) => {
      // Find the user object in users array
      const user = users.find((u) => u.id === id);

      // If user is found, return the key and name (userName or email if userName is empty)
      return user
        ? {
            key: user.id.toString(),
            name: user.userName !== '' ? user.userName : user.email,
          }
        : null; // Return null if no user is found for that ID
    })
    .filter(Boolean) || []; // Filter out any null values in case a user ID doesn't match
