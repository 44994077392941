import produce from 'immer';
import React from 'react';
import { Link } from 'react-router-dom';
import PasswordValidationChecklist from '../../../../shared/components/password-validation-checklist';
import Alert from '../../../../shared/design-system/components/alert';
import Button from '../../../../shared/design-system/components/atoms/button';
import Input from '../../../../shared/design-system/components/input';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../shared/toaster';
import {
  getCurrentTimeZone,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
} from '../../../../shared/utils';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import AlertContainer from '../alert-container';
import ButtonContainer from '../button-container';
import FormSection from '../form-section';
import PrivacyPolicy from '../privacy-policy';
import TopWelcome from '../top-welcome';
import { IProps, IState } from './types';
import { validate } from './validator';

class LTDSignupForm extends React.PureComponent<IProps, IState> {
  private readonly v3 = 'V3';

  constructor(props: IProps) {
    super(props);

    this.state = {
      values: {
        email: props.email ? props.email : '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
      dirty: {
        email: false,
        password: false,
      },
      showPassword: false,
    };

    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hideErrorHandler = this.hideErrorHandler.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      status,
      showVerificationCodeComponent,
      message,
      acceptInvitationRequestStatus,
      trackingId,
      firstName,
      lastName,
      email,
    } = this.props;

    if (
      acceptInvitationRequestStatus !== prevProps.acceptInvitationRequestStatus
    ) {
      executeOnRequestStatus({
        status: acceptInvitationRequestStatus,
        onSuccess: () => {
          initializeThirdPartyIntegrations({
            trackingId,
            firstName,
            lastName,
            email,
          });

          initializeSentryIntegrations(email);
        },
      });
    }

    if (status !== prevProps.status) {
      if (status === RequestStatus.Succeeded) {
        showVerificationCodeComponent?.();
        toaster.success(message, {
          theme: Theme.New,
        });
      }
    }
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values: errorValues } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, errorValues[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    let timeZone = getCurrentTimeZone();
    if (timeZone === 'Asia/Calcutta') {
      timeZone = 'Asia/Kolkata';
    }
    const { values } = this.state;
    const { email, password } = values;
    const {
      forInvitation,
      sendAcceptInvitationRequest,
      token,
      sendSignupRequest,
      isLtdUser = false,
      setUserEmail,
    } = this.props;
    if (forInvitation) {
      sendAcceptInvitationRequest({
        password,
        token,
        timeZone,
      });
    } else {
      let rewardfulReferralId = '';
      if (window.rewardful) {
        rewardfulReferralId = window.Rewardful?.referral;
      }
      sendSignupRequest({
        email,
        password,
        timeZone,
        rewardfulReferralId,
        isLtdUser,
      });
    }

    setUserEmail(email);
  }

  togglePasswordVisibility() {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  }

  hideErrorHandler() {
    const { hideError } = this.props;
    hideError?.();
  }

  render() {
    const { values, errors, showPassword, dirty } = this.state;
    const {
      status,
      error,
      showError,
      forInvitation,
      welcomeHeader,
      welcomeBody,
      showPasswordValidationMessage = true,
      agencyConfig,
      showTermOfServiceAndPrivacyPolicy = true,
      showLoginLink = true,
    } = this.props;
    const isLoading = status === RequestStatus.Pending;
    const isSignUpButtonDisable =
      !(!!values?.email && !!values?.password) ||
      !!validate('email', values.email) ||
      !!validate('password', values.password) ||
      isLoading;

    return (
      <div className="signup">
        <div>
          <TopWelcome>
            <TopWelcome.Header>{welcomeHeader}</TopWelcome.Header>
            <TopWelcome.Body>{welcomeBody}</TopWelcome.Body>
          </TopWelcome>
          {showError && (
            <AlertContainer>
              <Alert
                variant={Alert.Variant.Danger}
                dismissible={true}
                onClose={this.hideErrorHandler}
                iconIdentifier="close-o"
                header="Whoops"
              >
                {error.message}
              </Alert>
            </AlertContainer>
          )}
          <FormSection>
            <form
              onSubmit={this.onFormSubmit}
              className="signup-form signup step_1"
            >
              <Input
                disabled={forInvitation}
                name="email"
                label="Email"
                placeholder="Preferably your work email..."
                value={values.email}
                variant={errors.email && Input.Variant.Error}
                caption={errors.email}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="current-email"
              />
              <Input
                name="password"
                label="Create Password"
                placeholder="A secure one please..."
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                variant={errors.password && Input.Variant.Error}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                caption={showPasswordValidationMessage && errors.password}
                autoComplete="current-password"
                icons={[
                  {
                    place: Input.IconPlace.Right,
                    identifier: showPassword ? 'eye-alt' : 'eye',
                    className: 'pointer',
                    onClick: this.togglePasswordVisibility,
                  },
                ]}
              />
              <PasswordValidationChecklist
                password={values.password}
                isDirty={dirty.password}
              />
              <ButtonContainer>
                <Button
                  variant={Button.Variant.Primary}
                  type={Button.Type.Submit}
                  isLoading={isLoading}
                  disabled={isSignUpButtonDisable}
                  className="sign-up-btn"
                >
                  {forInvitation ? 'Continue' : 'Sign up'}
                </Button>
              </ButtonContainer>
            </form>
          </FormSection>
          {showTermOfServiceAndPrivacyPolicy &&
            agencyConfig?.showTermOfServiceAndPrivacyPolicy && (
              <PrivacyPolicy />
            )}
          {showLoginLink && (
            <div className="bottom-navigation">
              <p>
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LTDSignupForm;
