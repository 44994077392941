import api from '../../../api';
import {
  MailboxEmailActivityPayload,
  MailboxEmailNotificationPayload,
  MailboxEmailsFiltersMeta,
} from '../type';

// Get Mailbox Emails List
export const getMailboxEmailsList = async ({
  mailbox,
  ...rest
}: MailboxEmailsFiltersMeta) =>
  api.get('/mailboxes/emails', {
    params: {
      ...rest,
      ...(mailbox > 0 && { mailboxes: mailbox }),
    },
  });

// Get Mailboxed List
export const getMailboxesList = async () => api.get('/mailboxes');

// Get Mailbox Emails List
export const getMailboxEmailActivity = async ({
  id,
  ...rest
}: MailboxEmailActivityPayload) =>
  api.get(`/activity/emails/${id}`, {
    params: {
      ...rest,
    },
  });

// Toggle Mailbox Email Notification
export const toggleMailboxEmailNotification = async ({
  id,
  ...rest
}: MailboxEmailNotificationPayload) =>
  api.patch(`/mailboxes/emails/${id}`, {
    ...rest,
  });

// Delete Mailbox Email
export const deleteMailboxEmail = async (mailboxEmailId: number) =>
  api.delete(`/mailboxes/emails/${mailboxEmailId}`);
