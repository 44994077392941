import React from 'react';
import classNames from 'classnames';
import {
  Toast as ReactBootstrapToast,
  ToastProps as ReactBootstrapToastProps,
} from 'react-bootstrap';
import { Modify } from '../types';
import Icon from '../design-system/components/atoms/icon/icon';

export enum Variant {
  Default = 'default',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type IState = {
  showNotification: boolean;
};

export type PartialNotificationProps = {
  variant?: Variant;
  header: React.ReactNode;
  content: React.ReactNode;
  delay?: number;
  // animation?: boolean;
  button?: React.ReactNode;
  onClose?: () => void;
};

type IProps = Modify<
  Omit<ReactBootstrapToastProps, 'animation'>,
  PartialNotificationProps
>;

class Notification extends React.Component<IProps, IState> {
  static Variant = Variant;

  static defaultProps: Partial<PartialNotificationProps> = {
    variant: Variant.Default,
    // animation: false,
  };

  constructor(props) {
    super(props);
    this.state = { showNotification: true };
    this.hideNotification = this.hideNotification.bind(this);
  }

  hideNotification() {
    this.setState({ showNotification: false });
    this.props.onClose?.();
  }

  render() {
    const {
      variant,
      header,
      content,
      delay,
      // animation,
      button,
      className,
      // we aren't using onClose directly as a prop to ReactBootstrapToast,
      // but if we don't extract onClose here, then it would be in rest array,
      // which will add the onClose prop to ReactBootstrapToast.
      // if you keep the ...rest before providing any props to ReactBootstrapToast,
      // then the provided onClose will override the onClose provided by rest array.
      // but still, for safekeeping, we should extract onClose here.
      // eslint-disable-next-line
      onClose,
      ...rest
    } = this.props;

    const toastClass = classNames([
      'bs-toast',
      'notification-container',
      className,
    ]);

    const isIcon = variant !== Variant.Default;

    const iconWrapperSubClass = classNames([
      {
        'icon-basic': variant === Variant.Primary,
        'icon-success': variant === Variant.Success,
        'icon-warning': variant === Variant.Warning,
        'icon-error': variant === Variant.Error,
      },
    ]);

    const toastHeadIconClass = 'notification-icon';

    const iconWrapperClass = classNames([
      iconWrapperSubClass,
      toastHeadIconClass,
    ]);

    const iconIdentifier = classNames([
      {
        'check-o': variant === Variant.Success,
        danger: variant === Variant.Primary || variant === Variant.Warning,
        'close-o': variant === Variant.Error,
      },
    ]);

    return (
      <ReactBootstrapToast
        {...rest}
        className={toastClass}
        // animation={animation}
        autohide={!!delay}
        delay={delay}
        show={this.state.showNotification}
        onClose={this.hideNotification}
      >
        <ReactBootstrapToast.Header
          bsPrefix="bs-toast-header"
          className={isIcon && toastHeadIconClass}
        >
          {isIcon && (
            <div className={iconWrapperClass}>
              <Icon identifier={iconIdentifier} />
            </div>
          )}
          <span className="mr-auto">{header}</span>
        </ReactBootstrapToast.Header>
        <ReactBootstrapToast.Body
          bsPrefix="bs-toast-body"
          className={isIcon && 'notification-desc'}
        >
          {content}
        </ReactBootstrapToast.Body>
        {button && (
          <div className="d-flex justify-content-end bs-mt-10">{button}</div>
        )}
      </ReactBootstrapToast>
    );
  }
}

export default Notification;
