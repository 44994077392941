import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  getAcademyTopics,
  getAcademyTopicProgress,
  updateAcademyTopicsProgress,
} from './helper/growth-hub.api';
import { UpdateAcademyTopicsProgressRequest } from './types';

export const getAcademyTopicsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('academy/getAcademyTopicRequest', async (_args, thunkAPI) => {
  try {
    return await getAcademyTopics();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAcademyTopicsProgressRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('academy/getAcademyTopicProgressRequest', async (_args, thunkAPI) => {
  try {
    return await getAcademyTopicProgress();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateAcademyTopicProgressRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateAcademyTopicsProgressRequest,
  { rejectValue: ResponseErrorWithHandled }
>('academy/updateAcademyTopicProgressRequest', async (args, thunkAPI) => {
  try {
    return await updateAcademyTopicsProgress(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
