import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Trash } from '@saleshandy/icons';
import Button from '../../design-system/components/atoms/button';
import Modal from '../../design-system/components/atoms/modal/modal';
import { DeleteAlertModalProps, ConfirmDeleteContentProps } from './type';
import Input from '../../design-system/components/input/input';

const ConfirmDeleteContent: React.FC<ConfirmDeleteContentProps> = ({
  onInputChange,
  inputValue,
  focusInputRef,
  deleteConfirmText = 'delete',
  onKeyPress,
}) => (
  <div className="Confirm_DeleteContent--input">
    <p className="Confirm_DeleteContent--input-text">
      Confirm by typing
      <span className="Confirm_DeleteContent--input-delete-text">
        {deleteConfirmText}
      </span>
      below
    </p>
    <Input
      type="text"
      value={inputValue}
      onKeyPress={onKeyPress}
      onChange={onInputChange}
      ref={focusInputRef}
    />
  </div>
);

const DeleteAlertModal: React.FC<DeleteAlertModalProps> = ({
  show,
  title,
  onClose,
  onSubmit,
  className = '',
  contents = [],
  isSubmitLoading = false,
  isConfirmDelete = false,
  children = '',
  deleteConfirmText = isConfirmDelete && 'delete',
  submitButtonText = 'Delete',
}) => {
  const focusInputRef = useRef<HTMLInputElement>(null);
  const [isInputValid, setIsInputValid] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onInputChange = (
    value: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    const isValidInput = value.trim();
    setInputValue(isValidInput);
    setIsInputValid(isValidInput === deleteConfirmText);
  };

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && isInputValid) {
        onSubmit();
      }
    },
    [isInputValid, onSubmit],
  );

  useEffect(() => {
    if (show && focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, [show]);

  const generateModalTitleContent = () => (
    <div className="delete-alert-modal--title">
      <Trash className="delete-alert-modal--title__icon" />
      <h2>{title}</h2>
    </div>
  );

  return (
    <Modal
      show={show}
      titleContent={generateModalTitleContent()}
      className={`delete-alert-modal ${className}`}
      onClose={onClose}
      onSubmit={onSubmit}
      showCloseIcon={false}
      hideCancelButton={false}
      hideSubmitButton={false}
      submitButtonClassName="action-btn-delete"
      cancelButtonClassName="action-btn-cancel"
      isSubmitLoading={isSubmitLoading}
      isCancelLoading={false}
      isSubmitDisabled={isSubmitLoading || (isConfirmDelete && !isInputValid)}
      isCancelDisabled={false}
      submitButtonText={submitButtonText}
      cancelButtonText="Cancel"
      submitButtonVariant={Button.Variant.Primary}
      cancelButtonVarient={Button.Variant.Outlined}
      backdrop="static"
      extraModalProps={{
        centered: true,
        enforceFocus: false,
        'aria-labelledby': 'contained-modal-title-vcenter',
        onShow: () => {
          if (focusInputRef.current) {
            focusInputRef.current.focus();
          }
        },
      }}
    >
      {contents.length > 0 && (
        <div className="delete-alert-modal--body__contents">
          {contents.map((contentItem, index) => (
            <p key={index} className="">
              {contentItem}
            </p>
          ))}
        </div>
      )}
      {isConfirmDelete && (
        <ConfirmDeleteContent
          onInputChange={onInputChange}
          inputValue={inputValue}
          focusInputRef={focusInputRef}
          deleteConfirmText={deleteConfirmText}
          onKeyPress={handleKeyPress}
        />
      )}
      {children && <div className="custom-jsx-element">{children}</div>}
    </Modal>
  );
};

export default DeleteAlertModal;
