import { DateTime } from 'luxon';

/**
 * Parses JS Date to string.
 * @summary Use this to store dates in Redux Store because Non-Serializable date(JS Date) can't be stored(without warnings :( )
 * in Redux Store
 * @param  {Date} date - Date
 * @returns {string} parsed date.
 */
export const dateToString = (date: Date): string => {
  return DateTime.fromJSDate(date).toString();
};

/**
 * Formats the date to readable format
 * @summary Useful to parse dates before displaying them on UI
 * @param {Date} date - Date to parse.
 * @return {string} Formatted date
 */
export const getFormattedDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat('MMM dd, yyyy');
};

/**
 * Formats the date to numerical date format like (20-02-2021)
 * @param {Date} date - Date to parse
 * @returns {string} Formatted date
 */

export const getNumericalFormattedDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat('dd-MM-yyyy');
};

/**
 * Parses string to JS Date.
 * @summary Useful to parse string dates before sending them to API or Date Picker
 * @param  {string} date - date to parse
 * @returns {Date} JS Date
 */
export const stringToDate = (date: string): Date => {
  return new Date(DateTime.fromISO(date).toISO());
};
