import React from 'react';
import EmptyDataIcon2 from '../../../../../shared/components/images/empty-data-icon-2';

const EmailAccountsEmptyUI: React.FC = () => (
  <div className="empty-block">
    <EmptyDataIcon2 />
    <p>No Email Account Found</p>
  </div>
);

export default EmailAccountsEmptyUI;
