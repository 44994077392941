/* eslint-disable @typescript-eslint/no-shadow */
import { map } from 'lodash';
import {
  Filters,
  ProspectsFilters,
  FilterProperties,
} from '../../../shared/types/prospects-filters';
import {
  ContactStatusFilterEnum,
  ContactStatusType,
  ContactStatusTypeDisplayName,
  ContactVerificationStatus,
  ContactVerificationStatusTypeDisplayName,
  ProspectOutcomeDisplayName,
  CreationSourceDisplayName,
  ProspectCreationSource,
  SequenceStepPriority,
} from '../../sequence/enums';

// To separate filters when displayed on UI
export const filterSeparator = ', ';

export const filterToProperties: Record<Filters, FilterProperties> = {
  tags: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  owners: {
    displayNameKey: 'firstName',
    valueKey: 'id',
    separator: filterSeparator,
  },
  status: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  outcomes: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'current step': {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  sequences: {
    displayNameKey: 'title',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'email account': {
    displayNameKey: 'email',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'client associated': {
    displayNameKey: 'email',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'verification status': {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'task priority': {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'created date': {
    displayNameKey: 'displayDate',
    valueKey: 'date',
    separator: ' - ',
  },
  'creation source': {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
};

/**
 * Prospect Statuses
 */
export const statuses = [
  {
    name: ContactStatusTypeDisplayName.subscribed,
    id: ContactStatusType.Subscribed,
    isHeaderFilter: false,
  },
  {
    name: ContactStatusTypeDisplayName.unsubscribed,
    id: ContactStatusType.Unsubscribed,
    isHeaderFilter: false,
  },
  {
    name: ContactStatusTypeDisplayName.bounced,
    id: ContactStatusType.Bounced,
    isHeaderFilter: false,
  },
  {
    name: ContactStatusTypeDisplayName.paused,
    id: ContactStatusType.Paused,
    isHeaderFilter: false,
  },
  {
    name: ContactStatusFilterEnum.NotContacted,
    id: ContactStatusFilterEnum.NotContacted,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.Contacted,
    id: ContactStatusFilterEnum.Contacted,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.Open,
    id: ContactStatusFilterEnum.Open,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.Replied,
    id: ContactStatusFilterEnum.Replied,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.Clicked,
    id: ContactStatusFilterEnum.Clicked,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.SentNotOpen,
    id: ContactStatusFilterEnum.SentNotOpen,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.OpenNotClick,
    id: ContactStatusFilterEnum.OpenNotClick,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.OpenNotReply,
    id: ContactStatusFilterEnum.OpenNotReply,
    isHeaderFilter: true,
  },
  {
    name: ContactStatusFilterEnum.ClickedNoReply,
    id: ContactStatusFilterEnum.ClickedNoReply,
    isHeaderFilter: true,
  },
];

/**
 * Prospect Outcomes
 */
export const outcomes = [
  {
    name: ProspectOutcomeDisplayName.Interested,
    id: 2,
  },
  {
    name: ProspectOutcomeDisplayName.NotInterested,
    id: 3,
  },
  {
    name: ProspectOutcomeDisplayName.MeetingBooked,
    id: 4,
  },
  {
    name: ProspectOutcomeDisplayName.OutOfOffice,
    id: 5,
  },
  {
    name: ProspectOutcomeDisplayName.Closed,
    id: 6,
  },
  {
    name: ProspectOutcomeDisplayName.NotNow,
    id: 7,
  },
  {
    name: ProspectOutcomeDisplayName.DoNotContact,
    id: 8,
  },
];

/**
 * Prospect Statuses
 */
export const verificationStatuses = [
  {
    name: ContactVerificationStatusTypeDisplayName.Unverified,
    id: ContactVerificationStatus.Unverified,
  },
  {
    name: ContactVerificationStatusTypeDisplayName.Valid,
    id: ContactVerificationStatus.Valid,
  },
  {
    name: ContactVerificationStatusTypeDisplayName.Risky,
    id: ContactVerificationStatus.Risky,
  },
  {
    name: ContactVerificationStatusTypeDisplayName.Bad,
    id: ContactVerificationStatus.Bad,
  },
];

/**
 * Task Priorities
 */
export const taskPriorities = [
  {
    name: SequenceStepPriority.Low,
    id: 4,
  },
  {
    name: SequenceStepPriority.Normal,
    id: 3,
  },
  {
    name: SequenceStepPriority.High,
    id: 2,
  },
  {
    name: SequenceStepPriority.Urgent,
    id: 1,
  },
];

/**
 * Prospect creation source
 */
export const creationSource = [
  {
    name: CreationSourceDisplayName.Manually_Created,
    id: ProspectCreationSource.manually_created,
  },
  {
    name: CreationSourceDisplayName.CSV_Import,
    id: ProspectCreationSource.csv_import,
  },
  {
    name: CreationSourceDisplayName.Chrome_Extention,
    id: ProspectCreationSource.chrome_extension,
  },
  {
    name: CreationSourceDisplayName.API,
    id: ProspectCreationSource.api,
  },
  {
    name: CreationSourceDisplayName.Lead_Finder,
    id: ProspectCreationSource.lead_finder,
  },
  {
    name: CreationSourceDisplayName.Undefined,
    id: ProspectCreationSource.undefined,
  },
];

/**
 * Retrieves all the value of the filter.
 * @summary - Gets all the values of the applied filters using the value Key mentioned in filter properties
 * @param  {Filters} filterName - Name of the filter.
 * @param  {ProspectsFilters} filter - Applied filters.
 * @returns {string[]} Values of the filter.
 */
export const getFilterValues = (
  filterName: Filters,
  filter: ProspectsFilters,
): string[] => map(filter, filterToProperties[filterName].valueKey);

/**
 * Resolves the filters to query string
 * @param  {Record<Filters, any>} appliedFilters - applied filters.
 * @returns {Record<Filters, string>} Returns the Object having the applied filter and its corresponding query string
 */
type ResolveFilters = Record<Filters, string> & { isSequenceNone: boolean };

export const resolveFilters = (
  appliedFilters: Record<Filters, any>,
): ResolveFilters => {
  const payload: ResolveFilters = {
    tags: '',
    owners: '',
    status: '',
    outcomes: '',
    sequences: '',
    isSequenceNone: false,
    'verification status': '',
    'task priority': '',
    'created date': '',
    'creation source': '',
    'current step': '',
    'email account': '',
    'client associated': '',
  };

  Object.entries(appliedFilters).forEach(([filter, values]) => {
    payload[filter] = '';
    let filterValues: any = [...values];

    if (filterValues.some((item) => item?.isNoneSequence === true)) {
      payload.isSequenceNone = true;
      filterValues = filterValues.filter((item) => item.id !== null);
    }

    const filterVal = getFilterValues(filter as Filters, filterValues).join(
      ',',
    );
    filterVal && (payload[filter] += filterVal);
  });

  return payload;
};

export const setProspectsFiltersInLocalStore = (filters: ProspectsFilters) => {
  localStorage.setItem('p-f', JSON.stringify(filters));
};

export const getProspectsFiltersFromLocalStore = (): ProspectsFilters =>
  JSON.parse(localStorage.getItem('p-f'));

export const removeProspectsFiltersFromLocalStore = (): void => {
  localStorage.removeItem('p-f');
};

/**
 * Returns the extended params of an API with the applied filters.
 * @param  {Record<string, any>} params - Params of a API
 * @param  {ProspectsFilters} filters - Applied filters.
 */
export const getProspectParams = (
  params: Record<string, any>,
  filters: ProspectsFilters,
) => {
  // Resolving the applied filters to query string
  const {
    tags,
    owners,
    status,
    outcomes: ocs,
    sequences,
    isSequenceNone,
    'verification status': verificationStatus,
    'created date': createdDate,
    'creation source': creationSource,
    'current step': stepIds,
    'email account': emailAccount,
    'client associated': clients,
  } = resolveFilters(filters);

  const updatedParams: Record<string, any> = { ...params };
  status && (updatedParams.status = status);
  ocs && (updatedParams.outcomes = ocs);
  tags && (updatedParams.tags = tags);
  owners && (updatedParams.owners = owners);
  sequences && (updatedParams.sequences = sequences);
  isSequenceNone && (updatedParams.isSequenceNone = isSequenceNone);
  verificationStatus && (updatedParams.verification = verificationStatus);
  creationSource && (updatedParams.creationSource = creationSource);
  createdDate && (updatedParams.createdDate = createdDate);
  stepIds && (updatedParams.stepIds = stepIds);
  emailAccount && (updatedParams.emailAccount = emailAccount);
  clients && (updatedParams.clients = clients);

  return updatedParams;
};

export const areFiltersApplied = (filters: ProspectsFilters): boolean =>
  Object.values(filters).some((value) => value.length);
