import { ResponseSuccess } from '../../../shared/types';
import api from '../../../api';
import {
  CreateCustomFieldRequestPayload,
  GetFieldsQueryParams,
  UpdateCustomFieldRequestPayload,
} from '../types/request-payload';
import { ProspectType } from '../enums/prospect-type';
import { PaginationQueryParams } from '../../../shared/types/request';
import { FieldCategory } from '../enums/field-category';

// Get fields with pagination function
export const getCustomFieldsWithPagination = async (
  params: PaginationQueryParams = {},
) =>
  api.get<ResponseSuccess>('/fields/list', {
    params: { ...params, category: FieldCategory.Custom },
  });

// Get fields with pagination function
export const getDefaultFieldsWithPagination = async (
  params: PaginationQueryParams = {},
) =>
  api.get<ResponseSuccess>('/fields/list', {
    params: { ...params, category: FieldCategory.Default },
  });

// Get All Fields
export const getAllFields = async (params: PaginationQueryParams = {}) =>
  api.get<ResponseSuccess>('/fields/list', { params });

// Get fields generic function
export const getFieldsGeneric = async (params: GetFieldsQueryParams = {}) =>
  api.get<ResponseSuccess>('/fields', { params });

// Get fields (excluding defaults)
export const getCustomFields = () =>
  getFieldsGeneric({ includeDefaults: true });

// Get contact fields (including defaults)
export const getContactFields = async () =>
  getFieldsGeneric({
    prospectType: ProspectType.Contact,
    includeDefaults: true,
  });

// Get account fields (including defaults)
export const getAccountFields = async () =>
  getFieldsGeneric({
    prospectType: ProspectType.Account,
    includeDefaults: true,
  });

// Get contact fields (excluding defaults)
export const getContactCustomFields = async () =>
  getFieldsGeneric({
    prospectType: ProspectType.Contact,
    includeDefaults: false,
  });

// Get account fields (excluding defaults)
export const getAccountCustomFields = async () =>
  getFieldsGeneric({
    prospectType: ProspectType.Account,
    includeDefaults: false,
  });

// Create field
export const createCustomField = async (
  payload: CreateCustomFieldRequestPayload,
) => api.post<ResponseSuccess>('/fields', payload);

// Update field
export const updateCustomField = async (
  fieldId: number,
  payload: UpdateCustomFieldRequestPayload,
) => api.patch<ResponseSuccess>(`/fields/${fieldId}`, payload);

// Delete field
export const deleteCustomField = async (fieldId: number) =>
  api.delete<ResponseSuccess>(`/fields/${fieldId}`);
