export enum StatusBadgeVariant {
  SUCCESS = 'success',
  DANGER = 'danger',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  LIGHT_GREEN = 'light_green',
  BLUE = 'blue',
}

export type IProps = {
  label?: string;
  variant: StatusBadgeVariant;
};
