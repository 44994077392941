export const supportUrls = Object.freeze({
  teamsOfService: 'https://www.saleshandy.com/terms/',
  scheduleCall:
    'https://www.saleshandy.com/demo/?utm_source=webapp&utm_medium=top_right_button&utm_campaign=product_demo_v3/',
  refundPolicy: 'https://docs.saleshandy.com/en/articles/6969685-refund-policy',
  becomeOurAffiliate:
    'https://www.saleshandy.com/affiliate-program/?utm_source=inapp&utm_medium=direct&utm_campaign=affiliate',
  warmupFaq:
    'https://docs.saleshandy.com/en/articles/7146556-transition-to-trulyinbox-for-email-warm-up',
  outreachPlanViewFAQ:
    'https://docs.saleshandy.com/en/articles/6980297-everything-about-saleshandy-s-pricing-and-plans',
  leadFinderPlanViewFAQ:
    'https://docs.saleshandy.com/en/articles/8778847-saleshandy-lead-finder-pricing-plans',
  updateCardDetails:
    'https://billing.stripe.com/p/login/14kbJC7H05AW8qk9AA?prefilled_email=',
  smtpImapCouldNotAuth:
    'https://docs.saleshandy.com/en/articles/7041196-how-to-fix-smtp-imap-could-not-authenticate-error',
  smtpImapEmailNotFound:
    'https://docs.saleshandy.com/en/articles/6970075-how-to-fix-smtp-imap-email-not-found-error',
  smtpImapConnectionTimeOut:
    'https://docs.saleshandy.com/en/articles/7041212-how-to-fix-smtp-imap-connection-timeout-error',
  smtpImapOtherError:
    'https://docs.saleshandy.com/en/articles/7041222-how-to-fix-smtp-imap-other-error',
  pauseProspectInSequence:
    'https://docs.saleshandy.com/en/articles/6970073-how-to-pause-a-prospect-in-sequence',
  spintaxBlog:
    'https://docs.saleshandy.com/en/articles/6946631-what-is-spintax-how-to-use-it-with-saleshandy',
  coldEmailMasterClass:
    'https://www.saleshandy.com/cold-email-masterclass/?utm_source=inapp&utm_medium=inappsection&utm_campaign=coldemailmastery',
  intercomBaseUrl: 'https://api-iam.intercom.io',
  celloBaseUsrl: 'https://api.sandbox.cello.so/	',
  howToConnectGmailAccountWithSmtpImap:
    'https://docs.saleshandy.com/en/articles/6948265-how-to-connect-a-gmail-account-with-smtp-imap',
  howToConnectGmailAccountWithSmtpImapStep3:
    'https://docs.saleshandy.com/en/articles/6948265-how-to-connect-a-gmail-account-with-smtp-imap#h_6cddbd857b',
  howToConnectGmailAccountWithSmtpImapVideo: 'https://youtu.be/fUpKGwuncvQ',
  learnHowUnifiedInboxWorks:
    'https://docs.saleshandy.com/en/articles/6999237-unified-inbox-explained',
  trulyInboxForWarmupFaq:
    'https://docs.saleshandy.com/en/articles/7146556-transition-to-trulyinbox-for-email-warm-up',
  sequenceReport:
    'https://docs.saleshandy.com/en/articles/7237800-sequence-reports-explained',

  spfYtUrl: 'https://www.youtube.com/embed/EvZE4FEzF6Q',
  ctdYtUrl: 'https://www.youtube.com/embed/0KBK9bIggoc',
  dmarcYtUrl: 'https://www.youtube.com/embed/DrG1j0G1krg',
  emailAgeYtUrl: 'https://www.youtube.com/embed/rcv6PPyTn70',
  spfBlog:
    'https://docs.saleshandy.com/en/articles/8001502-spf-what-why-and-how-to-set-it-up',
  ctdBLog:
    'https://docs.saleshandy.com/en/articles/7991392-setting-up-your-custom-tracking-domain-step-by-step-guide',
  dmarcBlog:
    'https://docs.saleshandy.com/en/articles/8003992-dmarc-what-why-and-how-to-set-it-up',
  integration:
    'https://docs.saleshandy.com/en/collections/3820211-integrations',
  zapierIntegrationBlog:
    'https://docs.saleshandy.com/en/articles/8861470-zapier-integration',
  zapierIntegration: 'https://zapier.com/apps/saleshandy/integrations',
  videoPersonalizationBlog:
    'https://docs.saleshandy.com/en/articles/9614843-elevate-your-cold-email-strategy-with-personalized-video-campaigns',
  pitchlaneIntegration:
    'https://pitchlane.com/integrations/saleshandy?ref=Saleshandy',
  sendlerIntegration: 'https://sendler.ai?fpr=naitik13',
  sendsparkIntegration: 'https://sendspark.com/?via=saleshandy',
  weezlyIntegration: 'https://weezly.com/?aff=SaleshandyIntegration',
  bulkImportGuide:
    'https://docs.saleshandy.com/en/articles/7881940-bulk-email-import-explained',
  bulkImportSampleCSV:
    'https://v3artifacts.s3.us-west-2.amazonaws.com/161024/Connect%2BBulk%2BEmail%2BDemo%2B__%2BSaleshandy.csv',
  bulkImportSampleCSVWhitelabel:
    'https://v3artifacts.s3.us-west-2.amazonaws.com/Connect+Bulk+Email+Demo.csv',
  ccBccBlog:
    'https://docs.saleshandy.com/en/articles/7055488-sequence-settings-what-why-and-how-to-set-it-up#h_f79518846d',
  agencyPortalVideoGuide: 'https://www.youtube.com/embed/pOvzJp84QI0',
  customTrackingDomainVideoGuide: 'https://www.youtube.com/embed/0KBK9bIggoc',
  doYouKnowWhyScheduledEmailsAreNotShown:
    'https://docs.saleshandy.com/en/articles/8286429-do-you-know-why-scheduled-emails-are-not-shown',
  planComparisonPDFURL:
    'https://v3artifacts.s3.us-west-2.amazonaws.com/downloads/Saleshandy+plans+comparison.pdf',
  prospectEngagement:
    'https://docs.saleshandy.com/en/articles/7237800-reports-what-why-and-how-to-set-it-up#h_b95ab79588',
  prospectOutcome:
    'https://docs.saleshandy.com/en/articles/7237800-reports-what-why-and-how-to-set-it-up#h_fb48cf48e3',
  emailEngagement:
    'https://docs.saleshandy.com/en/articles/7237800-reports-what-why-and-how-to-set-it-up#h_de9e535255',
  outOfOfficeBlog:
    'https://docs.saleshandy.com/en/articles/8535613-out-of-office-detection-auto-pause-and-resume',
  agencyPortalSignupURL:
    'https://agency.saleshandy.com/signup?utm_source=V3_inappagencytab&utm_medium=V3product&utm_campaign=product',
  g2ReviewSubmissionForm: 'https://forms.gle/tLnbisjNK67dKGE87',
  startG2ReviewLink: 'https://www.g2.com/products/saleshandy/reviews/start',
  AMSButtonSequenceScoreURL:
    'https://www.saleshandy.com/account-management-service/?utm_source=AMS&utm_medium=saleshandy&utm_campaign=AMS_Sequence_Score&utm_id=ams-sequence-score',
  AMSButtonEmailSetupScoreURL:
    'https://www.saleshandy.com/account-management-service/?utm_source=AMS&utm_medium=saleshandy&utm_campaign=AMS_Email_Score&utm_id=ams-email-score',
  webhookBlogURL: 'https://docs.saleshandy.com/en/articles/9202403-webhook-new',
  webhookSetupGuideURL:
    'https://docs.saleshandy.com/en/articles/9202403-webhook-new#h_e0881be3d3',
  webhookExampleDataURL:
    'https://docs.saleshandy.com/en/articles/9207215-example-webhook-data',
  agencyOnboardingVideoURL:
    'https://fast.wistia.net/embed/iframe/cnj4a7b95u?seo=false&videoFoam=false',
  leadOnboardingVideoURL:
    'https://fast.wistia.net/embed/iframe/c0g7p8xi49?seo=false&videoFoam=false',
  outreachOnboardingVideoURL:
    'https://fast.wistia.net/embed/iframe/eh213adf5f?seo=false&videoFoam=false',
  clientPermissionsBlog:
    'https://docs.saleshandy.com/en/articles/9335104-client-permissions',
  dncBlogURL:
    'https://docs.saleshandy.com/en/articles/9439569-introducing-the-do-not-contact-list-a-new-feature-in-saleshandy',
  findYourDNSProvider:
    'https://docs.saleshandy.com/en/articles/6969623-how-to-check-your-dns-provider',
  whitelabelYoutubeURL:
    'https://fast.wistia.net/embed/iframe/mc1whpvvu6?seo=true&videoFoam=false',
  whitelabelKnowMoreURL:
    'https://docs.saleshandy.com/en/articles/9439052-white-label',
  variableTagsBlogURL: '',
  // 'https://docs.saleshandy.com/en/articles/9795619-variable-merge-tags-in-saleshandy',
});
