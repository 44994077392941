import React, { ReactElement } from 'react';
import NotificationComponent, {
  PartialNotificationProps,
  Variant,
} from './notification';
import produce from 'immer';

export enum Position {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  TopCenter = 'top-center',
  BottomCenter = 'bottom-center',
}

export type NotificationConfig = PartialNotificationProps & {
  position?: Position;
};

type NotificationCallbackArgs = {
  id: number;
  close: () => void;
};

type NotificationCallbackGeneric<T> = (args: NotificationCallbackArgs) => T;

export type NotificationOptionsGeneric<T> = T | NotificationCallbackGeneric<T>;

export type NotificationOptions = NotificationOptionsGeneric<
  NotificationConfig
>;

type NotificationCallback = NotificationCallbackGeneric<NotificationConfig>;

type Notification = NotificationConfig & { id: number };

type NotificationManagerState = {
  id: number;
  activeNotifications: Notification[];
};

type NotificationManagerProps = {
  bindActions: (...args: any[]) => any;
};

class NotificationManager extends React.Component<
  NotificationManagerProps,
  NotificationManagerState
> {
  // The toast fade timeout is 0.15s. so keeping this value anything >= 0.15s
  // would work. here, we're keeping it 500 to be safe.
  // settings this value to < 0.15s would remove the toast element from
  // DOM before the css fade animation finishes.
  removeNotificationTimeout = 500;

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      activeNotifications: [],
    };

    this.addNotification = this.addNotification.bind(this);

    this.props.bindActions(this.addNotification);
  }

  getNextId(id: number): number {
    return id + 1;
  }

  addNotification(options: NotificationOptions, variant?: Variant) {
    const id = this.getNextId(this.state.id);
    let config: NotificationConfig;

    if (isNotificationCallback(options)) {
      const callbackArgs: NotificationCallbackArgs = {
        id,
        close: () => this.removeNotification(id),
      };
      config = options(callbackArgs);
    } else {
      config = options;
    }

    const notification: Notification = {
      id,
      position: Position.TopRight,
      ...config,
      ...(variant && { variant }),
    };

    this.setState(
      produce((draft: NotificationManagerState) => {
        draft.id = id;
        draft.activeNotifications.push(notification);
      }),
    );
  }

  removeNotification(id: number) {
    const notification = this.state.activeNotifications.find(
      (n) => n.id === id,
    );

    notification.onClose?.();

    // const waitTime = notification.animation
    //   ? this.removeNotificationTimeout
    //   : 0;

    const waitTime = 0;

    setTimeout(() => {
      this.setState(
        produce((draft: NotificationManagerState) => {
          draft.activeNotifications = draft.activeNotifications.filter(
            (activeNotification) => activeNotification.id !== notification.id,
          );
        }),
      );
    }, waitTime);
  }

  getNotificationComponent(notification: Notification): ReactElement {
    return (
      <NotificationComponent
        key={notification.id}
        // position={notification.position}
        variant={notification.variant}
        header={notification.header}
        content={notification.content}
        delay={notification.delay}
        // animation={notification.animation}
        button={notification.button}
        onClose={() => this.removeNotification(notification.id)}
      />
    );
  }

  render() {
    const { activeNotifications } = this.state;

    const topLeftNotifications = activeNotifications
      .filter((notification) => notification.position === Position.TopLeft)
      .map((notification) => this.getNotificationComponent(notification));

    const topRightNotifications = activeNotifications
      .filter((notification) => notification.position === Position.TopRight)
      .map((notification) => this.getNotificationComponent(notification));

    const bottomLeftNotifications = activeNotifications
      .filter((notification) => notification.position === Position.BottomLeft)
      .map((notification) => this.getNotificationComponent(notification));

    const bottomRightNotifications = activeNotifications
      .filter((notification) => notification.position === Position.BottomRight)
      .map((notification) => this.getNotificationComponent(notification));

    const topCenterNotifications = activeNotifications
      .filter((notification) => notification.position === Position.TopCenter)
      .map((notification) => this.getNotificationComponent(notification));

    const bottomCenterNotifications = activeNotifications
      .filter((notification) => notification.position === Position.BottomCenter)
      .map((notification) => this.getNotificationComponent(notification));

    return (
      <div className="bs-notification-container">
        <div className="bs-notification top left">{topLeftNotifications}</div>
        <div className="bs-notification top right">{topRightNotifications}</div>
        <div className="bs-notification bottom left">
          {bottomLeftNotifications}
        </div>
        <div className="bs-notification bottom right">
          {bottomRightNotifications}
        </div>
        <div className="bs-notification top center">
          {topCenterNotifications}
        </div>
        <div className="bs-notification bottom center">
          {bottomCenterNotifications}
        </div>
      </div>
    );
  }
}

function isNotificationCallback(
  options: NotificationOptions,
): options is NotificationCallback {
  return typeof options === 'function';
}

export default NotificationManager;
