import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SkeletonLoading } from '@saleshandy/design-system';
import { Adjustments, Refresh, Trash, Unlink } from '@saleshandy/icons';
import classNames from 'classnames';

import type { IProps } from './email-accounts-actions-container';

import SearchInput from '../../../../../../shared/design-system/components/atoms/search-input';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { IconPlace } from '../../../../../../shared/design-system/components/input/enums';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { isAgency } from '../../../../../../shared/utils/user-details';
import AgencyClientFilters from '../../../../../agency-client-management/components/agency-client-filters';

const EmailAccountsActions: React.FC<IProps> = ({
  filters,
  onAddEmailAccount,
  onFiltersChange,
  openBulkEmailUpdateModal,
  planCode,
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  getConnectedUsersAndEmailAccountsRequestStatus,
  sendBulkEmailAccountUpdateRequest,
  bulkEmailUpdateRequestStatus,
  sendGetAgencyClientsDropdownRequest,
  getAgencyClientsDropdownRequestStatus,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState(filters.search || '');
  // const [clientIds, setClientIds] = useState(filters.clientIds || []);

  const [
    showDisconnectEmailConfirmationPopup,
    setShowDisconnectEmailConfirmationPopup,
  ] = useState(false);
  const [
    showDeleteEmailConfirmationPopup,
    setShowDeleteEmailConfirmationPopup,
  ] = useState(false);

  const onSearch = () => {
    onFiltersChange({ search, page: 1 });
  };

  const onClearSearch = () => {
    setSearch('');
    onFiltersChange({ search: '', page: 1 });
  };

  const onHideBulkEmailDisconnectConfirmationModal = () => {
    setShowDisconnectEmailConfirmationPopup(false);
  };

  const onHideBulkEmailDeleteConfirmationModal = () => {
    setShowDeleteEmailConfirmationPopup(false);
  };

  // Initialize refs outside the array
  const bulkUpdateSettingsRef = useRef(null);
  const refreshSetupScoreRef = useRef(null);
  const disconnectEmailAccountsRef = useRef(null);
  const deleteEmailAccountsRef = useRef(null);

  const actions = [
    (hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE) ||
      hasPermission(
        Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
      )) && {
      key: 'bulk_update_settings',
      tooltip: 'Bulk update Settings',
      ref: bulkUpdateSettingsRef,
      icon: <Adjustments height={24} width={24} />,
      onClick: () => {
        openBulkEmailUpdateModal();
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      key: 'refresh_setup_score',
      tooltip: 'Refresh Setup Score ',
      ref: refreshSetupScoreRef,
      icon: <Refresh height={24} width={24} />,
      onClick: () => {
        sendBulkEmailAccountUpdateRequest({
          refreshSetupScore: true,
          ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
            ? {
                allEmailsSelected: true,
                ignoreEmailAccountIds:
                  bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
              }
            : {
                emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
              }),
        });
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) && {
      key: 'disconnect_email_accounts',
      tooltip: 'Disconnect Email Accounts',
      ref: disconnectEmailAccountsRef,
      icon: <Unlink height={24} width={24} />,
      onClick: () => {
        setShowDisconnectEmailConfirmationPopup(true);
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
      key: 'delete_email_accounts',
      tooltip: 'Delete Email Accounts',
      ref: deleteEmailAccountsRef,
      icon: <Trash height={24} width={24} />,
      onClick: () => {
        setShowDeleteEmailConfirmationPopup(true);
      },
    },
  ].filter((opt) => opt);

  const handleDisconnectEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      disconnectEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  const handleDeleteEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      deleteEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkEmailUpdateRequestStatus,
      onSuccess: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
      onFailed: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
    });
  }, [bulkEmailUpdateRequestStatus]);

  const actionClassName = classNames([
    'icon d-flex align-items-center justify-content-center',
    {
      disabled: !selectedEmailAccounts?.length,
    },
  ]);

  useEffect(() => {
    if (hasPermission(Permissions.CLIENT_READ) && isAgency()) {
      sendGetAgencyClientsDropdownRequest({});
    }
  }, []);

  const bulkUpdateLoading = getIsRequestPending(bulkEmailUpdateRequestStatus);
  const isAssociatedClientRequestPending = getIsRequestPending(
    getAgencyClientsDropdownRequestStatus,
  );

  return (
    <>
      <div className="email-accounts-actions">
        <div className="d-flex align-items-center">
          <SearchInput
            searchValue={search}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            setSearchValue={setSearch}
            searchIconPlace={IconPlace.Left}
            searchIconFixed
            placeholder="Search"
          />
          {hasPermission(Permissions.CLIENT_READ) &&
            isAgency() &&
            (isAssociatedClientRequestPending ? (
              <SkeletonLoading
                height={32}
                width={187}
                className="agency-client-email-accounts"
              />
            ) : (
              <AgencyClientFilters
                clientIds={filters.clientIds || []}
                onClientIdsChange={(newClientIds) =>
                  onFiltersChange({ clientIds: newClientIds })
                }
                btnClassName="agency-client-email-accounts btn-white"
              />
            ))}
          <div className="d-flex align-items-center justify-content-center email-account-icons">
            {actions.map((action) => (
              <OverlayTooltip text={t(`labels.${action.key}`)} rootClose={true}>
                <div
                  ref={action.ref}
                  className={actionClassName}
                  {...accessibleOnClick(() => {
                    if (action.ref.current) {
                      action.ref.current.blur();
                    }
                    if (selectedEmailAccounts?.length) {
                      action.onClick();
                    }
                  })}
                >
                  {action.icon}
                </div>
              </OverlayTooltip>
            ))}
          </div>
        </div>
        <div>
          {hasPermission(Permissions.EMAIL_ACCOUNT_CONNECT) && (
            <Button
              onClick={onAddEmailAccount}
              disabled={planCode === SubscriptionPlans.FREE}
              className="header-btn"
              isLoading={getIsRequestPending(
                getConnectedUsersAndEmailAccountsRequestStatus,
              )}
              loadingText="Add Email Account"
            >
              Add Email Account
            </Button>
          )}
        </div>
      </div>

      {showDisconnectEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDisconnectEmailConfirmationPopup}
          onClose={onHideBulkEmailDisconnectConfirmationModal}
          onSubmit={handleDisconnectEmailAccounts}
          title={t('labels.disconnect_email_accounts')}
          content={t('messages.disconnect_email_accounts_desc')}
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          className="email-account-confirmation-popup disconnect"
          isSubmitLoading={bulkUpdateLoading}
        />
      )}
      {showDeleteEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDeleteEmailConfirmationPopup}
          onClose={onHideBulkEmailDeleteConfirmationModal}
          onSubmit={handleDeleteEmailAccounts}
          title={t('labels.delete_email_accounts')}
          content={
            <span>
              {t('messages.delete_email_message_one')}{' '}
              {t('messages.delete_email_message_two')}
              {t('messages.delete_email_message_three')}
            </span>
          }
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.TRASH_RED}
          className="email-account-confirmation-popup delete"
          isSubmitLoading={bulkUpdateLoading}
          showCloseIcon={false}
        />
      )}
    </>
  );
};

export default EmailAccountsActions;
