import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { ChevronDown, ChevronUp, Users } from '@saleshandy/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import Transfer from '../../../../shared/design-system/components/transfer';
import type { IProps } from './agency-client-filters-container';
import { Placement } from '../../../../shared/design-system/components/overlay/enums';
import type { Option } from './types';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';
import { getClientName } from '../../helpers/helpers';

const AgencyClientFilters: React.FC<IProps> = ({
  clients,
  clientIds,
  onClientIdsChange,
  btnClassName = '',
  className,
}) => {
  const { t } = useTranslation();

  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setSelectedClientIds([...clientIds]);
  }, [show, clientIds]);

  const options = clients.map((client) => ({
    key: client.id.toString(),
    id: client.id,
    name: getClientName(client, 60, {
      company: true,
      email: true,
      deleted: true,
    }),
  }));

  const getClientLabel = () => {
    let label = 'Client Associated';
    if (clientIds.length === options.length) {
      label = 'All clients';
    } else if (clientIds.length === 1) {
      label = `1 client ${t('labels.selected')}`.toLowerCase();
    } else if (clientIds.length > 1) {
      label = `${clientIds.length} clients ${t(
        'labels.selected',
      )}`.toLowerCase();
    } else if (label.length > 18) {
      label = `${label.slice(0, 15)}...`;
    }

    return label;
  };

  return (
    <Transfer<Option>
      show={show}
      onToggle={(value) => setShow(value)}
      className={`agency-filter-dropdown agency-filter ${className}`}
      showSearch
      searchPlaceholder="Search"
      multiSelect
      options={options}
      showOptionsSeparator={true}
      selectedOptionKey={selectedClientIds.map((key) => key.toString())}
      optionFilterProp="name"
      filterOption={(value, option) =>
        option?.name?.toLowerCase().includes(value.toLowerCase())
      }
      optionRenderer={(option) => (
        <span className="sequence-name-item">{option.name}</span>
      )}
      onChange={(selectedOptions) => {
        setSelectedClientIds(
          selectedOptions.map((option) => parseInt(option.key, 10)),
        );
      }}
      placement={Placement.BottomStart}
      footer={
        <>
          <Checkbox
            checked={selectedClientIds.length === options.length}
            intermediate={selectedClientIds.length !== 0}
            label={t('labels.select_all')}
            onChange={(checked) => {
              if (checked) {
                setSelectedClientIds(
                  options.map((option) => parseInt(option.key, 10)),
                );
              } else {
                setSelectedClientIds([]);
              }
            }}
          />
          <Button
            onClick={() => {
              onClientIdsChange(selectedClientIds);
              setShow(false);
            }}
          >
            {t('labels.apply')}
          </Button>
        </>
      }
    >
      <Button
        className={`transfer-select-btn btn-white w-1 ${btnClassName}`}
        variant="tertiary"
        onClick={() => setShow(!show)}
      >
        <div className="transfer-select-btn-content">
          <div className="select-icon">
            <Users />
          </div>
          <span>{getClientLabel()}</span>
        </div>
        <div className="bs-select-box-icon-wrapper">
          {show ? <ChevronUp /> : <ChevronDown />}
        </div>
      </Button>
    </Transfer>
  );
};

export default withRouter(AgencyClientFilters);
