import React from 'react';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import queryString from 'query-string';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../shared/toaster';
import { EmailAccountCallbackQuery } from '../../types/email-account-callback-query';
import store from '../../../../store';

class ConnectEmailAccountCallback extends React.Component<IProps, null> {
  componentDidMount() {
    const { match, location, sendVerifyEmailAccountRequest } = this.props;
    const { method } = match.params;
    const query = location.search;
    location.search = '';

    const { code, state, error } = queryString.parse(
      query,
    ) as EmailAccountCallbackQuery;

    if (error) {
      this.handleRedirection();
    } else if (code) {
      sendVerifyEmailAccountRequest(method, code, state);
      store.dispatch(showLoading());
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      status,
      message,
      error,
      handleHasUserConnectedEmailAccount,
    } = this.props;
    if (status !== prevProps.status) {
      if (
        status === RequestStatus.Succeeded ||
        status === RequestStatus.Failed
      ) {
        store.dispatch(hideLoading());
        if (status === RequestStatus.Succeeded) {
          handleHasUserConnectedEmailAccount(true);
          this.handleRedirection();
        }

        if (status === RequestStatus.Failed && error) {
          toaster.error(error.message, {
            theme: Theme.New,
            showCloseIcon: true,
            delay: 10000,
          });
        }
      }
    }
  }

  handleRedirection() {
    const { whitelabelEmailAccountResponse } = this.props;
    const isConnected = !!whitelabelEmailAccountResponse?.emailAccount;
    const msg = isConnected
      ? 'Email Account connected successfully'
      : 'Email Account could not be connected';

    if (whitelabelEmailAccountResponse?.domain) {
      window.open(
        `https://${whitelabelEmailAccountResponse?.domain}/settings/email-accounts?message=${msg}&isConnected=${isConnected}`,
        '_self',
      );

      return;
    }

    window.open(process.env.REACT_APP_WEB_URL, '_self');
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 vh-100">
        <span>Loading...</span>
      </div>
    );
  }
}

export default ConnectEmailAccountCallback;
