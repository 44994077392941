import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import EmailAccountsTable from './email-accounts-table';
import { EmailAccount } from '../../../../types/email-account';
import { BulkSelectedEmailAccounts, EmailAccountsFilters } from '../../types';
import {
  onBulkSelectEmailAccounts,
  clearEmailAccountsSelection,
  selectAllEmailAccounts,
  selectSingleEmailAccount,
  resetSelectedEmailAccount,
} from '../../email-account-slice';

const mapStateToProps = (state: RootState) => ({
  emailAccounts: state.emailAccount.emailAccounts,
  paginationOptions: state.emailAccount.emailAccountsPagination,
  bulkSelectedEmailAccounts: state.emailAccount.bulkSelectedEmailAccounts,
  selectedEmailAccounts: state.emailAccount.selectedEmailAccounts,
  getEmailAccountsRequestStatus:
    state.emailAccount.getEmailAccountsRequest.status,
});

const mapDispatchToProps = {
  onBulkSelectEmailAccounts: (payload: BulkSelectedEmailAccounts) =>
    onBulkSelectEmailAccounts(payload),
  selectAllEmailAccounts: (payload: { rows: any[]; status: boolean }) =>
    selectAllEmailAccounts(payload),
  clearEmailAccountsSelection: () => clearEmailAccountsSelection(),
  selectSingleEmailAccount: (payload: { row: any; status: boolean }) =>
    selectSingleEmailAccount(payload),
  resetSelectedEmailAccount: () => resetSelectedEmailAccount(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  filters: EmailAccountsFilters;
  onAction: (key: string, emailAccount: EmailAccount) => void;
  onFiltersChange: (partialFilters: Partial<EmailAccountsFilters>) => void;
  onEmailAccountsSwitchChangeHandler: (emailAccount: EmailAccount) => void;
  redirectToGeneralSettings: (emailAccount: EmailAccount) => void;
  onSmtpImapModalShow: (emailAccount: EmailAccount) => void;
  resetSelected: boolean;
  handleResetSelected: VoidFunction;
};

export default connector(EmailAccountsTable);
