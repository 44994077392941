import React from 'react';
import { Spinner as ReactBootstrapSpinner } from 'react-bootstrap';

export enum SpinnerAnimation {
  Border = 'border',
  Grow = 'grow',
}

export type SpinnerProps = {
  animation: SpinnerAnimation;
};

const Spinner: React.FC<SpinnerProps> = ({ animation }) => (
  <ReactBootstrapSpinner animation={animation} />
);

export default Spinner;
