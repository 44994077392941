import classNames from 'classnames';
import React from 'react';
import ImageIcon from '../../../../components/images/image-icon';

export type EmptyListProps = {
  children?;
  title: React.ReactNode;
  description: React.ReactNode;
  imgSrc: string;
  className?: string;
  isVertical?: boolean;
  withBgAndShadow?: boolean;
};

const EmptyList: React.FC<EmptyListProps> = ({
  children,
  title,
  description,
  imgSrc,
  className,
  isVertical = false,
  withBgAndShadow = false,
}) => {
  const emptyListInnerClass = classNames([
    { 'empty-list__inner': !isVertical },
    { 'empty-list__inner-vertical': isVertical },
  ]);
  const emptyListPhotoClass = classNames([
    { 'empty-list__photo': !isVertical },
    { 'empty-list__photo-vertical': isVertical },
    {
      'empty-list__photo-vertical--withBgAndShadow':
        isVertical && withBgAndShadow,
    },
  ]);
  const emptyListContentClass = classNames([
    { 'empty-list__content': !isVertical },
    {
      'empty-list__content-vertical d-flex align-items-center justify-content-center flex-column': isVertical,
    },
    {
      'empty-list__content-vertical--withBgAndShadow':
        isVertical && withBgAndShadow,
    },
  ]);
  const emptyListTitleClass = classNames([
    { 'empty-list__title': !isVertical },
    { 'empty-list__title-vertical': isVertical },
  ]);
  const emptyListBodyClass = classNames([
    { 'empty-list__body': !isVertical },
    { 'empty-list__body-vertical': isVertical },
  ]);
  const emptyListButtonClass = classNames([
    { 'empty-list__buttons': !isVertical },
    { 'empty-list__buttons-vertical': isVertical },
  ]);

  return (
    <div className={classNames(['empty-list', className])}>
      <div className={emptyListInnerClass}>
        <div className={emptyListPhotoClass}>
          {imgSrc && withBgAndShadow ? (
            <div className="empty-list__photo-withBgAndShadow d-flex align-items-center justify-content-center">
              <ImageIcon src={imgSrc} alt="empty-data/" />
            </div>
          ) : (
            <ImageIcon src={imgSrc} alt="empty-data/" />
          )}
        </div>
        <div className={emptyListContentClass}>
          <div className={emptyListTitleClass}>{title}</div>
          <p className={emptyListBodyClass}>{description}</p>
          <div className={emptyListButtonClass}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default EmptyList;
