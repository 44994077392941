import React from 'react';
import classNames from 'classnames';
import { AlertTriangle } from '@saleshandy/icons';

import { Images } from '../../../../../../../../shared/app-constants';
import Alert from '../../../../../../../../shared/design-system/components/alert';
import DailyRampUpItem from './daily-ramp-up-item';

const DailyRampUpContainer = ({
  t,
  dailyRampUp,
  onKeyPress,
  onDropAndPaste,
  emailsPerDay,
  onEmailsPerDayChange,
  emailsPerDayError,
  emailsPerDayPercent,
  onEmailsPerDayPercentChange,
  emailsPerDayPercentError,
  rampUpStatus,
  rampUpSettings,
  rampUpLimitReached,
  dirty,
  isBulkUpdatingEmailAccounts = false,
}) => {
  const dailyRampUpContainerClasses = classNames([
    'daily-rampup-container',
    {
      'bulk-update-email-accounts-row': isBulkUpdatingEmailAccounts,
    },
  ]);

  return (
    <div className={dailyRampUpContainerClasses}>
      <DailyRampUpItem
        staring_text={t('messages.emails_per_day_start')}
        ending_text={t('messages.emails_per_day_end')}
        suggestion_text={
          isBulkUpdatingEmailAccounts
            ? ''
            : t('messages.emails_per_day_suggestion')
        }
        isDisabled={!dailyRampUp}
        onKeyPress={onKeyPress}
        onDropAndPaste={onDropAndPaste}
        min={1}
        max={5000}
        value={emailsPerDay}
        onChange={onEmailsPerDayChange}
        isError={emailsPerDayError !== ''}
      />

      {emailsPerDayError !== '' && !isBulkUpdatingEmailAccounts && (
        <div className="row mt-2 mb-2">
          <div className="col-md-10">
            <Alert
              contentContainer={<span>{emailsPerDayError}</span>}
              variant={Alert.Variant.Danger}
              theme={Alert.Theme.New}
              svgIcon={Images.AlertTriangleRed}
            />
          </div>
        </div>
      )}

      {emailsPerDayError !== '' && isBulkUpdatingEmailAccounts && (
        <div className="bulk-update-email-error-wrapper">
          <div className="icon">
            <AlertTriangle />
          </div>
          <p>{emailsPerDayError}</p>
        </div>
      )}

      <DailyRampUpItem
        staring_text={t('messages.emails_per_day_percent_start')}
        ending_text={t('messages.emails_per_day_percent_end')}
        suggestion_text={
          isBulkUpdatingEmailAccounts
            ? ''
            : t('messages.emails_per_day_percent_suggestion')
        }
        isDisabled={!dailyRampUp}
        onKeyPress={onKeyPress}
        onDropAndPaste={onDropAndPaste}
        min={1}
        max={100}
        value={emailsPerDayPercent}
        onChange={onEmailsPerDayPercentChange}
        isError={emailsPerDayPercentError !== ''}
      />

      {emailsPerDayPercentError !== '' && !isBulkUpdatingEmailAccounts && (
        <div className="row mt-2 mb-2">
          <div className="col-md-10">
            <Alert
              contentContainer={<span>{emailsPerDayPercentError}</span>}
              variant={Alert.Variant.Danger}
              theme={Alert.Theme.New}
              svgIcon={Images.AlertTriangleRed}
            />
          </div>
        </div>
      )}

      {emailsPerDayPercentError !== '' && isBulkUpdatingEmailAccounts && (
        <div className="bulk-update-email-error-wrapper">
          <div className="icon">
            <AlertTriangle />
          </div>
          <p>{emailsPerDayPercentError}</p>
        </div>
      )}

      {Boolean(rampUpStatus) && dailyRampUp && (
        <div className="daily-rampup rampup-result">
          <p>
            <i>
              {t('messages.rampup_results')}{' '}
              <span>{rampUpSettings.rampUpLimit}</span>.
            </i>
          </p>
        </div>
      )}

      {rampUpLimitReached && !dirty.dailyRampUp && (
        <div className="row mt-2 mb-2">
          <div className="col-md-10">
            <Alert
              contentContainer={
                <span>{t('messages.ramp_up_limit_reached')}</span>
              }
              variant={Alert.Variant.Warning}
              theme={Alert.Theme.New}
              svgIcon={Images.AlertCircleOrange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyRampUpContainer;
