import { FileData } from '../../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/select-file/types';

export type IProps = {
  onFileSelect: (fileData: FileData) => void;
};

export enum EmailAccountsFileConfig {
  MaxFileSizeInMB = 5,
  MaxRows = 100,
}
