import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangePasswordRequestPayload,
  UserSetPasswordPayload,
} from '../../types';
import { changePassword, userSetPassword } from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';

type ChangePasswordRequestPayloadCreatorArgs = ChangePasswordRequestPayload & {
  code: string;
};

export const changePasswordRequest = createAsyncThunk<
  ResponseSuccess,
  ChangePasswordRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'changePassword/changePasswordRequest',
  async ({ code, ...rest }, thunkAPI) => {
    try {
      return await changePassword(code, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const setUserPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  UserSetPasswordPayload,
  { rejectValue: ResponseErrorWithHandled }
>('user/setUserPasswordRequest', async ({ ...rest }, thunkAPI) => {
  try {
    return await userSetPassword(rest);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
