import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../../../store/root-reducer';

import WebhookList from './webhook-list';

import {
  deleteWebhookRequest,
  getWebhookListRequest,
  getWebhookRequest,
  updateWebhookStatusRequest,
} from '../../extra-actions';
import {
  resetCreateWebhookRequest,
  resetDeleteWebhookRequest,
  resetGetWebhookListRequest,
  resetGetWebhookRequest,
  resetUpdateWebhookStatusRequest,
} from '../../webhook-slice';
import {
  GetWebhookListPayload,
  UpdateWebhookStatusPayload,
} from '../../../../types/webhook';

const mapStateToProps = (state: RootState) => ({
  getWebhookRequestStatus: state.webhook.getWebhookRequest.status,
  getWebhookRequestMessage: state.webhook.getWebhookRequest.message,
  getWebhookRequestError: state.webhook.getWebhookRequest.error,

  getWebhookListRequestStatus: state.webhook.getWebhookListRequest.status,
  getWebhookListRequestMessage: state.webhook.getWebhookListRequest.message,
  getWebhookListRequestError: state.webhook.getWebhookListRequest.error,

  updateWebhookStatusRequestStatus:
    state.webhook.updateWebhookStatusRequest.status,
  updateWebhookStatusRequestMessage:
    state.webhook.updateWebhookStatusRequest.message,
  updateWebhookStatusRequestError:
    state.webhook.updateWebhookStatusRequest.error,

  createWebhookRequestStatus: state.webhook.createWebhookRequest.status,
  createWebhookRequestMessage: state.webhook.createWebhookRequest.message,
  createWebhookRequestError: state.webhook.createWebhookRequest.error,

  deleteWebhookRequestStatus: state.webhook.deleteWebhookRequest.status,
  deleteWebhookRequestMessage: state.webhook.deleteWebhookRequest.message,
  deleteWebhookRequestError: state.webhook.deleteWebhookRequest.error,

  webhook: state.webhook.webhook,
  webhooks: state.webhook.webhooks,
  webhookPaginationOptions: state.webhook.webhookPaginationOptions,
});

const mapDispatchToProps = {
  sendGetWebhookListRequest: (payload: GetWebhookListPayload) =>
    getWebhookListRequest(payload),
  resetGetWebhookListRequest: () => resetGetWebhookListRequest(),

  sendGetWebhookRequest: (webhookId: string) => getWebhookRequest(webhookId),
  resetGetWebhookRequest: () => resetGetWebhookRequest(),

  resetCreateWebhookRequest: () => resetCreateWebhookRequest(),

  sendUpdateWebhookStatusRequest: (payload: UpdateWebhookStatusPayload) =>
    updateWebhookStatusRequest(payload),
  resetUpdateWebhookStatusRequest: () => resetUpdateWebhookStatusRequest(),

  sendDeleteWebhookRequest: (webhookId: string) =>
    deleteWebhookRequest(webhookId),
  resetDeleteWebhookRequest: () => resetDeleteWebhookRequest(),

  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(WebhookList);
