import React, { RefObject } from 'react';
import produce from 'immer';
import { Row, Col } from 'react-bootstrap';
import {
  timeSlotDataValidation,
  validate,
  validateOverlapTimeSlot,
} from './validator';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { IProps, IState, ModifySlot, ModiFyTimeSlot } from './types';
import Input from '../../../../../../shared/design-system/components/input';
import Icon from '../../../../../../shared/design-system/components/atoms/icon/icon';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { Schedule } from '../../../../types/schedule';
import { UpdateScheduleRequestPayload } from '../../../../types/request-payload';
import Select from '../../../../../../shared/design-system/components/select';
import {
  getTimezoneValueByName,
  Timezone,
} from '../../../../../../shared/utils';
import { modifyTimeSlotsJSON } from './helper';
import TimezoneOptionRenderer from '../../../../../../shared/design-system/components/atoms/timezone-option-renderer';
import toaster from '../../../../../../shared/toaster';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';

type TimezoneOption = Timezone & { key: string };

type SlotItem = {
  hour: number | string;
  minute: number | string;
};

type Slot = {
  start: SlotItem;
  end: SlotItem;
};

type TimeSlot = {
  day: number;
  slots: Slot[];
};

type ModifiedIState = IState & {
  timeSlots: ModiFyTimeSlot[];
  actionSchedule: Schedule;
};

type TimeSlotLabelMap = { label: string; day: number };

type TimeSlotLabelMapWithSlot = TimeSlotLabelMap & {
  slots: ModifySlot[];
};

const dirty = {
  scheduleName: false,
  timezone: false,
  slots: false,
};

const errors = {
  scheduleName: '',
  timezone: '',
};

class UpdateSchedule extends React.Component<IProps, ModifiedIState> {
  private readonly inputStartHourRef: RefObject<HTMLInputElement>;
  private readonly inputStartMinRef: RefObject<HTMLInputElement>;
  private readonly inputEndHourRef: RefObject<HTMLInputElement>;
  private readonly inputEndMinRef: RefObject<HTMLInputElement>;

  private readonly slot1Ref: RefObject<HTMLDivElement>;
  private readonly slot2Ref: RefObject<HTMLDivElement>;
  private readonly slot3Ref: RefObject<HTMLDivElement>;

  private readonly slotInputRef = {};

  constructor(props) {
    super(props);

    this.slotInputRef = {};
    const { schedule } = this.props;
    this.state = {
      values: {
        scheduleName: schedule.name,
        timezone: schedule.timezone,
      },
      errors,
      dirty,
      timeSlots: modifyTimeSlotsJSON(JSON.parse(schedule.timeSlots)),
      actionSchedule: null,
    };

    for (let i = 0; i < 7; i++) {
      const tempArr = [];
      for (let j = 0; j < 3; j++) {
        tempArr.push([
          (this.inputStartHourRef = React.createRef<HTMLInputElement>()),
          (this.inputStartMinRef = React.createRef<HTMLInputElement>()),
          (this.inputEndHourRef = React.createRef<HTMLInputElement>()),
          (this.inputEndMinRef = React.createRef<HTMLInputElement>()),
        ]);
      }
      this.slotInputRef[i] = tempArr;
    }

    this.addSlotHandler = this.addSlotHandler.bind(this);
    this.onSlotChange = this.onSlotChange.bind(this);
    this.addSlot = this.addSlot.bind(this);
    this.removeSlot = this.removeSlot.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputSlotBlur = this.onInputSlotBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSlotChange(
    day: number,
    slotNumber: number,
    slot: ModifySlot,
    nextRef: RefObject<HTMLInputElement>,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { value } = e.currentTarget;

    this.setState(
      produce((state: ModifiedIState) => {
        state.timeSlots[day].slots[slotNumber] = slot;
      }),
      () => {
        if (value.toString().trim().length === 2) {
          nextRef.current.focus();
        }
      },
    );
  }

  onInputChange(value, e) {
    const { name } = e.target;

    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onTimezoneSelect(e) {
    this.setState(
      produce((draft) => {
        draft.values.timezone = e.key;
        draft.dirty.timezone = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = this.validate(name, draft.values[name]);
        }
      }),
    );
  }

  onInputSlotBlur(parentIndex, slotNumber) {
    const { timeSlots } = this.state;
    const invalidDataError = timeSlotDataValidation(
      timeSlots[parentIndex].slots[slotNumber],
    );

    this.setState(
      produce((state) => {
        const slot = state.timeSlots[parentIndex].slots[slotNumber];
        state.timeSlots[parentIndex].slots[slotNumber] = {
          ...slot,
          invalidDataError,
        };

        const timeSlot = state.timeSlots[parentIndex];
        const slotIds = validateOverlapTimeSlot(timeSlot);

        timeSlot.slots = timeSlot.slots.map((slot) => {
          const slotId = slot.id;

          let overlapError = false;
          if (slotIds.includes(slotId)) {
            overlapError = true;
          }
          return { ...slot, overlapError };
        });

        state.timeSlots[parentIndex] = timeSlot;
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const {
      dirty: dirtyFromState,
      errors: errorsFromState,
      values,
    } = this.state;

    const dirtyRef = { ...dirtyFromState };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errorsFromState };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    // Validation of slots of different days.
    this.setState(
      produce((state) => {
        state.timeSlots = state.timeSlots.map((timeSlot) => {
          const slotIds = validateOverlapTimeSlot(timeSlot);
          timeSlot.slots = timeSlot.slots.map((slot) => {
            const invalidDataError = timeSlotDataValidation(slot);
            const overlapError = slotIds.includes(slot.id);
            isError = isError || overlapError || invalidDataError;
            return { ...slot, overlapError, invalidDataError };
          });
          return timeSlot;
        });
        state.errors = errors;
        state.dirty = dirty;
      }),

      () => {
        if (isError) {
          return;
        }

        const { values: val, timeSlots } = this.state;
        const { schedule, sendUpdateScheduleRequest } = this.props;

        const timeSlotWithoutErrorAndId: TimeSlot[] = timeSlots.map(
          (timeSlot) => {
            const slots = timeSlot.slots.map((slot) => {
              const slotWithoutErrorAndId = { ...slot };
              delete slotWithoutErrorAndId.overlapError;
              delete slotWithoutErrorAndId.id;
              delete slotWithoutErrorAndId.invalidDataError;
              return slotWithoutErrorAndId;
            });
            return { ...timeSlot, slots };
          },
        );

        const payload: UpdateScheduleRequestPayload = {
          name: val.scheduleName.trim(),
          timezone: val.timezone,
          timeSlots: timeSlotWithoutErrorAndId,
        };
        const scheduleId = schedule.id;
        sendUpdateScheduleRequest(scheduleId, payload);
      },
    );
  }

  onInputSlotFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.currentTarget.select();
  }

  validate(name, value): string {
    const error = validate(name, value);
    return error;
  }

  addSlotHandler(day: number) {
    const { timeSlots } = this.state;
    const slot: ModifySlot = {
      start: { hour: '', minute: '' },
      end: { hour: '', minute: '' },
      invalidDataError: false,
      overlapError: false,
      id: timeSlots[day].slots.length,
    };
    this.addSlot(day, slot);
  }

  addSlot(day: number, slot: ModifySlot) {
    this.setState(
      produce((state: ModifiedIState) => {
        const timeSlot = state.timeSlots.find(
          (timeslot) => timeslot.day === day,
        );
        timeSlot.slots.push(slot);
      }),
    );
  }

  removeSlot(day: number, slotNumber: number) {
    this.setState(
      produce((state: ModifiedIState) => {
        const timeSlot = state.timeSlots.find(
          (timeslot) => timeslot.day === day,
        );
        timeSlot.slots = timeSlot.slots.filter(
          (slot, index) => index !== slotNumber,
        );
        const slotIds = validateOverlapTimeSlot(timeSlot);

        timeSlot.slots = timeSlot.slots.map((slot) => {
          const overlapError = slotIds.includes(slot.id);
          return { ...slot, overlapError };
        });
      }),
    );
  }

  render() {
    const { values, errors: err } = this.state;
    const {
      timezoneList,
      schedule,
      onDelete,
      updateScheduleRequestStatus,
    } = this.props;

    const timezoneOptions = timezoneList.map((timezone) => ({
      key: timezone.name,
      ...timezone,
    }));

    const isLoading = updateScheduleRequestStatus === RequestStatus.Pending;

    const timeSlotLabelMap: TimeSlotLabelMap[] = [
      {
        label: 'Monday',
        day: 1,
      },
      {
        label: 'Tuesday',
        day: 2,
      },
      {
        label: 'Wednesday',
        day: 3,
      },
      {
        label: 'Thursday',
        day: 4,
      },
      {
        label: 'Friday',
        day: 5,
      },
      {
        label: 'Saturday',
        day: 6,
      },
      {
        label: 'Sunday',
        day: 0,
      },
    ];

    const timeSlotLabelMapWithSlots: TimeSlotLabelMapWithSlot[] = timeSlotLabelMap.map(
      (item: TimeSlotLabelMap) => {
        const { timeSlots } = this.state;
        const slotOfGivenDay = timeSlots.find(
          (slot: TimeSlot) => slot.day === item.day,
        );
        return {
          ...item,
          slots: slotOfGivenDay.slots,
        };
      },
    );

    return (
      <div className="schedule-form">
        <Row style={{ width: '35%' }}>
          <Col>
            <label className="semibold-2">Name</label>
            <Input
              size={Input.Size.Medium}
              name="scheduleName"
              placeholder="Enter a schedule name"
              type="text"
              value={values.scheduleName}
              variant={err.scheduleName && Input.Variant.Error}
              caption={err.scheduleName}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              disabled={!hasPermission(Permissions.SCHEDULE_UPDATE)}
              autoFocus
            />
          </Col>
        </Row>
        <br />
        <Row style={{ width: '35%' }}>
          <Col>
            <label className="semibold-2">Time zone</label>
            <div className="sequence-filter-menu">
              <Select<TimezoneOption>
                // multiSelect
                options={timezoneOptions}
                showSearch={true}
                selectedOptionKey={values.timezone}
                optionFilterProp="key"
                filterOption={(value, option) =>
                  option.value.toLowerCase().includes(value.toLowerCase())
                }
                optionRenderer={(option) => (
                  <TimezoneOptionRenderer timezone={option.value} />
                )}
                showOptionsSeparator={true}
                onSelect={(option) => {
                  this.onTimezoneSelect(option);
                }}
                selectedOptionRenderer={() => (
                  <span>{getTimezoneValueByName(values.timezone)}</span>
                )}
                disabled={!hasPermission(Permissions.SCHEDULE_UPDATE)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul>
              {timeSlotLabelMapWithSlots.map((timeSlot, index) => (
                <li key={index}>
                  <span>{timeSlot.label}</span>
                  <div className="schedule-timeslot-container">
                    {timeSlot.slots.map((slot, index) => (
                      <div
                        key={index}
                        className={
                          slot && (slot.invalidDataError || slot.overlapError)
                            ? 'schedule-timeslot active error'
                            : 'schedule-timeslot active'
                        }
                      >
                        <input
                          type="number"
                          placeholder="hh"
                          value={slot.start.hour}
                          ref={this.slotInputRef[timeSlot.day][index][0]}
                          min="1"
                          max="23"
                          onFocus={this.onInputSlotFocus}
                          onBlur={() =>
                            this.onInputSlotBlur(timeSlot.day, index)
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const modifiedSlot: ModifySlot = {
                              ...slot,
                              start: {
                                ...slot.start,
                                hour: e.target.value,
                              },
                            };
                            this.onSlotChange(
                              timeSlot.day,
                              index,
                              modifiedSlot,
                              this.slotInputRef[timeSlot.day][index][1],
                              e,
                            );
                          }}
                          disabled={!hasPermission(Permissions.SCHEDULE_UPDATE)}
                        />
                        <small>:</small>
                        <input
                          type="number"
                          placeholder=" mm"
                          value={slot.start.minute}
                          ref={this.slotInputRef[timeSlot.day][index][1]}
                          min="0"
                          max="59"
                          onFocus={this.onInputSlotFocus}
                          onBlur={() =>
                            this.onInputSlotBlur(timeSlot.day, index)
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const modifiedSlot: ModifySlot = {
                              ...slot,
                              start: {
                                ...slot.start,
                                minute: e.target.value,
                              },
                            };
                            this.onSlotChange(
                              timeSlot.day,
                              index,
                              modifiedSlot,
                              this.slotInputRef[timeSlot.day][index][2],
                              e,
                            );
                          }}
                          disabled={!hasPermission(Permissions.SCHEDULE_UPDATE)}
                        />
                        <p>-</p>
                        <input
                          type="number"
                          placeholder="hh"
                          value={slot.end.hour}
                          min="1"
                          max="23"
                          onFocus={this.onInputSlotFocus}
                          ref={this.slotInputRef[timeSlot.day][index][2]}
                          onBlur={() =>
                            this.onInputSlotBlur(timeSlot.day, index)
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const modifiedSlot: ModifySlot = {
                              ...slot,
                              end: {
                                ...slot.end,
                                hour: e.target.value,
                              },
                            };
                            this.onSlotChange(
                              timeSlot.day,
                              index,
                              modifiedSlot,
                              this.slotInputRef[timeSlot.day][index][3],
                              e,
                            );
                          }}
                          disabled={!hasPermission(Permissions.SCHEDULE_UPDATE)}
                        />
                        <small>:</small>
                        <input
                          type="number"
                          placeholder=" mm"
                          value={slot.end.minute}
                          min="0"
                          max="59"
                          onFocus={this.onInputSlotFocus}
                          ref={this.slotInputRef[timeSlot.day][index][3]}
                          onBlur={() =>
                            this.onInputSlotBlur(timeSlot.day, index)
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const modifiedSlot: ModifySlot = {
                              ...slot,
                              end: {
                                ...slot.end,
                                minute: e.target.value,
                              },
                            };
                            this.onSlotChange(
                              timeSlot.day,
                              index,
                              modifiedSlot,
                              this.slotInputRef[timeSlot.day][index][0],
                              e,
                            );
                          }}
                          disabled={!hasPermission(Permissions.SCHEDULE_UPDATE)}
                        />
                        {hasPermission(Permissions.SCHEDULE_UPDATE) && (
                          <div
                            className="timeslot-remove-action"
                            {...accessibleOnClick(() => {
                              this.removeSlot(timeSlot.day, index);
                            })}
                          >
                            <Icon identifier="close-o" />
                          </div>
                        )}
                      </div>
                    ))}
                    {hasPermission(Permissions.SCHEDULE_UPDATE) && (
                      <div
                        className="add-schedule"
                        {...accessibleOnClick(() => {
                          if (timeSlot.slots.length < 3) {
                            this.addSlotHandler(timeSlot.day);
                          } else {
                            toaster.error(
                              'More than 3 slots are not allowed for a single day',
                            );
                          }
                        })}
                      >
                        <Icon identifier="add" />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="schedule-footer">
            {hasPermission(Permissions.SCHEDULE_UPDATE) && (
              <Button
                variant={Button.Variant.Primary}
                onClick={this.onFormSubmit}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Save
              </Button>
            )}
            {hasPermission(Permissions.SCHEDULE_DELETE) && (
              <span
                className="delete-schedule semibold-1 pointer"
                {...accessibleOnClick(() => onDelete(schedule))}
              >
                <Icon identifier="trash" />
                Delete Schedule
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default UpdateSchedule;
