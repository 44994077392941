import api from '../../../../api';
import { ResponseSuccess } from '../../../types';
import { UpdatePreferredAppRequest } from '../types';

export const sendUpdatePreferredApp = (payload: UpdatePreferredAppRequest) => {
  return api.post<ResponseSuccess>('/user/update-preferred-app', payload, {
    baseURL: process.env.REACT_APP_V2_API_URL,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
      'X-SH-Source': 'WEB_APP',
    },
  });
};
