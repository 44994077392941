import { connect, ConnectedProps } from 'react-redux';
import { RouteProps, withRouter, RouteComponentProps } from 'react-router';
import { withTranslation } from 'react-i18next';
import { RootState } from '../../store/root-reducer';
import Home from './home';
import {
  getUserSettingsRequest,
  resetCompareAuthTokenRequest,
  showGettingStartedVideoModal,
  updateApplicationState,
} from './home-slice';
import {
  compareAuthTokenRequest,
  createSequenceFromGettingStartedRequest,
  extendTrialPeriodRequest,
  getAgencyConfigRequest,
  getPostLoadMetaRequest,
  getSampleSequenceRequest,
  upgradeOnboardingStepRequest,
} from './extra-actions';
import updateProfileProgressRequest from '../../shared/components/getting-started-onboarding/extra-actions';
import { setPageHeaderBannerAlert } from '../../shared/design-system/components/atoms/page-header/page-header-slice';
import { UpdateProfileProgressPayload } from '../../shared/types/user-setting';
import { PageHeaderAlertTypes } from '../../shared/utils/page-header-alert-types';
import { CompareAuthTokenPayload, UpgradeOnboardingStepPayload } from './types';
import { finishOnboardingRequest } from '../sequence/extra-actions';
import { Source } from '../../shared/enums/source';
import { TrialExtendSource } from '../../shared/enums/trial-extend-source';
import {
  onboardingDetailsRequest,
  resendVerificationEmailRequest,
} from '../auth/components/signup/extra-actions';
import { OnBoardingRequest } from '../auth/types';
import { resetChangeOutcomeRequest } from '../sequence/sequence-slice';

const mapStateToProps = (state: RootState) => ({
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
  getUserSettingsRequestMessage: state.home.getUserSettingsRequest.message,
  getUserSettingsRequestError: state.home.getUserSettingsRequest.error,
  getSampleSequenceRequestStatus: state.home.getSampleSequenceRequest.status,
  createSequenceFromGettingStartedRequestStatus:
    state.home.createSequenceFromGettingStartedRequest.status,
  createSequenceFromGettingStartedRequestMessage:
    state.home.createSequenceFromGettingStartedRequest.message,
  createSequenceFromGettingStartedRequestError:
    state.home.createSequenceFromGettingStartedRequest.error,
  gettingStatedCreatedSequenceId: state.home.gettingStatedCreatedSequenceId,
  meta: state.home.meta,
  sampleSequence: state.home.firstSequence,
  profileProgress: state.home.profileProgress,
  updateProfileProgressRequestStatus:
    state.home.updateProfileProgressRequest.status,
  updateProfileProgressRequestMessage:
    state.home.updateProfileProgressRequest.message,
  updateProfileProgressRequestError:
    state.home.updateProfileProgressRequest.error,
  compareAuthTokenRequestStatus: state.home.compareAuthTokenRequest.status,
  compareAuthTokenRequestMessage: state.home.compareAuthTokenRequest.message,
  compareAuthTokenRequestError: state.home.compareAuthTokenRequest.error,
  subscriptionDetails: state.home.subscription,
  shAccountId: state.home.shAccountId,
  client: state.home.client,
  userRole: state.home.role,
  firstName: state.home.firstName,
  lastName: state.home.lastName,
  team_size: state.home.team_size,
  designation: state.home.designation,
  signup_date: state.home.signup_date,
  hasUserConnectedEmailAccount:
    state.home.application.hasUserConnectedEmailAccount,
  agencyConfig: state.home.agencyConfig,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  email: state.myProfile.myProfile.email,
  isGettingStartedVideoModalShow: state.home.showYoutubeVideModal,
  shouldLogoutUser: state.home.application.shouldLogoutUser,
  helpscoutBeaconId: state.home.application.helpscoutBeaconId,
  icpStatus: state.home.icpStatus,
  trackingId: state.home.trackingId,
  userId: state.home.userId,
  planName: state.home.subscription?.planName,

  isEligibleToExtendTrial: state.home.isEligibleToExtendTrial,
  pageHeaderBannerMessage: state.pageHeader.message,

  extendTrialPeriodRequestStatus: state.home.extendTrialPeriodRequest.status,
  extendTrialPeriodRequestError: state.home.extendTrialPeriodRequest.error,
  extendTrialPeriodRequestMessage: state.home.extendTrialPeriodRequest.message,

  verified: state.home.verified,

  onBoardingRequestStatus: state.signup.onBoardingDetailsRequest.status,
  onBoardingRequestError: state.signup.onBoardingDetailsRequest.error,
  onBoardingRequestMessage: state.signup.onBoardingDetailsRequest.message,

  resendVerificationEmailRequestStatus:
    state.signup.resendVerificationEmailRequest.status,
  resendVerificationEmailRequestError:
    state.signup.resendVerificationEmailRequest.error,
  outcomeChangeRequestStatus: state.sequence.onOutcomeChangeRequest.status,
  outcomeChangeRequestMessage: state.sequence.onOutcomeChangeRequest.message,
  isMemberAccount: !state.home.isSelfSignup,
  getMyProfileRequestStatus: state.myProfile.getUserDetailRequest.status,
  getAgencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,
});

const mapDispatchToProps = {
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendGetPostLoadMetaRequest: () => getPostLoadMetaRequest(),
  sendGetSampleSequenceRequest: () => getSampleSequenceRequest(),
  updateProfileProgressRequest: (payload: UpdateProfileProgressPayload) =>
    updateProfileProgressRequest(payload),
  setPageHeaderBannerAlert: (payload: {
    bannerType: PageHeaderAlertTypes;
    paymentActionUrl?: string;
    message?: string;
    ctaText?: string;
    bannerMessageOne?: string;
    bannerMessageTwo?: string;
    paymentActionType?: string;
  }) => setPageHeaderBannerAlert(payload),
  handleActivateSequenceTooltip: (showActivateSequenceTooltip: boolean) =>
    updateApplicationState({ showActivateSequenceTooltip }),
  handleAddEmailAccountModal: (showAddEmailAccountModal: boolean) =>
    updateApplicationState({ showAddEmailAccountModal }),
  handleHasUserConnectedEmailAccount: (hasUserConnectedEmailAccount: boolean) =>
    updateApplicationState({ hasUserConnectedEmailAccount }),
  handleHasSignUpCompleted: (hasSignUpCompleted: boolean) =>
    updateApplicationState({ hasSignUpCompleted }),
  handleHelpscoutBeaconId: (helpscoutBeaconId: string) =>
    updateApplicationState({ helpscoutBeaconId }),
  sendCreateSequenceRequest: (title: string, source: Source) =>
    createSequenceFromGettingStartedRequest({ title, source }),
  sendFinishOnboardingRequest: () => finishOnboardingRequest(),
  sendUpgradeOnboardingStepRequest: (payload?: UpgradeOnboardingStepPayload) =>
    upgradeOnboardingStepRequest(payload),
  sendGetAgencyConfigRequest: (agencyConfigPayload: {
    baseUrl: string;
    email?: string;
  }) => getAgencyConfigRequest(agencyConfigPayload),
  showGettingStartedVideoModal: (show: boolean) =>
    showGettingStartedVideoModal({ show }),
  sendCompareAuthTokenRequest: (payload: CompareAuthTokenPayload) =>
    compareAuthTokenRequest(payload),
  resetCompareAuthTokenRequest: () => resetCompareAuthTokenRequest(),
  sendExtendTrialPeriodRequest: (source: TrialExtendSource) =>
    extendTrialPeriodRequest(source),

  sendOnboardingRequest: (payload: OnBoardingRequest) =>
    onboardingDetailsRequest(payload),

  resendVerificationEmailRequest: (email: string) =>
    resendVerificationEmailRequest(email),
  resetChangeOutcomeRequest: () => resetChangeOutcomeRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & { t: (x: string) => string } & Omit<
    RouteProps,
    'render'
  >;

export type IState = {
  userSubscriptionPlan: string;
  showWatchVideoModal: boolean;
  showGettingStarted: boolean;
  showHelpscoutWelcomeMessage: boolean;
  isViewSequenceBtnClicked: boolean;
  showOnboardingModal: boolean;
  planName: string;
  willRedirectToLeadFinder: boolean;
};

export default withTranslation()(withRouter(connector(Home)));
