import React from 'react';
import { NoEmailAccountsFound } from '../../../../../shared/svg';

const EmailAccountsNoSearchUI: React.FC = () => (
  <div className="empty-block no-search-ui">
    <div>
      <NoEmailAccountsFound />
    </div>
    <div className="content">
      <h2>No Search Result</h2>
      <p>We can’t find any email accounts matching your search.</p>
    </div>
  </div>
);

export default EmailAccountsNoSearchUI;
