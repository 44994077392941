import { SpintaxOption } from './types';

export const INSERT_NEW_SPINTAX_TAG =
  '{spin} value1 | value2 | value3 {endspin} ';

export const spintaxOptions: SpintaxOption[] = [
  {
    key: '1',
    name: 'Opening Greetings',
    options: [
      {
        key: '1',
        name: '{Hi | Hello | Good Morning}',
        tag: '{spin} Hi | Hello | Good Morning {endspin} ',
      },
      {
        key: '2',
        name: '{Hi | Hello | Hey there}',
        tag: '{spin} Hi | Hello | Hey there {endspin} ',
      },
      {
        key: '3',
        name: '{Good morning | Good afternoon}',
        tag: '{spin} Good morning | Good afternoon {endspin} ',
      },
    ],
  },
  {
    key: '2',
    name: 'Closing Greetings',
    options: [
      {
        key: '1',
        name: '{Best regards, | Sincerely, | Regards}',
        tag: '{spin} Best regards, | Sincerely, | Regards {endspin} ',
      },
      {
        key: '2',
        name: '{Thank you | Thanks | Appreciate it}',
        tag: '{spin} Thank you | Thanks | Appreciate it {endspin} ',
      },
      {
        key: '3',
        name: '{Warm regards | Cheers | All the best}',
        tag: '{spin} Warm regards | Cheers | All the best {endspin} ',
      },
    ],
  },
  {
    key: '3',
    name: 'Praises',
    options: [
      {
        key: '1',
        name: '{Excellent | Outstanding | Impressive}',
        tag: '{spin} Excellent | Outstanding | Impressive {endspin} ',
      },
      {
        key: '2',
        name: '{Remarkable | Phenomenal | Exceptional}',
        tag: '{spin} Remarkable | Phenomenal | Exceptional {endspin} ',
      },
      {
        key: '3',
        name: '{Brilliant | Fantastic | Superb}',
        tag: '{spin} Brilliant | Fantastic | Superb {endspin} ',
      },
    ],
  },
];
