import React from 'react';
import { Avatar } from '@saleshandy/design-system';
import { EmailAccount } from '../../../../../types/email-account';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  emailAccount: EmailAccount;
};

const AddedByField: React.FC<IProps> = ({ emailAccount: { user } }) => (
  <div className="added-by-field">
    <OverlayTooltip
      text={`${user?.firstName || ''} ${user?.lastName || ''}`.trim()}
    >
      <Avatar
        firstName={user?.firstName || ''}
        lastName={user?.lastName || ''}
      />
    </OverlayTooltip>
  </div>
);

export default AddedByField;
