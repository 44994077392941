import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { AdminSettings } from '../../types/admin-settings';
import {
  getAdminSettingsRequest,
  updateAdminSettingsRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getAdminSettingsRequest: RequestState;
  updateAdminSettingsRequest: RequestState;
  adminSettings: AdminSettings[];
};

const initialState: State = {
  getAdminSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateAdminSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  adminSettings: null,
};

const adminSettingsSlice = createSlice({
  name: 'adminSettings',
  initialState,
  reducers: {
    resetUpdateAdminSettings: (state) => {
      state.updateAdminSettingsRequest =
        initialState.updateAdminSettingsRequest;
    },
    resetAdminSettings: (state) => {
      state.getAdminSettingsRequest = initialState.getAdminSettingsRequest;
      state.updateAdminSettingsRequest =
        initialState.updateAdminSettingsRequest;
      state.adminSettings = initialState.adminSettings;
    },
  },
  extraReducers: (builder) => {
    // Get Admin Settings
    builder.addCase(getAdminSettingsRequest.pending, (state) => {
      state.getAdminSettingsRequest.status = RequestStatus.Pending;
      state.getAdminSettingsRequest.error = null;
    });
    builder.addCase(getAdminSettingsRequest.fulfilled, (state, action) => {
      state.getAdminSettingsRequest.status = RequestStatus.Succeeded;
      state.getAdminSettingsRequest.message = action.payload.message;
      state.adminSettings = action.payload.payload;
    });
    builder.addCase(getAdminSettingsRequest.rejected, (state, action) => {
      state.getAdminSettingsRequest.status = RequestStatus.Failed;
      state.getAdminSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Admin Settings
    builder.addCase(updateAdminSettingsRequest.pending, (state) => {
      state.updateAdminSettingsRequest.status = RequestStatus.Pending;
      state.updateAdminSettingsRequest.error = null;
    });
    builder.addCase(updateAdminSettingsRequest.fulfilled, (state, action) => {
      state.updateAdminSettingsRequest.status = RequestStatus.Succeeded;
      state.updateAdminSettingsRequest.message = action.payload.message;
    });
    builder.addCase(updateAdminSettingsRequest.rejected, (state, action) => {
      state.updateAdminSettingsRequest.status = RequestStatus.Failed;
      state.updateAdminSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetUpdateAdminSettings,
  resetAdminSettings,
} = adminSettingsSlice.actions;

export { getAdminSettingsRequest };
export default adminSettingsSlice.reducer;
