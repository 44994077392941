import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  VerifyEmailAccountRequestPayload,
  UpdateEmailAccountRequestPayload,
  ConnectEmailAccountRequestPayload,
  UpdateEmailAccountRampUpSettingsRequestPayload,
  EmailTimeIntervalSettingsRequest,
  EmailAccountSetupScorePayload,
  EmailAgePayload,
} from '../../types/request-payload';
import {
  connectEmailAccount,
  verifyEmailAccount,
  verifyWhitelabelEmailAccount,
  setDefaultEmailAccount,
  disconnectEmailAccount,
  getEmailAccounts,
  getEmailAccountsList,
  deleteEmailAccount,
  updateEmailAccount,
  uploadImage,
  updateEmailAccountRampUpSettings,
  getEmailAccountSetupScore,
  getEmailAccount,
  getEmailAccountSettings,
  testSmtpImapConnection,
  connectSmtpImapAccount,
  getSmtpImapAccountDetails,
  updateSmtpImapAccountDetails,
  emailTimeIntervalSettings,
  updateEmailAge,
  importEmailAccountCsv,
  bulkEmailUpdate,
} from '../../helpers/email-account.api';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';
import { EmailAccountMethod } from '../../enums/email-account';
import { ImageUploadPayloadWithProgressCallback } from '../../../../shared/types/request';
import {
  ConnectSmtpImapAccountRequest,
  TestConnectionRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../../types/smtp-imap';
import { EmailAccountsFilters } from './types';

export const connectEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  ConnectEmailAccountRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/connectEmailAccountRequest',
  async ({ method, emailAccountId }, thunkAPI) => {
    try {
      return await connectEmailAccount(method, emailAccountId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type VerifyEmailAccountRequestPayloadCreatorArgs = VerifyEmailAccountRequestPayload & {
  method: EmailAccountMethod;
};

export const verifyEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  VerifyEmailAccountRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/verifyEmailAccountRequest',
  async ({ method, ...rest }, thunkAPI) => {
    try {
      return await verifyEmailAccount(method, rest);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const verifyWhitelabelEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  VerifyEmailAccountRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/verifyWhitelabelEmailAccountRequest',
  async ({ method, ...rest }, thunkAPI) => {
    try {
      return await verifyWhitelabelEmailAccount(method, rest);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const setDefaultEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/setDefaultEmailAccountRequest', async (args, thunkAPI) => {
  try {
    return await setDefaultEmailAccount(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const disconnectEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/disconnectEmailAccountRequest', async (args, thunkAPI) => {
  try {
    return await disconnectEmailAccount(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountsFilters,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/getEmailAccountsRequest', async (args, thunkAPI) => {
  try {
    return await getEmailAccounts(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountsListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/getEmailAccountsListRequest', async (_args, thunkAPI) => {
  try {
    return await getEmailAccountsList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/deleteEmailAccountRequest',
  async (emailAccountId, thunkAPI) => {
    try {
      return await deleteEmailAccount(emailAccountId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type UpdateEmailAccountPayloadCreatorArgs = UpdateEmailAccountRequestPayload & {
  emailAccountId: number;
};

type UpdateSmtpImapAccountRequestPayload = {
  payload: UpdateSMTPIMAPAccountRequest;
  emailAccountId: number;
};

type EmailTimeIntervalRequestPayload = EmailTimeIntervalSettingsRequest & {
  emailAccountId: number;
};

export const updateEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateEmailAccountPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/updateEmailAccountRequest',
  async ({ emailAccountId, ...rest }, thunkAPI) => {
    try {
      return await updateEmailAccount(emailAccountId, rest);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const emailTimeIntervalSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailTimeIntervalRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/emailTimeIntervalSettingsRequest',
  async ({ emailAccountId, ...rest }, thunkAPI) => {
    try {
      return await emailTimeIntervalSettings(emailAccountId, rest);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  string | number,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccountSendingLimit/getEmailAccount', async (id, thunkAPI) => {
  try {
    return await getEmailAccount(id);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getEmailAccountSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  string | number,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/getEmailAccountSettingsRequest', async (hashId, thunkAPI) => {
  try {
    return await getEmailAccountSettings(hashId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateEmailAccountRampUpSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateEmailAccountRampUpSettingsRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/updateEmailAccountRampUpSettingsRequest',
  async ({ emailAccountId, ...payload }, thunkAPI) => {
    try {
      return await updateEmailAccountRampUpSettings(emailAccountId, payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountSetupScoreRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountSetupScorePayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/getEmailAccountSetupScoreRequest',
  async ({ emailAccountId, refresh }, thunkAPI) => {
    try {
      return await getEmailAccountSetupScore(emailAccountId, refresh);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const imageUploadRequest = createAsyncThunk<
  ResponseSuccess,
  ImageUploadPayloadWithProgressCallback,
  { rejectValue: ResponseErrorWithHandled }
>(
  'settings/imageUploadRequest',
  async ({ formData, onUploadProgress }, thunkAPI) => {
    try {
      return await uploadImage(formData, onUploadProgress);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const testSMTPConnectionRequest = createAsyncThunk<
  ResponseSuccess,
  TestConnectionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/testSMTPConnection', async (args, thunkAPI) => {
  // Test SMTP Connection
  try {
    return await testSmtpImapConnection(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const testIMAPConnectionRequest = createAsyncThunk<
  ResponseSuccess,
  TestConnectionRequest,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/testIMAPConnection', async (args, thunkAPI) => {
  // Test IMAP Connection
  try {
    return await testSmtpImapConnection(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const connectSmtpImapAccountRequest = createAsyncThunk<
  ResponseSuccess,
  ConnectSmtpImapAccountRequest,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/connectSmtpImapAccount', async (args, thunkAPI) => {
  try {
    return await connectSmtpImapAccount(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSmtpImapAccountDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/getSmtpImapAccountDetails',
  async (emailAccountId, thunkAPI) => {
    try {
      return await getSmtpImapAccountDetails(emailAccountId);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateSmtpImapAccountDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSmtpImapAccountRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/updateSmtpImapAccountDetails',
  async ({ emailAccountId, payload }, thunkAPI) => {
    try {
      return await updateSmtpImapAccountDetails(emailAccountId, payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateEmailAgeRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAgePayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailAccount/updateEmailAge',
  async ({ emailAccountId, ageInMonths }, thunkAPI) => {
    try {
      return await updateEmailAge(emailAccountId, ageInMonths);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const importEmailAccountCsvRequest = createAsyncThunk<
  ResponseSuccess,
  { file: File },
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/import', async ({ file }, thunkAPI) => {
  try {
    return await importEmailAccountCsv(file);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const bulkEmailUpdateRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/bulk-operation', async (payload, thunkAPI) => {
  try {
    return await bulkEmailUpdate(payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
