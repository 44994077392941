import React from 'react';
import ConfirmationModal from '../../../../../../../shared/design-system/components/atoms/confirmation-modal';
import { IProps } from './types';

const DeleteScheduleAlert: React.FC<IProps> = ({ show, message, onClose }) => (
  <ConfirmationModal
    show={show}
    onClose={onClose}
    onSubmit={onClose}
    isRequestPending={false}
    title={message}
    showSuccessButton={true}
    showCancelButton={false}
    hideIcon={true}
  />
);

export default DeleteScheduleAlert;
