import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import { Images } from '../../../../../../../../shared/app-constants';

const DisableRampUpConfirmation = ({ show, onSubmit, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal disable-confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.AlertCircleOrange} alt="Alert" />
          <h2>{t('messages.disable_rampup_confirmation_title')}</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmit}
      onClose={onClose}
      onHide={onClose}
      submitButtonText={t('labels.yes')}
      cancelButtonText={t('labels.no')}
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
    >
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">
          {t('messages.disable_rampup_confirmation_content_1')}{' '}
          {t('messages.disable_rampup_confirmation_content_2')}{' '}
          {t('messages.disable_rampup_confirmation_content_3')}
        </p>
      </div>
    </Modal>
  );
};

export default DisableRampUpConfirmation;
