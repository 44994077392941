import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';

const Spinner: React.FC = () => (
  <div className="update-email-account-loading email-tracking">
    <BootstrapSpinner
      className="update-email-account-loading-spinner"
      animation="border"
    />
  </div>
);

export default Spinner;
