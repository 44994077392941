import { ModiFyTimeSlot, TimeSlot } from './types';

export const modifyTimeSlotsJSON = (
  timeSlots: TimeSlot[],
): ModiFyTimeSlot[] => {
  const updatedTimeSlots = timeSlots.map((timeSlot) => {
    const slots = timeSlot.slots.map((slot, index) => {
      const slotStartHour =
        Math.floor(Number(slot.start.hour) / 10) === 0
          ? `0${slot.start.hour}`
          : slot.start.hour;
      const slotStartMinute =
        Math.floor(Number(slot.start.minute) / 10) === 0
          ? `0${slot.start.minute}`
          : slot.start.minute;
      const slotEndHour =
        Math.floor(Number(slot.end.hour) / 10) === 0
          ? `0${slot.end.hour}`
          : slot.end.hour;
      const slotEndMinute =
        Math.floor(Number(slot.end.minute) / 10) === 0
          ? `0${slot.end.minute}`
          : slot.end.minute;
      const updatedSlot = {
        start: {
          hour: slotStartHour,
          minute: slotStartMinute,
        },
        end: {
          hour: slotEndHour,
          minute: slotEndMinute,
        },
      };
      const temp = {
        ...updatedSlot,
        invalidDataError: false,
        overlapError: false,
        id: index,
      };
      return temp;
    });
    return { ...timeSlot, slots };
  });
  return updatedTimeSlots;
};
