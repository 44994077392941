import { VariableTags } from '../enum/variable-tags';
import { getVariableTagValue } from './get-variable-tag-value';

export const getVariableTagTooltip = (
  tag: string,
  currentTime: moment.Moment,
  offset = 0,
  timezone = '',
): string | undefined => {
  if (tag === '{{CurrentTimePeriod}}') {
    return 'Inserts the current time period of the day (Morning, Afternoon, Evening).';
  }

  if (tag === '{{CurrentGreeting}}') {
    return 'Inserts a greeting based on the time of day (Good Morning, Good Afternoon).';
  }

  if (tag === '{{Yesterday}}') {
    const yesterday = getVariableTagValue(
      VariableTags.Yesterday,
      currentTime.clone(),
      {},
      offset,
    );
    const today = getVariableTagValue(
      VariableTags.Today,
      currentTime.clone(),
      {},
      offset,
    );
    return `Inserts the day for yesterday (As today is ${today}, it will insert ${yesterday}).`;
  }

  if (tag === '{{Today}}') {
    const today = getVariableTagValue(
      VariableTags.Today,
      currentTime,
      {},
      offset,
    );
    return `Inserts today's day (As today is ${today}, it will insert ${today}).`;
  }

  if (tag === '{{Tomorrow}}') {
    const tomorrow = getVariableTagValue(
      VariableTags.Tomorrow,
      currentTime.clone(),
      {},
      offset,
    );
    const today = getVariableTagValue(
      VariableTags.Today,
      currentTime.clone(),
      {},
      offset,
    );
    return `Inserts the day for tomorrow (As today is ${today}, it will insert ${tomorrow}).`;
  }

  if (tag === '{{CurrentTime}}') {
    const currentTimeValue = getVariableTagValue(
      VariableTags.CurrentTime,
      currentTime,
      {},
      offset,
    );
    return `Inserts the current time (As it's ${currentTimeValue}, it will insert ${currentTimeValue}).`;
  }

  if (tag === '{{CurrentDate}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    return `Inserts today's date (As today is ${currentDate}, it will insert ${currentDate}).`;
  }

  if (tag === '{{CurrentDate + N}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const futureDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the date after N days from today (As today is ${currentDate} and N=3, it will insert ${futureDate}).`;
  }

  if (tag === '{{CurrentDate - N}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const pastDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the date N days before today (As today is ${currentDate} and N=3, it will insert ${pastDate}).`;
  }

  if (tag === '{{PreviousWorkingDay}}') {
    const today = getVariableTagValue(VariableTags.Today, currentTime, {}, 0);
    const previousWorkingDay = getVariableTagValue(
      VariableTags.PreviousWorkingDay,
      currentTime,
      {},
      offset,
    );
    return `Inserts the date of the last working day (As today is ${today}, it will insert ${previousWorkingDay}).`;
  }

  if (tag === '{{PreviousWorkingDate}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const previousWorkingDate = getVariableTagValue(
      VariableTags.PreviousWorkingDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the last working date (As today is ${currentDate}, it will insert ${previousWorkingDate}).`;
  }

  if (tag === '{{NextWorkingDay}}') {
    const today = getVariableTagValue(VariableTags.Today, currentTime, {}, 0);
    const nextWorkingDay = getVariableTagValue(
      VariableTags.NextWorkingDay,
      currentTime,
      {},
      offset,
    );
    return `Inserts the date of the next working day (As today is ${today}, it will insert ${nextWorkingDay}).`;
  }

  if (tag === '{{NextWorkingDate}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const nextWorkingDate = getVariableTagValue(
      VariableTags.NextWorkingDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the next working date (As today is ${currentDate}, it will insert ${nextWorkingDate}).`;
  }

  if (tag === '{{NextWorkingDate + N}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const nextWorkingDate = getVariableTagValue(
      VariableTags.NextWorkingDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the date N working days after the next working date (As today is ${currentDate}, and N=3, it will insert ${nextWorkingDate}).`;
  }

  if (tag === '{{NextWorkingDate - N}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const nextWorkingDate = getVariableTagValue(
      VariableTags.NextWorkingDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the date N working days before the next working date (As today is ${currentDate}, and N=3, it will insert ${nextWorkingDate}).`;
  }

  if (tag === '{{CurrentMonth}}') {
    const currentMonth = getVariableTagValue(
      VariableTags.CurrentMonth,
      currentTime,
      {},
      0,
    );
    return `Inserts the current month (${currentMonth}).`;
  }

  if (tag === '{{CurrentMonth + N}}') {
    const currentMonth = getVariableTagValue(
      VariableTags.CurrentMonth,
      currentTime,
      {},
      0,
    );
    const futureMonth = getVariableTagValue(
      VariableTags.CurrentMonth,
      currentTime,
      {},
      offset,
    );
    return `Inserts the month N months from the current month (As this month is ${currentMonth} and N=2, it will insert ${futureMonth}).`;
  }

  if (tag === '{{CurrentMonth - N}}') {
    const currentMonth = getVariableTagValue(
      VariableTags.CurrentMonth,
      currentTime,
      {},
      0,
    );
    const pastMonth = getVariableTagValue(
      VariableTags.CurrentMonth,
      currentTime,
      {},
      offset,
    );
    return `Inserts the month N months before the current month (As this month is ${currentMonth} and N=2, it will insert ${pastMonth}).`;
  }

  if (tag === '{{CurrentYear}}') {
    const currentYear = getVariableTagValue(
      VariableTags.CurrentYear,
      currentTime,
      {},
      0,
    );
    return `Inserts the current year (${currentYear}).`;
  }

  if (tag === '{{CurrentYear + N}}') {
    const currentYear = getVariableTagValue(
      VariableTags.CurrentYear,
      currentTime,
      {},
      0,
    );
    const futureYear = getVariableTagValue(
      VariableTags.CurrentYear,
      currentTime,
      {},
      offset,
    );
    return `Inserts the year N years from the current year (As this year is ${currentYear} and N=3, it will insert ${futureYear}).`;
  }

  if (tag === '{{CurrentYear - N}}') {
    const currentYear = getVariableTagValue(
      VariableTags.CurrentYear,
      currentTime,
      {},
      0,
    );
    const pastYear = getVariableTagValue(
      VariableTags.CurrentYear,
      currentTime,
      {},
      offset,
    );
    return `Inserts the year N years before the current year (As this year is ${currentYear} and N=3, it will insert ${pastYear}).`;
  }

  if (tag === '{{CurrentQuarter}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const currentQuarter = getVariableTagValue(
      VariableTags.CurrentQuarter,
      currentTime,
      {},
      offset,
    );
    return `Inserts the current quarter of the year (As today is ${currentDate}, it will insert ${currentQuarter}).`;
  }

  if (tag === '{{PreviousQuarter}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const previousQuarter = getVariableTagValue(
      VariableTags.PreviousQuarter,
      currentTime,
      {},
      offset,
    );
    return `Inserts the previous quarter (As today is ${currentDate}, it will insert ${previousQuarter}).`;
  }

  if (tag === '{{NextQuarter}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const nextQuarter = getVariableTagValue(
      VariableTags.NextQuarter,
      currentTime,
      {},
      offset,
    );
    return `Inserts the next quarter (As today is ${currentDate}, it will insert ${nextQuarter}).`;
  }

  if (tag === '{{CurrentWeekNumber}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const currentWeekNumber = getVariableTagValue(
      VariableTags.CurrentWeekNumber,
      currentTime,
      {},
      offset,
    );
    return `Inserts the current week number of the year (As today is ${currentDate}, it will insert ${currentWeekNumber}).`;
  }

  if (tag === '{{DaysLeftInMonth}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const daysLeftInMonth = getVariableTagValue(
      VariableTags.DaysLeftInMonth,
      currentTime,
      {},
      offset,
    );
    return `Inserts the number of days left in the current month (As today is ${currentDate}, it will insert ${daysLeftInMonth}).`;
  }

  if (tag === '{{DaysLeftInQuarter}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const daysLeftInQuarter = getVariableTagValue(
      VariableTags.DaysLeftInQuarter,
      currentTime,
      {},
      offset,
    );
    return `Inserts the number of days left in the current quarter (As today is ${currentDate}, it will insert ${daysLeftInQuarter}).`;
  }

  if (tag === '{{EndOfMonthDate}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const endOfMonthDate = getVariableTagValue(
      VariableTags.EndOfMonthDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the last date of the current month (As today is ${currentDate}, it will insert ${endOfMonthDate}).`;
  }

  if (tag === '{{EndOfQuarterDate}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const endOfQuarterDate = getVariableTagValue(
      VariableTags.EndOfQuarterDate,
      currentTime,
      {},
      offset,
    );
    return `Inserts the last date of the current quarter (As today is ${currentDate}, it will insert ${endOfQuarterDate}).`;
  }

  if (tag === '{{DaysLeftIn / DDMMYYYY}}') {
    const currentDate = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      0,
    );
    const dateAfter15Days = getVariableTagValue(
      VariableTags.CurrentDate,
      currentTime,
      {},
      15,
    );
    const daysLeftIn = getVariableTagValue(
      VariableTags.DaysLeftIn,
      currentTime,
      {},
      0,
      currentTime.clone().add(15, 'days').format('DDMMYYYY'),
      timezone,
    );
    return `Inserts the number of days left in the specified date (As today is ${currentDate} and date is ${dateAfter15Days}, it will insert ${daysLeftIn}).`;
  }

  return '';
};
