import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Input from '../../../../../../shared/design-system/components/input';
import { IconPlace } from '../../../../../../shared/design-system/components/input/enums';
import { InputIconConfig } from '../../../../../../shared/design-system/components/input/types';
import toaster from '../../../../../../shared/toaster';
import { copyToClipboard } from '../../../../../../shared/utils/copy-to-clipboard';

const CustomDomainDnsRecord = ({ host, value }) => {
  const hostInput = useRef<HTMLInputElement>(null);
  const valueInput = useRef<HTMLInputElement>(null);

  const onCopyClickHost = () => {
    copyToClipboard(host ? host : '', hostInput.current);
    toaster.success('Host copied to clipboard');
  };

  const onCopyClickValue = () => {
    copyToClipboard(value ? value : '', valueInput.current);
    toaster.success('Value copied to clipboard');
  };

  // static.
  const inputIconConfig: InputIconConfig[] = [
    {
      identifier: 'copy',
      place: IconPlace.Right,
      colorDefault: false,
      onClick: onCopyClickHost,
    },
    {
      identifier: 'copy',
      place: IconPlace.Right,
      colorDefault: false,
      onClick: onCopyClickValue,
    },
  ];

  return (
    <div className="dns-record-container">
      <Row className="dns-record-header mb-3">
        <Col md={2}>Type</Col>
        <Col>Host</Col>
        <Col>Value</Col>
      </Row>
      <Row>
        <Col className="my-auto" md={2}>
          CNAME
        </Col>
        <Col>
          <Input
            ref={hostInput}
            placeholder={'Domain Name'}
            value={host}
            icons={[inputIconConfig[0]]}
            readOnly
          />
        </Col>
        <Col>
          <Input ref={valueInput} value={value} icons={[inputIconConfig[1]]} />
        </Col>
      </Row>
    </div>
  );
};

export default CustomDomainDnsRecord;
