import { RootState } from '../../../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { verifyCurrentPasswordRequest } from '../../extra-actions';
import ChangePasswordModal from './change-password-modal';
import { UpdatePasswordRequestPayload } from './types';

const mapStateToProps = (state: RootState) => ({
  verifyCurrentPasswordRequestStatus:
    state.myProfile.verifyCurrentPasswordRequest.status,
  isCurrentPasswordValid: state.myProfile.isCurrentPasswordValid,
});

const mapDispatchToProps = {
  sendVerifyCurrentPasswordRequest: (password: string) =>
    verifyCurrentPasswordRequest({ password }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onSubmit: (payload: UpdatePasswordRequestPayload) => void;
  onClose: () => void;
  isLoading: boolean;
};

export default connector(ChangePasswordModal);
