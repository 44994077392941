import { Variant } from './notification';
import {
  NotificationConfig,
  NotificationOptions,
  NotificationOptionsGeneric,
} from './notification-manager';

export type NotificationOptionsWithoutVariant = NotificationOptionsGeneric<
  Omit<NotificationConfig, 'variant'>
>;

class NotificationHelper {
  private _addNotification;

  bindActions = (addNotification) => {
    this._addNotification = addNotification;
  };

  private readonly _notify = (
    options: NotificationOptions,
    variant?: Variant,
  ) => this._addNotification(options, variant);

  notify = (options: NotificationOptions) => this._notify(options);

  info = (options: NotificationOptionsWithoutVariant) =>
    this._notify(options, Variant.Primary);

  success = (options: NotificationOptionsWithoutVariant) =>
    this._notify(options, Variant.Success);

  warning = (options: NotificationOptionsWithoutVariant) =>
    this._notify(options, Variant.Warning);

  error = (options: NotificationOptionsWithoutVariant) =>
    this._notify(options, Variant.Error);
}

export default NotificationHelper;
