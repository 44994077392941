import validator from 'validator';

const validate = (name, value) => {
  switch (name) {
    case 'bcc':
      if (!validator.isEmpty(value) && !validator.isEmail(value)) {
        return 'Enter a valid bcc email address';
      }
      break;

    default:
      break;
  }

  return '';
};

export default validate;
