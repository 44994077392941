import React, { useRef } from 'react';
import classNames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import Icon from '../atoms/icon/icon';
import { Modify } from '../../../types';
import { ForwardRef } from '../../../types/forward-ref';
import { Size, Variant } from './enums';
import { getSizeSubClass } from './helper';
import { changeNativeValue } from '../../../utils/native-elements';
import { OverlayTooltip } from '../overlay';
import { accessibleOnClick } from '../../../utils/accessible-on-click';

type InputNumberProps = Modify<
  React.HTMLProps<HTMLInputElement>,
  {
    size?: Size;
    variant?: Variant;
    label?: React.ReactNode;
    caption?: React.ReactNode;
    disabled?: boolean;
    min?: number;
    max?: number;
    value?: number | string;
    containerClassName?: string;
    onChange?: (
      value: string,
      event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    showUpHandlerWithTooltip?: boolean;
    showDownHandlerWithTooltip?: boolean;
    upHandlerTooltipText?: string;
    downHandlerTooltipText?: string;
  }
>;

type InputNumberStatic = {
  Size: typeof Size;
  Variant: typeof Variant;
};

const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      size,
      variant,
      label,
      caption,
      disabled,
      value,
      max,
      min,
      className,
      onChange,
      containerClassName,
      showUpHandlerWithTooltip,
      showDownHandlerWithTooltip,
      upHandlerTooltipText,
      downHandlerTooltipText,
      ...rest
    },
    ref,
  ) => {
    const localRef = useRef<HTMLInputElement>(null);

    const applyRangeFilter = (v: number): number =>
      Math.max(min, Math.min(max, v));

    const addToValue = (step: number) => {
      const previousValue = Number(value ?? localRef.current.value);
      let newValue = previousValue + step;
      newValue = applyRangeFilter(newValue);
      if (newValue !== previousValue) {
        changeNativeValue(localRef.current, newValue);
      }
    };

    const upHandler = () => addToValue(1);
    const downHandler = () => addToValue(-1);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const targetValue = event.target.value;
      onChange?.(targetValue, event);
    };

    const sizeSubClass = getSizeSubClass(size);
    const sizeClass = `bs-input-number-${sizeSubClass}`;
    const inputNumberClass = classNames([
      'bs-input-number',
      sizeClass,
      {
        'bs-input-number-disabled': disabled,
      },
      className,
    ]);

    const variantClass = classNames([
      {
        'bs-input-number-error': variant === Variant.Error,
      },
    ]);

    const inputNumberContainerClass = classNames([
      'bs-input-number-container',
      containerClassName,
      variantClass,
    ]);

    const inputNumberHandlerUp = () => {
      const handler = (
        <span
          className="bs-input-number-handler bs-input-number-handler-up"
          {...accessibleOnClick(upHandler)}
        >
          <span className="bs-input-number-handler-up-inner">
            <Icon identifier="chevron-up" />
          </span>
        </span>
      );

      if (showUpHandlerWithTooltip && value >= max) {
        return (
          <OverlayTooltip text={upHandlerTooltipText}>{handler}</OverlayTooltip>
        );
      }

      return handler;
    };

    const inputNumberHandlerDown = () => {
      const handler = (
        <span
          className="bs-input-number-handler bs-input-number-handler-down"
          {...accessibleOnClick(downHandler)}
        >
          <span className="bs-input-number-handler-down-inner">
            <Icon identifier="chevron-down" />
          </span>
        </span>
      );

      if (showDownHandlerWithTooltip && value <= min) {
        return (
          <OverlayTooltip text={downHandlerTooltipText}>
            {handler}
          </OverlayTooltip>
        );
      }

      return handler;
    };

    return (
      <div className={inputNumberContainerClass}>
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="bs-input-number-label-prefix">{label}</label>
        )}
        <div className="bs-input-number-inner">
          <div className={inputNumberClass}>
            <div className="bs-input-number-handler-wrap">
              {inputNumberHandlerUp()}
              {inputNumberHandlerDown()}
            </div>
            <div className="bs-input-number-input-wrap">
              <input
                {...rest}
                type="number"
                className="bs-input-number-input"
                max={max}
                min={min}
                value={value}
                onChange={onChangeHandler}
                disabled={disabled}
                ref={composeRefs(ref, localRef)}
              />
            </div>
          </div>
        </div>
        {caption && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="bs-input-number-label-suffix">{caption}</label>
        )}
      </div>
    );
  },
) as ForwardRef<HTMLInputElement, InputNumberProps> & InputNumberStatic;

InputNumber.displayName = 'InputNumber';

InputNumber.defaultProps = {
  size: Size.Medium,
  variant: Variant.Default,
  disabled: false,
  min: -Infinity,
  max: Infinity,
};

InputNumber.Size = Size;
InputNumber.Variant = Variant;

export default InputNumber;
