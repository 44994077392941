import axios from 'axios';
import { checkTotalEmailContentSizeValidator } from '../../components/sequence/shared/modals/email-modal/helpers';
import toaster, { Theme } from '../toaster';
import {
  EmailContentSizeAllowed,
  generateId,
  generateRandomString,
  NumberOfAttachmentsAllowed,
  validAttachmentExtensions,
} from '../utils';
import iconsSvg from './iconSvg';
import {
  AttachmentType,
  AttachmentUploadStatus,
  TagsOptionsForInlineDropdown,
} from './types';
import { getVariableTags } from '../components/tags-dropdown/helpers/get-variable-tags';
import { MergeTagOption } from '../components/overlay-merge-tags';

export const generateEditorId = (variableLength?: number) =>
  generateId({ prefix: 'editor', variableLength });

export { addAlignmentSplitButton } from './alignment-splitbutton';

export const addIcon = (editor) => {
  Object.keys(iconsSvg).forEach((key) => {
    editor.ui.registry.addIcon(key, iconsSvg[key]);
  });
};

export const getAcceptedExtensionsForAttachment = (): string =>
  validAttachmentExtensions.join(',');

export const hasValidExtension = (fileName: string): boolean =>
  new RegExp(
    `(${validAttachmentExtensions.join('|').replace(/\./g, '\\.')})$`,
    'i',
  ).test(fileName);

export const checkIsTotalAttachmentSizeIsValid = (
  attachments: AttachmentType[],
  file,
  content: string,
): boolean => {
  let attachmentsSizeInBytes: number = parseInt(file.size, 10);

  attachments.forEach((item) => {
    attachmentsSizeInBytes += parseInt(item.file.size, 10);
  });

  return !checkTotalEmailContentSizeValidator({
    content,
    attachmentsSizeInBytes,
  });
};

export const showToasterError = (msg: string) => {
  toaster.error(msg, { theme: Theme.New });
};

export const addFileToTheLocalAttachments = ({
  file,
  localAttachments,
  onLocalAttachmentChange,
  onLocalAttachmentMetaChange,
  breakLoop,
  t,
  content,
}) => {
  // Validating is attachments hard limit is exceeding `NumberOfAttachmentsAllowed` or not
  if (localAttachments.length < NumberOfAttachmentsAllowed) {
    // Validating is total size of attachments is exceeding `EmailContentSizeAllowed` or not
    if (checkIsTotalAttachmentSizeIsValid(localAttachments, file, content)) {
      // Validating is attachment has a supported file extension or not
      if (hasValidExtension(file.name)) {
        // Generating random attachment identifier for a key to link up the attachment status
        const attachmentIdentifier = generateRandomString(6);

        // Creating a new attachment data object
        const attachment = {
          attachmentIdentifier,
          file,
          status: AttachmentUploadStatus.Upload,
          attachmentSize: file.size,
        };

        // Creating a new attachment meta data object
        const attachmentsMeta = {
          percentage: 0,
          attachmentIdentifier,
          source: axios.CancelToken.source(),
        };

        // Storing the attachment and its meta in local attachment array
        onLocalAttachmentChange(attachment);
        onLocalAttachmentMetaChange(attachmentsMeta);
      } else {
        // Show error as attachment does not have valid extension
        showToasterError(t('messages.unsupported_file_type'));
      }
    } else {
      // Show error as attachments total size is exceeding the limit `EmailContentSizeAllowed`
      const sizeInMb = Math.round(EmailContentSizeAllowed / 1024 / 1000);
      showToasterError(
        `${t('messages.email_content_size_exceeds')} ${sizeInMb} MB.`,
      );
      breakLoop();
    }
  } else {
    // Show error as attachments count exceeding the limit `NumberOfAttachmentsAllowed`
    showToasterError(
      `${t(
        'messages.number_of_attachments_allowed_1',
      )} ${NumberOfAttachmentsAllowed} ${t(
        'messages.number_of_attachments_allowed_2',
      )}`,
    );
    breakLoop();
  }
};

const formatMergeTags = (tags) =>
  tags?.map((tag) => ({
    text: tag.label,
    value: tag.label.slice(1),
    type: 'merge tag',
  })) || [];

const formatVariableTags = () =>
  getVariableTags().flatMap((category) =>
    category.tags.map((tagObj) => ({
      text: tagObj.tag,
      value: tagObj.tag.slice(1),
      type: 'variable tag',
    })),
  );

export const getTagsOptionsForInlineDropdown = (
  mergeTags: MergeTagOption[],
): TagsOptionsForInlineDropdown => [
  ...formatMergeTags(mergeTags),
  ...formatVariableTags(),
];
