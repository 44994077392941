export enum VariableTags {
  Today = 'today',
  Yesterday = 'yesterday',
  Tomorrow = 'tomorrow',
  CurrentTime = 'currenttime',
  CurrentDate = 'currentdate',
  CurrentMonth = 'currentmonth',
  CurrentYear = 'currentyear',
  CurrentQuarter = 'currentquarter',
  PreviousQuarter = 'previousquarter',
  NextQuarter = 'nextquarter',
  CurrentTimePeriod = 'currenttimeperiod',
  CurrentGreeting = 'currentgreeting',
  CurrentWeekNumber = 'currentweeknumber',
  PreviousWorkingDay = 'previousworkingday',
  PreviousWorkingDate = 'previousworkingdate',
  NextWorkingDay = 'nextworkingday',
  NextWorkingDate = 'nextworkingdate',
  DaysLeftInMonth = 'daysleftinmonth',
  DaysLeftInQuarter = 'daysleftinquarter',
  EndOfMonthDate = 'endofmonthdate',
  EndOfQuarterDate = 'endofquarterdate',
  SenderFirstName = 'senderfirstname',
  SenderLastName = 'senderlastname',
  SenderEmail = 'senderemail',
  SenderSignature = 'sendersignature',
  DaysLeftIn = 'daysleftin',
}
