class LoaderHelper {
  private _showLoader;
  private _hideLoader;

  bindActions = (showLoader, hideLoader) => {
    this._showLoader = showLoader;
    this._hideLoader = hideLoader;
  };

  show = () => this._showLoader();

  hide = () => this._hideLoader();
}

export default LoaderHelper;
