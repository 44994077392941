/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect, useMemo } from 'react';
import Autosuggest from 'react-autosuggest';
import { withTranslation } from 'react-i18next';
import { differenceBy, find, remove } from 'lodash';
import ProspectTagWithCancel from '../../atoms/prospect-tag/with-cancel';
import './tag-autosuggest.scss';
import { SelectAllContacts } from '../../../../../components/prospect/types';
import Icon from '../../atoms/icon';

// Render the selected tags inline or in block mode.
export enum RenderMode {
  Inline = 'Inline',
  Block = 'Block',
}

export interface ContainerProps {
  style: React.CSSProperties;
}

/**
 * Styles for the react-autosuggest <Autosuggest />
 * https://www.npmjs.com/package/react-autosuggest#theme-prop
 */
export interface Styles {
  suggestionsList?: React.CSSProperties;
  suggestionsContainer?: React.CSSProperties;
  suggestionsContainerOpen?: React.CSSProperties;
  suggestionHighlighted?: React.CSSProperties;
}

const extractTagsFromProspects = (prospects) => {
  const tagsToProspects = {};
  const prospectsTags = [];

  prospects.forEach((prospect) => {
    const { tags } = prospect;
    if (tags && tags.length) {
      tags.forEach((tag) => {
        const { id } = tag;
        if (tagsToProspects[id]) {
          tagsToProspects[id].push(prospect.id);
        } else {
          tagsToProspects[id] = [prospect.id];
          prospectsTags.push(tag);
        }
      });
    }
  });

  return prospectsTags;
};

const defaultTheme: Styles = {
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestionsContainer: {
    position: 'absolute',
    zIndex: 99999,
    left: '1.5rem',
    right: '1.5rem',
  },
  suggestionsContainerOpen: {
    display: 'block',
    border: '2px solid green',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '10px',
    height: '11.45rem',
    overflow: 'auto',
    zIndex: 2,
    backgroundColor: 'white',
  },
  suggestionHighlighted: {
    backgroundColor: '#EFF6FF',
  },
};

type IProps = {
  selectedProspects?: any[];
  /**
   * Tags to display as suggestions.
   */
  tags: any[];
  /**
   * Tags which are needed to be removed.
   */
  tagsToRemove?: number[];
  /**
   * Tags which are tp be added to the prospects.
   */
  tagsToAdd: any[];
  setTagsToRemove?: (tags: any[]) => void;
  setTagsToAdd: (tags: any[]) => void;
  t: (x: string) => React.ReactNode;
  /**
   * Mode for rendering the selected tags
   */
  renderMode?: RenderMode;
  containerProps?: ContainerProps;
  styles?: Styles;
  selectedAllProspectsDetails?: SelectAllContacts;

  showAllTags?: boolean;
};

const TagAutoSuggest: React.FC<IProps> = ({
  renderMode = RenderMode.Block,
  tags,
  selectedProspects = [],
  tagsToRemove = [],
  tagsToAdd,
  setTagsToAdd,
  setTagsToRemove,
  containerProps = {
    style: {
      marginTop: '1.25rem',
    },
  },
  t,
  styles = {},
  selectedAllProspectsDetails,
  showAllTags = false,
}) => {
  const theme = useMemo(
    () => ({
      suggestionsList: {
        ...defaultTheme.suggestionsList,
        ...styles.suggestionsList,
      },
      suggestionsContainer: {
        ...defaultTheme.suggestionsContainer,
        ...styles.suggestionsContainer,
      },
      suggestionsContainerOpen: {
        ...defaultTheme.suggestionsContainerOpen,
        ...styles.suggestionsContainerOpen,
      },
      suggestionHighlighted: {
        ...defaultTheme.suggestionHighlighted,
        ...styles.suggestionHighlighted,
      },
    }),
    [],
  );

  const [inputValue, setInputValue] = useState<string>('');

  // Suggestions to display in autosuggest.
  const [suggestions, setSuggestions] = useState([]);

  // Tags which are already added/assigned/linked to the selected prospects.
  const [alreadyAddedTags, setAlreadyAddedTags] = useState([]);

  // Show Add Remove Tag Warning For Bulk Actions.
  const [showAddRemoveWarning, setShowAddRemoveWarning] = useState<boolean>(
    false,
  );
  const [
    addRemoveTagWarningText,
    setAddRemoveTagWarningText,
  ] = useState<string>('');

  useEffect(() => {
    if (!showAllTags) {
      // Extracting tags which are already added/assigned/linked to selected prospects
      setAlreadyAddedTags(extractTagsFromProspects(selectedProspects));
    }
  }, []);

  useEffect(() => {
    if (showAllTags && tags?.length > 0) {
      const tagsToRender = tags.length > 15 ? tags.slice(0, 15) : tags;
      setAlreadyAddedTags(tagsToRender);
    }
  }, [showAllTags, tags]);

  const hideAutoSuggestBorder = () => {
    theme.suggestionsContainerOpen.border = 'none';
  };

  const showAutoSuggestBorder = () => {
    theme.suggestionsContainerOpen.border = '1px solid #D1D5DB';
  };

  /**
   * Returns the valid list of suggestions to display.
   * How the validate which suggestions to display?
   *  1. The suggestion should include the input text.
   *  1. Its not already selected.
   *  2. If the exact match is not found then its a new tag.
   *  3. If no match is found then its a new tag
   * @param inputValue String: Input text
   */
  const getSuggestions = (inputValue: string) => {
    // Exact match found for the input value and a suggestion
    let exactMatch = false;

    // Filtering already selected tags
    const selectableSuggestions = differenceBy(
      [...tags],
      tagsToAdd.concat(alreadyAddedTags),
      'name',
    );

    const suggestions = selectableSuggestions.filter((tag) => {
      if (tag.name === inputValue) {
        exactMatch = true;
      }
      return tag.name.toLowerCase().includes(inputValue.toLowerCase());
    });

    if (!suggestions.length) {
      /**
       * No Suggestions are found for this input value.
       * So user should be able to create a tag for this value.
       * Hiding the border because of design constraint :(
       */
      hideAutoSuggestBorder();
      return [
        {
          name: inputValue,
          isNew: true,
        },
      ];
    }
    /**
     * If the exact match is not found for the input then
     * User should be able to create a tag for the same value.
     * Showing the border :)
     */
    showAutoSuggestBorder();
    if (!exactMatch) {
      suggestions.push({
        name: inputValue,
        isNew: true,
      });
    }
    return suggestions;
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const onSuggestionSelected = (e: Event, { suggestion }) => {
    const suggestionRef = {
      ...suggestion,
      name: suggestion.name.trim(),
    };
    if (
      !tagsToAdd
        .concat(alreadyAddedTags)
        .filter((s) => s.name === suggestionRef.name).length
    ) {
      setTagsToAdd([...tagsToAdd, suggestionRef]);
      setInputValue('');
    }
  };

  const onSuggestionsFetchRequest = ({ value }) => {
    setSuggestions(getSuggestions(value.trim()));
  };

  const onChange = (event, { newValue }) => {
    if (
      selectedAllProspectsDetails?.isAllProspectsSelected &&
      tagsToAdd.length === 2
    ) {
      setAddRemoveTagWarningText(
        'You can assign a maximum of 2 tags to each prospect.',
      );
      setShowAddRemoveWarning(true);
      return;
    }
    setInputValue(newValue);
    setShowAddRemoveWarning(false);
  };

  const onKeyDown = (event) => {
    if (
      event.keyCode === 13 &&
      event.key.trim().toLowerCase() === 'enter' &&
      inputValue.trim().toLowerCase() !== ''
    ) {
      onSuggestionSelected(event, {
        suggestion: {
          name: inputValue,
          isNew: true,
        },
      });
    }
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion.isNew) {
      return (
        <div className="add-new-tag px-3 py-1">
          [+] Create new tag "{inputValue}"
        </div>
      );
    }

    return <div className="px-3 py-1">{suggestion.name}</div>;
  };

  /**
   * Cancelling a tag can mean 2 things.
   *  1. It was a local tag -> it was not yet added to prospects.
   *  2. The tag was assigned to prospects. By cancelling here, we mean to remove this tag from the selected prospects
   */
  const cancelTag = (tag) => {
    if (
      selectedAllProspectsDetails?.isAllProspectsSelected &&
      tagsToRemove.length === 2
    ) {
      setAddRemoveTagWarningText(
        'You can remove a maximum of 2 tags from each prospect.',
      );
      setShowAddRemoveWarning(true);
      return;
    }

    // this tag was already added to prospects;
    if (find(alreadyAddedTags, { id: tag.id })) {
      /**
       * This tag was already added to prospects.
       * So removing the tag from `alreadyAdded`.
       * And this tags needs to be removed from the selected prospects. So pushing it into the `tagsToRemove`
       */
      remove(alreadyAddedTags, { id: tag.id });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setTagsToRemove && setTagsToRemove([...tagsToRemove, tag.id]);
      setAlreadyAddedTags(alreadyAddedTags);
    } else {
      if (tag.isNew) {
        remove(tagsToAdd, { name: tag.name });
      } else {
        remove(tagsToAdd, { id: tag.id });
      }
      setTagsToAdd([...tagsToAdd]);
    }
  };

  const renderSelectedTags = () =>
    alreadyAddedTags.concat(tagsToAdd).map((tag, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={idx} className="selected-tags-container">
        <ProspectTagWithCancel
          name={tag.name}
          tooltipText={tag.name}
          onCancel={() => {
            cancelTag(tag);
          }}
        />
      </div>
    ));

  const renderInputComponent = (inputProps) => {
    if (renderMode === RenderMode.Inline) {
      return (
        <div className="bs-input d-flex flex-row flex-wrap p-2">
          <div className="d-flex flex-row align-items-center flex-flow-wrap tags-row-gap tags-autosuggest-width">
            {renderSelectedTags()}
            <div className="d-inline-block mw-100 tag-input-container">
              <div>
                <input {...inputProps} className="selected-tags-inline-input" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <input {...inputProps} className="bs-input" />;
  };

  const renderNoTags = () => (
    <div className="d-flex flex-fill justify-content-center align-items-center mt-5">
      <span className="no-tags-text">
        {t('messages.no_tags_found_title')}
        <br />
        {t('messages.no_tags_found_sub_title')}
      </span>
    </div>
  );

  const renderAddRemoveTagRestrictionWarning = () => {
    if (showAddRemoveWarning) {
      return (
        <div className="d-flex align-items-center">
          <Icon identifier="warning" className="mr-2 red-txt-16" />
          <span className="semibold-1 font-medium red-txt-16">
            {addRemoveTagWarningText}
          </span>
        </div>
      );
    }

    return null;
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: t('messages.tags_placeholder'),
    value: inputValue,
    onChange,
    onKeyDown,
  };

  return (
    <div className="tags-autosuggest-container">
      <Autosuggest
        suggestions={[...suggestions]}
        onSuggestionsFetchRequested={onSuggestionsFetchRequest}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        theme={theme}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        containerProps={containerProps}
      />
      <div className="tags-container tags-row-gap">
        {renderAddRemoveTagRestrictionWarning()}
        {renderMode === RenderMode.Block &&
          (tagsToAdd.length || alreadyAddedTags.length
            ? renderSelectedTags()
            : renderNoTags())}
      </div>
    </div>
  );
};

export default withTranslation()(TagAutoSuggest);
