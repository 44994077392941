import React from 'react';
import {
  Gift,
  Inbox,
  UserList,
  FileText,
  Book,
  Briefcase,
  PaperPlane,
  MailCheck,
  Flame,
  Gear,
  Search,
  Dots,
  Checkbox,
  ChartBar,
} from '@saleshandy/icons';

export const getNavItemIcon = (eventKey: string) => {
  if (eventKey === 'referral') {
    return <Gift height={24} width={24} />;
  }
  if (eventKey === 'sequence') {
    return <PaperPlane height={24} width={24} />;
  }
  if (eventKey === 'tasks') {
    return <Checkbox width={24} height={24} />;
  }
  if (eventKey === 'agency-client') {
    return <Briefcase height={24} width={24} />;
  }
  if (eventKey === 'unifiedInbox') {
    return <Inbox height={24} width={24} />;
  }
  if (eventKey === 'prospects') {
    return <UserList height={24} width={24} />;
  }
  if (eventKey === 'email-insights') {
    return <MailCheck height={24} width={24} />;
  }
  if (eventKey === 'warmup') {
    return <Flame height={24} width={24} />;
  }
  if (eventKey === 'templates') {
    return <FileText height={24} width={24} />;
  }
  if (eventKey === 'reports') {
    return <ChartBar height={24} width={24} />;
  }
  if (eventKey === 'growthHub') {
    return <Book height={24} width={24} />;
  }
  if (eventKey === 'agencyPortal') {
    return <Briefcase height={24} width={24} />;
  }
  if (eventKey === 'setting') {
    return <Gear height={24} width={24} />;
  }
  if (eventKey === 'cold-email-masterclass') {
    return <Gift height={24} width={24} />;
  }
  if (eventKey === 'leads') {
    return <Search height={24} width={24} />;
  }
  if (eventKey === 'threeDot') {
    return <Dots height={24} width={24} />;
  }
  if (eventKey === 'Refer') {
    return <Gift height={24} width={24} />;
  }
  return <span />;
};
