import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  cloneTemplateRequest,
  createTemplateRequest,
  deleteTemplateRequest,
  getTemplatesRequest,
  shareTemplateRequest,
  updateTemplateRequest,
} from './extra-actions';
import { Template, TemplateMeta } from './types/templates';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getTemplateRequest: RequestState;
  createTemplateRequest: RequestState;
  updateTemplateRequest: RequestState;
  deleteTemplateRequest: RequestState;
  cloneTemplateRequest: RequestState;
  shareTemplateRequest: RequestState;
  templateList: Template[];
  paginationMeta: TemplateMeta;
};

const initialState: State = {
  getTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cloneTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  shareTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  templateList: null,
  paginationMeta: null,
};

const templateSlice = createSlice({
  name: 'templates',
  initialState: initialState,
  reducers: {
    resetTemplates: (state) => {
      state.cloneTemplateRequest = initialState.cloneTemplateRequest;
      state.createTemplateRequest = initialState.createTemplateRequest;
      state.deleteTemplateRequest = initialState.deleteTemplateRequest;
      state.getTemplateRequest = initialState.getTemplateRequest;
      state.shareTemplateRequest = initialState.shareTemplateRequest;
      state.paginationMeta = initialState.paginationMeta;
      state.templateList = initialState.templateList;
      state.updateTemplateRequest = initialState.updateTemplateRequest;
    },
  },
  extraReducers: (builder) => {
    // Get template list
    builder.addCase(getTemplatesRequest.pending, (state) => {
      state.getTemplateRequest.status = RequestStatus.Pending;
      state.getTemplateRequest.error = null;
      state.getTemplateRequest.message = null;
    });
    builder.addCase(getTemplatesRequest.fulfilled, (state, action) => {
      state.getTemplateRequest.status = RequestStatus.Succeeded;
      state.templateList = action.payload.payload.items;
      state.paginationMeta = action.payload.payload.meta;
    });
    builder.addCase(getTemplatesRequest.rejected, (state, action) => {
      state.getTemplateRequest.status = RequestStatus.Failed;
      state.getTemplateRequest.message = action.payload.message;
      state.getTemplateRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Create template
    builder.addCase(createTemplateRequest.pending, (state) => {
      state.createTemplateRequest.status = RequestStatus.Pending;
      state.createTemplateRequest.error = null;
      state.createTemplateRequest.message = null;
    });
    builder.addCase(createTemplateRequest.fulfilled, (state, action) => {
      state.createTemplateRequest.status = RequestStatus.Succeeded;
      state.createTemplateRequest.message = action.payload.message;
    });
    builder.addCase(createTemplateRequest.rejected, (state, action) => {
      state.createTemplateRequest.status = RequestStatus.Failed;
      state.createTemplateRequest.message = action.payload.message;
      state.createTemplateRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update template
    builder.addCase(updateTemplateRequest.pending, (state) => {
      state.updateTemplateRequest.status = RequestStatus.Pending;
      state.updateTemplateRequest.error = null;
      state.updateTemplateRequest.message = null;
    });
    builder.addCase(updateTemplateRequest.fulfilled, (state, action) => {
      state.updateTemplateRequest.status = RequestStatus.Succeeded;
      state.updateTemplateRequest.message = action.payload.message;
    });
    builder.addCase(updateTemplateRequest.rejected, (state, action) => {
      state.updateTemplateRequest.status = RequestStatus.Failed;
      state.updateTemplateRequest.message = action.payload.message;
      state.updateTemplateRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Template
    builder.addCase(deleteTemplateRequest.pending, (state) => {
      state.deleteTemplateRequest.status = RequestStatus.Pending;
      state.deleteTemplateRequest.error = null;
      state.deleteTemplateRequest.message = null;
    });
    builder.addCase(deleteTemplateRequest.fulfilled, (state, action) => {
      state.deleteTemplateRequest.status = RequestStatus.Succeeded;
      state.deleteTemplateRequest.message = action.payload.message;
    });
    builder.addCase(deleteTemplateRequest.rejected, (state, action) => {
      state.deleteTemplateRequest.status = RequestStatus.Failed;
      state.deleteTemplateRequest.message = action.payload.message;
      state.deleteTemplateRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Clone Template
    builder.addCase(cloneTemplateRequest.pending, (state) => {
      state.cloneTemplateRequest.status = RequestStatus.Pending;
      state.cloneTemplateRequest.error = null;
      state.cloneTemplateRequest.message = null;
    });
    builder.addCase(cloneTemplateRequest.fulfilled, (state, action) => {
      state.cloneTemplateRequest.status = RequestStatus.Succeeded;
      state.cloneTemplateRequest.message = action.payload.message;
    });
    builder.addCase(cloneTemplateRequest.rejected, (state, action) => {
      state.cloneTemplateRequest.status = RequestStatus.Failed;
      state.cloneTemplateRequest.message = action.payload.message;
      state.cloneTemplateRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Share Template
    builder.addCase(shareTemplateRequest.pending, (state) => {
      state.shareTemplateRequest.status = RequestStatus.Pending;
      state.shareTemplateRequest.error = null;
      state.shareTemplateRequest.message = null;
    });
    builder.addCase(shareTemplateRequest.fulfilled, (state, action) => {
      state.shareTemplateRequest.status = RequestStatus.Succeeded;
      state.shareTemplateRequest.message = action.payload.message;
    });
    builder.addCase(shareTemplateRequest.rejected, (state, action) => {
      state.shareTemplateRequest.status = RequestStatus.Failed;
      state.shareTemplateRequest.message = action.payload.message;
      state.shareTemplateRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const { resetTemplates } = templateSlice.actions;

export default templateSlice.reducer;
