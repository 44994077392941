import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import { resendUserVerificationCodeRequest } from '../../signup-slice';
import ResendVerificationCode from './resend-verification-code';

const mapStateToProps = (state: RootState) => ({
  resendUserVerificationCodeRequestStatus:
    state.signup.resendUserVerificationCodeRequest.status,
  resendUserVerificationCodeRequestMessage:
    state.signup.resendUserVerificationCodeRequest.message,
  resendUserVerificationCodeRequestError:
    state.signup.resendUserVerificationCodeRequest.error,
});

const mapDispatchToProps = {
  sendResendUserVerificationCodeRequest: (email: string) =>
    resendUserVerificationCodeRequest({ email }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  email: string;
};

export default connector(ResendVerificationCode);
