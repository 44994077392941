import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProspect,
  getProspectCount,
  getSequenceList,
  getStepList,
  getStepDetailsList,
  addContactsToStep,
  removeProspect,
  unsubscribeProspect,
  getSingleProspectDetails,
  getMinimalSingleProspectSequencesDetails,
  singleProspectSequencesMarkAsReplied,
  singleProspectRemoveFromSequences,
  saveContactAttributes,
  saveContactDealValue,
  addProspectManually,
  singleProspectSequencesMarkAsFinished,
  verifyProspects,
  getTags,
  addTagsToProspects,
  removeTagsFromProspects,
  verifyBulkProspects,
  deleteBulkProspects,
  unsubscribeBulkProspects,
  addBulkProspectsToStep,
  addTagsToBulkProspects,
  removeTagsFromBulkProspects,
  deleteSecondaryEmail,
  deleteSecondaryPhone,
  changePrimaryEmailInProspect,
  getProspectList,
  getProspectListCount,
  getProspectListByIds,
  getFilterViewList,
  pipUnpinSavedFilter,
  updateSavedFilter,
  deleteSavedView,
  getProspectFiltersFieldsList,
  getProspectFiltersFieldValues,
  getProspectFiltersAttributeFieldValues,
  getProspectFiltersSequencesList,
  getProspectFiltersClientsList,
  saveProspectFiltersView,
  updateProspectFiltersView,
  deleteProspectFiltersView,
  getContactListByViewId,
} from './helpers/prospect.api';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  AddProspectManuallyPayload,
  AddToStepParams,
  markAsRepliedParam,
  ProspectCountParams,
  ProspectParams,
  removeFromSequenceParam,
  SaveContactAttributesPayload,
  saveContactDealValuePayload,
  AddTagsToProspectsPayload,
  RemoveTagsFromProspectsPayload,
  BulkActionPayload,
  changePrimaryEmailInProspectPayload,
  deleteSecondaryEmailParams,
  deleteSecondaryPhoneParams,
  ProspectListPayload,
  ProspectListByIdsPayload,
  PinUnpinActionType,
  ProspectFiltersValuesPayload,
  ProspectFiltersSequencesClientsPayload,
  ProspectFiltersSaveViewPayload,
  ProspectFiltersUpdateViewPayload,
  TagsGenerationType,
} from './types';
import { getFieldsGeneric } from '../settings/helpers/field.api';
import { GetFieldsQueryParams } from '../settings/types/request-payload';
import { getTeamsAndMembersList } from '../settings/helpers/users-and-teams.api';
import { getAllOutcomes } from '../settings/helpers/outcome.api';

export const getProspectListRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectListPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectList', async (args, thunkAPI) => {
  try {
    return await getProspectList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectListCountRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectListPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectListCount', async (args, thunkAPI) => {
  try {
    return await getProspectListCount(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectListByIdsRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectListByIdsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectListByIds', async (args, thunkAPI) => {
  try {
    return await getProspectListByIds(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectParams,
  { rejectValue: ResponseErrorWithHandled }
>('contact/getProspectRequest', async (args: ProspectParams, thunkAPI) => {
  try {
    return await getProspect(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectCountRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectCountParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contact/getProspectCountRequest',
  async (args: ProspectCountParams, thunkAPI) => {
    try {
      return await getProspectCount(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getSequenceListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceList', async (args, thunkAPI) => {
  try {
    return await getSequenceList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getStepListRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceStepList', async ({ sequenceId }, thunkAPI) => {
  try {
    return await getStepList(sequenceId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getStepDetailsListRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getStepDetailsList', async (args, thunkAPI) => {
  try {
    return await getStepDetailsList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addToStepRequest = createAsyncThunk<
  ResponseSuccess,
  AddToStepParams,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/addToStep', async (payload, thunkAPI) => {
  try {
    return await addContactsToStep(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const removeProspectRequest = createAsyncThunk<
  ResponseSuccess,
  number[],
  { rejectValue: ResponseErrorWithHandled }
>('contacts/removeProspectRequest', async (args, thunkAPI) => {
  try {
    return await removeProspect(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSingleProspectDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getSingleProspectDetailsRequest', async (args, thunkAPI) => {
  try {
    return await getSingleProspectDetails(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSingleProspectSequencesDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/getSingleProspectSequencesDetailsRequest',
  async (args, thunkAPI) => {
    try {
      return await getMinimalSingleProspectSequencesDetails(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const singleProspectSequencesMarkAsRepliedRequest = createAsyncThunk<
  ResponseSuccess,
  markAsRepliedParam[],
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/singleProspectSequencesMarkAsRepliedRequest',
  async (args, thunkAPI) => {
    try {
      return await singleProspectSequencesMarkAsReplied(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const singleProspectSequencesMarkAsFinishedRequest = createAsyncThunk<
  ResponseSuccess,
  markAsRepliedParam[],
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/singleProspectSequencesMarkAsFinishedRequest',
  async (args, thunkAPI) => {
    try {
      return await singleProspectSequencesMarkAsFinished(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const singleProspectRemoveFromSequencesRequest = createAsyncThunk<
  ResponseSuccess,
  removeFromSequenceParam,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/singleProspectRemoveFromSequencesRequest',
  async (args, thunkAPI) => {
    try {
      return await singleProspectRemoveFromSequences(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const unsubscribeProspectRequest = createAsyncThunk<
  ResponseSuccess,
  number[],
  { rejectValue: ResponseErrorWithHandled }
>('contacts/unsubscribeProspectRequest', async (args, thunkAPI) => {
  try {
    return await unsubscribeProspect(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const saveContactAttributesRequest = createAsyncThunk<
  ResponseSuccess,
  SaveContactAttributesPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/saveContactAttributesRequest',
  async ({ contactId, attributes }, thunkAPI) => {
    try {
      return await saveContactAttributes(contactId, attributes);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const saveContactDealValueRequest = createAsyncThunk<
  ResponseSuccess,
  saveContactDealValuePayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/saveContactDealValueRequest',
  async ({ sequenceId, attributes }, thunkAPI) => {
    try {
      return await saveContactDealValue(sequenceId, attributes);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getFieldsRequest = createAsyncThunk<
  ResponseSuccess,
  GetFieldsQueryParams | null,
  { rejectValue: ResponseErrorWithHandled }
>('prospect/getFieldsRequest', async (args = {}, thunkAPI) => {
  try {
    return await getFieldsGeneric({ includeDefaults: true, ...args });
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const addProspectManuallyRequest = createAsyncThunk<
  ResponseSuccess,
  AddProspectManuallyPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/addProspectManuallyRequest', async ({ attributes }, thunkAPI) => {
  try {
    return await addProspectManually(attributes);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const verifyProspectsManuallyRequest = createAsyncThunk<
  ResponseSuccess,
  { contactIds: number[] },
  { rejectValue: ResponseErrorWithHandled }
>('contacts/verifyProspectManuallyRequest', async (args, thunkAPI) => {
  try {
    return await verifyProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTagsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('contact/getTagsRequest', async (args, thunkAPI) => {
  try {
    return await getTags(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const addTagsToProspectsRequest = createAsyncThunk<
  ResponseSuccess,
  AddTagsToProspectsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/addTagsToProspectsRequest', async (args, thunkAPI) => {
  try {
    return await addTagsToProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const removeTagsFromProspectsRequest = createAsyncThunk<
  ResponseSuccess,
  RemoveTagsFromProspectsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/removeTagsFromProspectsRequest', async (args, thunkAPI) => {
  try {
    return await removeTagsFromProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const verifyBulkProspectsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/verifyBulkProspectsRequest', async (args, thunkAPI) => {
  try {
    return await verifyBulkProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteBulkProspectsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/deleteBulkProspectsRequest', async (args, thunkAPI) => {
  try {
    return await deleteBulkProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const unsubscribeBulkProspectRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/unsubscribeBulkProspectRequest', async (args, thunkAPI) => {
  try {
    return await unsubscribeBulkProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addBulkProspectsToStepRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/addBulkProspectsToStepRequest', async (args, thunkAPI) => {
  try {
    return await addBulkProspectsToStep(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addTagsToBulkProspectsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/addTagsToBulkProspectsRequest', async (args, thunkAPI) => {
  try {
    return await addTagsToBulkProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const removeTagsFromBulkProspectsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/removeTagsFromBulkProspectsRequest', async (args, thunkAPI) => {
  try {
    return await removeTagsFromBulkProspects(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const changePrimaryEmailInProspectRequest = createAsyncThunk<
  ResponseSuccess,
  changePrimaryEmailInProspectPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/changePrimaryEmailInProspectRequest', async (args, thunkAPI) => {
  try {
    return await changePrimaryEmailInProspect(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteSecondaryEmailRequest = createAsyncThunk<
  ResponseSuccess,
  deleteSecondaryEmailParams,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/deleteSecondaryEmail', async (args, thunkAPI) => {
  try {
    return await deleteSecondaryEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteSecondaryPhoneRequest = createAsyncThunk<
  ResponseSuccess,
  deleteSecondaryPhoneParams,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/deleteSecondaryPhone', async (args, thunkAPI) => {
  try {
    return await deleteSecondaryPhone(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getCustomFieldRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getFieldsGeneric', async (args, thunkAPI) => {
  try {
    return await getFieldsGeneric({ includeDefaults: false });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getFilterViewListRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectListPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getFilterViewList', async (args, thunkAPI) => {
  try {
    return await getFilterViewList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersFieldsListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersFieldsList', async (_args, thunkAPI) => {
  try {
    return await getProspectFiltersFieldsList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const pinUnpinSavedFilterRequest = createAsyncThunk<
  ResponseSuccess,
  { viewId: number; action: PinUnpinActionType },
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/pinUnpinSavedFilterRequest',
  async ({ viewId, action }, thunkAPI) => {
    try {
      return await pipUnpinSavedFilter(viewId, action);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const updateSavedFilterRequest = createAsyncThunk<
  ResponseSuccess,
  {
    viewId: number;
    payload: { filterName: string; isShared: boolean; teamIds: number[] };
  },
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/updateSavedFilterRequest',
  async ({ viewId, payload }, thunkAPI) => {
    try {
      return await updateSavedFilter(viewId, payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const deleteSavedViewRequest = createAsyncThunk<
  ResponseSuccess,
  { viewId: number },
  { rejectValue: ResponseErrorWithHandled }
>('contacts/deleteSavedViewRequest', async ({ viewId }, thunkAPI) => {
  try {
    return await deleteSavedView(viewId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersFieldValuesRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectFiltersValuesPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersFieldValues', async (args, thunkAPI) => {
  try {
    return await getProspectFiltersFieldValues(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersAttributeFieldValuesRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectFiltersValuesPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersAttributeFieldValues', async (args, thunkAPI) => {
  try {
    return await getProspectFiltersAttributeFieldValues(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersSequencesListRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectFiltersSequencesClientsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersSequencesList', async (args, thunkAPI) => {
  try {
    return await getProspectFiltersSequencesList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersClientsListRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectFiltersSequencesClientsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersClientsList', async (args, thunkAPI) => {
  try {
    return await getProspectFiltersClientsList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersTagsListRequest = createAsyncThunk<
  ResponseSuccess,
  TagsGenerationType,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersTagsList', async (args, thunkAPI) => {
  try {
    return await getTags(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersOwnersListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersOwnersList', async (_args, thunkAPI) => {
  try {
    return await getTeamsAndMembersList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProspectFiltersOutcomeListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/getProspectFiltersOutcomeList', async (_args, thunkAPI) => {
  try {
    return await getAllOutcomes();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const saveProspectFiltersViewRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectFiltersSaveViewPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/saveProspectFiltersView', async (args, thunkAPI) => {
  try {
    return await saveProspectFiltersView(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateProspectFiltersViewRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectFiltersUpdateViewPayload,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/updateProspectFiltersView', async (args, thunkAPI) => {
  try {
    return await updateProspectFiltersView(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteProspectFiltersViewRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/deleteProspectFiltersView', async (args, thunkAPI) => {
  try {
    return await deleteProspectFiltersView(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getContactListByViewIdRequest = createAsyncThunk<
  ResponseSuccess,
  ProspectListPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contacts/getContactListByViewIdRequest',
  async ({ viewId, selectedField }, thunkAPI) => {
    try {
      return await getContactListByViewId(viewId, selectedField);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
