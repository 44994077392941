import React from 'react';
import classNames from 'classnames';
import { Variant } from './enums';
import Icon from '../atoms/icon/icon';

type TagSubSpanProps = {
  variant: Variant;
  iconBefore: boolean;
};

const TagSubSpan: React.FC<TagSubSpanProps> = ({ variant, iconBefore }) => {
  const iconSpanClass = classNames([
    {
      'bs-tag-close-icon':
        variant === Variant.Add || variant === Variant.Remove,
    },
  ]);

  if (!iconSpanClass) {
    return null;
  }

  const identifier = classNames([
    {
      'math-plus': variant === Variant.Add,
      close: variant === Variant.Remove,
    },
  ]);

  return (
    <React.Fragment>
      {!iconBefore && ' '}
      <span className={iconSpanClass}>
        <Icon identifier={identifier} />
      </span>
      {iconBefore && ' '}
    </React.Fragment>
  );
};

export default TagSubSpan;
