export enum Status {
  Inactive = 0,
  Active = 1,
  Invited = 2,
  InviteCancelled = 3,
  Deleted = 4,
  Failed = 5,
}

export const displayTag = [
  'Inactive',
  'Active',
  'Invited',
  'Invite Cancelled',
  'Deleted',
  'Failed',
];
