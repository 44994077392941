import { AgencyConfig } from '../../../../../home/types';
import { SMTPIMAP } from '../../../../enums/smtp-or-imap';

export type PropsType = {
  show: boolean;
  onClose: () => void;
  isSMTPTestConnectionRequestPending: boolean;
  isIMAPTestConnectionRequestPending: boolean;
  isConnectAccountRequestPending: boolean;
  emailAccountId?: number;
  isUpdateSmtpImapAccountRequestPending?: boolean;
  agencyConfig: Partial<AgencyConfig>;
  showErrorLogModal: (accountType: SMTPIMAP, errorLog: string) => void;
  selectGmailAsDefault?: boolean;
};

export type EmailProviderDropDown = {
  key: string;
  value: string;
};

export type TestConnection = {
  username?: string;
  emailAddress: string;
  password: string;
  host: string;
  port: number;
  encryption?: string;
  requestFrom: SMTPIMAP;
};

export enum SmtpImapProviders {
  GmailGsuite = 'Gmail/Gsuite',
  Office365 = 'Office 365',
  OutlookHotmailLive = 'Outlook.com / Hotmail.com / Live.com',
  YahooMail = 'Yahoo Mail',
  Zoho = 'Zoho.com',
  Godaddy = 'Godaddy',
  Sendgrid = 'Sendgrid',
  Yandex = 'Yandex',
}

export enum LastTestConnection {
  Test = 'test',
  ConnectAndSave = 'connectAndSave',
  Update = 'update',
}

export type ValidationProp = {
  validCondition: boolean;
  msg: string;
};
