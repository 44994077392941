import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import LTDCheckout from './ltd-checkout';
import {
  applyCouponCodeRequest,
  getPlansRequest,
  purchaseLtdPlanRequest,
} from '../../../settings/components/billing-subscription/extra-actions';
import {
  ApplyCouponCodeRequest,
  LTDPurchasePayload,
} from '../../../settings/components/billing-subscription/types';
import { resetPurchaseLtdPlan } from '../../../settings/components/billing-subscription/billing-subscription-slice';
import { getUserSettingsRequest } from '../../../home/extra-actions';

const mapStateToProps = (state: RootState) => ({
  email: state.signup.email,
  plans: state.billingAndSubscription.getPlansResponse,

  sendGetPlansRequestStatus:
    state.billingAndSubscription.getPlansRequest.status,
  sendGetPlansRequestError: state.billingAndSubscription.getPlansRequest.error,

  meta: state.home.meta,
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,

  purchaseLtdPlanRequestStatus:
    state.billingAndSubscription.purchaseLtdPlanRequest.status,
  purchaseLtdPlanRequestMessage:
    state.billingAndSubscription.purchaseLtdPlanRequest.message,
  purchaseLtdPlanRequestError:
    state.billingAndSubscription.purchaseLtdPlanRequest.error,
  purchaseLtdPlanResponse: state.billingAndSubscription.purchaseLtdPlanResponse,

  sendApplyCouponCodeRequestStatus:
    state.billingAndSubscription.applyCouponCodeRequest.status,
  sendApplyCouponCodeRequestMessage:
    state.billingAndSubscription.applyCouponCodeRequest.message,
  sendApplyCouponCodeRequestError:
    state.billingAndSubscription.applyCouponCodeRequest.error,
  applyCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,

  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,
});

const mapDispatchToProps = {
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendGetPlansRequest: () => getPlansRequest(),
  sendApplyCouponCodeRequest: (payload: ApplyCouponCodeRequest) =>
    applyCouponCodeRequest(payload),
  sendPurchaseLtdPlanRequest: (payload: LTDPurchasePayload) =>
    purchaseLtdPlanRequest(payload),

  resetPurchaseLtdPlan: () => resetPurchaseLtdPlan(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(LTDCheckout);
