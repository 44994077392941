import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trash } from '@saleshandy/icons';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { DeleteCustomOutcomeDropdown, IProps } from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import Select from '../../../../../../shared/design-system/components/select';
import { capitalize, executeOnRequestStatus, getIsRequestPending } from '../../../../../../shared/utils';
import { Placement } from '../../../../../../shared/design-system/components/overlay';
import { getOutcomeIcon } from '../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import { getDeleteOutcomeDropdownOptions } from '../custom-outcome-content/helpers/custom-outcome-column-data';

const DeleteCustomOutcomeModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  title,
  className,
  content,
  isRequestPending,
  showCloseIcon,
  onOutcomeIdUpdate,
  getAllOutcomesRequestStatus,
  sendAllOutcomesRequest,
  options,
  actionCustomOutcome,
}) => {
  const { t } = useTranslation();

  const [selectedCustomOutcomeUpdateId, setSelectedCustomOutcomeUpdateId] = React.useState(1);


  React.useEffect(() => {
    sendAllOutcomesRequest();
  }, []);

  React.useEffect(() => {
    executeOnRequestStatus({
      status: getAllOutcomesRequestStatus,
      onSuccess: () => {},
    });
  }, [getAllOutcomesRequestStatus]);

  const isFetching = getIsRequestPending(getAllOutcomesRequestStatus);


  return (
    <Modal
      show={show}
      className={`delete-modal ${className}`}
      titleContent={
        <div className="d-flex align-items-center delete-modal-title">
          <Trash width={23} height={23} className="red-txt-12" />
          <div className="delete-title">{title}</div>
        </div>
      }
      showCloseIcon={showCloseIcon}
      onClose={onClose}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={t('labels.delete')}
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isRequestPending}
      onSubmit={onSubmit}
      cancelButtonVarient={Button.Variant.Outlined}
      onHide={onClose}
      extraModalProps={{
        enforceFocus: false,
      }}
    >
      <span className="regular-2 popover-arrow-color-txt">{content}</span>
      <span className="regular-2 popover-arrow-color-txt">
        Please select a new outcome for them.
      </span>
      <Select<DeleteCustomOutcomeDropdown>
        showSearch
        className="delete-outcome-modal-transfer"
        filterOption={(value, option) =>
          option.name.toLowerCase().includes(value.toLowerCase())
        }
        selectedOptionRenderer={([option]) => <span>{option?.name}</span>}
        optionRenderer={(option) => (
          <div className="sentiment-content">
            <div className="sentiment-icon">
              {getOutcomeIcon(option.name, false, {
                isDefault: option.isDefault,
                sentiment: option.sentiment,
                width: 16,
                height: 16,
              })}
            </div>
            <div>{capitalize(option.name)}</div>
          </div>
        )}
        options={getDeleteOutcomeDropdownOptions(options, actionCustomOutcome?.id)}
        placeholder="Select Outcome"
        searchPlaceholder="Search"
        placement={Placement.BottomEnd}
        selectedOptionKey={selectedCustomOutcomeUpdateId}
        onChange={([selectedOption]) => {
          setSelectedCustomOutcomeUpdateId(selectedOption?.key);
          onOutcomeIdUpdate(selectedOption?.key)
        }}
        isLoading={isFetching}
      />
    </Modal>
  );
};

export default DeleteCustomOutcomeModal;
