import React from 'react';
import { NoTagsFound } from '../../../svg';

const NoTagsFoundUI: React.FC = () => (
  <div className="no-tags-found">
    <NoTagsFound />
    <p>No tags found</p>
  </div>
);

export default NoTagsFoundUI;
