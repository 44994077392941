import { connect, ConnectedProps } from 'react-redux';
import Setting from './setting';
import { RootState } from '../../store/root-reducer';

const mapStateToProps = (state: RootState) => ({
  subscriptionDetails: state.home.subscription,
  pageHeaderBannerMessage: state.pageHeader.message,
  ctaURL: state.pageHeader.CTAUrl,
  ctaText: state.pageHeader.CTAText,
  target: state.pageHeader.target,
  role: state.home.role,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(Setting);
