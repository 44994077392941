import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IPropsComponent } from './types';
import { RootState } from '../../../../store/root-reducer';
import AgencyClientFilters from './agency-client-filters';
import { GetAgencyClientDropdownRequestPayload } from '../../types/request-payload';
import { getAgencyClientsDropdown } from '../../extra-actions';

const mapStateToProps = (state: RootState) => ({
  getAgencyClientsDropdownRequestStatus:
    state.agencyClient.getAgencyClientsDropdownRequest.status,
  getAgencyClientsDropdownRequestMessage:
    state.agencyClient.getAgencyClientsDropdownRequest.message,
  getAgencyClientsDropdownRequestError:
    state.agencyClient.getAgencyClientsDropdownRequest.error,
  clients: state.agencyClient.clients,
});

const mapDispatchToProps = {
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any> &
  IPropsComponent;

export default connector(AgencyClientFilters);
