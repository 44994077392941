import React from 'react';
import { components } from 'react-select';
import Icon from '../icon';

export const style = {
  clearIndicator: (base, state) => {
    return {
      ...base,
      cursor: 'pointer',
      color: state.isFocused ? 'blue' : 'black',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  option: (provided, { isFocused }) => {
    return {
      ...provided,
      padding: '.25rem .5rem',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '22px',
      color: '#262626',
      backgroundColor: isFocused ? '#e6f7ff' : null,
      ':active': {
        ...provided[':active'],
        backgroundColor: '#e6f7ff',
      },
    };
  },
  container: (base) => {
    return {
      ...base,
      width: '100%',
      fontSize: '14px',
    };
  },

  valueContainer: (base) => {
    return {
      ...base,
      padding: '4px 11px',
    };
  },

  placeholder: (provided) => {
    return {
      ...provided,
      fontSize: '14px',
      fontFamily: 'inherit',
      color: '#BFBFBF',
      margin: '0',
      lineHeight: '0',
    };
  },
  input: (provided) => {
    return {
      ...provided,
      margin: '0',
      padding: '0',
    };
  },
};
export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon identifier="caret-down" />
    </components.DropdownIndicator>
  );
};
