import React from 'react';
import { Link } from 'react-router-dom';

const FixedHome = () => {
  const showNavigation = localStorage.getItem('showNavigation');

  if (showNavigation) {
    return (
      <Link
        to="/navigation"
        style={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          padding: '5px 10px',
          border: '1px solid black',
          zIndex: 100,
        }}
      >
        Nav
      </Link>
    );
  }

  localStorage.setItem('showNavigation', '');

  return null;
};

export default FixedHome;
