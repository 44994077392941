import React, { useMemo, useState } from 'react';

import { MergeTagOption } from '../../overlay-merge-tags';

import NoTagsFoundUI from './no-tags-found-ui';
import TagsDropdownSearchInput from './tags-dropdown-search-input';
import { accessibleOnClick } from '../../../utils/accessible-on-click';

type IProps = {
  mergeTags: MergeTagOption[];
  onAddMergeTag: (mergeTag: MergeTagOption) => void;
  onHide: VoidFunction;
};

const MergeTagsContent: React.FC<IProps> = ({
  mergeTags,
  onAddMergeTag,
  onHide,
}) => {
  const [search, setSearch] = useState('');

  const options: MergeTagOption[] = useMemo(() => {
    if (!mergeTags || mergeTags.length === 0) {
      return [];
    }

    if (search === '') {
      // return all tags if search is empty
      return mergeTags;
    }

    return mergeTags.filter((mergeTag) =>
      mergeTag.label.toLowerCase().includes(search.toLowerCase()),
    );
  }, [mergeTags, search]);

  return (
    <div className="merge-variable-tags--content">
      <TagsDropdownSearchInput search={search} onSearch={setSearch} />

      <div className="merge-variable-tags--list">
        {options?.length === 0 && search !== '' ? (
          <NoTagsFoundUI />
        ) : (
          options?.map((mergeTag) => (
            <div
              className="merge-tag-item"
              key={mergeTag.key}
              {...accessibleOnClick(() => {
                onAddMergeTag(mergeTag);
                onHide();
              })}
            >
              {mergeTag.label.length > 30 ? (
                <span>{`${mergeTag.label.slice(0, 30).trim()}...`}</span>
              ) : (
                <span>{mergeTag.label}</span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MergeTagsContent;
