import notification from '../../notification';

export const showGeneralErrorNotification = (
  message: React.ReactNode,
  header = 'Oops!',
) => {
  notification.error({
    header,
    content: message,
  });
};
