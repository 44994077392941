import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import EmailAccountsHeader from './email-accounts-header';

const mapStateToProps = (state: RootState) => ({
  emailsCount: state.emailAccount.emailsCount,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(EmailAccountsHeader);
