import React from 'react';
import './loader-manager.scss';
import Loader from './loader';
import classNames from 'classnames';

type IState = {
  showLoader: boolean;
  // startAnimation: boolean;
};

type IProps = {
  bindActions: (...args: any[]) => any;
};

class LoaderManager extends React.Component<IProps, IState> {
  // animationTime = 3000; // sync this time with css animation.

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      // startAnimation: false,
    };

    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);

    this.props.bindActions(this.showLoader, this.hideLoader);
  }

  showLoader() {
    this.setState({ showLoader: true });
  }

  hideLoader() {
    this.setState({ showLoader: false });
    // setTimeout(() => {
    //   this.setState({ showLoader: false, startAnimation: false });
    // }, this.animationTime);
  }

  render() {
    const {
      showLoader,
      // startAnimation
    } = this.state;

    // const visibilityClass = startAnimation && 'fade';

    const loaderBackgroundClass = classNames([
      'loader-background',
      // visibilityClass,
    ]);

    return (
      showLoader && (
        <div className={loaderBackgroundClass}>
          <div className="loader-wrapper">
            <Loader />
          </div>
        </div>
      )
    );
  }
}

export default LoaderManager;
