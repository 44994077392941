import passwordValidator from '../../../../../../shared/utils/password-validator';
import { StateValues } from './types';

export const validate = (name, value, values: StateValues) => {
  switch (name) {
    case 'currentPassword':
      if (!value) {
        return 'Enter current password';
      }
      break;
    case 'newPassword':
      if (!passwordValidator.all(value)) {
        return 'Password must contain at least one upper case, lowercase and a number';
      }
      break;
    case 'confirmNewPassword':
      if (value !== values.newPassword) {
        return `Password doesn't match`;
      }
      break;
    default:
      return '';
  }
};
