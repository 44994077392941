export const getFormatNumber = (num: number): string => {
  if (num < 1000) return num.toString();

  const units = ['k', 'M', 'B', 'T'];

  const unitIndex = Math.floor(Math.log10(num) / 3);

  // eslint-disable-next-line no-restricted-properties
  const scaledNum = num / Math.pow(1000, unitIndex);

  return scaledNum % 1 === 0
    ? `${Math.floor(scaledNum)}${units[unitIndex - 1]}`
    : `${scaledNum.toFixed(1)}${units[unitIndex - 1]}`;
};
