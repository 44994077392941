import { RootState } from '../../../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import {
  updateScheduleRequest,
  deleteScheduleRequest,
} from '../../schedule-slice';
import { UpdateScheduleRequestPayload } from '../../../../types/request-payload';
import UpdateSchedule from './update-schedule';
import { Schedule } from '../../../../types/schedule';
import { getTimezoneList } from '../../../../../../shared/utils';

const mapStateToProps = (state: RootState) => ({
  updateScheduleRequestStatus: state.schedule.updateScheduleRequest.status,
  updateScheduleRequestMessage: state.schedule.updateScheduleRequest.message,
  updateScheduleRequestError: state.schedule.updateScheduleRequest.error,
  timezoneList: getTimezoneList(),
  isLoading: state.schedule.isLoading,
});

const mapDispatchToProps = {
  sendUpdateScheduleRequest: (
    scheduleId: number,
    payload: UpdateScheduleRequestPayload,
  ) => updateScheduleRequest({ scheduleId, ...payload }),
  sendDeleteScheduleRequest: (scheduleId: number) =>
    deleteScheduleRequest(scheduleId),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  schedule: Schedule;
  onDelete: (schedule: Schedule) => void;
};

export default connector(UpdateSchedule);
