import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IProps } from './types';
import { Images } from '../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const ResumeSubscriptionModal: React.FC<IProps> = ({
  show,
  onClose,
  onResumeSubscription,
  pauseEndsDate,
  isRequestPending,
  resumeSubscriptionModalHeaderMessage,
  resumeSubscriptionModalMessage1,
  resumeSubscriptionModalMessage2,
  submitButtonText,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      className="resume-subscription-modal"
      titleContent={
        <div className="d-flex">
          <ImageIcon
            src={Images.AlertCircleOrange}
            height={24}
            className="mb-1"
          />
          <div className="pl-3 semibold-3 modal-title">
            {' '}
            {t(`messages.${resumeSubscriptionModalHeaderMessage}`)}
          </div>
        </div>
      }
      showCloseIcon={!isRequestPending}
      onClose={onClose}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={t(`labels.${submitButtonText}`)}
      onSubmit={onResumeSubscription}
      cancelButtonVarient={Variant.Outlined}
      isSubmitDisabled={isRequestPending}
      isSubmitLoading={isRequestPending}
      isCancelDisabled={isRequestPending}
      cancelButtonClassName="resume-subscription-modal-cancel-btn"
      submitButtonClassName="resume-subscription-modal-submit-btn"
      onHide={onClose}
      backdrop="static"
    >
      <div>
        <span className="regular-2">
          {t(`messages.${resumeSubscriptionModalMessage1}`)}
        </span>{' '}
        <span className="semibold-2">
          {DateTime.fromJSDate(new Date(pauseEndsDate)).toFormat('d MMM, yyyy')}
        </span>
      </div>
      <div className="mt-2">
        <span className="regular-2">
          {t(`messages.${resumeSubscriptionModalMessage2}`)}
        </span>
      </div>
    </Modal>
  );
};

export default ResumeSubscriptionModal;
