import React, { useState } from 'react';
import type { IProps } from './add-bulk-import-modal-container';
import Modal from '../../../../../../shared/design-system/components/atoms/modal/modal';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../shared/app-constants';
import { arr } from './utils/helper';
import SelectEmailAccountsFile from './components/select-email-accounts-file';
import { getIsRequestPending } from '../../../../../../shared/utils/get-request-status';
import { FileData } from '../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/select-file/types';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { isWhitelabelWithoutClientView } from '../../../../../../shared/utils/user-details';

const AddBulkImportModal: React.FC<IProps> = ({
  show,
  onCancel,
  importEmailAccountCsvRequestStatus,
  importEmailAccountViaCsv,
}) => {
  const isRequestPending = getIsRequestPending(
    importEmailAccountCsvRequestStatus,
  );
  //* State
  const [fileData, setFileData] = useState<FileData>(null);

  //* Functions
  const handleSubmit = () => {
    importEmailAccountViaCsv(fileData.file);
  };

  //  Connect Button
  const [showConnectButton, setShowConnectButton] = useState<boolean>(true);

  const handleConnectButton = (fdata: FileData) => {
    setFileData(fdata);
    setShowConnectButton(false);
  };
  // setShowConnectButton(true)

  const handleCancel = () => {
    onCancel();
    setShowConnectButton(true);
  };

  return (
    <Modal
      className="add-bulk-import-modals"
      submitButtonClassName="header-btn"
      show={show}
      onCancel={() => handleCancel()}
      onHide={onCancel}
      onSubmit={handleSubmit}
      submitButtonText="Connect"
      cancelButtonVarient={Button.Variant.Outlined}
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
      }}
      titleContent={
        <div>
          <span>Import email accounts</span>
        </div>
      }
      isSubmitDisabled={showConnectButton}
      isSubmitLoading={isRequestPending}
    >
      <div className="add-bulk-import-headings">
        <ImageIcon src={Images.Bulb} className="ml-3 add-bulk-import-image" />
        <div className=" ml-3 mt-2">
          {arr().map((item) => (
            <div className="ml-1 regular-1 font-medium gray-txt-15">
              {!item.isSelected && item.value}
              {item.linkValue}
              {isWhitelabelWithoutClientView() && item.URL}
            </div>
          ))}
        </div>
      </div>
      {/* drag & drop section */}
      <SelectEmailAccountsFile
        onFileSelect={(fData: FileData) => handleConnectButton(fData)}
      />
    </Modal>
  );
};

export default AddBulkImportModal;
