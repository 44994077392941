import { Browser } from '../enums/browsers';
import { detectBrowser } from './detect-browser';

export const copyToClipboard = (text: string, domRef?: HTMLInputElement) => {
  if (detectBrowser() === Browser.InternetExplorer) {
    domRef.select();
    document.execCommand('copy');
  } else {
    navigator.clipboard.writeText(text);
  }
};
