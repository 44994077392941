import { connect, ConnectedProps } from 'react-redux';
import { TrialExtendSource } from '../../../../shared/enums/trial-extend-source';
import { RootState } from '../../../../store/root-reducer';
import { extendTrialPeriodRequest } from '../../../home/extra-actions';
import HeaderBanner from './header-banner';

const mapStateToProps = (state: RootState) => ({
  firstName: state.home.adminDetails?.firstName,
  email: state.home.adminDetails?.email,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,

  isEligibleToExtendTrial: state.home.isEligibleToExtendTrial,
});

const mapDispatchToProps = {
  sendExtendTrialPeriodRequest: (source: TrialExtendSource) =>
    extendTrialPeriodRequest(source),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  isEmailAccountPage?: boolean;
  isTeamPage?: boolean;
};

export default connector(HeaderBanner);
