import { BulkSelectedEmailAccounts } from '../../../types';
import { constants } from '../../../../../../../shared/enums/constants';

export const checkIsTableLastPage = (pageNumber: number, pageSize: number) => {
  const lastPage = constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT / pageSize;

  return lastPage >= pageNumber;
};

export const isCurrentPageUnderTheSelectAllPage = (
  bulkSelectedEmailAccounts: BulkSelectedEmailAccounts,
  pageNumber: number,
  pageSize: number,
) => {
  const lastPage = constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT / pageSize;

  return (
    bulkSelectedEmailAccounts?.isAllEmailAccountsSelected &&
    lastPage >= pageNumber
  );
};

// * Get Should Show Selected Row Info Banner Handler
export const getShouldShowSelectedRowInfoBanner = (
  selectedEmailAccounts: any[],
  bulkSelectedEmailAccounts: BulkSelectedEmailAccounts,
  isBulkEmailAccountsSelected: boolean,
) =>
  selectedEmailAccounts.length > 0 ||
  bulkSelectedEmailAccounts?.isAllEmailAccountsSelected ||
  isBulkEmailAccountsSelected;

export const getSelectedEmailAccountsCount = (
  selectedEmailAccounts: any[],
  bulkSelectedEmailAccounts: BulkSelectedEmailAccounts,
  totalEmailAccounts: number,
) => {
  if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
    const count =
      totalEmailAccounts > constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT
        ? constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT
        : totalEmailAccounts;

    return count - bulkSelectedEmailAccounts?.deSelectedEmailAccountIds?.length;
  }

  return selectedEmailAccounts.length;
};

export const getFormattedEmailAccountsCount = (
  selectedEmailAccountsCount: number,
) =>
  `${selectedEmailAccountsCount
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Email Account(s) selected.`;
