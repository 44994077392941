/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import { AlertCircle, Trash } from '@saleshandy/icons';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils';
import Input from '../../input';
import { Placement } from '../../overlay';
import { OverlayTooltip } from '../../overlay/tooltip';
import Select from '../../select';
import DeleteCustomFieldModal from '../../../../../components/settings/components/custom-fields/components/delete-custom-field-modal';
import toaster from '../../../../toaster';
import ConfirmationModalV3 from '../confirmation-modal/confirmation-modal-v3';
import { CONTACT_CATEGORY_OPTIONS } from './types';
import { FormMode } from '../../organisms/form/types';

export default function IndividualPhoneNumberField({
  phonenumber,
  PhoneNumbers,
  setPhoneNumbers,
  index,
  handleBlur,
  errors,
  touched,
  setFieldTouched,
  formMode,
  deletePhoneFromProspectRequest,
  selectedProspectId,
  setIsExpandedContacts,
}) {
  const { t } = useTranslation();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);

  const handleDeleteClick = (_index) => {
    const deletePhone = PhoneNumbers.find((_, i) => i === _index);
    deletePhoneFromProspectRequest(deletePhone?.id, selectedProspectId);
  };

  const AddProspectHandleDeleteClick = (_index) => {
    const updatedPhoneNumbers = PhoneNumbers.filter((_, i) => i !== _index);

    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handlePhoneNumberChange = (index, newEmail: any) => {
    const updatedPhoneNumbers = PhoneNumbers.map((phonenumber, i) =>
      i === index ? { ...phonenumber, phonenumber: newEmail } : phonenumber,
    );

    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handlePhoneTypeChange = (index, phoneType: any) => {
    const updatedPhoneNumbers = PhoneNumbers.map((phonenumber, i) =>
      i === index ? { ...phonenumber, phoneType } : phonenumber,
    );

    setPhoneNumbers(updatedPhoneNumbers);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    handleBlur;
  };

  const handleIsPrimaryChange = (index) => {
    const updatedPhoneNumbers = PhoneNumbers.map((phonenumber, i) =>
      i === index
        ? { ...phonenumber, isPrimary: true }
        : { ...phonenumber, isPrimary: false },
    );
    updatedPhoneNumbers.sort((a: any, b: any) => b.isPrimary - a.isPrimary);

    setPhoneNumbers(updatedPhoneNumbers);
  };

  useEffect(() => {
    if (PhoneNumbers?.length > 3) {
      setIsExpandedContacts(true);
    } else {
      setIsExpandedContacts(false);
    }
  }, [PhoneNumbers?.length]);

  const shouldPhoneRadioInputDisabled =
    !phonenumber?.isPrimary &&
    (phonenumber?.phonenumber === '' || errors[`phonenumber${index}`]);

  const radioInput = (
    <input
      type="radio"
      checked={phonenumber.isPrimary}
      disabled={formMode === FormMode.Edit || shouldPhoneRadioInputDisabled}
      className="bs-radio-input radio-button"
      value={phonenumber.isPrimary}
      onClick={() => setIsShowConfirmationModal(true)}
    />
  );

  return (
    <div className="individual-contact-field row ml-0" key={index}>
      <div className="col-7 contact-col d-flex align-items-center pl-0">
        {shouldPhoneRadioInputDisabled ? (
          radioInput
        ) : (
          <OverlayTooltip
            text={
              //  eslint-disable-next-line
              formMode === FormMode.Edit
                ? 'This feature is disabled'
                : phonenumber.isPrimary
                ? 'Primary'
                : 'Mark as primary'
            }
            className="tooltip-md"
            placement={Placement.Bottom}
          >
            {radioInput}
          </OverlayTooltip>
        )}
        <Input
          type="text"
          placeholder="Enter Phone Number"
          disabled={phonenumber?.id && phonenumber.isPrimary}
          className="ml-2 mb-0"
          name="phonenumber"
          value={phonenumber?.phonenumber}
          onChange={(e: any) => handlePhoneNumberChange(index, e)}
          onBlur={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            handleBlur;
            setFieldTouched(`phonenumber${index}`);
          }}
          variant={
            errors[`phonenumber${index}`] &&
            touched[`phonenumber${index}`] &&
            Input.Variant.Error
          }
        />
      </div>
      <div className="col-5 contact-col d-flex align-items-center">
        <Select<any>
          options={CONTACT_CATEGORY_OPTIONS}
          optionFilterProp="name"
          placeholder="Select Type"
          selectedOptionKey={phonenumber.phoneType}
          optionRenderer={(option) => <span>{capitalize(option?.name)}</span>}
          showOptionsSeparator={true}
          onChange={([e]) => {
            handlePhoneTypeChange(index, e?.name);
            setFieldTouched(`phonenumber${index}`);
          }}
          selectedOptionRenderer={([option]) => (
            <span>{capitalize(option?.name)}</span>
          )}
        />

        <OverlayTooltip
          rootClose={true}
          text={
            phonenumber.isPrimary
              ? 'Primary phone number can not be deleted'
              : 'Delete this phone number'
          }
          className="tooltip-md tooltip-arrow"
          placement={Placement.Bottom}
        >
          <span>
            <Trash
              width={20}
              height={20}
              className={`gray-txt-15 ml-2 ${
                phonenumber.isPrimary ? 'disable-trash-icon' : 'trash-icon'
              }`}
              onClick={() => {
                if (!phonenumber?.isPrimary) {
                  if (phonenumber?.id) {
                    setIsShowDeleteModal(true);
                    return;
                  }

                  setPhoneNumbers(
                    PhoneNumbers?.filter(
                      (phone, indexTobeDelete) => index !== indexTobeDelete,
                    ),
                  );
                }
              }}
            />
          </span>
        </OverlayTooltip>
      </div>
      <span className="form-input-error-caption red-txt-12 ml-4 py-1">
        {errors[`phonenumber${index}`] &&
          touched[`phonenumber${index}`] &&
          errors[`phonenumber${index}`]}
      </span>
      {isShowDeleteModal && (
        <DeleteCustomFieldModal
          show={isShowDeleteModal}
          title={t('messages.delete_phone_number_modal_title')}
          content={t('messages.delete_phone_number_modal_content')}
          onClose={() => setIsShowDeleteModal(false)}
          className="delete-modal prospect-delete-modal"
          showCloseIcon={false}
          onSubmit={() => {
            if (!phonenumber.isPrimary) {
              if (formMode === FormMode.Edit) {
                handleDeleteClick(index);
              } else {
                AddProspectHandleDeleteClick(index);
                toaster.success('Phone number deleted successfully');
              }
              setIsShowDeleteModal(false);
            }
          }}
          isRequestPending={false}
        />
      )}
      {isShowConfirmationModal && (
        <ConfirmationModalV3
          show={isShowConfirmationModal}
          onClose={() => setIsShowConfirmationModal(false)}
          onSubmit={() => {
            if (!phonenumber.isPrimary) {
              handleIsPrimaryChange(index);
              setIsShowConfirmationModal(false);
              toaster.success('Primary phone number updated successfully');
            }
          }}
          title={t('messages.confirmation_phone_number_modal_title')}
          content={t('messages.confirmation_phone_number_modal_content')}
          className="confirmation-modal-v3 confirmation-modal"
          cancelButtonText="cancel"
          showCloseIcon={false}
          icon={<AlertCircle />}
          hideTitleIcon
        />
      )}
    </div>
  );
}
