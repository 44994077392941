import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getEspLimitsRequest,
  updateMaxSendingLimitRequest,
} from './extra-actions';
import { EspLimits } from './types';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  espLimits: EspLimits[];
  getEspLimitRequest: RequestState;
  updateMaxSendingLimitRequest: RequestState;
  isLoading: boolean;
}

const initialState: State = {
  getEspLimitRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateMaxSendingLimitRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  espLimits: [],
  isLoading: false,
};

const EmailDailySendingLimitSlice = createSlice({
  name: 'emailDailySendingLimit',
  initialState,
  reducers: {
    resetEmailDailySendingLimitState: (state) => {
      state.espLimits = initialState.espLimits;
      state.updateMaxSendingLimitRequest =
        initialState.updateMaxSendingLimitRequest;
      state.getEspLimitRequest = initialState.getEspLimitRequest;
    },
    resetUpdateMaxSendingLimitRequestState: (state) => {
      state.updateMaxSendingLimitRequest =
        initialState.updateMaxSendingLimitRequest;
    },
  },
  extraReducers: (builder) => {
    //   Get esp sending limits
    builder.addCase(getEspLimitsRequest.pending, (state) => {
      state.getEspLimitRequest.status = RequestStatus.Pending;
      state.getEspLimitRequest.error = null;
      state.isLoading = true;
    });

    builder.addCase(getEspLimitsRequest.fulfilled, (state, action) => {
      state.getEspLimitRequest.status = RequestStatus.Succeeded;
      state.getEspLimitRequest.error = null;
      state.espLimits = action.payload.payload;
      state.isLoading = false;
    });

    builder.addCase(getEspLimitsRequest.rejected, (state, action) => {
      state.getEspLimitRequest.status = RequestStatus.Failed;
      state.getEspLimitRequest.error = action.payload;
      state.isLoading = false;
    });

    //   Update max sending limit
    builder.addCase(updateMaxSendingLimitRequest.pending, (state) => {
      state.updateMaxSendingLimitRequest.status = RequestStatus.Pending;
      state.updateMaxSendingLimitRequest.error = null;
      state.isLoading = true;
    });

    builder.addCase(updateMaxSendingLimitRequest.fulfilled, (state) => {
      state.updateMaxSendingLimitRequest.status = RequestStatus.Succeeded;
      state.updateMaxSendingLimitRequest.error = null;
      state.isLoading = false;
    });

    builder.addCase(updateMaxSendingLimitRequest.rejected, (state, action) => {
      state.updateMaxSendingLimitRequest.status = RequestStatus.Failed;
      state.updateMaxSendingLimitRequest.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  resetEmailDailySendingLimitState,
  resetUpdateMaxSendingLimitRequestState,
} = EmailDailySendingLimitSlice.actions;
export default EmailDailySendingLimitSlice.reducer;
