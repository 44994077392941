export const getMergedProspects = (arr1 = [], arr2 = []) => {
  // Convert arr2 to a Map for easier lookup
  const arr2Map = new Map(arr2.map((item) => [item.id, item]));

  return arr1.map((prospect) => {
    const prospectFromArr2 = arr2Map.get(prospect.id);

    // If there's no matching prospect in arr2, return the original prospect
    if (!prospectFromArr2) return prospect;

    // Merge attributes array
    const mergedAttributes = [
      ...(prospect.attributes || []),
      ...(prospectFromArr2.attributes || []),
    ];

    // Merge engagementFields, ensuring no value is overwritten
    const mergedEngagementFields = {
      ...(prospect.engagementFields || {}),
      ...(prospectFromArr2.engagementFields || {}),
    };

    // Return the merged prospect object
    return {
      ...prospect,
      ...prospectFromArr2,
      attributes: mergedAttributes,
      engagementFields: mergedEngagementFields,
    };
  });
};
