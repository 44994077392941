import React from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { Accordion } from '@saleshandy/design-system';
import { ChevronDown, ChevronUp } from '@saleshandy/icons';
import { VariableTag } from '../types';
import { accessibleOnClick } from '../../../utils/accessible-on-click';
import {
  OverlayTooltip,
  Placement,
} from '../../../design-system/components/overlay';
import { getVariableTagTooltip } from '../helpers/get-variable-tag-tooltip';

type IProps = {
  option: VariableTag;
  preExpanded: string[];
  handleOnAddVariableTag: (tag: string) => void;
  currentTime: moment.Moment;
  disabledVariableTags: string[];
  timezone: string;
};

const VariableTagCategory: React.FC<IProps> = ({
  option,
  preExpanded,
  handleOnAddVariableTag,
  currentTime,
  disabledVariableTags,
  timezone,
}) => (
  <Accordion
    allowMultipleExpanded
    allowZeroExpanded
    preExpanded={preExpanded}
    key={preExpanded.length}
  >
    <Accordion.Item uuid={option.key}>
      <Accordion.ItemHeading>
        <Accordion.ItemButton>
          <span>{option.categoryName}</span>
          <Accordion.ItemState>
            {({ expanded }) => (expanded ? <ChevronUp /> : <ChevronDown />)}
          </Accordion.ItemState>
        </Accordion.ItemButton>
      </Accordion.ItemHeading>

      <Accordion.ItemPanel>
        {option.tags.map((item) => {
          const tooltipText = getVariableTagTooltip(
            item.tag,
            currentTime.clone(),
            item.offset ?? 0,
            timezone,
          );

          const isTagDisabled = disabledVariableTags.includes(item.tag);
          const clsName = classNames([
            'merge-tag-item',
            { 'disabled-tag': isTagDisabled },
          ]);

          const tagElement = (
            <div
              key={item.key}
              className={clsName}
              {...accessibleOnClick(() => {
                if (!isTagDisabled) {
                  handleOnAddVariableTag(item.tag);
                }
              })}
            >
              <span>{item.tag}</span>
            </div>
          );

          return tooltipText ? (
            <OverlayTooltip
              text={tooltipText}
              placement={Placement.Right}
              className="tooltip-md"
            >
              {tagElement}
            </OverlayTooltip>
          ) : (
            tagElement
          );
        })}
      </Accordion.ItemPanel>
    </Accordion.Item>
  </Accordion>
);

export default VariableTagCategory;
