import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/root-reducer';
import UpgradePlanErrorModal from './upgrade-plan-error-modal';

const mapStateToProps = (state: RootState) => ({
  adminFirstName: state.home?.adminDetails?.firstName,
  senderFirstName: state.home?.firstName,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: VoidFunction;
  planName: string;
  restrictionOn: string;
};

export default connector(UpgradePlanErrorModal);
