export function getHeaderHelper(locationPathName, Routes) {
  switch (locationPathName) {
    case Routes.SEQUENCE:
      return Routes.SEQUENCE[0].toUpperCase() + Routes.SEQUENCE.slice(1);
    case Routes.TASKS:
      return Routes.TASKS[0].toUpperCase() + Routes.TASKS.slice(1);
    case Routes.AGENCY_CLIENT:
      return 'Client Management';
    case Routes.UNIFIED_INBOX:
      return 'Unified Inbox';
    case Routes.PROSPECTS:
      return Routes.PROSPECTS[0].toUpperCase() + Routes.PROSPECTS.slice(1);
    case Routes.TEMPLATES:
      return Routes.TEMPLATES[0].toUpperCase() + Routes.TEMPLATES.slice(1);
    case Routes.REPORTS:
      return 'Analytics';
    case Routes.EMAIL_INSIGHTS:
      return '1:1 Email Insights';
    case Routes.EMAIL_WARMUP:
      return (
        Routes.EMAIL_WARMUP[0].toUpperCase() + Routes.EMAIL_WARMUP.slice(1)
      );
    case Routes.GROWTH_HUB:
      return 'Growth Hub';
    case Routes.AGENCY_PORTAL:
      return 'Agency Portal';
    case Routes.SETTINGS:
      return Routes.SETTINGS[0].toUpperCase() + Routes.SETTINGS.slice(1);
    case Routes.LEADS:
      return 'Lead Finder';
    default:
      return '';
  }
}

export function routeStateChange({
  getHeader,
  path,
  Routes,
  sequenceRoutes,
  setSingleSequenceHeader,
  emailWarmupRoutes,
  setEmailWarmupHeader,
  setEmailWarmupHeaderWithReport,
  setHeaderTitle,
}) {
  if (
    getHeader(path?.[1]).toLowerCase() === Routes.SEQUENCE &&
    sequenceRoutes.includes(path?.[3])
  ) {
    setSingleSequenceHeader(true);
  } else {
    setSingleSequenceHeader(false);
  }

  if (getHeader(path?.[1]).toLowerCase() === Routes.EMAIL_WARMUP) {
    if (emailWarmupRoutes.includes(path?.[3])) {
      setEmailWarmupHeader(true);
      setEmailWarmupHeaderWithReport(true);
    } else {
      setEmailWarmupHeader(true);
      setEmailWarmupHeaderWithReport(false);
    }
  } else {
    setEmailWarmupHeader(false);
    setEmailWarmupHeaderWithReport(false);
  }
  // changing header as the page changes
  setHeaderTitle(getHeader(path?.[1]));
}
