import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import { getIsRequestPending } from '../../../email-account/components/update-email-account/helpers';
import {
  deleteDNSRequest,
  getDNSRequest,
  saveDNSRequest,
} from '../../extra-actions';
import DomainConfiguration from './domain-configuration';
import { showGettingStartedVideoModal } from '../../../../../home/home-slice';

const mapStateToProps = (state: RootState) => ({
  isLoading: getIsRequestPending(state.whitelabel.saveDNSRequest.status),
  dnsDetails: state.whitelabel.dnsDetails,
  getDNSDetailsRequestStatus: state.whitelabel.getDNSRequest.status,
  saveDNSRequestStatus: state.whitelabel.saveDNSRequest.status,
  deleteDNSRequestStatus: state.whitelabel.deleteDNSRequest.status,

  planType: state.home.subscription?.planType,
  planId: state.home.subscription?.planId,
});

const mapDispatchToProps = {
  getDNSRequest: () => getDNSRequest(),
  saveDNSRequest: (domain: string) => saveDNSRequest({ domain }),
  deleteDNSRequest: () => deleteDNSRequest(),
  showGettingStartedVideoModal: (show: boolean) =>
    showGettingStartedVideoModal({ show }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(DomainConfiguration);
