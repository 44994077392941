export enum Variant {
  Add = 'add',
  Remove = 'remove',
  Default = 'default',
}

export enum Color {
  Geekblue = 'geekblue',
  Blue = 'blue',
  Red = 'red',
  Volcano = 'volcano',
  Orange = 'orange',
  Gold = 'gold',
  Lime = 'lime',
  Green = 'green',
  Cyna = 'cyan',
  Magenta = 'magenta',
}
