import React from 'react';
import { supportUrls } from '../../../../../../../shared/utils/urls';
import { isWhiteLabel } from '../../../../../../../shared/utils/user-details';

const anchorLinkAdd = () => (
  <>
    <span>1. Download</span>
    <a
      className="blue-txt-6 ml-1 mr-1"
      href={
        isWhiteLabel()
          ? supportUrls.bulkImportSampleCSVWhitelabel
          : supportUrls.bulkImportSampleCSV
      }
      download
    >
      <span>Sample CSV</span>
    </a>
    <span>file</span>
  </>
);

const redirectLink = () => (
  <>
    <span>6. Still have questions? Read step-by-step</span>

    <a
      className="blue-txt-6 ml-1"
      href={supportUrls.bulkImportGuide}
      target="_blank"
      download
      rel="noreferrer"
    >
      <span>guide.</span>
    </a>
  </>
);

export const arr = () => [
  {
    id: 1,
    value: '1. Download',
    isSelected: true,
    linkValue: anchorLinkAdd(),
  },
  {
    id: 2,
    value:
      '2. Fill details for all connection fields.(Email account, SMTP host, etc.)',
    isSelected: false,
  },
  {
    id: 3,
    value:
      '3. Fill details for email account settings.(Sending interval, daily limit, etc.)',
    isSelected: false,
  },
  {
    id: 4,
    value: '4. Email account field should be valid and not left blank.',
    isSelected: false,
  },
  {
    id: 5,
    value: '5. Import your CSV file and click on Next.',
    isSelected: false,
  },
  {
    id: 6,
    value: '6. Still have questions? Read step-by-step',
    isSelected: true,
    URL: redirectLink(),
  },
];
