import React from 'react';

const DotsVerticals = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16211 3.68191C4.16211 2.979 4.73193 2.40918 5.43484 2.40918C6.13774 2.40918 6.70756 2.979 6.70756 3.68191C6.70756 4.38481 6.13774 4.95463 5.43484 4.95463C4.73193 4.95463 4.16211 4.38481 4.16211 3.68191Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16211 7.49978C4.16211 6.79687 4.73193 6.22705 5.43484 6.22705C6.13774 6.22705 6.70756 6.79687 6.70756 7.49978C6.70756 8.20269 6.13774 8.77251 5.43484 8.77251C4.73193 8.77251 4.16211 8.20269 4.16211 7.49978Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16211 11.3181C4.16211 10.6152 4.73193 10.0454 5.43484 10.0454C6.13774 10.0454 6.70756 10.6152 6.70756 11.3181C6.70756 12.021 6.13774 12.5909 5.43484 12.5909C4.73193 12.5909 4.16211 12.021 4.16211 11.3181Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.98047 3.68191C7.98047 2.979 8.55029 2.40918 9.2532 2.40918C9.9561 2.40918 10.5259 2.979 10.5259 3.68191C10.5259 4.38481 9.9561 4.95463 9.2532 4.95463C8.55029 4.95463 7.98047 4.38481 7.98047 3.68191Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.98047 7.50027C7.98047 6.79736 8.55029 6.22754 9.2532 6.22754C9.9561 6.22754 10.5259 6.79736 10.5259 7.50027C10.5259 8.20317 9.9561 8.77299 9.2532 8.77299C8.55029 8.77299 7.98047 8.20317 7.98047 7.50027Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.98047 11.3181C7.98047 10.6152 8.55029 10.0454 9.2532 10.0454C9.9561 10.0454 10.5259 10.6152 10.5259 11.3181C10.5259 12.021 9.9561 12.5909 9.2532 12.5909C8.55029 12.5909 7.98047 12.021 7.98047 11.3181Z"
      fill="#6B7280"
    />
  </svg>
);

export default DotsVerticals;
