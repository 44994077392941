import React from 'react';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import MyProfileContent from './components/my-profile-content';

const MyProfile: React.FC<IProps> = ({
  getMyProfileRequestStatus,
  myProfile,
  sendGetMyProfileRequest,
  isEmpty,
}) => {
  if (getMyProfileRequestStatus !== RequestStatus.Succeeded && isEmpty) {
    return null;
  }

  return (
    <MyProfileContent
      getMyProfileRequest={sendGetMyProfileRequest}
      myProfile={myProfile}
    />
  );
};

export default MyProfile;
