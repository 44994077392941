import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import { ProductTour } from '../../../../shared/enums/product-tour';
import { getNavItemIcon } from './get-nav-item-icon';

const NavItem = ({
  eventKey,
  route,
  text,
  tooltipText,
  count,
  hasVisitedAgencyPortal,
}) => {
  const history = useHistory();

  const navItemClassName = classNames([
    'sidenav__item',
    {
      active:
        eventKey !== 'referral'
          ? history.location.pathname.startsWith(route)
          : false,
    },
  ]);

  const badgeClassName = classNames([
    'sidenav__badge',
    {
      sm: count < 10,
      md: count >= 10 && count < 100,
      lg: count >= 100,
    },
  ]);

  const dotBadgeClassName = classNames(['sidenav__dot-badge']);

  const badge = (
    <div className={badgeClassName}>
      <span>{count < 100 ? count : '99+'}</span>
    </div>
  );

  const agencyClientbadge = (
    <div className="sidenav__rect-text-badge">
      <span>New</span>
    </div>
  );

  const getNavItemId = () => {
    if (eventKey === 'sequence') {
      return ProductTour.NavigateToSequences;
    }
    if (eventKey === 'leads') {
      return ProductTour.NavigateToLeadFinder;
    }

    return null;
  };

  const dotBadge = <div className={dotBadgeClassName} />;

  const shouldShowBadgeForUnifiedInbox =
    eventKey === 'unifiedInbox' && count > 0;

  const shouldShowBadgeForAgencyPortal =
    eventKey === 'agencyPortal' && !hasVisitedAgencyPortal;

  const shouldShowBadgeForAgencyClient = eventKey === 'agency-client';

  const navItem = (
    <li id={getNavItemId()} key={eventKey} className={navItemClassName}>
      <div className="sidenav__icon">
        {getNavItemIcon(eventKey)}
        {shouldShowBadgeForUnifiedInbox && badge}
        {shouldShowBadgeForAgencyPortal && dotBadge}
        {shouldShowBadgeForAgencyClient && agencyClientbadge}
      </div>

      <div className="sidenav__text">{text}</div>
    </li>
  );
  if (route === '') {
    return (
      <a className="sidenav__item-wrapper nav-item-container badge-referral">
        <OverlayTooltip
          id={eventKey}
          text={tooltipText}
          placement={Placement.Right}
          className="sidenav__item-tooltip"
        >
          <li className="sidenav__item ">
            <div className="sidenav__icon celloClass ">{navItem}</div>
          </li>
        </OverlayTooltip>
      </a>
    );
  }
  return (
    <Link
      className="sidenav__item-wrapper nav-item-container"
      to={route}
      key={eventKey}
    >
      <OverlayTooltip
        id={eventKey}
        text={tooltipText}
        placement={Placement.Right}
        className="sidenav__item-tooltip"
      >
        {navItem}
      </OverlayTooltip>
    </Link>
  );
};

export default NavItem;
