import { loadStripe } from '@stripe/stripe-js';
import api from '../../../api';

type IProps = {
  response: any;
  apiEndPoint?: string;
  uuid?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const handle3dSecureCardPayment = async (body: IProps) => {
  const { response, apiEndPoint, uuid } = body;

  let isError = false;
  let serverResponse = null;

  const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

  // Use Stripe.js to handle the required card action
  const {
    error: errorAction,
    paymentIntent,
  } = await stripePromise.confirmCardPayment(
    response.payment_intent_client_secret,
  );

  if (errorAction) {
    isError = true;
  } else {
    // The card action has been handled
    // The PaymentIntent can be confirmed again on the server
    if (apiEndPoint) {
      const res = await api.post(apiEndPoint, {
        paymentIntentId: paymentIntent.id,
        uuid,
      });

      serverResponse = res;
    }
    return { isError, serverResponse };
  }
  return { isError };
};
