import moment from 'moment-timezone';
import { RedirectUser } from '../../../../../../components/settings/components/billing-subscription/enums/redirect-user';
import SubscriptionStatus from '../../../../../../components/settings/components/billing-subscription/enums/subscription-status';
import {
  PlanType,
  SubscriptionPlans,
} from '../../../../../utils/subscription-plans';
import { isValueBasedPlan } from '../../../../../../components/settings/components/billing-subscription/components/outreach-plans/utils/helper';

export const getSubscriptionPlanClassName = (
  subscriptionPlanStatus: SubscriptionStatus,
) =>
  subscriptionPlanStatus === SubscriptionStatus.Canceled
    ? 'canceled-plan'
    : 'paused-plan';

export const isUserInPaidPlan = (planCode: SubscriptionPlans) =>
  planCode !== SubscriptionPlans.TRIAL && planCode !== SubscriptionPlans.FREE;

export const redirectUser = (rediract) =>
  rediract === RedirectUser.EmailAccounts
    ? window.open('/settings/email-accounts', '_blank')
    : window.open('/settings/team', '_blank');

export const checkIsLtdPlan = (planType: PlanType): boolean =>
  planType === PlanType.LTD;

export const checkNewLtdPlanUser = (planType: PlanType): boolean =>
  planType === PlanType.LTD2;

export const checkIsUserPlan = (planType: PlanType): boolean =>
  planType === PlanType.User;

export const checkIsSubscriptionPausedOrCancelled = (subscriptionPlanStatus) =>
  subscriptionPlanStatus === SubscriptionStatus.Paused ||
  subscriptionPlanStatus === SubscriptionStatus.Canceled;

export const getButtonTitle = (isUserSubscribed, planType, planCode) => {
  if (checkIsLtdPlan(planType)) {
    // if (planType === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
    //   return 'upgrade_plan';
    // }
    // if (
    //   planType === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME &&
    //   Number(userQuota) < 20
    // ) {
    // }
    return 'add_more_slots';
  }

  if (isUserSubscribed && !isValueBasedPlan(planCode)) {
    return 'modify_subscription';
  }

  if (isValueBasedPlan(planCode)) {
    return 'upgrade_subscription';
  }

  return 'upgrade_plan';
};

export const getBillOverview = (
  pausedAt,
  startAt,
  pauseEndsAt,
  nextBillingAt,
  planType,
  planCode,
  userQuota,
  planAmount,
  stripeCredits,
) => {
  const dateFormat = 'DD MMM, yyyy';
  return [
    {
      title: pausedAt ? 'Paused on' : 'Start Date',
      value: moment(pausedAt || startAt).format(dateFormat),
      icon: '',
    },
    ...(!checkIsLtdPlan(planType)
      ? [
          {
            title: pauseEndsAt ? 'Resumes on' : 'Next Billing Date',
            value: moment(pauseEndsAt || nextBillingAt).format(dateFormat),
            icon: '',
          },
          {
            title: 'Next Billing Amount',
            value: `$${planAmount}`,
          },
          !isValueBasedPlan(planType) && {
            title: 'Number of Slots',
            value: userQuota,
          },
          stripeCredits > 0 && {
            title: 'Credit Balance',
            value: `$${stripeCredits}`,
            showTooltip: true,
            tooltipText:
              'The credit balance automatically applies to the next payment.',
          },
        ]
      : [
          {
            title: 'Number of Slots',
            value: userQuota,
          },
        ]),
  ];
};

export const getLtdExpiryDateLabel = (expiryDate: string): string => {
  if (expiryDate) {
    const date = moment(expiryDate);

    return `Hurry up! Upgrade your plan now. (Only ${date.diff(
      moment(),
      'days',
    )} days left)`;
  }

  return '';
};

export const checkIsLtdPeriodExpired = (expiryDate: string): boolean =>
  moment(expiryDate).diff(moment(), 'days') >= 0;

export const shouldShowBillSummaryForLtd = ({
  planType,
  planCode,
  userQuota,
  ltdExpiryDate,
}) => {
  if (checkIsLtdPlan(planType)) {
    if (!checkIsLtdPeriodExpired(ltdExpiryDate)) {
      return true;
    }

    if (planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME) {
      return Number(userQuota) === 20;
    }
  }

  return false;
};

export const showBillSummary = ({
  planType,
  planCode,
  userQuota,
  ltdExpiryDate,
  isUserSubscribed,
  isInvoice,
}) => {
  if (planType) {
    if (checkNewLtdPlanUser(planType)) {
      return false;
    }

    if (checkIsLtdPlan(planCode)) {
      return shouldShowBillSummaryForLtd({
        planType,
        planCode,
        userQuota,
        ltdExpiryDate,
      });
    }

    return isUserSubscribed && !isInvoice;
  }

  return false;
};

export const shouldShowLtdExpireAlertOrModifyBtn = ({
  ltdExpiryDate,
}): boolean => {
  if (checkIsLtdPeriodExpired(ltdExpiryDate)) {
    return true;
  }
  return false;
};
