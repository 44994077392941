import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { DiscountType } from '../../../settings/components/billing-subscription/types/apply-coupon-code';

export const getPlansForCheckoutDropdown = (plans) => {
  const plansForDropdown = [];

  plans?.forEach((plan) => {
    const planName = plan?.planName.split('(')[0];

    plansForDropdown.push({
      key: plan.id.toString(),
      id: plan.id,
      planName,
      planCode: plan.planCode,
      amount: plan.amount,
    });
  });

  return plansForDropdown;
};

export const getEmailOutreachProLifetimePlan = (plans: any[]) =>
  plans.filter(
    (plan) => plan.planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME,
  )[0];

export const getNewLifetimePlans = (plans: any[]) =>
  plans.filter(
    (plan) =>
      plan.planCode !== SubscriptionPlans.EMAIL_OUTREACH_LIFETIME &&
      plan.planCode !== SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME,
  );

export const setUserSelectedPlansAndSlotsOnLoad = ({
  plans,
  setSelectedPlan,
  // setSlots,
  search,
  setProspects,
}) => {
  if (plans) {
    // const plan = getEmailOutreachProLifetimePlan(plans);
    const newPlans = getNewLifetimePlans(plans);

    // const slots = new URLSearchParams(search).get('slots');
    const planId = new URLSearchParams(search).get('planId');

    const selectedPlan = newPlans.filter(
      (item) => item.id === Number(planId),
    )?.[0];

    setSelectedPlan(selectedPlan || newPlans[0]);
    // setSlots(slots || 1);
    setProspects(
      newPlans.map((item) => ({
        key: item?.id?.toString(),
        id: item?.id,
        amount: item?.amount,
        planName: item?.planName,
      })),
    );
  }
};

export const formatProspect = (planName: string) => {
  const regex = /\d.*\d/g;
  const found = planName.match(regex);
  if (found) {
    return found[0];
  }
  return '';
};

export const getSubTotalDiscountAmount = ({
  discountType,
  discountValue,
  totalAmount,
}) =>
  discountType === DiscountType.Percentage
    ? (totalAmount * discountValue) / 100
    : discountValue;

export const getTotalAmountToPay = ({
  discountType,
  discountValue,
  totalAmount,
}) => {
  const discountAmout = getSubTotalDiscountAmount({
    discountType,
    discountValue,
    totalAmount,
  });

  return (totalAmount - discountAmout).toFixed(2);
};
