import React from 'react';
import { Images } from '../../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../../shared/components/images/image-icon';
import { FieldContentTabs } from '../types';
import TruncatedTextWithTooltip from '../../../../../../../shared/components/truncated-text-with-tooltip';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';

export const getCustomFieldColumns = (onCopyClickHandler, onKeyHandler) => [
  {
    dataField: 'label',
    text: 'Field Name',
    component: (cell) => <TruncatedTextWithTooltip string={cell} length={40} />,
  },
  {
    dataField: 'fieldHashedId',
    text: 'Field Id',
    component: (cell) => (
      <div className="field-hashed-id-container">
        <div className="field-hashed-id-content">{cell}</div>
        <span
          tabIndex={0}
          onKeyPress={onKeyHandler}
          role="button"
          onClick={() => {
            onCopyClickHandler(cell);
          }}
          className="field-hashed-id-icon"
        >
          <ImageIcon src={Images.Copy} />
        </span>
      </div>
    ),
  },
  {
    dataField: 'type',
    text: 'Field Type',
  },
];

export const generateActionsColumn = (tab: FieldContentTabs) => {
  let actions = null;

  if (hasPermission(Permissions.FIELD_UPDATE)) {
    actions = [
      {
        displayName: 'Edit',
        icon: 'pencil',
        key: 'edit',
        position: 'out',
      },
    ];
  }

  if (
    hasPermission(Permissions.FIELD_DELETE) &&
    tab === FieldContentTabs.CustomFields
  ) {
    actions = [
      ...actions,
      {
        displayName: 'Delete',
        icon: 'trash',
        key: 'delete',
        position: 'out',
      },
    ];
  }

  return actions;
};

export const getDefaultTableWrapper = (
  t: FieldContentTabs,
  customFieldPaginationCount: number,
  defaultFieldPaginationCount: number,
) => {
  switch (t) {
    case FieldContentTabs.CustomFields:
      return customFieldPaginationCount > 25 ? 'paginated-table' : '';
    case FieldContentTabs.SystemFields:
      return defaultFieldPaginationCount > 25 ? 'paginated-table' : '';
    default:
      return '';
  }
};
