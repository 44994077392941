import React from 'react';
import { Col } from 'react-bootstrap';
import type { IProps } from './page-header-container';

const PageHeader: React.FC<IProps> = ({ title, heading, children }) => (
  <>
    <Col className="page-header">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="semibold-3">{title}</h2>
        {heading}
      </div>
    </Col>
    {children}
  </>
);

export default PageHeader;
