export enum Source {
  ProspectImportCsv = 'prospect-import-csv',
  SequenceProspectImportCsv = 'sequence-prospect-import-csv',
  ProspectManually = 'prospect-manually',
  SequenceProspectSearchAndAdd = 'sequence-prospect-search-and-add',
  OnboardingVideo = 'onboarding-video',
  OnboardingChecklist = 'onboarding-checklist',
  SequenceTab = 'sequence-create',
  System = 'system',
}
