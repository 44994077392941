import React from 'react';

import type { IProps } from './email-accounts-header-container';
import {
  OverlayPopover,
  Placement,
  TriggerType,
} from '../../../../../../shared/design-system/components/overlay';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

const EmailAccountsHeader: React.FC<IProps> = ({ emailsCount }) => {
  const renderEmailAccounts = (
    type: 'active' | 'inactive',
    label: string,
    count: number,
  ) => (
    <div className="connect-email-accounts-item">
      <div className="connect-email-accounts-item--inner">
        <div className={type} />
        <p>{label}</p>
      </div>
      <p>{count}</p>
    </div>
  );

  return (
    <GlobalSettings.Header>
      <GlobalSettings.HeaderLeft>
        <GlobalSettings.HeaderTitle title="Email Accounts" />
        <div className="email-accounts-header">
          <OverlayPopover
            className="p-0 connected-email-accounts-popover"
            content={
              <div className="connected-email-accounts">
                {renderEmailAccounts(
                  'active',
                  'Active Accounts',
                  emailsCount.connectedEmailsCount,
                )}
                {renderEmailAccounts(
                  'inactive',
                  'Inactive Accounts',
                  emailsCount.disconnectedEmailsCount,
                )}
              </div>
            }
            trigger={TriggerType.Hover}
            placement={Placement.BottomStart}
          >
            <div className="connected-email-accounts-badge">
              <span>
                {emailsCount.connectedEmailsCount +
                  emailsCount.disconnectedEmailsCount}
              </span>
            </div>
          </OverlayPopover>
        </div>
      </GlobalSettings.HeaderLeft>
    </GlobalSettings.Header>
  );
};

export default EmailAccountsHeader;
