import React, { useEffect, useRef, useState } from 'react';
import { FileCheck, Pencil, Trash } from '@saleshandy/icons';
import { SortOrder } from 'react-bootstrap-table-next';
import classNames from 'classnames';

import type { IProps } from './email-accounts-table-container';

import Table from '../../../../../../shared/design-system/components/organisms/table';
import EmailSelectedRowInfoBanner from './components/email-selected-info-banner';

import { getEmailAccountsColumns } from './utils/get-email-accounts-columns';
import { PaginationShowHide } from '../../../../../../shared/design-system/components/organisms/table/types';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { EmailAccountsSortBy } from '../../../../enums/email-account';
import { getColDataFieldForSorting } from './utils/get-col-data-field-for-sorting';
import {
  checkIsTableLastPage,
  getShouldShowSelectedRowInfoBanner,
  isCurrentPageUnderTheSelectAllPage,
} from './utils/helper';
import { constants } from '../../../../../../shared/enums/constants';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';

const EmailAccountsTable: React.FC<IProps> = ({
  emailAccounts,
  paginationOptions,
  getEmailAccountsRequestStatus,
  filters,
  onFiltersChange,
  onAction,
  onEmailAccountsSwitchChangeHandler,
  onSmtpImapModalShow,
  redirectToGeneralSettings,

  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  onBulkSelectEmailAccounts,
  clearEmailAccountsSelection,
  selectAllEmailAccounts,
  selectSingleEmailAccount,
  resetSelected,
  handleResetSelected,
}) => {
  const emailAccountTableRef = useRef<any>(null);
  const [
    isBulkEmailAccountsSelected,
    setIsBulkEmailAccountsSelected,
  ] = useState<boolean>(false);

  // * handle table pagination change
  const onPaginationOptionsChange = ({ page, limit }) => {
    if (filters.page === page && filters.limit === limit) {
      return;
    }
    onFiltersChange({ page, limit });
  };

  // * handle table sorting
  const onSort = (key: EmailAccountsSortBy, order: SortOrder) => {
    const newFilters = { sortByKey: key, order: order === 'asc' ? 1 : -1 };

    if (
      newFilters.sortByKey !== filters.sortByKey ||
      newFilters.order !== filters.order
    ) {
      onFiltersChange(newFilters);
    }
  };

  // * handle select/deselect all email accounts within a page
  const handleSelectDeselectEmailAccounts = (rows: any, status: boolean) => {
    selectAllEmailAccounts({ rows, status });
    setIsBulkEmailAccountsSelected(false);
  };

  // * handle bulk select email accounts from banner
  const handleSelectAllEmailAccount = () => {
    if (
      checkIsTableLastPage(
        paginationOptions.currentPage,
        paginationOptions.itemsPerPage,
      )
    ) {
      emailAccountTableRef?.current?.selectAllCurrentPageRows();
    } else {
      emailAccountTableRef?.current?.resetSelectedRows();
    }

    onBulkSelectEmailAccounts({
      isAllEmailAccountsSelected: true,
      deSelectedEmailAccountIds: [],
    });
  };

  // * handle clear selected email accounts
  const handleClearSelection = (resetSelectedRows = false) => {
    clearEmailAccountsSelection();
    handleSelectDeselectEmailAccounts(emailAccounts, false);

    if (
      isCurrentPageUnderTheSelectAllPage(
        bulkSelectedEmailAccounts,
        paginationOptions?.currentPage,
        paginationOptions?.itemsPerPage,
      ) ||
      resetSelectedRows
    ) {
      emailAccountTableRef.current?.resetSelectedRows();
    }
  };

  // * handle reset email accounts selected via select all
  const handleResetSelectAllEmailAccounts = () => {
    const shouldResetSelection = isCurrentPageUnderTheSelectAllPage(
      bulkSelectedEmailAccounts,
      paginationOptions?.currentPage,
      paginationOptions?.itemsPerPage,
    );

    if (!shouldResetSelection && shouldResetSelection !== undefined) {
      handleClearSelection();
    }
  };

  // * handle select single email account
  const handleSelectSingleEmailAccount = (row: any, status: boolean) => {
    selectSingleEmailAccount({ row, status });
    handleResetSelectAllEmailAccounts();
    setIsBulkEmailAccountsSelected(false);
  };

  // * select all email accounts on current page
  const selectAllCurrentPageRows = () => {
    const lastPage =
      constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT / paginationOptions?.itemsPerPage;

    if (
      paginationOptions?.totalItems <=
        constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT ||
      lastPage > paginationOptions?.currentPage
    ) {
      emailAccountTableRef.current?.selectAllCurrentPageRows();
    } else {
      emailAccountTableRef.current?.resetSelectedRows();
    }
  };

  const actions = [
    {
      key: 'edit',
      iconElement: <Pencil />,
      displayName: 'Edit',
      ...(!hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) &&
      !hasPermission(Permissions.EMAIL_ACCOUNT_SET_AS_DEFAULT)
        ? {
            position: 'out',
          }
        : {}),
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
      key: 'delete',
      iconElement: <Trash />,
      displayName: 'Delete',
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SET_AS_DEFAULT) && {
      key: 'setAsDefault',
      iconElement: <FileCheck />,
      displayName: 'Set as Default',
    },
  ].filter((opt) => opt);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEmailAccountsRequestStatus,
      onSuccess: () => {
        if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
          selectAllCurrentPageRows();
        }
      },
    });
  }, [getEmailAccountsRequestStatus]);

  useEffect(
    () => () => {
      handleClearSelection();
    },
    [],
  );

  const isLoading = getIsRequestPending(getEmailAccountsRequestStatus);

  const tableWrapperClasses = classNames(['email-accounts-table']);

  return (
    <>
      {getShouldShowSelectedRowInfoBanner(
        selectedEmailAccounts,
        bulkSelectedEmailAccounts,
        isBulkEmailAccountsSelected,
      ) && (
        <div className="email-account-banner">
          <EmailSelectedRowInfoBanner
            selectedEmailAccounts={selectedEmailAccounts}
            bulkSelectedEmailAccounts={bulkSelectedEmailAccounts}
            totalEmailAccounts={paginationOptions?.totalItems}
            handleSelectAllEmailAccount={handleSelectAllEmailAccount}
            handleClearSelection={() => handleClearSelection(true)}
          />
        </div>
      )}

      <Table
        columns={getEmailAccountsColumns({
          itemCount: paginationOptions?.itemCount ?? 0,
          onSort,
          onEmailAccountsSwitchChangeHandler,
          redirectToGeneralSettings,
          onSmtpImapModalShow,
        })}
        data={emailAccounts}
        actions={actions}
        onAction={onAction}
        sort={{
          dataField: getColDataFieldForSorting(filters.sortByKey),
          order: filters.order === 1 ? 'asc' : 'desc',
        }}
        paginationOptions={{
          options: {
            limit: paginationOptions?.itemsPerPage,
            page: paginationOptions?.currentPage,
            totalElements: paginationOptions?.totalItems,
          },
        }}
        pagination={PaginationShowHide.SHOW}
        isNewPagination={true}
        onPaginationOptionsChange={onPaginationOptionsChange}
        headerVisibleForGenerateColumn={true}
        isLoading={isLoading}
        tableWrapperClasses={tableWrapperClasses}
        onRowSelect={handleSelectSingleEmailAccount}
        onRowSelectAll={handleSelectDeselectEmailAccounts}
        resetSelected={resetSelected}
        handleResetSelected={handleResetSelected}
        deselectedRows={bulkSelectedEmailAccounts?.deSelectedEmailAccountIds}
        tableRef={(refValue: any) => {
          emailAccountTableRef.current = refValue;
        }}
      />
    </>
  );
};

export default EmailAccountsTable;
