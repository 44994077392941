import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PauseAccountManager } from '..';
import { getUserSettingsRequest } from '../../../../../components/home/extra-actions';
import { resetResumeSubscriptionResponse } from '../../../../../components/settings/components/billing-subscription/billing-subscription-slice';
import { resumeSubscriptionRequest } from '../../../../../components/settings/components/billing-subscription/extra-actions';
import { ReactivateResumeSubscriptionRequest } from '../../../../../components/settings/components/billing-subscription/types';
import { RootState } from '../../../../../store/root-reducer';

const mapStateToProps = (state: RootState) => ({
  sendGetResumeSubscriptionRequestStatus:
    state.billingAndSubscription.resumeSubscriptionRequest.status,
  sendGetResumeSubscriptionRequestError:
    state.billingAndSubscription.resumeSubscriptionRequest.error,
  sendGetResumeSubscriptionResponse:
    state.billingAndSubscription.resumeSubscriptionResponse,
  subscriptionPlanStatus: state.home.subscription?.status,
  pauseEndsAt: state.home.subscription?.pauseEndsAt,
  customerId: state.home.subscription?.customerId,
  firstName: state.home.adminDetails?.firstName,
});

const mapDispatchToProps = {
  resetResumeSubscriptionResponse: () => resetResumeSubscriptionResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendResumeSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => resumeSubscriptionRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    bindActions: (...args: any[]) => any;
    t: (x: string) => string;
  };

export type LocationStateType = {
  isModifySubscription: boolean;
};

export default connector(PauseAccountManager);
