import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/root-reducer';
import Logo from './logo';

const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  logoTextStyles?: Record<string, string>;
  isTertiary?: boolean;
};

export default connector(Logo);
