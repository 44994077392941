import { Field } from '../../../../settings/types/field';

import { getProspectSystemAttributes } from './get-prospect-system-attributes';

export const getDefaultSelectedProspectAttributes = (fields: Field[]) =>
  getProspectSystemAttributes(fields).filter((attr) => attr?.isDefault);

export const getProspectTableColumnsFromLocalStorage = () =>
  JSON.parse(localStorage.getItem('prospectTableColumns'));

export const setProspectTableColumnsInLocalStorage = (fields) => {
  localStorage.setItem('prospectTableColumns', JSON.stringify(fields));
};

export const removeProspectTableColumnsFromLocalStorage = () => {
  localStorage.removeItem('prospectTableColumns');
};
