import React, { RefObject } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { UUID } from 'react-accessible-accordion/dist/types/components/ItemContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Tag from '../../../../../../shared/design-system/components/tag';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Schedule } from '../../../../types/schedule';
import UpdateSchedule from '../update-schedule';
import { IProps, IState } from './types';

class ScheduleList extends React.Component<IProps, IState> {
  private days: Array<string> = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  private readonly accordionItemPanel: RefObject<HTMLDivElement>;

  toggleAccordionCounter = 0;

  constructor(props) {
    super(props);
    this.accordionItemPanel = React.createRef<HTMLDivElement>();

    this.state = {
      activeUUIDs: [''],
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { schedules } = this.props;

    if (schedules.length > prevProps.schedules.length) {
      this.accordionItemPanel.current.click();
    }
  }

  onAccordionItemClick(uuids: UUID[]) {
    this.setState({
      activeUUIDs: uuids,
    });
  }

  onTagClick(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    schedule: Schedule,
  ) {
    const { setDefaultHandler } = this.props;
    e.stopPropagation();
    if (!schedule.isDefault) {
      setDefaultHandler(schedule);
    }
  }

  render() {
    const { onDeleteHandler, schedules } = this.props;
    const { activeUUIDs } = this.state;

    const getTooltip = (tooltip: string) => (
      <Tooltip id="popover-basic" className="bs-tooltip-inner">
        {tooltip}
      </Tooltip>
    );

    const accordionScheduleItem = schedules.map((schedule, index) => (
      <AccordionItem uuid={`${schedule.id}`} key={schedule.id}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p ref={index === 0 ? this.accordionItemPanel : null}>
              <OverlayTrigger
                placement="bottom"
                overlay={getTooltip(schedule.name)}
              >
                <span>
                  {schedule.name.length > 20
                    ? `${schedule.name.slice(0, 20)}....`
                    : schedule.name}
                </span>
              </OverlayTrigger>
              <Tag
                value={schedule.isDefault ? 'Default' : 'Make It Default'}
                color={schedule.isDefault ? Tag.Color.Green : undefined}
                className={
                  schedule.isDefault ? 'bs-ml-10' : 'tag-visibility bs-ml-10'
                }
                onClick={
                  hasPermission(Permissions.SCHEDULE_UPDATE) &&
                  ((e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                    this.onTagClick(e, schedule);
                  })
                }
              />
            </p>
            <small>{schedule.timezone}</small>
            <div>
              {this.days.map((day, index) => {
                const disabled =
                  JSON.parse(schedule.timeSlots)[(index + 1) % 7].slots
                    .length === 0;

                return (
                  <Tag
                    key={index}
                    value={day}
                    className="bs-mr-5"
                    variant={Tag.Variant.Default}
                    color={disabled ? undefined : Tag.Color.Blue}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <UpdateSchedule
            key={
              activeUUIDs.includes(`${schedule.id}`)
                ? `${schedule.id}-opened`
                : schedule.id
            }
            schedule={schedule}
            onDelete={onDeleteHandler}
          />
        </AccordionItemPanel>
      </AccordionItem>
    ));

    return (
      <>
        <Accordion
          allowZeroExpanded={true}
          allowMultipleExpanded={false}
          className="bs-accordions accordion"
          onChange={(uuid) => this.onAccordionItemClick(uuid)}
        >
          {accordionScheduleItem}
        </Accordion>
      </>
    );
  }
}

export default ScheduleList;
