import NotificationHelper from './notification-helper';

const notification = new NotificationHelper();

export type { NotificationOptions } from './notification-manager';
export type { NotificationOptionsWithoutVariant } from './notification-helper';
export {
  Position,
  default as NotificationManager,
} from './notification-manager';
export { Variant } from './notification';
export default notification;
