import classNames from 'classnames';
import { Size } from './enums';

export const getSizeSubClass = (size: Size): string => {
  return classNames({
    sm: size === Size.Small,
    md: size === Size.Medium,
    lg: size === Size.Large,
  });
};
