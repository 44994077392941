import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import {
  acceptInvitation,
  verifyInvitaitonToken,
} from '../../helpers/auth.api';
import { AcceptInvitationRequestPayload } from '../../types';

export const verifyInvitationTokenRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>(
  'acceptInvitationCallback/verifyInvitationTokenRequest',
  async (code, thunkAPI) => {
    try {
      return await verifyInvitaitonToken(code);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const acceptInvitationRequest = createAsyncThunk<
  ResponseSuccess,
  AcceptInvitationRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('signup/acceptInvitationRequest', async (args, thunkAPI) => {
  try {
    return await acceptInvitation(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
