import React from 'react';
import classNames from 'classnames';
import type { IProps } from './logo-container';

const Logo: React.FC<IProps> = ({
  agencyConfig,
  logoTextStyles = {},
  isTertiary = false,
}) => {
  if (agencyConfig && agencyConfig?.logo) {
    const {
      primary,
      tertiary,
      showAgencyNameWithLogo,
    } = agencyConfig?.logo || {
      primary: '',
      tertiary: '',
      showAgencyNameWithLogo: false,
    };

    const logoPrimary = primary ? <img src={primary} alt="Logo" /> : <></>;

    const logoTertiary = tertiary ? <img src={tertiary} alt="Logo" /> : <></>;

    const logo = isTertiary ? logoTertiary : logoPrimary;

    const logoWarpperClassName = classNames([
      'logo--primary',
      {
        'logo--with-text': showAgencyNameWithLogo,
      },
    ]);

    return (
      <div className={logoWarpperClassName}>
        {logo}
        {showAgencyNameWithLogo && (
          <span className="semibold-4" style={logoTextStyles}>
            {agencyConfig?.agencyName}
          </span>
        )}
      </div>
    );
  }

  return <></>;
};

export default Logo;
