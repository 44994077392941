import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { signupRequest, hideSignupRequestError } from '../signup/signup-slice';
import { acceptInvitationRequest } from '../invitation-signup/invitation-signup-slice';
import {
  AcceptInvitationRequestPayload,
  SignupRequestPayload,
} from '../../types/request-payload';
import LTDSignupForm from './ltd-signup-form';

const mapStateToProps = (state: RootState) => ({
  status: state.signup.signupRequest.status,
  message: state.signup.signupRequest.message,
  error: state.signup.signupRequest.error,
  showError: state.signup.signupRequest.showError,
  trackingId: state.invitationSignup.trackingId,
  firstName: state.invitationSignup.firstName,
  lastName: state.invitationSignup.lastName,
  acceptInvitationRequestStatus:
    state.invitationSignup.acceptInvitationRequest.status,
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {
  hideError: () => hideSignupRequestError(),
  sendSignupRequest: (payload: SignupRequestPayload) => signupRequest(payload),
  sendAcceptInvitationRequest: (payload: AcceptInvitationRequestPayload) =>
    acceptInvitationRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    showVerificationCodeComponent?: () => void;
    forInvitation?: boolean;
    token?: string;
    email?: string;
    welcomeHeader: string;
    welcomeBody: string;
    showPasswordValidationMessage?: boolean;
    showTermOfServiceAndPrivacyPolicy?: boolean;
    showLoginLink?: boolean;
    isLtdUser?: boolean;
    setUserEmail: (email: string) => void;
  };

export default withRouter(connector(LTDSignupForm));
