import React from 'react';
import Lottie, { LottieComponentProps } from 'lottie-react';
import loaderData from './lf20_j3bZ7k.json';

export type LoaderProps = Omit<LottieComponentProps, 'animationData'>;

const Loader: React.FC<LoaderProps> = ({ ...rest }) => (
  <Lottie {...rest} animationData={loaderData} />
);

export default Loader;
