import React from 'react';
import Icon from '../../../../../../../shared/design-system/components/atoms/icon';
import {
  Overlay,
  Placement,
  Tooltip,
} from '../../../../../../../shared/design-system/components/overlay';
import Tag from '../../../../../../../shared/design-system/components/tag';

const CustomDomainValidIcon = ({ customDomain }) => {
  const iconConfig = {
    identifier: customDomain.isValid ? 'check-o' : 'danger',
    color: customDomain.isValid ? 'lime-txt-8' : 'red-txt-6',
    text: customDomain.isValid ? 'Valid' : 'Invalid',
  };
  return (
    <div className="custom-domain-domain-cell">
      <span>{customDomain.domain}</span>
      <Overlay
        placement={Placement.Right}
        overlay={<Tooltip id="hola-tooltip" text={iconConfig.text} />}
      >
        <Icon
          identifier={iconConfig.identifier}
          className={iconConfig.color}
          style={{ marginLeft: '.5rem' }}
        />
      </Overlay>
      {customDomain.isDefault ? (
        <Tag value="Default" color={Tag.Color.Green} className="ml-2" />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomDomainValidIcon;
