import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IProps } from './types';
import Button from '../../../../design-system/components/atoms/button';
import { Images } from '../../../../app-constants';
import ImageIcon from '../../../images/image-icon';

const SMTPModal: React.FC<IProps> = ({ onClose, skipConnectEmailAccount }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={true}
      backdrop="static"
      className="smtp-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div>
            <ImageIcon src={Images.ServerBlue} alt="sent-icon" />
            <span className="semibold-3 ml-3">Connect SMTP</span>
          </div>
        </div>

        <div className="mt-3">
          <p className="regular-2 popover-arrow-color-txt">
            {t('messages.smtp_message_one')}
          </p>
          <p className="regular-2 popover-arrow-color-txt">
            {t('messages.smtp_message_two')}
          </p>
          <p className="regular-2 popover-arrow-color-txt">
            {t('messages.smtp_message_three')}
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant={Button.Variant.Outlined}
          theme={Button.Theme.Ghost}
          onClick={onClose}
        >
          {t('labels.cancel')}
        </Button>
        <Button
          variant={Button.Variant.Primary}
          onClick={skipConnectEmailAccount}
        >
          {t('labels.get_started')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SMTPModal;
