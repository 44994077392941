import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateUpdateCustomFieldModal from '../..';
import { UpdateProspectCustomFieldModalProps } from './types';

const UpdateProspectCustomFieldModal: React.FC<UpdateProspectCustomFieldModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { customField } = props;
  return (
    <CreateUpdateCustomFieldModal
      {...props}
      disableDropdownBox={true}
      modalHeaderTitle={
        customField.isDefault
          ? t('labels.update_system_field')
          : t('labels.update_custom_field')
      }
      showWarning={true}
    />
  );
};

export default UpdateProspectCustomFieldModal;
