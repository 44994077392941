import React from 'react';

export const CircleAdd = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 2.66659C5.05513 2.66659 2.66732 5.0544 2.66732 7.99992C2.66732 10.9454 5.05513 13.3333 8.00065 13.3333C10.9462 13.3333 13.334 10.9454 13.334 7.99992C13.334 5.0544 10.9462 2.66659 8.00065 2.66659ZM1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33398 7.99992C5.33398 7.63173 5.63246 7.33325 6.00065 7.33325H10.0007C10.3688 7.33325 10.6673 7.63173 10.6673 7.99992C10.6673 8.36811 10.3688 8.66659 10.0007 8.66659H6.00065C5.63246 8.66659 5.33398 8.36811 5.33398 7.99992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 5.33325C8.36884 5.33325 8.66732 5.63173 8.66732 5.99992V9.99992C8.66732 10.3681 8.36884 10.6666 8.00065 10.6666C7.63246 10.6666 7.33398 10.3681 7.33398 9.99992V5.99992C7.33398 5.63173 7.63246 5.33325 8.00065 5.33325Z"
      fill="currentColor"
    />
  </svg>
);
