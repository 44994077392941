import React from 'react';
import TopLogo from './top-logo';

const TopWelcome = (props) => (
  <>
    <TopLogo prefix={props.prefix} />
    <div className="top-welcome">{props.children}</div>
  </>
);

TopWelcome.Header = function Header(props) {
  return <h3 className="top-welcome--header semibold-5">{props.children}</h3>;
};

TopWelcome.Body = function Body(props) {
  return <p className="top-welcome--body regular-2">{props.children}</p>;
};

export default TopWelcome;
