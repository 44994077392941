import React from 'react';
import Input from '../../../design-system/components/input';

type IProps = {
  search: string;
  onSearch: (value: string) => void;
};

const TagsDropdownSearchInput: React.FC<IProps> = ({ search, onSearch }) => (
  <Input
    placeholder="Search"
    icons={[
      {
        place: Input.IconPlace.Left,
        identifier: 'search',
      },
    ]}
    value={search}
    onChange={onSearch}
    className="merge-variable-tags--search-input"
  />
);

export default TagsDropdownSearchInput;
