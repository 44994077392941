import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  Mailbox,
  MailboxEmail,
  MailboxEmailActivity,
  MailboxEmailsPaginationMeta,
} from './type';

import {
  deleteMailboxEmailRequest,
  getMailboxesListRequest,
  getMailboxEmailsListRequest,
  getMailboxEmailActivityRequest,
  toggleMailboxEmailNotificationRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getMailboxEmailsListRequest: RequestState;
  getMailboxesListRequest: RequestState;
  getMailboxEmailActivityRequest: RequestState;
  toggleMailboxEmailNotificationRequest: RequestState;
  deleteMailboxEmailRequest: RequestState;
  mailboxEmails: MailboxEmail[];
  mailboxes: Mailbox[];
  mailboxEmailActivity: MailboxEmailActivity[];
  mailboxEmailsPaginationMeta: MailboxEmailsPaginationMeta;
};

const initialState: State = {
  getMailboxEmailsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getMailboxesListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getMailboxEmailActivityRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  toggleMailboxEmailNotificationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteMailboxEmailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  mailboxEmails: [],
  mailboxes: [],
  mailboxEmailActivity: [],
  mailboxEmailsPaginationMeta: null,
};

const mailboxEmailsSlice = createSlice({
  name: 'mailboxEmails',
  initialState,
  reducers: {
    resetMailboxEmailsState: (state) => {
      state.getMailboxEmailsListRequest =
        initialState.getMailboxEmailsListRequest;
      state.getMailboxesListRequest = initialState.getMailboxesListRequest;
      state.getMailboxEmailActivityRequest =
        initialState.getMailboxEmailActivityRequest;
      state.toggleMailboxEmailNotificationRequest =
        initialState.toggleMailboxEmailNotificationRequest;
      state.deleteMailboxEmailRequest = initialState.deleteMailboxEmailRequest;
      state.mailboxEmails = initialState.mailboxEmails;
      state.mailboxes = initialState.mailboxes;
      state.mailboxEmailActivity = initialState.mailboxEmailActivity;
      state.mailboxEmailsPaginationMeta =
        initialState.mailboxEmailsPaginationMeta;
    },
    resetMailboxEmailActivity: (state) => {
      state.getMailboxEmailActivityRequest =
        initialState.getMailboxEmailActivityRequest;
      state.mailboxEmailActivity = initialState.mailboxEmailActivity;
    },
    resetMailboxEmailNotifications: (state) => {
      state.toggleMailboxEmailNotificationRequest =
        initialState.toggleMailboxEmailNotificationRequest;
    },
    resetMailboxEmailDelete: (state) => {
      state.deleteMailboxEmailRequest = initialState.deleteMailboxEmailRequest;
    },
  },
  extraReducers: (builder) => {
    // Get Mailbox Emails List
    builder.addCase(getMailboxEmailsListRequest.pending, (state) => {
      state.getMailboxEmailsListRequest.status = RequestStatus.Pending;
      state.getMailboxEmailsListRequest.error = null;
    });
    builder.addCase(getMailboxEmailsListRequest.fulfilled, (state, action) => {
      state.getMailboxEmailsListRequest.status = RequestStatus.Succeeded;
      state.getMailboxEmailsListRequest.message = action.payload.message;
      state.mailboxEmails = action.payload.payload.emails;
      state.mailboxEmailsPaginationMeta = action.payload.payload.meta;
    });
    builder.addCase(getMailboxEmailsListRequest.rejected, (state, action) => {
      state.getMailboxEmailsListRequest.status = RequestStatus.Failed;
      state.getMailboxEmailsListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Mailboxes List
    builder.addCase(getMailboxesListRequest.pending, (state) => {
      state.getMailboxesListRequest.status = RequestStatus.Pending;
      state.getMailboxesListRequest.error = null;
    });
    builder.addCase(getMailboxesListRequest.fulfilled, (state, action) => {
      state.getMailboxesListRequest.status = RequestStatus.Succeeded;
      state.getMailboxesListRequest.message = action.payload.message;
      state.mailboxes = action.payload.payload.mailboxes;
    });
    builder.addCase(getMailboxesListRequest.rejected, (state, action) => {
      state.getMailboxesListRequest.status = RequestStatus.Failed;
      state.getMailboxesListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Mailbox Email Activity
    builder.addCase(getMailboxEmailActivityRequest.pending, (state) => {
      state.getMailboxEmailActivityRequest.status = RequestStatus.Pending;
      state.getMailboxEmailActivityRequest.error = null;
    });
    builder.addCase(
      getMailboxEmailActivityRequest.fulfilled,
      (state, action) => {
        state.getMailboxEmailActivityRequest.status = RequestStatus.Succeeded;
        state.getMailboxEmailActivityRequest.message = action.payload.message;
        state.mailboxEmailActivity = action.payload.payload;
      },
    );
    builder.addCase(
      getMailboxEmailActivityRequest.rejected,
      (state, action) => {
        state.getMailboxEmailActivityRequest.status = RequestStatus.Failed;
        state.getMailboxEmailActivityRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Toggle Mailbox Email Notification
    builder.addCase(toggleMailboxEmailNotificationRequest.pending, (state) => {
      state.toggleMailboxEmailNotificationRequest.status =
        RequestStatus.Pending;
      state.toggleMailboxEmailNotificationRequest.error = null;
    });
    builder.addCase(
      toggleMailboxEmailNotificationRequest.fulfilled,
      (state, action) => {
        state.toggleMailboxEmailNotificationRequest.status =
          RequestStatus.Succeeded;
        state.toggleMailboxEmailNotificationRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      toggleMailboxEmailNotificationRequest.rejected,
      (state, action) => {
        state.toggleMailboxEmailNotificationRequest.status =
          RequestStatus.Failed;
        state.toggleMailboxEmailNotificationRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Mailbox Email
    builder.addCase(deleteMailboxEmailRequest.pending, (state) => {
      state.deleteMailboxEmailRequest.status = RequestStatus.Pending;
      state.deleteMailboxEmailRequest.error = null;
    });
    builder.addCase(deleteMailboxEmailRequest.fulfilled, (state, action) => {
      state.deleteMailboxEmailRequest.status = RequestStatus.Succeeded;
      state.deleteMailboxEmailRequest.message = action.payload.message;
    });
    builder.addCase(deleteMailboxEmailRequest.rejected, (state, action) => {
      state.deleteMailboxEmailRequest.status = RequestStatus.Failed;
      state.deleteMailboxEmailRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetMailboxEmailsState,
  resetMailboxEmailActivity,
  resetMailboxEmailNotifications,
  resetMailboxEmailDelete,
} = mailboxEmailsSlice.actions;

export default mailboxEmailsSlice.reducer;
