import validator from 'validator';
import { ModiFyTimeSlot, ModifySlot } from './types';

// type SlotDataValidation = {
//   errorMessage: string;
//   result: boolean;
// };

export const validate = (name, value) => {
  switch (name) {
    case 'scheduleName':
      if (validator.isEmpty(value)) {
        return 'Please provide a schedule name';
      }
      if (!validator.isLength(value, { max: 50 })) {
        return 'Schedule name should be at most 50 characters long';
      }
      break;

    case 'timezone':
      if (validator.isEmpty(value)) {
        return 'Please select a timezone';
      }
      break;
  }

  return '';
};

// Validate single slot data.
export const timeSlotDataValidation = (timeSlotObj: ModifySlot): boolean => {
  if (timeSlotObj) {
    const tmpSlot = [];

    tmpSlot[0] = timeSlotObj.start;
    tmpSlot[1] = timeSlotObj.end;
    return checkValidation(tmpSlot);
  }
};

const checkValidation = (slotItem): boolean => {
  // Check all value should numeric and start time should greater than end time.
  const slotHourMinuteStart = slotItem[0];
  const slotHourMinuteEnd = slotItem[1];
  if (
    validator.isEmpty(`${slotHourMinuteStart.hour}`) ||
    validator.isEmpty(`${slotHourMinuteStart.minute}`) ||
    validator.isEmpty(`${slotHourMinuteEnd.hour}`) ||
    validator.isEmpty(`${slotHourMinuteEnd.minute}`)
  ) {
    return true;
  }

  if (
    slotHourMinuteStart.hour.toString().length > 2 ||
    slotHourMinuteStart.minute.toString().length > 2 ||
    slotHourMinuteEnd.hour.toString().length > 2 ||
    slotHourMinuteEnd.minute.toString().length > 2
  ) {
    return true;
  }

  // Check hours and minutes values between range hour 0 to 23 and minutes 0 to 59.
  if (
    parseInt(slotHourMinuteStart.hour) < 0 ||
    parseInt(slotHourMinuteStart.minute) < 0 ||
    parseInt(slotHourMinuteStart.hour) > 23 ||
    parseInt(slotHourMinuteStart.minute) > 59 ||
    parseInt(slotHourMinuteEnd.hour) < 0 ||
    parseInt(slotHourMinuteEnd.minute) < 0 ||
    parseInt(slotHourMinuteEnd.hour) > 23 ||
    parseInt(slotHourMinuteEnd.minute) > 59
  ) {
    return true;
  }

  const intervalInMinutesStart =
    parseInt(slotHourMinuteStart.hour) * 60 +
    parseInt(slotHourMinuteStart.minute);
  const intervalInMinutesEnd =
    parseInt(slotHourMinuteEnd.hour) * 60 + parseInt(slotHourMinuteEnd.minute);

  if (intervalInMinutesEnd - intervalInMinutesStart < 1) {
    return true;
  }

  return false;
};

// Overlapping Validator for slots of one day.
export const validateOverlapTimeSlot = (timeSlot: ModiFyTimeSlot): number[] => {
  // convert into absolute minute.
  const slotsWithAbsoluteValue = convertTimeSlotInAbsoluteTime(timeSlot.slots);

  // Sort array of Slots.
  slotsWithAbsoluteValue.sort((a, b) => {
    if (a.startAbsoluteValue < b.startAbsoluteValue) {
      return -1;
    }
    if (a.startAbsoluteValue > b.startAbsoluteValue) {
      return 1;
    }
    return 0;
  });

  const validationObj = new Set();

  for (let i = 0; i < slotsWithAbsoluteValue.length; i++) {
    for (let j = i + 1; j < slotsWithAbsoluteValue.length; j++) {
      if (
        slotsWithAbsoluteValue[i].endAbsoluteValue >
        slotsWithAbsoluteValue[j].startAbsoluteValue
      ) {
        validationObj.add(slotsWithAbsoluteValue[i].id);
        validationObj.add(slotsWithAbsoluteValue[j].id);
      }
    }
  }

  for (let j = 0; j < slotsWithAbsoluteValue.length; j++) {
    if (validationObj.has(slotsWithAbsoluteValue[j].id)) {
      slotsWithAbsoluteValue[j].overlapError = true;
    } else {
      slotsWithAbsoluteValue[j].overlapError = false;
    }
  }

  return slotsWithAbsoluteValue
    .filter((slot) => slot.overlapError)
    .map((slot) => slot.id);
};

const convertTimeSlotInAbsoluteTime = (slots: ModifySlot[]) => {
  const absoluteValueOfSlots = slots.map((slot: ModifySlot) => {
    const absoluteValueOfStartTime =
      Number(slot.start.hour) * 60 + Number(slot.start.minute);
    const absoluteValueOfEndTime =
      Number(slot.end.hour) * 60 + Number(slot.end.minute);
    const tempSlot = {
      ...slot,
      startAbsoluteValue: absoluteValueOfStartTime,
      endAbsoluteValue: absoluteValueOfEndTime,
    };
    return tempSlot;
  });
  return absoluteValueOfSlots;
};
