import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  bulkCategoryAssign,
  bulkDelete,
  bulkMarkAsReadUnread,
  replyScheduleCancel,
  deleteEmailConversation,
  forwardEmail,
  getEmailAccountsListForFilter,
  getEmailCategories,
  getEmailInbox,
  getEmailThread,
  getEmailThreadActivity,
  getSequencesListForFilter,
  replyDraft,
  replyEmail,
  updateEmailCategory,
  updateEmailReadUnreadStatus,
  replyDraftDiscard,
  replySchedule,
} from './helpers/unified-inbox.api';
import {
  AttachmentDeleteUniboxPayload,
  AttachmentUploadUniboxPayload,
  BulkCategoryAssignPayload,
  BulkDeletePayload,
  BulkMarkAsReadUnreadPayload,
  EmailCategoryPayload,
  EmailDeletePayload,
  EmailInboxFiltersPayload,
  EmailReadUnreadStatusPayload,
  EmailThreadActivityPayload,
  EmailThreadFilters,
  ForwardEmailPayload,
  ReplyDraftDiscardPayload,
  ReplyDraftPayload,
  ReplyEmailPayload,
  ReplyScheduleCancelPayload,
  ReplySchedulePayload,
} from './type';
import {
  deleteAttachment,
  uploadAttachment,
} from '../sequence/helpers/sequence.api';

export const getEmailInboxRequest = createAsyncThunk<
  ResponseSuccess,
  EmailInboxFiltersPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailInbox', async (args, thunkAPI) => {
  try {
    return await getEmailInbox(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailThreadRequest = createAsyncThunk<
  ResponseSuccess,
  EmailThreadFilters,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailThread', async (args, thunkAPI) => {
  try {
    return await getEmailThread(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailThreadActivityRequest = createAsyncThunk<
  ResponseSuccess,
  EmailThreadActivityPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailThreadActivity', async (args, thunkAPI) => {
  try {
    return await getEmailThreadActivity(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequencesListForFilterRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getSequencesListForFilter', async (_args, thunkAPI) => {
  try {
    return await getSequencesListForFilter();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountsListForFilterRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailAccountsListForFilter', async (_args, thunkAPI) => {
  try {
    return await getEmailAccountsListForFilter();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const replyEmailRequest = createAsyncThunk<
  ResponseSuccess,
  ReplyEmailPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/replyEmail', async (args, thunkAPI) => {
  try {
    return await replyEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const forwardEmailRequest = createAsyncThunk<
  ResponseSuccess,
  ForwardEmailPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/forwardEmail', async (args, thunkAPI) => {
  try {
    return await forwardEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Upload Attachment Request [This is a copy of uploadAttachmentRequest made for multiple editors]
export const uploadAttachmentUniboxRequest = createAsyncThunk<
  ResponseSuccess,
  AttachmentUploadUniboxPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/uploadAttachmentRequest',
  async ({ formData, onUploadProgress, cancelToken }, thunkAPI) => {
    try {
      return await uploadAttachment(formData, onUploadProgress, cancelToken);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// Delete Attachment Request [This is a copy of deleteAttachmentRequest made for multiple editors]
export const deleteAttachmentUniboxRequest = createAsyncThunk<
  ResponseSuccess,
  AttachmentDeleteUniboxPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/deleteAttachmentRequest',
  async (
    { attachmentId, deleteFromTemplate, sequenceStepVariantId },
    thunkAPI,
  ) => {
    try {
      return await deleteAttachment(
        attachmentId,
        deleteFromTemplate,
        sequenceStepVariantId,
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const replyDraftRequest = createAsyncThunk<
  ResponseSuccess,
  ReplyDraftPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/replyDraft', async (args, thunkAPI) => {
  try {
    return await replyDraft(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailCategoriesRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailCategories', async (_args, thunkAPI) => {
  try {
    return await getEmailCategories();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateEmailCategoryRequest = createAsyncThunk<
  ResponseSuccess,
  EmailCategoryPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/updateEmailCategory', async (args, thunkAPI) => {
  try {
    return await updateEmailCategory(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const updateEmailReadUnreadStatusHandler = async (args, thunkAPI) => {
  try {
    return await updateEmailReadUnreadStatus(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
};

export const markEmailConversationAsRead = createAsyncThunk<
  ResponseSuccess,
  EmailReadUnreadStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/markEmailConversationAsRead',
  updateEmailReadUnreadStatusHandler,
);

export const markEmailConversationAsUnRead = createAsyncThunk<
  ResponseSuccess,
  EmailReadUnreadStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/markEmailConversationAsUnRead',
  updateEmailReadUnreadStatusHandler,
);

export const deleteEmailConversationRequest = createAsyncThunk<
  ResponseSuccess,
  EmailDeletePayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/deleteEmailConversation', async (args, thunkAPI) => {
  try {
    return await deleteEmailConversation(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkMarkAsReadUnreadRequest = createAsyncThunk<
  ResponseSuccess,
  BulkMarkAsReadUnreadPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulkMarkAsReadUnread', async (args, thunkAPI) => {
  try {
    return await bulkMarkAsReadUnread(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkDeleteRequest = createAsyncThunk<
  ResponseSuccess,
  BulkDeletePayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulkDelete', async (args, thunkAPI) => {
  try {
    return await bulkDelete(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkCategoryAssignRequest = createAsyncThunk<
  ResponseSuccess,
  BulkCategoryAssignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulkCategoryAssign', async (args, thunkAPI) => {
  try {
    return await bulkCategoryAssign(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const replyDraftDiscardRequest = createAsyncThunk<
  ResponseSuccess,
  ReplyDraftDiscardPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/replyDraftDiscard', async ({ repliedOnEmailId }, thunkAPI) => {
  try {
    return await replyDraftDiscard({ repliedOnEmailId });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const replyScheduleCancelRequest = createAsyncThunk<
  ResponseSuccess,
  ReplyScheduleCancelPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/replyScheduleCancel',
  async ({ repliedOnEmailId, unifiedScheduledId }, thunkAPI) => {
    try {
      return await replyScheduleCancel({
        repliedOnEmailId,
        unifiedScheduledId,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const replyScheduleRequest = createAsyncThunk<
  ResponseSuccess,
  ReplySchedulePayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/replySchedule',
  async (
    { unifiedScheduledId, scheduledAt, sendEmailIfReplied, timezone },
    thunkAPI,
  ) => {
    try {
      return await replySchedule({
        unifiedScheduledId,
        scheduledAt,
        sendEmailIfReplied,
        timezone,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
