import { getTaskTitle } from './helper';

export const tasksTableData = (tasks) =>
  tasks?.map((task) => {
    const prospect = task?.prospect?.attributes.map(
      ({ attributeValue, fieldRef }) => ({
        id: task?.prospect?.id,
        value: attributeValue,
        label: fieldRef.label,
        isDefault: fieldRef.isDefault,
      }),
    );
    prospect.push({
      id: task?.prospect?.id,
      value: task?.prospect?.email,
      label: 'Email',
      isDefault: true,
    });

    const stepNumber = task?.sequenceProspectTask?.step?.number;
    const sequenceName = task?.sequenceProspectTask?.step?.sequence?.title;

    return {
      id: task.id,
      prospect,
      taskTitle: {
        type: task?.type,
        title:
          task?.taskPayload?.payload?.title ||
          getTaskTitle(task?.type, task?.taskPayload?.payload?.purpose),
      },
      sequence: {
        stepNumber,
        sequenceName,
        sequenceId: task?.sequenceProspectTask?.step?.sequence?.id,
      },
      priority: task?.priority,
      date: {
        dueAt: task?.dueAt,
        skippedAt: task?.skippedAt,
        processedAt: task?.processedAt,
        completedAt: task?.completedAt,
      },
      owner: {
        taskId: task.id,
        firstName: task?.assignee?.firstName,
        lastName: task?.assignee?.lastName,
        taskType: task?.type,
      },
    };
  });
