import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../types';
import { UpdateProfileProgressPayload } from '../../types/user-setting';
import updateProfileProgress from './helpers/meta-api';

const updateProfileProgressRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateProfileProgressPayload,
  { rejectValue: ResponseErrorWithHandled }
>('home/updateProfileProgress', async (args, thunkAPI) => {
  try {
    return await updateProfileProgress(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export default updateProfileProgressRequest;
