import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';
import { InfoCircle, Trash } from '@saleshandy/icons';

import { DeleteAccountModalFlow } from '../enums';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../../shared/utils';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../../../../../../shared/types';

import Input from '../../../../../../../shared/design-system/components/input';
import Title from '../../change-email-modal/change-email-modal-flow/sub-components/title';
import Description from '../../change-email-modal/change-email-modal-flow/sub-components/description';
import Action from '../../change-email-modal/change-email-modal-flow/sub-components/action';
import Divider from '../../change-email-modal/change-email-modal-flow/sub-components/divider';
import Footer from '../../change-email-modal/change-email-modal-flow/sub-components/footer';

type IProps = {
  email: string;
  deleteMyProfileRequestStatus: RequestStatus;
  onCancel: VoidFunction;
  sendDeleteMyProfileRequest: (password: string) => void;
  onModalStateChange: (state: DeleteAccountModalFlow) => void;
  deleteMyProfileRequestError: ResponseErrorWithHandled;
  resetDeleteMyProfileRequest: VoidFunction;
};

const DeleteAccount: React.FC<IProps> = ({
  email,
  onCancel,
  onModalStateChange,
  sendDeleteMyProfileRequest,
  deleteMyProfileRequestStatus,
  deleteMyProfileRequestError,
  resetDeleteMyProfileRequest,
}) => {
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState<boolean>(
    true,
  );

  const onShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);

    if (!isCurrentPasswordValid) {
      setIsCurrentPasswordValid(true);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendDeleteMyProfileRequest(password);
    }
  };

  const handleOnSubmit = () => {
    sendDeleteMyProfileRequest(password);
  };

  const handleOnModalStateChange = () => {
    onModalStateChange(DeleteAccountModalFlow.ResetPassword);
  };

  useEffect(
    () => () => {
      setPassword('');
      setIsCurrentPasswordValid(true);
    },
    [],
  );

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteMyProfileRequestStatus,
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteMyProfileRequestError,
          onError: () => {
            if (deleteMyProfileRequestError.code === 1001) {
              setIsCurrentPasswordValid(false);
              resetDeleteMyProfileRequest();
            }
          },
        });
      },
    });
  }, [deleteMyProfileRequestStatus]);

  const isDeleteMyProfileRequestPending = getIsRequestPending(
    deleteMyProfileRequestStatus,
  );

  return (
    <>
      <Title>
        <div className="delete-account-title">
          <div className="delete-account-title-icon">
            <Trash />
          </div>
          <h1>Delete Account</h1>
        </div>
      </Title>

      <Description
        className="pb-3"
        content={
          <>
            <span>Delete account</span> <span className="bold">{email}</span>?
          </>
        }
      />

      <div className="alert-container">
        <div className="alert">
          <div className="alert--icon">
            <InfoCircle />
          </div>
          <p className="alert--content">
            This action cannot be undone. All your Saleshandy data will be
            permanently erased.
          </p>
        </div>
      </div>

      <Action>
        <div className="my-profile-input-container">
          <Input
            name="password"
            label="Current Password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter your current password"
            value={password}
            onChange={onPasswordChange}
            onKeyPress={onKeyPress}
            autoComplete="current-password"
            className="my-profile-input modal-input"
            variant={!isCurrentPasswordValid && Input.Variant.Error}
            icons={[
              {
                place: Input.IconPlace.Right,
                identifier: showPassword ? 'eye-alt' : 'eye',
                className: 'pointer blue-txt-15',
                onClick: onShowPasswordToggle,
              },
            ]}
          />

          {!isCurrentPasswordValid && (
            <div className="my-profile-input-error">
              <span>The password you entered is incorrect.</span>

              <Button
                className="p-0 link-cta-btn"
                variant="link"
                onClick={handleOnModalStateChange}
              >
                Forgot Password?
              </Button>
            </div>
          )}
        </div>
      </Action>

      <Divider />

      <Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
          className="secondary-cta-btn"
          disabled={isDeleteMyProfileRequestPending}
        >
          Cancel
        </Button>
        <Button
          loadingText="Deleting..."
          isLoading={isDeleteMyProfileRequestPending}
          disabled={
            !password ||
            !isCurrentPasswordValid ||
            isDeleteMyProfileRequestPending
          }
          onClick={handleOnSubmit}
          variant="danger"
        >
          Delete Account
        </Button>
      </Footer>
    </>
  );
};

export default DeleteAccount;
