// eslint-disable-next-line import/prefer-default-export
export enum UserRole {
  SHADMIN = 'shAdmin',
  ADMIN = 'admin',
  MEMBER = 'member',
  AGENCYUSER = 'agency-user',
  AGENCYOWNER = 'agency-owner',
  AGENCYTEAMLEADER = 'agency-team-leader',
  AGENCYCLIENTUSER = 'agency-client-user',
  AGENCYADMIN = 'agency-admin',
  LtdAdmin = 'ltd-admin',
  LtdMember = 'ltd-member',
}
