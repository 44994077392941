import { getUserEmail } from '../../../shared/utils/user-details';

export const baseUrls = {
  production: 'https://my.saleshandy.com',
  staging: 'https://pyxis.lifeisgoodforlearner.com',
};

export const defaultAgencyObj = {
  showClientName: false,
  isDefault: true,
  showInAppNotification: true,
  showWhatsNew: true,
  showScheduleADemo: true,
  showChatSupport: true,
  showHelp: true,
  showFeatureRequest: true,
  showGettingStarted: true,
  showInfoTips: true,
  showYTVideoGuide: true,
  showFAQ: true,
  showEmailContentGuideHelp: true,
  showEmailContentGuide: true,
  logo: {
    primary:
      'https://v3api.lifeisgoodforlearner.com/image-broker/image/' +
      'eyJpdiI6IjUyOTZlYzA4NmU3NTYzYjdmZmQ4ZmFhYjhmZWY4NDFmIiwiY29udGVudCI6IjU0YjE3N2E3MjZhMzk4ZTJiMmJhYjc3OWVhZDk2ZGZlYzM2' +
      'ZTNkZWU5M2VlOGU0OTg3Y2MwMDA3ZmM0ODI5MWIyZDNjMDg5MmYxODU1NzllZTVlYWNkZWI2MzlkM2U4NTFhYTg4OWYyYTQ4MjM3ZmJkZjlkM2I1ODM2' +
      'MzYzNTIzZDgxODg1MDdmZGE5ZGM1ZWUyNDQ3NzBiMDU3MjY2YTVhYSJ9',
    tertiary:
      'https://v3api.lifeisgoodforlearner.com/image-broker/image/' +
      'eyJpdiI6IjUyOTZlYzA4NmU3NTYzYjdmZmQ4ZmFhYjhmZWY4NDFmIiwiY29udGVudCI6IjU0YjE3N2E3MjZhMzk4ZTJiMmJhYjc3OWVhZDk2ZGZlYzM2' +
      'ZTNkZWU5M2VlOGU0OTg3Y2MwMDA3ZmM0ODI5MWIyZDNjMDg5MmYxODU1NzllZTVlYWNkZWI2MzlkM2U4NTFhYTg4OWYyYTQ4MjM3ZmJkZjlkM2I1ODM2' +
      'MzYzNTIzZDgxODg1MDdmZGE5ZGM1ZWUyNDQ3NzBiMDU3MjY2YTVhYSJ9',
    showAgencyNameWithLogo: true,
    secondary:
      'https://v3api.lifeisgoodforlearner.com/image-broker/image/' +
      'eyJpdiI6IjUyOTZlYzA4NmU3NTYzYjdmZmQ4ZmFhYjhmZWY4NDFmIiwiY29udGVudCI6IjU0YjE3N2E3MjZhMzk4ZTJiMmJhYjc3OWVhZDk2ZGZlYzM2' +
      'ZTNkZWU5M2VlOGU0OTg3Y2MwMDA3ZmM0ODI5MWIyZDNjMDg5MmYxODU1NzllZTVlYWNkZWI2MzlkM2U4NTFhYTg4OWYyYTQ4MjM3ZmJkZjlkM2I1ODM2' +
      'MzYzNTIzZDgxODg1MDdmZGE5ZGM1ZWUyNDQ3NzBiMDU3MjY2YTVhYSJ9',
  },
  baseUrl: process.env.REACT_APP_API_URL,
  createdAt: '2022-06-01T18:24:56.129Z',
  updatedAt: '2023-01-10T04:33:26.276Z',
  __v: 0,
  showAddStepButton: true,
  showColdEmailTips: true,
  showDraftSequence: true,
  agencyName: 'saleshandy',
  colorPalette: {
    sidebarBgColor: '#F3F4F6',
    navItemActiveBgColor: '#374151',
    navItemHoverBgColor: '#1F2937',
    navItemTextColor: '#1F2937',
  },
  showAgencyNameWithLogo: true,
  showNameFieldsInInviteModal: false,
  ltdExpiryDate: '12-20-2022',
  showTermOfServiceAndPrivacyPolicy: true,
  clientName: 'saleshandy',
  closeOnLogout: false,
  isLocallyCached: true,
  favicon: '/favicon-16x16.png',
};

export const storeUserEmail = () => {
  localStorage.setItem('userEmail', getUserEmail());
};
export const getUserEmailFromStore = () => localStorage.getItem('userEmail');
export const removeUserEmailFromStore = () =>
  localStorage.removeItem('userEmail');
