enum HelpscoutBeaconEvents {
  OPEN = 'open',
  CLOSE = 'close',
  INIT = 'init',
  DESTROY = 'destroy',
  MESSAGE_TRIGGERED = 'message-triggered',
  MESSAGE_CLOSED = 'message-closed',
  PAGE_VIEWED = 'page-viewed',
  SUGGEST = 'suggest',
  TOGGLE = 'toggle',
}

export default HelpscoutBeaconEvents;
