import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  forwardEmailRequest,
  getEmailInboxRequest,
  getEmailThreadActivityRequest,
  getEmailThreadRequest,
  replyEmailRequest,
  getEmailCategoriesRequest,
  updateEmailCategoryRequest,
  getSequencesListForFilterRequest,
  getEmailAccountsListForFilterRequest,
  markEmailConversationAsRead,
  markEmailConversationAsUnRead,
  deleteEmailConversationRequest,
  bulkMarkAsReadUnreadRequest,
  bulkDeleteRequest,
  bulkCategoryAssignRequest,
  uploadAttachmentUniboxRequest,
  deleteAttachmentUniboxRequest,
  replyDraftRequest,
  replyScheduleCancelRequest,
  replyDraftDiscardRequest,
  replyScheduleRequest,
} from './extra-actions';
import {
  AttachmentUploadResponse,
  EmailCategoryType,
  EmailInboxFilters,
  EmailListItem,
  EmailListMeta,
  EmailThreadItem,
  IsEmailSelectedPayload,
  SelectAllEmailThreads,
  UnifiedInboxEmailThreadActivity,
  UpdateUniboxEmailScheduleDataPayload,
} from './type';
import {
  getTotalSelectedEmailCount,
  getUniboxEmailListWithUpdatedScheduleData,
} from './helpers/helper';
import { getDefaultFilters } from './helpers/utils/get-default-filters';
import toaster, { Theme } from '../../shared/toaster';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  unifiedInboxFilters: EmailInboxFilters;
  getEmailInboxRequest: RequestState;
  getEmailThreadRequest: RequestState;
  getEmailThreadActivityRequest: RequestState;
  replyEmailRequest: {
    [id: number]: RequestState;
  };
  forwardEmailRequest: {
    [id: number]: RequestState;
  };
  replyDraftRequest: {
    [id: number]: RequestState;
  };
  uploadAttachmentUniboxRequest: {
    [id: number]: RequestState;
  };
  deleteAttachmentUniboxRequest: {
    [id: number]: RequestState;
  };
  uploadedAttachment: {
    [id: number]: AttachmentUploadResponse;
  };
  deletedAttachmentId: {
    [id: number]: number;
  };
  replyDraftDiscardRequest: {
    [id: number]: RequestState;
  };
  replyScheduleCancelRequest: {
    [id: number]: RequestState;
  };
  replyScheduleRequest: {
    [id: number]: RequestState;
  };
  getEmailCategoriesRequest: RequestState;
  updateEmailCategoryRequest: RequestState;
  getSequencesListForFilterRequest: RequestState;
  getEmailAccountsListForFilterRequest: RequestState;
  markEmailConversationAsRead: RequestState;
  markEmailConversationAsUnRead: RequestState;
  deleteEmailConversationRequest: RequestState;
  bulkMarkAsReadUnreadRequest: RequestState;
  bulkDeleteRequest: RequestState;
  bulkCategoryAssignRequest: RequestState;
  emailsList: EmailListItem[];
  emailsListMeta: EmailListMeta;
  emailThreadList: EmailThreadItem[];
  unifiedInboxEmailThreadActivity: UnifiedInboxEmailThreadActivity[];
  emailCategories: EmailCategoryType[];
  sequences: any[];
  emails: any[];
  selectedEmailThreads: EmailListItem[];
  selectAllEmailThreads: SelectAllEmailThreads;
  showMarkAsReadMessage: boolean;
};

const initialState: State = {
  getEmailInboxRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailThreadRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailThreadActivityRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  replyEmailRequest: {},
  forwardEmailRequest: {},
  replyDraftRequest: {},
  uploadAttachmentUniboxRequest: {},
  deleteAttachmentUniboxRequest: {},
  uploadedAttachment: {},
  deletedAttachmentId: {},
  replyDraftDiscardRequest: {},
  replyScheduleCancelRequest: {},
  replyScheduleRequest: {},
  getEmailCategoriesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailCategoryRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequencesListForFilterRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountsListForFilterRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  markEmailConversationAsRead: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  markEmailConversationAsUnRead: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteEmailConversationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkMarkAsReadUnreadRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkDeleteRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkCategoryAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailsList: [],
  emailsListMeta: null,
  emailThreadList: null,
  unifiedInboxEmailThreadActivity: null,
  unifiedInboxFilters: getDefaultFilters(),
  emailCategories: null,
  sequences: [],
  emails: [],
  selectedEmailThreads: [],
  selectAllEmailThreads: null,
  showMarkAsReadMessage: false,
};

const unifiedInboxSlice = createSlice({
  name: 'unifiedInbox',
  initialState,
  reducers: {
    resetGetEmailInboxRequestStatus: (state) => {
      state.getEmailInboxRequest = initialState.getEmailInboxRequest;
    },
    resetEmailInboxState: (state) => {
      state.getEmailInboxRequest = initialState.getEmailInboxRequest;
    },
    resetUnifiedInboxEmailThreadActivity: (state) => {
      state.getEmailThreadActivityRequest =
        initialState.getEmailThreadActivityRequest;
      state.unifiedInboxEmailThreadActivity =
        initialState.unifiedInboxEmailThreadActivity;
    },
    resetUpdateEmailCategoryRequest: (state) => {
      state.updateEmailCategoryRequest =
        initialState.updateEmailCategoryRequest;
    },
    resetMarkEmailConversationAsRead: (state) => {
      state.markEmailConversationAsRead =
        initialState.markEmailConversationAsRead;
    },
    resetMarkEmailConversationAsUnRead: (state) => {
      state.markEmailConversationAsUnRead =
        initialState.markEmailConversationAsUnRead;
    },
    resetDeleteEmailConversationRequest: (state) => {
      state.deleteEmailConversationRequest =
        initialState.deleteEmailConversationRequest;
    },
    updateReadStatus: (state, action) => {
      state.emailsList = state.emailsList.map((email) => {
        if (action.payload.hashId === email.hashId) {
          return {
            ...email,
            isRead: 1,
          };
        }
        return email;
      });
    },
    setUnifiedInboxFilters: (state, action) => {
      state.unifiedInboxFilters = {
        ...state.unifiedInboxFilters,
        ...action.payload,
      };
    },
    resetUnifiedInboxFilters: (state, action) => {
      const key: keyof EmailInboxFilters | 'all' = action.payload;
      if (key === 'all') {
        state.unifiedInboxFilters = initialState.unifiedInboxFilters;
      } else {
        state.unifiedInboxFilters = {
          ...state.unifiedInboxFilters,
          [key]: initialState.unifiedInboxFilters[key],
        };
      }
    },
    updateIsEmailSelected: (
      state,
      action: { payload: IsEmailSelectedPayload },
    ) => {
      const { hashId, isSelected, select } = action.payload;

      if (select === 'bulk') {
        // Update the email list isSelected key
        state.emailsList = state.emailsList.map((email) => ({
          ...email,
          isSelected,
        }));

        // Update the selectAllEmailThreads object
        state.selectAllEmailThreads = {
          isAllEmailThreadsSelected: isSelected,
          deSelectedEmailThreadIds: [],
          emailsFilter: action.payload.filters,
        };
      }

      if (select === 'all') {
        // Update the email list isSelected key
        state.emailsList = state.emailsList.map((email) => ({
          ...email,
          isSelected,
        }));

        if (isSelected) {
          if (state.selectAllEmailThreads?.isAllEmailThreadsSelected) {
            state.emailsList.forEach((email) => {
              state.selectAllEmailThreads.deSelectedEmailThreadIds = state.selectAllEmailThreads.deSelectedEmailThreadIds.filter(
                (id) => email.hashId !== id,
              );
            });
          }

          // Update the selectedEmailThreads array
          const refArray = [...state.selectedEmailThreads];

          state.emailsList.forEach((email) => {
            const emailData = state.selectedEmailThreads.find(
              (selectedEmail) => selectedEmail.hashId === email.hashId,
            );
            if (!emailData) {
              refArray.push(email);
            }
          });

          state.selectedEmailThreads = refArray;
        } else {
          if (state.selectAllEmailThreads?.isAllEmailThreadsSelected) {
            state.emailsList.forEach((email) => {
              state.selectAllEmailThreads.deSelectedEmailThreadIds = state.selectAllEmailThreads?.deSelectedEmailThreadIds.concat(
                [email.hashId],
              );
            });
          }

          state.selectedEmailThreads = state.selectedEmailThreads.reduce(
            (acc, item) => {
              let isPresent = false;
              state.emailsList.forEach((email) => {
                if (email.hashId === item.hashId) {
                  isPresent = true;
                }
              });
              if (isPresent) {
                return acc;
              }
              return [...acc, item];
            },
            [],
          );
        }
      }

      if (select === 'single') {
        state.emailsList = state.emailsList.map((email) => {
          if (hashId === email.hashId) {
            return {
              ...email,
              isSelected,
            };
          }
          return email;
        });

        if (isSelected) {
          if (state.selectAllEmailThreads?.isAllEmailThreadsSelected) {
            state.selectAllEmailThreads.deSelectedEmailThreadIds = state.selectAllEmailThreads.deSelectedEmailThreadIds.filter(
              (id) => hashId !== id,
            );
          }

          // Update the selectedEmailThreads array
          // state.selectedEmailThreads = isSelected ? state.emailsList : [];
          state.selectedEmailThreads = state.selectedEmailThreads.concat(
            state.emailsList.filter((email) => email.hashId === hashId),
          );
        } else {
          if (state.selectAllEmailThreads?.isAllEmailThreadsSelected) {
            state.selectAllEmailThreads.deSelectedEmailThreadIds = state.selectAllEmailThreads?.deSelectedEmailThreadIds.concat(
              [hashId],
            );
          }

          state.selectedEmailThreads = state.selectedEmailThreads.filter(
            (email) => email.hashId !== hashId,
          );
        }
      }

      if (state.selectAllEmailThreads) {
        state.selectAllEmailThreads.selectedEmailsCount =
          getTotalSelectedEmailCount(state.emailsListMeta?.totalItems) -
          state.selectAllEmailThreads?.deSelectedEmailThreadIds.length;

        if (state.selectAllEmailThreads.selectedEmailsCount === 0) {
          state.selectAllEmailThreads = initialState.selectAllEmailThreads;
          state.selectedEmailThreads = initialState.selectedEmailThreads;
          state.emailsList = state.emailsList.map((email) => ({
            ...email,
            isSelected: false,
          }));
        }
      }
    },

    updateUniboxEmailScheduleData: (
      state,
      action: { payload: UpdateUniboxEmailScheduleDataPayload },
    ) => {
      state.emailsList = getUniboxEmailListWithUpdatedScheduleData(
        state.emailsList,
        state.emailThreadList,
        action.payload,
      );
    },

    clearIsEmailSelected: (state) => {
      state.selectAllEmailThreads = initialState.selectAllEmailThreads;
      state.selectedEmailThreads = initialState.selectedEmailThreads;
      state.emailsList = state.emailsList.map((email) => ({
        ...email,
        isSelected: false,
      }));
    },

    resetBulkMarkAsReadUnreadRequest: (state) => {
      state.bulkMarkAsReadUnreadRequest =
        initialState.bulkMarkAsReadUnreadRequest;
    },
    resetBulkCategoryAssignRequest: (state) => {
      state.bulkCategoryAssignRequest = initialState.bulkCategoryAssignRequest;
    },
    resetBulkDeleteRequest: (state) => {
      state.bulkDeleteRequest = initialState.bulkDeleteRequest;
    },
    setShowMarkAsReadMessage: (state) => {
      state.showMarkAsReadMessage = true;
    },
    resetShowMarkAsReadMessage: (state) => {
      state.showMarkAsReadMessage = initialState.showMarkAsReadMessage;
    },
    resetFilterData: (state) => {
      state.emailCategories = initialState.emailCategories;
      state.sequences = initialState.sequences;
      state.emails = initialState.emails;
    },
    resetReplyForwardDraftRequest: (state, action: { payload: number }) => {
      delete state.replyEmailRequest[action.payload];
      delete state.forwardEmailRequest[action.payload];
      delete state.replyDraftRequest[action.payload];
      delete state.replyScheduleRequest[action.payload];
    },
    resetReplyDraftDiscardRequest: (state, action: { payload: number }) => {
      delete state.replyDraftDiscardRequest[action.payload];
    },
    resetReplyScheduleCancelRequest: (state, action: { payload: number }) => {
      delete state.replyScheduleCancelRequest[action.payload];
    },
    resetUploadAttachmentUniboxRequest: (
      state,
      action: { payload: number },
    ) => {
      delete state.uploadAttachmentUniboxRequest[action.payload];
      delete state.uploadedAttachment[action.payload];
    },
    resetDeleteAttachmentUniboxRequest: (
      state,
      action: { payload: number },
    ) => {
      delete state.deleteAttachmentUniboxRequest[action.payload];
      delete state.deletedAttachmentId[action.payload];
    },
  },
  extraReducers: (builder) => {
    // Get Email Inbox List
    builder.addCase(getEmailInboxRequest.pending, (state) => {
      state.getEmailInboxRequest.status = RequestStatus.Pending;
      state.getEmailInboxRequest.error = null;
    });
    builder.addCase(getEmailInboxRequest.fulfilled, (state, action) => {
      state.getEmailInboxRequest.status = RequestStatus.Succeeded;

      if (state.selectAllEmailThreads?.isAllEmailThreadsSelected) {
        state.emailsList = action.payload.payload.items.map((email) => ({
          ...email,
          isSelected: !state.selectAllEmailThreads?.deSelectedEmailThreadIds.includes(
            email.hashId,
          ),
        }));
      } else {
        state.emailsList = action.payload.payload.items.map((email) => ({
          ...email,
          isSelected:
            state.selectedEmailThreads?.find(
              (item) => item.hashId === email.hashId,
            ) !== undefined,
        }));
      }

      // if first page then only update totalItems & pages
      state.emailsListMeta = {
        ...action.payload.payload.meta,
        totalItems:
          action.meta.arg.page === 1
            ? action.payload.payload.meta?.totalItems
            : state.emailsListMeta?.totalItems,
        totalPages:
          action.meta.arg.page === 1
            ? action.payload.payload.meta?.totalPages
            : state.emailsListMeta?.totalPages,
      };

      state.getEmailInboxRequest.error = null;
    });
    builder.addCase(getEmailInboxRequest.rejected, (state, action) => {
      state.getEmailInboxRequest.status = RequestStatus.Failed;
      state.getEmailInboxRequest.message = action.payload.message;
      state.getEmailInboxRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Email Thread List
    builder.addCase(getEmailThreadRequest.pending, (state) => {
      state.getEmailThreadRequest.status = RequestStatus.Pending;
      state.getEmailThreadRequest.error = null;
    });
    builder.addCase(getEmailThreadRequest.fulfilled, (state, action) => {
      state.getEmailThreadRequest.status = RequestStatus.Succeeded;
      state.emailThreadList = action.payload.payload;
      state.getEmailThreadRequest.error = null;
    });
    builder.addCase(getEmailThreadRequest.rejected, (state, action) => {
      state.getEmailThreadRequest.status = RequestStatus.Failed;
      state.getEmailThreadRequest.message = action.payload.message;
      state.getEmailThreadRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Email Thread Activity
    builder.addCase(getEmailThreadActivityRequest.pending, (state) => {
      state.getEmailThreadActivityRequest.status = RequestStatus.Pending;
      state.getEmailThreadActivityRequest.error = null;
    });
    builder.addCase(
      getEmailThreadActivityRequest.fulfilled,
      (state, action) => {
        state.getEmailThreadActivityRequest.status = RequestStatus.Succeeded;
        state.getEmailThreadActivityRequest.message = action.payload.message;
        state.unifiedInboxEmailThreadActivity = action.payload.payload;
      },
    );
    builder.addCase(getEmailThreadActivityRequest.rejected, (state, action) => {
      state.getEmailThreadActivityRequest.status = RequestStatus.Failed;
      state.getEmailThreadActivityRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequences List
    builder.addCase(getSequencesListForFilterRequest.pending, (state) => {
      state.getSequencesListForFilterRequest.status = RequestStatus.Pending;
      state.getSequencesListForFilterRequest.error = null;
    });
    builder.addCase(
      getSequencesListForFilterRequest.fulfilled,
      (state, action) => {
        state.getSequencesListForFilterRequest.status = RequestStatus.Succeeded;
        state.getSequencesListForFilterRequest.message = action.payload.message;
        state.sequences = action.payload.payload;
      },
    );
    builder.addCase(
      getSequencesListForFilterRequest.rejected,
      (state, action) => {
        state.getSequencesListForFilterRequest.status = RequestStatus.Failed;
        state.getSequencesListForFilterRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Accounts List
    builder.addCase(getEmailAccountsListForFilterRequest.pending, (state) => {
      state.getEmailAccountsListForFilterRequest.status = RequestStatus.Pending;
      state.getEmailAccountsListForFilterRequest.error = null;
    });
    builder.addCase(
      getEmailAccountsListForFilterRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountsListForFilterRequest.status =
          RequestStatus.Succeeded;
        state.getEmailAccountsListForFilterRequest.message =
          action.payload.message;
        state.emails = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountsListForFilterRequest.rejected,
      (state, action) => {
        state.getEmailAccountsListForFilterRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountsListForFilterRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Reply Email
    builder.addCase(replyEmailRequest.pending, (state, action) => {
      const { emailId } = action.meta.arg;
      if (!state.replyEmailRequest[emailId]) {
        state.replyEmailRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.replyEmailRequest[emailId].status = RequestStatus.Pending;
      state.replyEmailRequest[emailId].error = null;
    });
    builder.addCase(replyEmailRequest.fulfilled, (state, action) => {
      const { emailId } = action.meta.arg;
      if (state.replyEmailRequest[emailId]) {
        state.replyEmailRequest[emailId].status = RequestStatus.Succeeded;
        state.replyEmailRequest[emailId].message = action.payload.message;
        state.replyEmailRequest[emailId].error = null;
      }
    });
    builder.addCase(replyEmailRequest.rejected, (state, action) => {
      const { emailId } = action.meta.arg;
      if (state.replyEmailRequest[emailId]) {
        state.replyEmailRequest[emailId].status = RequestStatus.Failed;
        state.replyEmailRequest[emailId].message = action.payload.message;
        state.replyEmailRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Forward Email
    builder.addCase(forwardEmailRequest.pending, (state, action) => {
      const { emailId } = action.meta.arg;
      if (!state.forwardEmailRequest[emailId]) {
        state.forwardEmailRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.forwardEmailRequest[emailId].status = RequestStatus.Pending;
      state.forwardEmailRequest[emailId].error = null;
    });
    builder.addCase(forwardEmailRequest.fulfilled, (state, action) => {
      const { emailId } = action.meta.arg;
      if (state.forwardEmailRequest[emailId]) {
        state.forwardEmailRequest[emailId].status = RequestStatus.Succeeded;
        state.forwardEmailRequest[emailId].message = action.payload.message;
        state.forwardEmailRequest[emailId].error = null;
      }
    });
    builder.addCase(forwardEmailRequest.rejected, (state, action) => {
      const { emailId } = action.meta.arg;
      if (state.forwardEmailRequest[emailId]) {
        state.forwardEmailRequest[emailId].status = RequestStatus.Failed;
        state.forwardEmailRequest[emailId].message = action.payload.message;
        state.forwardEmailRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Reply Draft Request
    builder.addCase(replyDraftRequest.pending, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (!state.replyDraftRequest[emailId]) {
        state.replyDraftRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.replyDraftRequest[emailId].status = RequestStatus.Pending;
      state.replyDraftRequest[emailId].error = null;
    });
    builder.addCase(replyDraftRequest.fulfilled, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (state.replyDraftRequest[emailId]) {
        state.replyDraftRequest[emailId].status = RequestStatus.Succeeded;
        state.replyDraftRequest[emailId].error = null;
      }
      state.emailThreadList = state.emailThreadList.map((email) => {
        if (email.emailId === emailId) {
          return {
            ...email,
            unifiedScheduled: action.payload.payload,
          };
        }
        return email;
      });
    });
    builder.addCase(replyDraftRequest.rejected, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (state.replyDraftRequest[emailId]) {
        state.replyDraftRequest[emailId].status = RequestStatus.Failed;
        state.replyDraftRequest[emailId].message = action.payload.message;
        state.replyDraftRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Upload Attachment Request [For unibox multiple reply editors]
    builder.addCase(uploadAttachmentUniboxRequest.pending, (state, action) => {
      const { emailId } = action.meta.arg;
      if (!state.uploadAttachmentUniboxRequest[emailId]) {
        state.uploadAttachmentUniboxRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.uploadAttachmentUniboxRequest[emailId].status =
        RequestStatus.Pending;
      state.uploadAttachmentUniboxRequest[emailId].message = null;
      state.uploadAttachmentUniboxRequest[emailId].error = null;
    });
    builder.addCase(
      uploadAttachmentUniboxRequest.fulfilled,
      (state, action) => {
        const { emailId } = action.meta.arg;
        if (state.uploadAttachmentUniboxRequest[emailId]) {
          state.uploadAttachmentUniboxRequest[emailId].status =
            RequestStatus.Succeeded;
          state.uploadAttachmentUniboxRequest[emailId].message =
            action.payload.message;
          state.uploadAttachmentUniboxRequest[emailId].error = null;
          state.uploadedAttachment[emailId] = action.payload.payload;
        }
      },
    );
    builder.addCase(uploadAttachmentUniboxRequest.rejected, (state, action) => {
      const { emailId } = action.meta.arg;
      if (state.uploadAttachmentUniboxRequest[emailId]) {
        state.uploadAttachmentUniboxRequest[emailId].status =
          RequestStatus.Failed;
        state.uploadAttachmentUniboxRequest[emailId].message =
          action.payload.message;
        state.uploadAttachmentUniboxRequest[emailId].error =
          !action.payload.isHandled && action.payload;

        state.uploadedAttachment[emailId] = {
          attachmentId: null,
          attachmentIdentifier: action.meta.arg.formData
            .get('attachmentIdentifier')
            .toString(),
        };
      }
    });

    // Delete Attachment Request [For unibox multiple reply editors]
    builder.addCase(deleteAttachmentUniboxRequest.pending, (state, action) => {
      const { emailId } = action.meta.arg;
      if (!state.deleteAttachmentUniboxRequest[emailId]) {
        state.deleteAttachmentUniboxRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.deleteAttachmentUniboxRequest[emailId].status =
        RequestStatus.Pending;
      state.deleteAttachmentUniboxRequest[emailId].message = null;
      state.deleteAttachmentUniboxRequest[emailId].error = null;
    });
    builder.addCase(
      deleteAttachmentUniboxRequest.fulfilled,
      (state, action) => {
        const { emailId } = action.meta.arg;
        if (state.deleteAttachmentUniboxRequest[emailId]) {
          state.deleteAttachmentUniboxRequest[emailId].status =
            RequestStatus.Succeeded;
          state.deleteAttachmentUniboxRequest[emailId].message =
            action.payload.message;
          state.deleteAttachmentUniboxRequest[emailId].error = null;
          state.deletedAttachmentId[emailId] = action.meta.arg.attachmentId;
        }
      },
    );
    builder.addCase(deleteAttachmentUniboxRequest.rejected, (state, action) => {
      const { emailId } = action.meta.arg;
      if (state.deleteAttachmentUniboxRequest[emailId]) {
        state.deleteAttachmentUniboxRequest[emailId].status =
          RequestStatus.Failed;
        state.deleteAttachmentUniboxRequest[emailId].message =
          action.payload.message;
        state.deleteAttachmentUniboxRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Reply Draft Discard Request
    builder.addCase(replyDraftDiscardRequest.pending, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (!state.replyDraftDiscardRequest[emailId]) {
        state.replyDraftDiscardRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.replyDraftDiscardRequest[emailId].status = RequestStatus.Pending;
      state.replyDraftDiscardRequest[emailId].error = null;
    });
    builder.addCase(replyDraftDiscardRequest.fulfilled, (state, action) => {
      const { repliedOnEmailId: emailId, hashId, isDraftTab } = action.meta.arg;
      if (state.replyDraftDiscardRequest[emailId]) {
        state.replyDraftDiscardRequest[emailId].status =
          RequestStatus.Succeeded;
        state.replyDraftDiscardRequest[emailId].error = null;
      }
      state.emailThreadList = state.emailThreadList.map((email) => {
        if (email.emailId === emailId) {
          return {
            ...email,
            unifiedScheduled: null,
          };
        }
        return email;
      });
      state.emailsList = getUniboxEmailListWithUpdatedScheduleData(
        state.emailsList,
        state.emailThreadList,
        {
          hashId,
          isDraftTab,
        },
      );
      // Adding toast here since component gets unmounted due to removal of unifiedScheduled
      toaster.success('Email has been discarded successfully.', {
        theme: Theme.New,
      });
    });
    builder.addCase(replyDraftDiscardRequest.rejected, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (state.replyDraftDiscardRequest[emailId]) {
        state.replyDraftDiscardRequest[emailId].status = RequestStatus.Failed;
        state.replyDraftDiscardRequest[emailId].message =
          action.payload.message;
        state.replyDraftDiscardRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Reply Schedule Cancel Request
    builder.addCase(replyScheduleCancelRequest.pending, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (!state.replyScheduleCancelRequest[emailId]) {
        state.replyScheduleCancelRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.replyScheduleCancelRequest[emailId].status = RequestStatus.Pending;
      state.replyScheduleCancelRequest[emailId].error = null;
    });
    builder.addCase(replyScheduleCancelRequest.fulfilled, (state, action) => {
      const { repliedOnEmailId: emailId, hashId, isDraftTab } = action.meta.arg;
      if (state.replyScheduleCancelRequest[emailId]) {
        state.replyScheduleCancelRequest[emailId].status =
          RequestStatus.Succeeded;
        state.replyScheduleCancelRequest[emailId].error = null;
      }
      state.emailThreadList = state.emailThreadList.map((email) => {
        if (email.emailId === emailId) {
          return {
            ...email,
            unifiedScheduled: action.payload.payload,
          };
        }
        return email;
      });

      state.emailsList = getUniboxEmailListWithUpdatedScheduleData(
        state.emailsList,
        state.emailThreadList,
        {
          hashId,
          isDraftTab,
        },
      );
      toaster.success('Schedule has been canceled successfully.', {
        theme: Theme.New,
      });
    });
    builder.addCase(replyScheduleCancelRequest.rejected, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (state.replyScheduleCancelRequest[emailId]) {
        state.replyScheduleCancelRequest[emailId].status = RequestStatus.Failed;
        state.replyScheduleCancelRequest[emailId].message =
          action.payload.message;
        state.replyScheduleCancelRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Reply Schedule Request
    builder.addCase(replyScheduleRequest.pending, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (!state.replyScheduleRequest[emailId]) {
        state.replyScheduleRequest[emailId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.replyScheduleRequest[emailId].status = RequestStatus.Pending;
      state.replyScheduleRequest[emailId].error = null;
    });
    builder.addCase(replyScheduleRequest.fulfilled, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (state.replyScheduleRequest[emailId]) {
        state.replyScheduleRequest[emailId].status = RequestStatus.Succeeded;
        state.replyScheduleRequest[emailId].error = null;
      }
      state.emailThreadList = state.emailThreadList.map((email) => {
        if (email.emailId === emailId) {
          return {
            ...email,
            unifiedScheduled: action.payload.payload,
          };
        }
        return email;
      });
    });
    builder.addCase(replyScheduleRequest.rejected, (state, action) => {
      const emailId = action.meta.arg.repliedOnEmailId;
      if (state.replyScheduleRequest[emailId]) {
        state.replyScheduleRequest[emailId].status = RequestStatus.Failed;
        state.replyScheduleRequest[emailId].message = action.payload.message;
        state.replyScheduleRequest[emailId].error =
          !action.payload.isHandled && action.payload;
      }
    });

    // Get Email Categories
    builder.addCase(getEmailCategoriesRequest.pending, (state) => {
      state.getEmailCategoriesRequest.status = RequestStatus.Pending;
      state.getEmailCategoriesRequest.error = null;
    });
    builder.addCase(getEmailCategoriesRequest.fulfilled, (state, action) => {
      state.getEmailCategoriesRequest.status = RequestStatus.Succeeded;
      state.getEmailCategoriesRequest.message = action.payload.message;
      state.emailCategories = action.payload.payload;
    });
    builder.addCase(getEmailCategoriesRequest.rejected, (state, action) => {
      state.getEmailCategoriesRequest.status = RequestStatus.Failed;
      state.getEmailCategoriesRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Email Categories
    builder.addCase(updateEmailCategoryRequest.pending, (state) => {
      state.updateEmailCategoryRequest.status = RequestStatus.Pending;
      state.updateEmailCategoryRequest.error = null;
    });
    builder.addCase(updateEmailCategoryRequest.fulfilled, (state, action) => {
      state.updateEmailCategoryRequest.status = RequestStatus.Succeeded;
      state.updateEmailCategoryRequest.message = action.payload.message;

      const hashIds = action.meta.arg.emailThreadIds;
      const { categoryId } = action.meta.arg;
      state.emailsList = state.emailsList.map((email) => {
        if (hashIds.includes(email.hashId)) {
          return {
            ...email,
            categoryId,
          };
        }
        return email;
      });
    });
    builder.addCase(updateEmailCategoryRequest.rejected, (state, action) => {
      state.updateEmailCategoryRequest.status = RequestStatus.Failed;
      state.updateEmailCategoryRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Mark Conversation As Read
    builder.addCase(markEmailConversationAsRead.pending, (state) => {
      state.markEmailConversationAsRead.status = RequestStatus.Pending;
      state.markEmailConversationAsRead.error = null;
    });
    builder.addCase(markEmailConversationAsRead.fulfilled, (state, action) => {
      state.markEmailConversationAsRead.status = RequestStatus.Succeeded;
      state.markEmailConversationAsRead.message = action.payload.message;
    });
    builder.addCase(markEmailConversationAsRead.rejected, (state, action) => {
      state.markEmailConversationAsRead.status = RequestStatus.Failed;
      state.markEmailConversationAsRead.error =
        !action.payload.isHandled && action.payload;
    });

    // Mark Conversation As UnRead
    builder.addCase(markEmailConversationAsUnRead.pending, (state) => {
      state.markEmailConversationAsUnRead.status = RequestStatus.Pending;
      state.markEmailConversationAsUnRead.error = null;
    });
    builder.addCase(
      markEmailConversationAsUnRead.fulfilled,
      (state, action) => {
        state.markEmailConversationAsUnRead.status = RequestStatus.Succeeded;
        state.markEmailConversationAsUnRead.message = action.payload.message;
      },
    );
    builder.addCase(markEmailConversationAsUnRead.rejected, (state, action) => {
      state.markEmailConversationAsUnRead.status = RequestStatus.Failed;
      state.markEmailConversationAsUnRead.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Email Conversations
    builder.addCase(deleteEmailConversationRequest.pending, (state) => {
      state.deleteEmailConversationRequest.status = RequestStatus.Pending;
      state.deleteEmailConversationRequest.error = null;
    });
    builder.addCase(
      deleteEmailConversationRequest.fulfilled,
      (state, action) => {
        state.deleteEmailConversationRequest.status = RequestStatus.Succeeded;
        state.deleteEmailConversationRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      deleteEmailConversationRequest.rejected,
      (state, action) => {
        state.deleteEmailConversationRequest.status = RequestStatus.Failed;
        state.deleteEmailConversationRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Bulk Mark As Read Or Unread
    builder.addCase(bulkMarkAsReadUnreadRequest.pending, (state) => {
      state.bulkMarkAsReadUnreadRequest.status = RequestStatus.Pending;
      state.bulkMarkAsReadUnreadRequest.error = null;
    });
    builder.addCase(bulkMarkAsReadUnreadRequest.fulfilled, (state, action) => {
      state.bulkMarkAsReadUnreadRequest.status = RequestStatus.Succeeded;
      state.bulkMarkAsReadUnreadRequest.message = action.payload.message;
    });
    builder.addCase(bulkMarkAsReadUnreadRequest.rejected, (state, action) => {
      state.bulkMarkAsReadUnreadRequest.status = RequestStatus.Failed;
      state.bulkMarkAsReadUnreadRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Bulk Delete
    builder.addCase(bulkDeleteRequest.pending, (state) => {
      state.bulkDeleteRequest.status = RequestStatus.Pending;
      state.bulkDeleteRequest.error = null;
    });
    builder.addCase(bulkDeleteRequest.fulfilled, (state, action) => {
      state.bulkDeleteRequest.status = RequestStatus.Succeeded;
      state.bulkDeleteRequest.message = action.payload.message;
    });
    builder.addCase(bulkDeleteRequest.rejected, (state, action) => {
      state.bulkDeleteRequest.status = RequestStatus.Failed;
      state.bulkDeleteRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Bulk Assign Category
    builder.addCase(bulkCategoryAssignRequest.pending, (state) => {
      state.bulkCategoryAssignRequest.status = RequestStatus.Pending;
      state.bulkCategoryAssignRequest.error = null;
    });
    builder.addCase(bulkCategoryAssignRequest.fulfilled, (state, action) => {
      state.bulkCategoryAssignRequest.status = RequestStatus.Succeeded;
      state.bulkCategoryAssignRequest.message = action.payload.message;
    });
    builder.addCase(bulkCategoryAssignRequest.rejected, (state, action) => {
      state.bulkCategoryAssignRequest.status = RequestStatus.Failed;
      state.bulkCategoryAssignRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetGetEmailInboxRequestStatus,
  resetEmailInboxState,
  resetUnifiedInboxEmailThreadActivity,
  resetUpdateEmailCategoryRequest,
  resetMarkEmailConversationAsRead,
  resetMarkEmailConversationAsUnRead,
  setUnifiedInboxFilters,
  resetUnifiedInboxFilters,
  resetDeleteEmailConversationRequest,
  updateReadStatus,
  updateIsEmailSelected,
  updateUniboxEmailScheduleData,
  clearIsEmailSelected,
  resetBulkMarkAsReadUnreadRequest,
  resetBulkCategoryAssignRequest,
  resetBulkDeleteRequest,
  setShowMarkAsReadMessage,
  resetShowMarkAsReadMessage,
  resetFilterData,
  resetReplyForwardDraftRequest,
  resetReplyDraftDiscardRequest,
  resetReplyScheduleCancelRequest,
  resetUploadAttachmentUniboxRequest,
  resetDeleteAttachmentUniboxRequest,
} = unifiedInboxSlice.actions;

export default unifiedInboxSlice.reducer;
