import { EmailAccountsSortBy } from '../../../../../enums/email-account';

export const getColDataFieldForSorting = (
  sortByKey: EmailAccountsSortBy,
): string => {
  if (sortByKey === EmailAccountsSortBy.EmailSent) {
    return 'emailSent';
  }

  return 'healthScore';
};
