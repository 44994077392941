import validator from 'validator';
import passwordValidator from '../../../../shared/utils/password-validator';

export const validate = (name, value) => {
  switch (name) {
    case 'email':
      if (!validator.isEmail(value)) {
        return 'Please enter a valid email address';
      }
      break;

    case 'password':
      if (!passwordValidator.all(value)) {
        return 'Password must contain at least one upper case, lowercase and a number';
      }
      break;
    default:
      break;
  }

  return '';
};
