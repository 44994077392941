import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { Status } from '../../../../../../shared/enums/status';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { showGeneralErrorNotification } from '../../../../../../shared/utils/errors';
import { planError } from '../../../../../../shared/utils/errors/plan-permission-error/plan-error';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import store from '../../../../../../store';
import DefaultRampUpSettings from '../../../../enums/default-rampup-settings';
import {
  EmailAccountMethod,
  EmailAccountType,
} from '../../../../enums/email-account';
import { EmailAccountSettingCode } from '../../../../enums/email-account-settings';

import { EmailAccountInfo, RampUpSettings } from './types';

export const getEmailAccountId = (id: string): number => {
  const state = store.getState();
  const { emailAccounts } = state.emailAccount;

  return emailAccounts.find(({ hashId }) => hashId === id)?.id;
};

export const getEmailAccountMethod = (emailAccount) =>
  emailAccount?.type === EmailAccountType.Gmail
    ? EmailAccountMethod.Gmail
    : EmailAccountMethod.Microsoft;

export const getEmailAccountInfo = (emailAccount): EmailAccountInfo => {
  let emailAccountInfo = {
    name: '',
    email: '',
    accountType: '',
    isConnected: 0,
    method: EmailAccountMethod.Gmail,
  };

  if (emailAccount) {
    const { fromName, fromEmail, type, status } = emailAccount;

    emailAccountInfo = {
      name: fromName || '',
      email: fromEmail || '',
      accountType: type === EmailAccountType.Gmail ? 'google' : 'windows',
      isConnected: status,
      method:
        type === EmailAccountType.Gmail
          ? EmailAccountMethod.Gmail
          : EmailAccountMethod.Microsoft,
    };
  }

  return emailAccountInfo;
};

export const getSettingsForEmailAccount = (
  emailAccountSettings,
): RampUpSettings => {
  let rampUpSettings = {
    rampUpStatus: 0,
    rampUpPercent: DefaultRampUpSettings.RampUpPercent,
    initialSendingLimit: DefaultRampUpSettings.InitialSendingLimit,
    rampUpLimit: '0',
    maxSendingLimit: '0',
    rampUpLimitReached: false,
    minInterval: 0,
    maxInterval: 0,
  };

  const rampUpStatus = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.RampUpStatus,
  )[0]?.value;
  const rampUpPercent = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.RampUpPercent,
  )[0]?.value;
  const initialSendingLimit = emailAccountSettings?.filter(
    (settings) =>
      settings.code === EmailAccountSettingCode.RampUpInitialSendingLimit,
  )[0]?.value;
  const rampUpLimit = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.RampUpLimit,
  )[0]?.value;
  const maxSendingLimit = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.MaxSendingLimit,
  )[0]?.value;
  const minInterval = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.MinInterval,
  )[0]?.value;
  const maxInterval = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.MaxInterval,
  )[0]?.value;

  rampUpSettings = {
    rampUpStatus: Number(rampUpStatus) || 0,
    rampUpPercent: Number(rampUpPercent) || DefaultRampUpSettings.RampUpPercent,
    initialSendingLimit:
      Number(initialSendingLimit) || DefaultRampUpSettings.InitialSendingLimit,
    rampUpLimit: rampUpLimit || '0',
    maxSendingLimit: maxSendingLimit || '0',
    rampUpLimitReached:
      Boolean(rampUpStatus) && rampUpLimit === maxSendingLimit,
    minInterval: Number(minInterval) || 0,
    maxInterval: Number(maxInterval) || 0,
  };

  return rampUpSettings;
};

export const showGeneralErrorMessage = (requestError) => {
  if (requestError) {
    showGeneralErrorNotification(requestError.message);
  }
};

export const getIsRequestPending = (status) => status === RequestStatus.Pending;

export const getErrorMessage = (error) => (error ? error.message : '');

export const showPlanErrorForSubscription = () => {
  if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
    planError(2009);
  } else {
    planError(3009);
  }
};

export const getShouldSendingSettingsTabDisabled = (emailAccount) =>
  emailAccount?.type === EmailAccountType.SmtpImap &&
  emailAccount?.status === Status.Inactive;

export const handleLoading = (isLoading) => {
  if (isLoading) {
    store.dispatch(showLoading());
  } else {
    store.dispatch(hideLoading());
  }
};

export const checkSubscriptionPlan = ({
  subscriptionPlan,
  setDoNotHaveSubscription,
}) => {
  if (subscriptionPlan === SubscriptionPlans.FREE) {
    setDoNotHaveSubscription(true);
  } else {
    setDoNotHaveSubscription(false);
  }
};

export const onTabChange = (
  tabKey: string,
  UpdateEmailTabKeys,
  setShowSmtpModalOnDisconnect,
  history,
  hashId,
) => {
  if (tabKey === UpdateEmailTabKeys.EMAIL_SETUP_SCORE) {
    setShowSmtpModalOnDisconnect(true);
  } else {
    setShowSmtpModalOnDisconnect(false);
  }

  history.push(`/settings/email-accounts/${hashId}/${tabKey}`);
};

export const connectEmailAccountHandler = (
  subscriptionPlan,
  emailAccount,
  emailAccountId,
  sendGetSmtpImapAccountDetailsRequest,
  sendConnectEmailAccountRequest,
) => {
  if (subscriptionPlan === SubscriptionPlans.FREE) {
    showPlanErrorForSubscription();
  } else if (emailAccount?.type === 3) {
    sendGetSmtpImapAccountDetailsRequest(emailAccountId);
  } else {
    sendConnectEmailAccountRequest(
      getEmailAccountMethod(emailAccount),
      emailAccountId,
    );
  }
};

const shouldRefreshEmailSetupScoreValue = 'shouldRefreshEmailSetupScoreValue';

export const getShouldRefreshEmailSetupScoreValue = () =>
  localStorage.getItem(shouldRefreshEmailSetupScoreValue);

export const setShouldRefreshEmailSetupScoreValue = (score: number) =>
  localStorage.setItem(
    shouldRefreshEmailSetupScoreValue,
    score <= 10 ? 'true' : 'false',
  );

export const clearShouldRefreshEmailSetupScoreValue = () =>
  localStorage.removeItem(shouldRefreshEmailSetupScoreValue);
