import React from 'react';
import classNames from 'classnames';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import Timer from '../timer/index';
import { IProps, IState } from './types';
import { VerificationCodeConfig } from '../../../../enums/verification-code-config';
import toaster from '../../../../../../shared/toaster';
import { showGeneralErrorNotification } from '../../../../../../shared/utils/errors';
import Icon from '../../../../../../shared/design-system/components/atoms/icon/icon';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';

class ResendVerificationCode extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.showTimer = this.showTimer.bind(this);
    this.hideTimer = this.hideTimer.bind(this);
    this.onResendUserVerificationCodeClick = this.onResendUserVerificationCodeClick.bind(
      this,
    );

    this.state = { showTimer: true };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      resendUserVerificationCodeRequestStatus,
      resendUserVerificationCodeRequestMessage,
      resendUserVerificationCodeRequestError,
    } = this.props;

    if (
      resendUserVerificationCodeRequestStatus !==
      prevProps.resendUserVerificationCodeRequestStatus
    ) {
      if (resendUserVerificationCodeRequestStatus === RequestStatus.Succeeded) {
        this.showTimer();
        toaster.success(resendUserVerificationCodeRequestMessage);
      }

      if (
        resendUserVerificationCodeRequestStatus === RequestStatus.Failed &&
        resendUserVerificationCodeRequestError
      ) {
        showGeneralErrorNotification(
          resendUserVerificationCodeRequestError.message,
        );
      }
    }
  }

  onResendUserVerificationCodeClick() {
    const { email, sendResendUserVerificationCodeRequest } = this.props;
    sendResendUserVerificationCodeRequest(email);
  }

  showTimer() {
    this.setState({ showTimer: true });
  }

  hideTimer() {
    this.setState({ showTimer: false });
  }

  render() {
    const { showTimer } = this.state;
    const { resendUserVerificationCodeRequestStatus } = this.props;

    const isLoading =
      resendUserVerificationCodeRequestStatus === RequestStatus.Pending;

    let resendVerificationCodeLinkOrTimer: React.ReactNode;

    if (showTimer) {
      resendVerificationCodeLinkOrTimer = (
        <Timer
          seconds={VerificationCodeConfig.ConsecutiveRequestDelay}
          onTimerFinish={this.hideTimer}
        />
      );
    } else {
      const resendCodeOptionClass = classNames([
        {
          'verification-code-resend': !isLoading,
          'verification-code-resend-disabled': isLoading,
        },
      ]);

      resendVerificationCodeLinkOrTimer = (
        <>
          <span
            className={resendCodeOptionClass}
            {...accessibleOnClick(
              isLoading ? undefined : this.onResendUserVerificationCodeClick,
            )}
          >
            Resend Code
          </span>
          {isLoading && (
            <span className="verification-code-resend-loading">
              <Icon identifier="spinner-alt" className="icon-loading" />
            </span>
          )}
        </>
      );
    }

    return (
      <small className="verification-code">
        <span>Didn't get verification code?</span>
        <span className="verification-code-resend-option-wrapper">
          {resendVerificationCodeLinkOrTimer}
        </span>
      </small>
    );
  }
}

export default ResendVerificationCode;
