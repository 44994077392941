import { Browser } from '../enums/browsers';

export function detectBrowser() {
  if (
    (navigator.userAgent.indexOf(Browser.Opera) ||
      navigator.userAgent.indexOf('OPR')) != -1
  ) {
    return Browser.Opera;
  } else if (navigator.userAgent.indexOf(Browser.Chrome) != -1) {
    return Browser.Chrome;
  } else if (navigator.userAgent.indexOf(Browser.Safari) != -1) {
    return Browser.Safari;
  } else if (navigator.userAgent.indexOf(Browser.Firefox) != -1) {
    return Browser.Firefox;
  } else if (navigator.userAgent.indexOf(Browser.InternetExplorer) != -1) {
    return Browser.InternetExplorer;
  } else {
    return null;
  }
}
