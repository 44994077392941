import React, { useEffect } from 'react';
import { Images } from '../app-constants';
import ImageIcon from '../components/images/image-icon';

const DragAndDrop = ({
  dropRef,
  dragging,
  setDragging,
  children,
  showAddAttachments,
}) => {
  let dragCounter = 0;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (dragCounter === 0) {
      dragCounter += 1;
    }
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (dragCounter > 0) {
      dragCounter -= 1;
    } else if (dragCounter === 0) {
      setDragging(false);
    }
  };

  useEffect(() => {
    const div = dropRef.current;
    if (showAddAttachments) {
      if (div) {
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
      }
    }

    return () => {
      if (showAddAttachments) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
      }
    };
  }, []);

  return (
    <>
      {children}
      {showAddAttachments && dragging && (
        <div className="drag-n-drop">
          <div className="drag-n-drop--icon">
            <ImageIcon src={Images.Upload} />
          </div>
          <h2 className="drag-n-drop--title">Drop to upload your attachment</h2>
          <p className="drag-n-drop--text">
            You can upload images, docs, sheets, presentations, videos or zip
            file.
          </p>
        </div>
      )}
    </>
  );
};

export default DragAndDrop;
