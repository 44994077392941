import React from 'react';
import InputNumber from '../../../../../../../../shared/design-system/components/input-number';

const DailyRampUpItem = ({
  staring_text,
  ending_text,
  suggestion_text,
  isDisabled,
  onKeyPress,
  onDropAndPaste,
  min,
  max,
  value,
  onChange,
  isError,
}) => (
  <div className={`daily-rampup ${isDisabled ? 'disabled' : ''}`}>
    <p>{staring_text}</p>
    <div className="number-input">
      <InputNumber
        onKeyPress={onKeyPress}
        onDrop={onDropAndPaste}
        onPaste={onDropAndPaste}
        min={min}
        max={max}
        step={1}
        name="dailyRampUp"
        size={InputNumber.Size.Medium}
        value={value}
        variant={isError && InputNumber.Variant.Error}
        onChange={onChange}
        disabled={isDisabled}
      />
    </div>
    <p>
      {ending_text} <span>{suggestion_text}</span>
    </p>
  </div>
);

export default DailyRampUpItem;
