import React from 'react';
import {
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonRadioProps,
} from 'react-bootstrap';
import classNames from 'classnames';
import { ForwardRef } from '../../types/forward-ref';

enum Variant {
  Default = 'default',
  Solid = 'solid',
  Outlined = 'outlined',
  OutlinedDanger = 'outline-danger',
}

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type Option = {
  label?: React.ReactNode;
  value: any;
  disabled?: boolean;
};

type RadioButtonGroupProps = Omit<
  ToggleButtonRadioProps<any>,
  'type' | 'value' | 'defaultValue' | 'onChange' | 'name' | 'size'
> & {
  variant?: Variant;
  size?: Size;
  options: Option[];
  value: any;
  onChange: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
};

type RadioButtonGroupStatic = {
  Variant: typeof Variant;
  Size: typeof Size;
};

const RadioButtonGroup = React.forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>(
  (
    { variant, options, size, value, onChange, name, className, ...rest },
    ref,
  ) => {
    if (variant === Variant.Default) {
      const radioButtonsList = options.map((option, index) => {
        const checked = option.value === value;

        const labelClass = classNames([
          'bs-radio-wrapper',
          {
            'bs-radio-wrapper-disabled': option.disabled,
          },
        ]);

        const spanClass = classNames([
          'bs-radio',
          {
            'bs-radio-checked': checked,
            'bs-radio-disabled': option.disabled,
          },
        ]);

        return (
          <label key={index} className={labelClass}>
            <span className={spanClass}>
              <input
                type="radio"
                name={name}
                checked={checked}
                disabled={option.disabled}
                className="bs-radio-input"
                value={option.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(option.value, e)
                }
              />
              <span className="bs-radio-inner" />
            </span>
            {option.label && <span>{option.label}</span>}
          </label>
        );
      });

      const radioButtonGroupClass = classNames(['rb-group', className]);

      return (
        <div className={radioButtonGroupClass} ref={ref}>
          {radioButtonsList}
        </div>
      );
    }

    let rbSize, selectedButtonVariant;

    // no need to set size for medium. react bootstrap uses medium size when
    // undefined is passed for size.
    switch (size) {
      case Size.Large:
        rbSize = 'lg';
        break;
      case Size.Small:
        rbSize = 'sm';
        break;
    }

    switch (variant) {
      case Variant.Solid:
        selectedButtonVariant = 'primary';
        break;
      case Variant.Outlined:
        selectedButtonVariant = 'outlined';
        break;
      case Variant.OutlinedDanger:
        selectedButtonVariant = 'outline-danger';
        break;
    }

    return (
      <ThemeProvider prefixes={{ btn: 'btn-solid' }}>
        <ToggleButtonGroup
          {...rest}
          name={name}
          className={className}
          type="radio"
          value={value}
          onChange={onChange}
          ref={ref}
        >
          {options.map((option, index) => (
            <ToggleButton
              key={index}
              variant={
                value === option.value ? selectedButtonVariant : 'outlined'
              }
              className={
                variant === Variant.OutlinedDanger ? 'btn-ghost-danger' : ''
              }
              value={option.value}
              size={rbSize}
              disabled={option.disabled}
            >
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </ThemeProvider>
    );
  },
) as ForwardRef<HTMLDivElement, RadioButtonGroupProps> & RadioButtonGroupStatic;

RadioButtonGroup.displayName = 'RadioButtonGroup';

RadioButtonGroup.Variant = Variant;
RadioButtonGroup.Size = Size;

RadioButtonGroup.defaultProps = {
  variant: Variant.Default,
  size: Size.Medium,
};

export default RadioButtonGroup;
