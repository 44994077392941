import React from 'react';
import { Trash } from '@saleshandy/icons';
import Papa from 'papaparse';
import type { IProps } from './types';
import ImageIcon from '../../../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../../../shared/app-constants';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const headers = [
  'Email Address',
  'Email service provider',
  'Sender name',
  'SMTP Username',
  'SMTP Password',
  'SMTP Host',
  'SMTP Port',
  'SMTP Encryption',
  'IMAP Username',
  'IMAP Password',
  'IMAP Host',
  'IMAP Port',
  'IMAP Encryption',
  'Daily quota',
  'Sending interval in Seconds (Minimum)',
  'Sending interval in Seconds (Maximum)',
  'Ramp up Email No. to start with',
  'Ramp up Percentage',
  'Bcc',
  'Signature HTMlL',
  'Owner',
];

const PostFileSelect: React.FC<IProps> = ({
  fileName,
  successCount,
  skippedCount,
  skRows,
  onRemove,
}) => {
  const onSkippedCSVDownload = () => {
    const csvData = Papa.unparse({
      fields: headers,
      data: skRows,
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link element to download the CSV file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Bulk Email Import Failure Report.csv');
    link.click();
  };

  return (
    <div className="post-container d-flex flex-column justify-content-center align-items-center">
      <div className="icon-wrapper">
        <ImageIcon src={Images.Sheets} />
      </div>

      <span className="regular-2 font-medium popover-arrow-color-txt mt-2">
        {fileName}
      </span>

      <div className="d-flex mt-2">
        <div>
          <ImageIcon src={Images.ChecksGreen} />
          <span className="regular-1 font-medium green-txt-14 ml-1">
            Successfully imported:{' '}
            <span className="semibold-1 popover-arrow-color-txt">
              {successCount}
            </span>
          </span>
        </div>

        <div className="ml-3">
          <ImageIcon src={Images.AlertTriangle} />
          <span className="regular-1 font-medium red-txt-12 ml-1 mr-1">
            Skipped:{' '}
            <span className="semibold-1 popover-arrow-color-txt">
              {skippedCount}
            </span>
          </span>
          <ImageIcon
            src={Images.ExportGrey}
            className="pointer"
            onClick={onSkippedCSVDownload}
          />
        </div>
      </div>

      <div
        className="mt-3 d-flex align-items-center"
        {...accessibleOnClick(onRemove)}
      >
        <Trash />
        <span className="regular-1 font-medium gray-txt-15">Remove file</span>
      </div>
    </div>
  );
};

export default PostFileSelect;
