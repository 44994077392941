import store from '../../../store';
import pauseAccountError from '../errors/pause-account-error';
import SubscriptionStatus from '../../../components/settings/components/billing-subscription/enums/subscription-status';

const hasResource = (resource: string) => {
  const state = store.getState();

  const userHasResource = state.home.resources?.includes(resource);
  const subscriptionStatus = state.home.subscription?.status;

  if (subscriptionStatus === SubscriptionStatus.Paused && !userHasResource) {
    pauseAccountError.showModal();
  }
  return userHasResource;
};

export default hasResource;
