import { tz } from 'moment-timezone';

const invalidTimeZones = [
  'CST6CDT',
  'EST5EDT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'MST7MDT',
  'PST8PDT',
];

const getTimeZonesArray = () =>
  tz.names().filter((timezone) => !invalidTimeZones.includes(timezone));

export default getTimeZonesArray;
