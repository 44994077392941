/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { AlertCircle, AlertTriangle } from '@saleshandy/icons';

import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import InputNumber from '../../../../../../../../shared/design-system/components/input-number';
import { OverlayTooltip } from '../../../../../../../../shared/design-system/components/overlay';
import { Images } from '../../../../../../../../shared/app-constants';
import { FeatureUsage } from '../../../../../../../../shared/types/feature-usage';
import { getFeatureCodeValue } from '../../../../../../../../shared/utils/feature-code';
import FeatureCodes from '../../../../../../../../shared/enums/feature-codes';

const TimeIntervalSetting = ({
  isRequestPending,
  onKeyPress,
  emailAccountId,
  sendEmailTimeIntervalRequest,
  minInterval,
  maxInterval,
  isBulkUpdatingEmailAccounts = false,
  setTimerIntervalSettingErrors,
  setMinTimeIntervalBulk = null,
  setMaxTimeIntervalBulk = null,
  featureUsages,
}: {
  isRequestPending: any;
  onKeyPress: any;
  emailAccountId: any;
  sendEmailTimeIntervalRequest: any;
  minInterval: any;
  maxInterval: any;
  isBulkUpdatingEmailAccounts?: boolean;
  setTimerIntervalSettingErrors: any;
  setMinTimeIntervalBulk?: Dispatch<SetStateAction<number>>;
  setMaxTimeIntervalBulk?: Dispatch<SetStateAction<number>>;
  featureUsages: FeatureUsage[];
}) => {
  //* Global Variable
  const minSec = parseInt(
    getFeatureCodeValue(
      featureUsages,
      FeatureCodes.EmailAccountSendingMinInterval,
    )?.remainingQuota || '20',
  );
  const maxSec = 2001;
  const idealSec = 40;
  const minDiff = parseInt(
    getFeatureCodeValue(
      featureUsages,
      FeatureCodes.EmailAccountSendingMinIntervalDifference,
    )?.remainingQuota || '10',
  );

  //* State

  const [minTimeInterval, setMinTimeInterval] = useState<number>(minInterval);
  const [maxTimeInterval, setMaxTimeInterval] = useState<number>(maxInterval);
  const [error, setError] = useState<string>('');
  const [dirty, setDirty] = useState({
    minInterval: false,
    maxInterval: false,
  });
  const [isWarning, setIsWarning] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  //* Helper Function
  const isSaveButtonDisable = () =>
    (!dirty.minInterval && !dirty.maxInterval) ||
    (error && !isWarning) ||
    (minTimeInterval === minInterval && maxTimeInterval === maxInterval) ||
    isRequestPending;

  
  useEffect(() => {
    setIsDisabled(isSaveButtonDisable());
  }, [dirty, error, isWarning, minTimeInterval, maxTimeInterval, isRequestPending]);  

  // Validation function
  const validateInputs = (minValue, maxValue) => {
    if (minValue.toString() === '' && maxValue.toString() === '') {
      setError('');
      setTimerIntervalSettingErrors?.(false);
      setIsWarning(false);
      return;
    }

    const parsedMinValue = Number(minValue);
    const parsedMaxValue = Number(maxValue);

    if (parsedMinValue < minSec || parsedMinValue > maxSec) {
      setError('Time interval should be between 20 to 2000 seconds');
      setTimerIntervalSettingErrors?.(true);
      setIsWarning(false);
    } else if (parsedMinValue > parsedMaxValue) {
      setError(`There should be a minimum interval of ${minDiff} seconds`);
      setTimerIntervalSettingErrors?.(true);
      setIsWarning(false);
    } else if (parsedMinValue === parsedMaxValue) {
      setError('Minimum and maximum intervals cannot be the same');
      setTimerIntervalSettingErrors?.(true);
      setIsWarning(false);
    } else if (parsedMaxValue - parsedMinValue < minDiff) {
      setError(`There should be a minimum interval of ${minDiff} seconds`);
      setTimerIntervalSettingErrors?.(true);
      setIsWarning(false);
    } else if (parsedMinValue < idealSec) {
      setError(
        'Sending too many emails in an hour may lead to suspension or blacklisting of the account. We suggest keeping this interval at 60-190 seconds for optimal deliverability.',
      );
      setTimerIntervalSettingErrors?.(false);
      setIsWarning(true);
    } else {
      setError('');
      setTimerIntervalSettingErrors?.(false);
      setIsWarning(false);
    }
  };

  const changeMinTimeInterval = (value) => {
    if (value === '') {
      setIsWarning(false);
      setError('');
      setTimerIntervalSettingErrors?.(false);
      setMinTimeInterval(value);
      setMinTimeIntervalBulk?.(value);
      setDirty({ ...dirty, minInterval: false });

      if (maxTimeInterval) {
        setError('Time interval should be between 20 to 2000 seconds');
        setTimerIntervalSettingErrors?.(true);
      }

      return;
    }

    if (!dirty.minInterval) {
      setDirty({ ...dirty, minInterval: true });
    }

    setMinTimeInterval(value);
    setMinTimeIntervalBulk?.(value);
  };

  const changeMaxTimeInterval = (value) => {
    if (value === '') {
      setError('');
      setIsWarning(false);
      setTimerIntervalSettingErrors?.(false);

      setMaxTimeInterval(value);
      setMaxTimeIntervalBulk?.(value);
      setDirty({ ...dirty, maxInterval: false });

      if (minInterval) {
        setError('Time interval should be between 20 to 2000 seconds');
        setTimerIntervalSettingErrors?.(true);
      }

      return;
    }

    if (!dirty.minInterval) {
      setDirty({ ...dirty, minInterval: true });
    }

    setMaxTimeInterval(value);
    setMaxTimeIntervalBulk?.(value);
  };

  const handleSave = () => {
    const payload = {
      minInterval: Number(minTimeInterval),
      maxInterval: Number(maxTimeInterval),
    };

    sendEmailTimeIntervalRequest(emailAccountId, payload);
  };

  const timeIntervalClasses = classNames([
    'time-interval-wrapper',
    { 'bulk-update-email-accounts-row': isBulkUpdatingEmailAccounts },
  ]);

  useEffect(() => {
    if (dirty.minInterval || dirty.maxInterval) {
      validateInputs(minTimeInterval, maxTimeInterval);
    }
  }, [minTimeInterval, maxTimeInterval]);

  return (
    <div className={timeIntervalClasses}>
      <div className="d-flex bulk-update-email-accounts-row--inner">
        <span className="semibold-2 popover-arrow-color-txt mr-3 setting-input-label">
          Time interval between two emails (in seconds)
        </span>

        <OverlayTooltip text="The system will pick any random second from the interval you set to send your emails.">
          <img className="info-icon" src={Images.InfoCircle} alt="Info" />
        </OverlayTooltip>
      </div>

      <div className="d-flex align-items-center number-input-container">
        <InputNumber
          onKeyPress={onKeyPress}
          min={minSec}
          max={maxSec}
          step={1}
          size={InputNumber.Size.Medium}
          value={minTimeInterval}
          onChange={changeMinTimeInterval}
          variant={error && !isWarning && InputNumber.Variant.Error}
        />
        <span className="ml-2 mr-2">-</span>
        <InputNumber
          onKeyPress={onKeyPress}
          min={minSec}
          max={maxSec}
          step={1}
          size={InputNumber.Size.Medium}
          value={maxTimeInterval}
          onChange={changeMaxTimeInterval}
          variant={error && !isWarning && InputNumber.Variant.Error}
        />
        <span className="regular-1 font-medium ml-2">Seconds</span>
      </div>

      {error !== '' && isBulkUpdatingEmailAccounts && (
        <div
          className={`bulk-update-email-error-wrapper ${
            isWarning ? 'warning' : ''
          }`}
        >
          <div className="icon">
            {isWarning ? <AlertCircle /> : <AlertTriangle />}
          </div>
          <p>{error}</p>
        </div>
      )}

      {error !== '' && !isBulkUpdatingEmailAccounts && (
        <div
          className={`d-flex justify-content-center error-wrapper ${
            isWarning
              ? 'time-interval-warning-wrapper'
              : 'time-interval-error-wrapper'
          }`}
        >
          <img
            src={isWarning ? Images.AlertCircleRed : Images.AlertTriangle}
            alt="error"
            className="align-self-start mt-1"
          />
          <span className="regular-1 red-txt-12 ml-2">{error}</span>
        </div>
      )}

      {(dirty.maxInterval || dirty.minInterval) &&
        !isBulkUpdatingEmailAccounts && (
          <div className="row mt-3">
            <div className="col-md-12">
              <Button
                variant={Button.Variant.Primary}
                onClick={handleSave}
                className={`save-sending-settings header-btn mt-4 ${isDisabled ? 'disabled' : ''}`}
                isLoading={isRequestPending}
                disabled={isDisabled}
              >
                Save
              </Button>
            </div>
          </div>
        )}
    </div>
  );
};

export default TimeIntervalSetting;
