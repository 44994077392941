import React from 'react';
import Avatar from '../initials-avatar';

type IProps = {
  firstName: string;
  lastName: string;
};

export const AvatarWithName: React.FC<IProps> = ({ firstName, lastName }) => (
  <div className="avatar-with-name-container">
    <Avatar firstName={firstName} lastName={lastName} />
    <div className="name-container">
      {firstName} {lastName}
    </div>
  </div>
);
