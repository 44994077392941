import React from 'react';
import { AuthHelper } from '../utils';
import { Redirect, Route, RouteProps } from 'react-router';

type IProps = Omit<RouteProps, 'render'>;

const AuthRoute: React.FC<IProps> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !AuthHelper.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default AuthRoute;
