import api from '../../../api';
import { UpdateScheduleRequestPayload } from '../types/request-payload';

export const createSchedule = async () => api.post(`/schedules`);

export const updateSchedule = async (
  scheduleId: number,
  payload: UpdateScheduleRequestPayload,
) => api.patch(`/schedules/${scheduleId}`, payload);

export const getSchedules = async () => api.get(`/schedules`);

export const deleteSchedule = async (scheduleId: number) =>
  api.delete(`/schedules/${scheduleId}`);

export const setDefaultSchedule = async (scheduleId: number) =>
  api.get(`/schedules/${scheduleId}/make-default`);
