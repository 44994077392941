import { useTranslation } from 'react-i18next';
import { Permissions } from '../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../shared/utils/access-control/has-permission';
import store from '../../../store';
import {
  isPlanDowngradedFromPaid,
  isPlanDowngradedFromTrial,
} from '../../home/utils/plan-transition';

export const getFreePlanBannerString = (
  isEmailAccountPage = false,
  isTeamPage = false,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const { home } = store.getState();
  let string: string;

  if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
    if (isPlanDowngradedFromTrial(home.subscription?.planTransitionDetails)) {
      string = t('messages.trail_expired');

      if (isEmailAccountPage) {
        string = t('messages.trail_plan_expired_email_account_page_message');
      }
      if (isTeamPage) {
        string = t('messages.trial_plan_expired_team_page_message');
      }
    }
    if (isPlanDowngradedFromPaid(home.subscription?.planTransitionDetails)) {
      if (isTeamPage) {
        string = t('messages.plan_expired_team_page_message');
      } else if (isEmailAccountPage) {
        string = t('messages.subscription_expired_email_account_page_message');
      } else {
        string = t('messages.subscription_ended_message');
      }
    }
  } else {
    if (isPlanDowngradedFromTrial(home.subscription?.planTransitionDetails)) {
      string = t('messages.trail_expired');

      if (isEmailAccountPage) {
        string = t('messages.trail_plan_expired_email_account_page_message');
      }
    }
    if (isPlanDowngradedFromPaid(home.subscription?.planTransitionDetails)) {
      string = t('messages.subscription_ended_message');

      if (isEmailAccountPage) {
        string = t('messages.subscription_expired_email_account_page_message');
      }
    }
  }

  return string;
};

export default getFreePlanBannerString;
