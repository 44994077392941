import {
  ModifyPlanAction,
  PlanType,
  SubscriptionPlanModes,
  SubscriptionPlans,
} from '../../../../../../shared/utils/subscription-plans';

export enum ModifySubscriptionSteps {
  SelectPlan = 'select plan',
  SelectSlots = 'select slots',
}

export type SetPlanDetails = {
  planCode?: SubscriptionPlans;
  planType?: PlanType;
  planMode?: SubscriptionPlanModes;
  planName?: string;
  action?: ModifyPlanAction;
};
