import React from 'react';
import notification from '../../../notification';
import ValidationError from './validation-error';

export const showValidationErrorNotification = (messages: string[]) => {
  notification.error({
    header: 'Validation Error!',
    content: <ValidationError messages={messages} />,
  });
};
