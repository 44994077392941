import React, { useEffect, useState } from 'react';
import { Button } from '@saleshandy/design-system';

import type { IProps } from './do-not-contact-list-details-input-container';

import EmailsAndDomainsInput from './emails-and-domains-input';

import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../../../shared/utils';
import toaster from '../../../../../../../../shared/toaster';
import hasPermission from '../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../shared/utils/access-control/enums/permissions';
import { isAgency } from '../../../../../../../../shared/utils/user-details';
import { getEntriesMessage } from '../../../../helper/get-entries-message';
import { getListHelperText } from '../../../../helper/get-list-helper-text';

const DoNotContactListDetailsInput: React.FC<IProps> = ({
  doNotContactListId,
  isGlobalList,

  updateDoNotContactListDetailsRequestStatus,
  updateDoNotContactListDetailsRequestError,
  sendUpdateDoNotContactListDetailsRequest,
  resetUpdateDoNotContactListDetailsRequest,
  updateDoNotContactListDetailsResponse,
}) => {
  const [emailsAndDomains, setEmailsAndDomains] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const isMaxedEmailAdded = emailsAndDomains.length > 1000;

  const onUpdateDoNotContactListDetails = () => {
    sendUpdateDoNotContactListDetailsRequest({
      id: doNotContactListId,
      items: emailsAndDomains,
    });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: updateDoNotContactListDetailsRequestStatus,
      onSuccess: () => {
        if (updateDoNotContactListDetailsResponse) {
          if (
            updateDoNotContactListDetailsResponse?.successfullyAddedItems ===
              0 &&
            updateDoNotContactListDetailsResponse?.skippedItemsDueToLimitExceeded >
              0
          ) {
            toaster.error('Operation Failed : DNC Limit has been reached');
          } else {
            toaster.success(
              getEntriesMessage('added', null, emailsAndDomains, false),
            );
          }
        }
        setEmailsAndDomains([]);
        setIsError(false);
        resetUpdateDoNotContactListDetailsRequest();
      },
      onFailed: () => {
        resetUpdateDoNotContactListDetailsRequest();
        executeOnErrorWithErrorCheck({
          error: updateDoNotContactListDetailsRequestError,
          onError: () => {
            toaster.error(updateDoNotContactListDetailsRequestError?.message);
          },
        });
      },
    });
  }, [updateDoNotContactListDetailsRequestStatus]);

  const isLoading = getIsRequestPending(
    updateDoNotContactListDetailsRequestStatus,
  );

  const isAgencyUser = hasPermission(Permissions.CLIENT_READ) && isAgency();

  return (
    <div className="do-not-contact-list-details--input-wrapper">
      <p className="input-label">Enter emails or domains</p>

      <EmailsAndDomainsInput
        emailsAndDomains={emailsAndDomains}
        setEmailsAndDomains={setEmailsAndDomains}
        isError={isError}
        setIsError={setIsError}
        isMaxedEmailAdded={isMaxedEmailAdded}
      />

      <div className="helper-alert-warning-wrapper">
        <p>
          {getListHelperText({
            isAgencyUser,
            isGlobalList,
            messageSpecificFor: 'user',
          })}
        </p>
      </div>

      <Button
        onClick={onUpdateDoNotContactListDetails}
        isLoading={isLoading}
        disabled={
          isLoading ||
          emailsAndDomains.length === 0 ||
          isMaxedEmailAdded ||
          isError
        }
        loadingText="Saving..."
      >
        Save
      </Button>
    </div>
  );
};

export default DoNotContactListDetailsInput;
