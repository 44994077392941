import React from 'react';
import Button from '../../../../../../../shared/design-system/components/atoms/button';
import ConfirmationModalV3 from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';

const DeleteUserModal = ({ show, onSubmit, onClose, isLoading }) => (
  <ConfirmationModalV3
    show={show}
    iconType={ConfirmationModalIconType.TRASH_RED}
    title="Delete User"
    className="action-modal"
    contents={[
      'Are you sure you want to delete the selected user permanently?',
      'If You delete this user, User will no longer be a part of a Saleshandy Account.',
    ]}
    cancelButtonText="Cancel"
    submitButtonText="Delete"
    submitButtonVariant={Button.Variant.Danger}
    onSubmit={onSubmit}
    onClose={onClose}
    submitButtonClassName="delete-btn"
    cancelButtonClassName="cancel-btn"
    isSubmitDisabled={isLoading}
    isSubmitLoading={isLoading}
    showCloseIcon={false}
  />
);

export default DeleteUserModal;
