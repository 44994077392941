import api from '../../../api';
import { UpdateCustomDomainPayload } from '../components/custom-domain/types';

export const getCustomDomains = async () => api.get('/custom-domain');
export const getCustomDomainsList = async () => api.get('/custom-domain/list');
export const createCustomDomain = async (
  domain: string,
  emailAccountIds?: number[],
) => api.post('/custom-domain', { domain, emailAccountIds });

export const deleteCustomDomain = async (userCustomDomainId: number) =>
  api.delete(`/custom-domain/${userCustomDomainId}`);

export const updateCustomDomain = async ({
  userCustomDomainId,
  ...rest
}: UpdateCustomDomainPayload) =>
  api.patch(`/custom-domain/${userCustomDomainId}`, rest);

export const setDefaultCustomDomain = async (userCustomDomainId: number) =>
  api.get(`/custom-domain/${userCustomDomainId}/make-default`);
