import React, { useMemo } from 'react';
import { OverlayTooltip, Placement } from '../../overlay';
import Icon from '../icon';
import './filter-tab.scss';

type IProps = {
  name: string;
  filters: any[];
  className?: string;
  itemId: number;
  onCancel: VoidFunction;
  /**
   * To Separate grouped filters
   * Example: filters = [{name: 'Active}, {name: 'Clicked}] then They will be display in a line separating
   * by a separator like if separator is ' -  ' -> [Active - Clicked]
   */
  separator: string;
  tooltipOptions?: {
    className?: string;
    placement?: Placement;
  };
};

const FilterTab: React.FC<IProps> = ({
  name,
  filters,
  className = '',
  onCancel,
  separator,
  tooltipOptions,
}) => {
  const groupedFilters = useMemo(
    () => (
      <OverlayTooltip
        text={filters.join(', ')}
        placement={tooltipOptions?.placement || Placement.Top}
        className={`cell-card__tooltip ${tooltipOptions?.className}`}
      >
        <div>{filters.join(separator)}</div>
      </OverlayTooltip>
    ),
    [filters],
  );

  return (
    !!filters.length && (
      <div className={className}>
        <div className="filter-tab-container">
          <span className="text filter-name">{name}: </span>
          <div className="applied-filters text filter-text ml-2">
            {groupedFilters}
          </div>
          <span className="ml-2">
            <Icon
              identifier="close"
              className="close-icon close"
              onClick={onCancel}
            />
          </span>
        </div>
      </div>
    )
  );
};

export default FilterTab;
