import React from 'react';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import queryString from 'query-string';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../shared/toaster';
import { EmailAccountCallbackQuery } from '../../types/email-account-callback-query';
import store from '../../../../store';

class ConnectEmailAccountCallback extends React.Component<IProps, null> {
  componentDidMount() {
    const { match, location, sendVerifyEmailAccountRequest } = this.props;
    const { method } = match.params;
    const query = location.search;
    location.search = '';

    const { code, state, error } = queryString.parse(
      query,
    ) as EmailAccountCallbackQuery;

    if (error) {
      this.redirectToEmailAccountsListing();
    } else if (code) {
      sendVerifyEmailAccountRequest(method, code, state);
      store.dispatch(showLoading());
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      status,
      message,
      error,
      handleHasUserConnectedEmailAccount,
    } = this.props;
    if (status !== prevProps.status) {
      if (
        status === RequestStatus.Succeeded ||
        status === RequestStatus.Failed
      ) {
        store.dispatch(hideLoading());
        if (status === RequestStatus.Succeeded) {
          toaster.success(message, { theme: Theme.New });
          handleHasUserConnectedEmailAccount(true);
        }

        if (status === RequestStatus.Failed && error) {
          toaster.error(error.message, {
            theme: Theme.New,
            showCloseIcon: true,
            delay: 10000,
          });
        }

        this.redirectToEmailAccountsListing();
      }
    }
  }

  redirectToEmailAccountsListing() {
    const { history } = this.props;
    history.push('/settings/email-accounts');
  }

  render() {
    return null;
  }
}

export default ConnectEmailAccountCallback;
